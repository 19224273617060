import { IconButton } from "@mui/material";
import { ReactComponent as ChevronDoubleIcon } from "assets/svg/icons/chevron-right-double.svg";
import { useLoanContext } from "pages/Borrower/LoanDetail";
import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { selectFiles, setDocumentsToShow, unSelectFiles } from "../../loanDetailSlice";
import Category from "../Category";
import CustomizedCheckbox from "../Checkbox/Checkbox";

import CustomizedTooltip from "../Tooltip/Tooltip";
import "./styles.scss";

const DocumentList = () => {
  const dispatch = useDispatch();
  const { canMultiSelect, canAddFile } = useLoanContext();

  const {
    currentTab,
    loanDocumentSelectedFiles,
    documentsToShow,
    testLoanDocumentsData: {
      isLoading,
      data: { emptyState, documents: categories },
    },
  } = useSelector(({ loanDetail }) => loanDetail);

  const allFiles = useMemo(
    () =>
      (!!categories &&
        !!categories.length &&
        categories.reduce((acc, category) => {
          category.documents.forEach((document) => {
            if (document.subDocuments.length) {
              document.subDocuments.forEach((sub) => {
                acc.push(...sub.files);
              });
            } else {
              acc.push(...document.files);
            }
          });
          return acc;
        }, [])) ||
      [],
    [categories],
  );
  const allDocuments = useMemo(
    () =>
      (!!categories &&
        !!categories.length &&
        categories.reduce((acc, category) => {
          category.documents.forEach((document) => {
            if (document.files.length) {
              acc.push(document);
            } else if (document.subDocuments.length) {
              acc.push(document);
              document.subDocuments.map((sub) => {
                acc.push(sub);
              });
            }

            // (document.files.length || document.subDocuments.length) && acc.push(document);
          });
          return acc;
        }, [])) ||
      [],
    [categories],
  );

  const hasAllDocuments = allDocuments
    .map((document) => document.id)
    .every((doc) => documentsToShow.includes(doc));

  const handleDocumentsDisplay = () => {
    dispatch(
      setDocumentsToShow(hasAllDocuments ? [] : allDocuments.map((document) => document.id)),
    );
  };

  const handleCheck = (files) => {
    const arg = files.map((file) => file.id);

    isChecked(files) ? dispatch(unSelectFiles(arg)) : dispatch(selectFiles(arg));
  };

  const isChecked = (files) =>
    !!files.length && files.every((file) => loanDocumentSelectedFiles.includes(file.id));

  const isIndeterminate = (files) =>
    files.some((file) => loanDocumentSelectedFiles.includes(file.id)) && !isChecked(files);

  return (
    <>
      {isLoading ? (
        <Skeleton
          count={10}
          height={45}
          className={"mb-16"}
          style={{ maxWidth: "92%", marginLeft: "40px" }}
        />
      ) : emptyState !== 0 ? (
        <div className="d-flex flex-column h-90 align-items-center justify-content-center">
          <h5 className="text-align-center mb-4">
            {emptyState === 1 ? "No Documents" : "Everything’s good"}
          </h5>
          <p className="text-align-center fs-14 max-width-40ch fw-400">
            {emptyState === 1
              ? currentTab === "requiresAttention"
                ? "There are no documents uploaded yet. You can add documents using Templates or Documents Manager"
                : "There are no documents uploaded yet."
              : "If any document requires your attention or needs re-uploading it will be notified here"}
          </p>
        </div>
      ) : (
        <ul>
          <div className="py-10 grid-cols-4 bg-[#FAFAFA] border-y border-[#E5E5E5]">
            <div className="d-flex align-items-center gap-8 px-40">
              {canMultiSelect && (
                <CustomizedCheckbox
                  disabled={!allFiles.length}
                  checked={isChecked(allFiles)}
                  indeterminate={isIndeterminate(allFiles)}
                  id="selectAll"
                  onChange={() => handleCheck(allFiles)}
                />
              )}

              <CustomizedTooltip title={`${hasAllDocuments ? "Hide All Files" : "Show All Files"}`}>
                <IconButton
                  disabled={!allDocuments.length}
                  className={`doublechevron_rotate ${
                    hasAllDocuments && allDocuments.length && "open"
                  }`}
                  onClick={() => handleDocumentsDisplay(allFiles)}
                >
                  <ChevronDoubleIcon />
                </IconButton>
              </CustomizedTooltip>
              <label htmlFor="selectAll">
                <p className="documents-label">Document Info</p>
              </label>
            </div>
            <div className="pr-16 d-flex align-items-center"></div>
            <div className="updatedDate d-flex align-items-center">
              <p className="documents-label">Update Date</p>
            </div>
            {canAddFile && (
              <div className="d-flex align-items-center updatedDate">
                <p className="documents-label">Actions</p>
              </div>
            )}
          </div>
          {categories?.map(({ category, documents }, index) => (
            <Category
              key={category}
              category={category}
              documents={documents}
              index={index}
              handleCheck={handleCheck}
              isChecked={isChecked}
              isIndeterminate={isIndeterminate}
            />
          ))}
        </ul>
      )}
    </>
  );
};

export default DocumentList;
