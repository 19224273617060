import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTransactionTypes,
  setTransactionType,
  setHeaderQuestion,
  setSubQuestion1,
  setSubQuestion2,
  setShowQuestions,
  setShowNext,
  setShowBack,
  setSendData,
} from "../onboardingSlice";

import Button from "../Components/Button";
import { ReactComponent as PurchaseIcon } from "assets/svg/icons/coins-hand.svg";
import { ReactComponent as RefinanceIcon } from "assets/svg/icons/coins-swap-01.svg";
import { CircularProgress } from "@mui/material";

import "./styles.scss";

const Step2 = () => {
  const dispatch = useDispatch();
  const { PropertyAddress, TransactionType } = useSelector((state) => state.onboarding.body.form);
  const { postOnboarding, transactionTypes } = useSelector((state) => state.onboarding.body);
  const isLoading = transactionTypes.isLoading;
  useEffect(() => {
    dispatch(getTransactionTypes());
    dispatch(setShowQuestions(true));
    dispatch(setShowBack(true));
  }, [dispatch]);

  const question = "What type of transaction are you interested in financing ?";
  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);
  useEffect(() => {
    const subQuestionArray = PropertyAddress.split(",");
    dispatch(setSubQuestion1(subQuestionArray[0]));
    dispatch(
      setSubQuestion2(
        `${subQuestionArray[1]}, ${subQuestionArray[2]} ${subQuestionArray[4] || ""} ${
          subQuestionArray[5] || ""
        }`,
      ),
    );
  }, [dispatch, PropertyAddress]);

  useEffect(() => {
    if (TransactionType !== null) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ TransactionType }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [TransactionType, dispatch, postOnboarding]);

  const handleClick = (id) => {
    dispatch(setTransactionType(id));
  };

  return isLoading ? (
    <div style={{ width: "100%", height: "100%" }}>
      <CircularProgress color="secondary" size={100} />
    </div>
  ) : (
    <div className="buttonWrap">
      {transactionTypes.data.map((type) => {
        return (
          <Button
            key={type?.id}
            buttonText={type?.name}
            buttonIcon={
              type?.id === 1 ? <PurchaseIcon /> : type?.id === 2 ? <RefinanceIcon /> : null
            }
            onClick={() => handleClick(type?.id)}
            active={TransactionType === type?.id}
          />
        );
      })}
    </div>
  );
};

export default Step2;
