import * as yup from "yup";

function validateNumberField(value) {
  if (/^\$0+$/.test(value)) {
    return false;
  }
  return true;
}

export const validationSchemaProfileInfo = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z]+$/, "First name should only contain alphabetic characters")
    .required("First name is required"),
  lastName: yup
    .string()
    .matches(/^[A-Za-z]+$/, "Last name should only contain alphabetic characters")
    .required("Last name is required"),
  email: yup
    .string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email format")
    .required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  monthlyRent: yup
    .string()
    .test("custom-validation", "Value should be more than $0", validateNumberField)
    .nullable(),
});

export const validationSchemaLoanDetails = yup.object().shape({
  mortgageBalance: yup.string().nullable(),
  purchasePrice: yup
    .number()
    .transform((value, originalValue) => {
      // Remove dollar sign and commas if they exist
      if (typeof originalValue === "string") {
        return parseFloat(originalValue.replace(/[$,]/g, ""));
      }
      return value;
    })
    .min(300000, "Purchase price must be at least $300,000")
    .max(5000000, "Purchase price must be at most $5,000,000")
    .nullable(),
  estimatedValue: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string") {
        return parseFloat(originalValue.replace(/[$,]/g, ""));
      }
      return value;
    })
    .min(300000, "Estimated value must be at least $300,000")
    .max(5000000, "Estimated value must be at most $5,000,000")
    .nullable(),
  loanAmount: yup
    .mixed()
    .test(
      "loan-amount",
      "Loan amount must be between $250,000 and 100% of purchase price or estimated value",
      function (value) {
        const { purchasePrice, estimatedValue, transactionType } = this.parent;
        const maxLoanAmount = transactionType === 1 ? purchasePrice : estimatedValue;
        const numericValue = parseFloat(value.replace(/[$,]/g, ""));
        return numericValue >= 250000 && numericValue <= maxLoanAmount;
      },
    )
    .nullable(),
  loanToValueRatio: yup
    .string()
    .transform((value) => (value.endsWith("%") ? value : value + "%"))
    .test("ltv-value", "LTV must be between 1% and 100%", function (value) {
      if (value === undefined || value === null || value === "") {
        return true; // Allow empty values
      }

      const numericValue = parseFloat(value.replace("%", ""));
      return /^\d+(\.\d+)?%$/.test(value) && numericValue >= 1 && numericValue <= 100;
    })
    .nullable(),
  grossRent: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === "string") {
        return parseFloat(originalValue.replace(/[$,]/g, ""));
      }
      return value;
    })
    .min(1000, "Gross Rent must be at least $1,000")
    .max(50000, "Gross Rent must be at most $50,000")
    .nullable(),
});

export const validationSchemaLenderUser = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z]+$/, "First name should only contain alphabetic characters")
    .required("First name is required"),
  lastName: yup
    .string()
    .matches(/^[A-Za-z]+$/, "Last name should only contain alphabetic characters")
    .required("Last name is required"),
  //   phone: yup
  //     .string()
  //     .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Phone should be in the format (123) 456-7890"),
  email: yup
    .string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email format")
    .required("Email is required"),
  monthlyRent: yup
    .string()
    .test("custom-validation", "Value should be more than $0", validateNumberField)
    .nullable(),
});

export const validationSchemaChangePassword = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .test("has-uppercase", "Password must contain at least one uppercase letter", (value) =>
      /[A-Z]/.test(value),
    )
    .test("has-lowercase", "Password must contain at least one lowercase letter", (value) =>
      /[a-z]/.test(value),
    )
    .test("has-digit", "Password must contain at least one digit", (value) => /\d/.test(value))
    .test(
      "has-special-character",
      "Password must contain at least one special character",
      (value) => /[!@#$%^&*]/.test(value),
    ),
  confirmPassword: yup.string().oneOf([yup.ref("password")], "Passwords must match"),
});

export const validationSchemaRejectionReason = yup.object().shape({
  rejectionReason: yup.string().nullable(),
});

export const validationSchemaInviteUser = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z]+$/, "First name should only contain alphabetic characters")
    .required("First name is required"),
  lastName: yup
    .string()
    .matches(/^[A-Za-z]+$/, "Last name should only contain alphabetic characters")
    .required("Last name is required"),
  email: yup
    .string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email format")
    .required("Email is required"),
});
