import { useState, useRef, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { usePopper } from "react-popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Controller } from "react-hook-form";

import "./styles.css";

const SearchInput = ({
  name,
  label,
  onClick,
  options,
  searchFunc,
  type,
  required = false,
  hasOptional = true,
  defaultValue = "",
  control,
  form,
  empty,
  itsGoogle = false,
  fullWidthList = false,
}) => {
  const [showPopper, setShowPopper] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [searchText, setSearchText] = useState(defaultValue);
  const [searchList, setSearchList] = useState([]);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const handleOnClick = (id, name, email) => {
    onClick({
      id,
      name,
      email,
    });

    setShowPopper(false);
    setSearchText(name);
  };

  const handleOnClickGoogle = (option) => {
    onClick(option);

    setShowPopper(false);
    setSearchText(option);
  };

  const handleChange = async (e) => {
    const val = e.target.value;
    setSearchText(val);
    await new Promise((res) => setTimeout(res, 100));
    const res = await searchFunc(val);
    setSearchList(res);
    val.length > 0 && setShowPopper(true);
  };

  const handleFocus = () => {
    setIsFocused(true);
    inputRef.current.focus();
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className={`inputContainer`}>
      <div className="inputContainer-labelRow">
        <label htmlFor={name} className={"inputLabel"}>
          {label}
        </label>
        {!required && hasOptional && <span className="inputContainerOptional">optional</span>}
      </div>
      <div
        className={`input-wrapper ${isFocused ? "input-wrapper-focus" : ""}`}
        tabIndex={0}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={setReferenceElement}
        onClick={() => {
          if (type === "select") {
            setShowPopper(true);
          }
        }}
      >
        {form ? (
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                onFocus={handleFocus}
                onBlur={handleBlur}
                ref={inputRef}
                onChange={(e) => handleChange(e)}
                readOnly={true}
                value={field.value ? field.value.name : ""}
              />
            )}
          />
        ) : (
          <input
            type="text"
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={inputRef}
            onChange={(e) => handleChange(e)}
            value={searchText}
            readOnly={type === "select" ? true : false}
          />
        )}
        {type === "select" &&
          (showPopper ? (
            <div className="cursor-pointer">
              <ExpandLessIcon />
            </div>
          ) : (
            <div className="cursor-pointer">
              <ExpandMoreIcon />
            </div>
          ))}
      </div>
      {showPopper && (
        <ClickAwayListener
          onClickAway={() => {
            setShowPopper(false);
          }}
        >
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="listwrapper"
          >
            <ul
              className={`${
                fullWidthList
                  ? "listcontainer-full searchDropdown-minWidth-full"
                  : "listcontainer searchDropDown-minWidth"
              } `}
            >
              {type === "select" ? (
                <>
                  {options.map((option) => {
                    return (
                      <li
                        key={option.id}
                        className="listitem cursorPointer"
                        onClick={() => handleOnClick(option.id, option.name)}
                      >
                        {option.name}
                      </li>
                    );
                  })}
                  {options?.length === 0 && empty}
                </>
              ) : itsGoogle ? (
                <>
                  {searchList?.map((option) => {
                    const splitAddress = option.split(", ");
                    return (
                      <li
                        key={option}
                        className="listitem-full cursorPointer"
                        onClick={() => handleOnClickGoogle(option)}
                      >
                        <div>
                          <p className="input-placement--main">{splitAddress[0]}</p>
                          <p className="input-placement--secondary">{`${splitAddress[1]}, ${
                            splitAddress[2] || ""
                          } ${splitAddress[4] || ""}`}</p>
                        </div>
                      </li>
                    );
                  })}
                </>
              ) : (
                <>
                  {searchList?.map((option) => {
                    return (
                      <li
                        key={option.userId}
                        className="listitem cursorPointer searchDropDown-listItem"
                        onClick={() => handleOnClick(option.userId, option.fullName, option.email)}
                      >
                        <div>
                          <p className="input-placement--main">{option.fullName}</p>
                          <p className="input-placement--secondary">{option.email}</p>
                        </div>
                      </li>
                    );
                  })}
                  {searchList?.length === 0 && empty}
                </>
              )}
            </ul>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};
export default SearchInput;

// import { useState, useRef, useEffect } from "react";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import { usePopper } from "react-popper";
// import ClickAwayListener from "@mui/material/ClickAwayListener";
// import { Controller } from "react-hook-form";

// import "./styles.css";

// const DropdownSelect = ({ options, handleOnClick, empty }) => (
//   <>
//     {options.map((option) => (
//       <li
//         key={option.id}
//         className="listitem cursorPointer"
//         onClick={() => handleOnClick(option.id, option.name)}
//       >
//         {option.name}
//       </li>
//     ))}
//     {options.length === 0 && empty}
//   </>
// );

// const DropdownSearch = ({ searchList, handleOnClick, empty }) => (
//   <>
//     {searchList.map((option) => (
//       <li
//         key={option.userId}
//         className="listitem cursorPointer searchDropDown-listItem"
//         onClick={() => handleOnClick(option.userId, option.fullName, option.email)}
//       >
//         <div>
//           <p className="input-placement--main">{option.fullName}</p>
//           <p className="input-placement--secondary">{option.email}</p>
//         </div>
//       </li>
//     ))}
//     {searchList.length === 0 && empty}
//   </>
// );

// const SearchInput = ({
//   name,
//   label,
//   onClick,
//   options,
//   searchFunc,
//   type,
//   defaultValue = "",
//   control,
//   form,
//   empty,
// }) => {
//   const [showPopper, setShowPopper] = useState(false);
//   const [isFocused, setIsFocused] = useState(false);
//   const [searchText, setSearchText] = useState(defaultValue);
//   const [searchList, setSearchList] = useState([]);

//   const inputRef = useRef(null);
//   const [referenceElement, setReferenceElement] = useState(null);
//   const [popperElement, setPopperElement] = useState(null);

//   const { styles, attributes } = usePopper(referenceElement, popperElement, {
//     placement: "bottom-start",
//     modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
//   });

//   const triggerPopper = (typeCondition, showCondition) => {
//     if (typeCondition) {
//       setShowPopper(showCondition);
//     }
//   };

//   const handleInputChange = async (e) => {
//     const val = e.target.value;
//     setSearchText(val);
//     await new Promise((res) => setTimeout(res, 100));
//     const results = await searchFunc(val);
//     setSearchList(results);
//     triggerPopper(type !== "select", val.length > 0);
//   };

//   const handleInputFocus = () => setIsFocused(true);
//   const handleInputBlur = () => setIsFocused(false);

//   return (
//     <div className="inputContainer">
//       <div className="inputContainer-labelRow">
//         <label htmlFor={name} className="inputLabel">
//           {label}
//         </label>
//       </div>
//       <div
//         className={`input-wrapper ${isFocused ? "input-wrapper-focus" : ""}`}
//         tabIndex={0}
//         onFocus={handleInputFocus}
//         onBlur={handleInputBlur}
//         ref={setReferenceElement}
//         onClick={() => triggerPopper(type === "select", true)}
//       >
//         {form ? (
//           <Controller
//             name={name}
//             control={control}
//             defaultValue={defaultValue}
//             render={({ field }) => (
//               <input
//                 {...field}
//                 type="text"
//                 onFocus={handleInputFocus}
//                 onBlur={handleInputBlur}
//                 ref={inputRef}
//                 onChange={handleInputChange}
//                 readOnly
//                 value={field.value ? field.value.name : ""}
//               />
//             )}
//           />
//         ) : (
//           <input
//             type="text"
//             onFocus={handleInputFocus}
//             onBlur={handleInputBlur}
//             ref={inputRef}
//             onChange={handleInputChange}
//             value={searchText}
//             readOnly={type === "select"}
//           />
//         )}
//         {type === "select" && (showPopper ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
//       </div>
//       {showPopper && (
//         <ClickAwayListener onClickAway={() => triggerPopper(type === "select", false)}>
//           <div
//             ref={setPopperElement}
//             style={styles.popper}
//             {...attributes.popper}
//             className="listwrapper"
//           >
//             <ul className="listcontainer searchDropDown-minWidth ">
//               {type === "select" ? (
//                 <DropdownSelect options={options} handleOnClick={onClick} empty={empty} />
//               ) : (
//                 <DropdownSearch searchList={searchList} handleOnClick={onClick} empty={empty} />
//               )}
//             </ul>
//           </div>
//         </ClickAwayListener>
//       )}
//     </div>
//   );
// };

// export default SearchInput;
