import { ChevronRight } from "@mui/icons-material";

import Collapse from "@mui/material/Collapse";
import { useBorrowerContext } from "pages/Borrower/BorrowerDetail";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as Calendar } from "../../../../assets/svg/icons/calendar.svg";
import { ReactComponent as CurrencyDollarCircle } from "../../../../assets/svg/icons/currency-dollar-circle.svg";
import { ReactComponent as ThreeDotsIconWhite } from "../../../../assets/svg/icons/three-dots-icon-white.svg";
import { ReactComponent as ThreeDotsIcon } from "../../../../assets/svg/icons/three-dots-icon.svg";
import { AppRoute } from "../../../../utils/constants/ConstantsUtil";
import { formatCurrency, getFormattedDate } from "../../../../utils/shared/helpers";
import { getLoanDocumentsApi } from "../../borrowerDetailSlice";
import { usePopper } from "../../hooks/usePopper";
import BorrowerLoanDocument from "../BorrowerLoanDocument";
import Dropdown from "../Dropdown/Dropdown";
import StatusLoanBadge from "../StatusLoanBadge";
import "./styles.scss";

const DOCUMENTS_STATUS = "requiresAttention";

const Property = ({ id, propertyAddress, loanAmount, lastUpdated, status, handleArchive }) => {
  const canBeArchived = ["DocsNeeded", "Review", "Lead", "ClearToClose"].includes(status);
  const { canArchiveLoan } = useBorrowerContext();

  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { borrowerId } = useParams();
  const [showPopper, handlePopper] = usePopper(canBeArchived);
  const {
    currentTab,
    loansDocuments: { data: loansDocuments, isLoading },
  } = useSelector(({ borrowerDetail }) => borrowerDetail);

  const propertyAddressArray =
    typeof propertyAddress === "string"
      ? propertyAddress.split(",").map((item) => item.trim())
      : [];

  useEffect(() => {
    const fetchData = async () => {
      id && (await dispatch(getLoanDocumentsApi({ id, status: DOCUMENTS_STATUS })).unwrap());
    };

    const timeout = setTimeout(fetchData, 10);

    return () => {
      clearTimeout(timeout);
    };
  }, [borrowerId, dispatch, currentTab]);

  const loanDocuments =
    useMemo(
      () =>
        !isLoading && loansDocuments.length
          ? loansDocuments
              .find((document) => document.loanId === id)
              ?.documents.flatMap((doc) => doc.documents)
          : [],
      [loansDocuments],
    ) ?? [];

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <li className="font-indivisible border-radius-12 border-gray-200 bg-white py-12 px-16 mb-8 hover:bg-[#f5f5f5] cursor-pointer transition duration-150">
      <div key={id} className="d-flex gap-8 align-items-center space-between">
        <button
          className={`button-unstyled p-10 ${!loanDocuments.length ? "cursor-default" : ""}`}
          onClick={handleOpen}
        >
          <ChevronRight
            className={`chevron__rotate ${isOpen && loanDocuments.length && "open"} ${
              !loanDocuments.length && "chevron_disabled"
            }`}
            style={{ fontSize: "20px" }}
          />
        </button>
        <Link
          to={AppRoute.BORROWER + `/${borrowerId}/loan/${id}`}
          className="linkWithoutUnderline flex-grow-for-rest"
        >
          <p className="text-sm-regular font-indivisible">
            <span className="label-1 font-indivisible">{propertyAddressArray[0]}</span> <br />{" "}
            {propertyAddressArray[1]}, {propertyAddressArray[2]} {propertyAddressArray[4]}
          </p>
        </Link>
        <p className="d-flex align-items-center text-sm-regular flex-grow-for-rest">
          <Calendar className="color-gray-400 mr-4" />
          <span className="font-indivisible color-gray-900">
            {getFormattedDate(lastUpdated)[0]}
          </span>
        </p>
        <p className="d-flex align-items-center text-sm-regular flex-grow-for-rest">
          <CurrencyDollarCircle className="color-gray-400 mr-4" />
          <span className="font-indivisible color-gray-900">{formatCurrency(loanAmount)}</span>
        </p>
        <div className=" d-flex justify-content-start flex-grow-for-badge">
          <StatusLoanBadge status={status} />
        </div>
        <div>
          <Dropdown
            showPopper={showPopper}
            disabled={!canBeArchived}
            handlePopper={handlePopper}
            listContainer="templatelistcontainer"
            clickHandler={handleArchive}
            loanId={id}
            label={
              canBeArchived && canArchiveLoan ? (
                <ThreeDotsIcon style={{ fontSize: "20px" }} />
              ) : (
                <ThreeDotsIconWhite style={{ fontSize: "20px", cursor: "default" }} />
              )
            }
          />
        </div>
      </div>
      <div>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          {loanDocuments.map((document) => (
            <BorrowerLoanDocument key={document.id} {...document} />
          ))}
        </Collapse>
      </div>
    </li>
  );
};

export default Property;
