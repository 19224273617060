import { useState } from "react";

export const usePopper = (condition = null) => {
  const [showPopper, setShowPopper] = useState(false);

  const triggerFunction = condition !== null ? !!condition : true;

  const handlePopper = (value) => {
    triggerFunction && setShowPopper((state) => value ?? !state);
  };

  return [showPopper, handlePopper];
};
