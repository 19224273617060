import { BorrowerInfo } from "../BorrowerInfo";
import { PropertiesTable } from "../PropertiesTable";
import { DetailsButton } from "../DetailsButton";
import { CreditScoreBox } from "../CreditScoreBox";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getLoansOfBorrowerByStatus } from "../../../../pages/BorrowerOld/services";
import Skeleton from "react-loading-skeleton";

export function PropertiesSection() {
  const {
    user,
    filters: { status },
  } = useSelector((state) => state.borrower);
  const { id } = user || {};

  const { data, isLoading } = useQuery({
    queryKey: ["get_loans_of_borrower", id, status],
    queryFn: () => getLoansOfBorrowerByStatus({ id, status }),
    enabled: !!id,
  });

  return (
    <section className="flex-1 background-color-gray-50 nv-propertiesSection">
      <div className="container d-flex h-100 gap-24">
        {isLoading ? (
          <div className={"nv-propertiesSection__leftSide"}>
            <Skeleton count={5} height={50} className={"mb-16"} />
          </div>
        ) : (
          <div className="border-radius-12 border-gray-200 background-color-white nv-propertiesSection__leftSide">
            {data?.length > 0 ? (
              <PropertiesTable propertyList={data} />
            ) : (
              <div className={"w-100 h-100 flex-center"}>No such data</div>
            )}
          </div>
        )}
        <div className="nv-propertiesSection__rightSide">
          <h4>Info</h4>
          <BorrowerInfo />
          <DetailsButton />
          <CreditScoreBox score={user?.creditScore} />
        </div>
      </div>
    </section>
  );
}
