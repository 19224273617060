import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Header } from "wrappers/NewVersion/components/Header/index.jsx";
import { Button } from "pages/Borrower/Components/Button/Button";
import { ReactComponent as HomeIcon } from "assets/svg/icons/home-line.svg";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { getListOfUsers, setCurrentTab, getListOfLenders } from "./userSlice";
import { useEffect } from "react";
import Users from "./Tabs/Users";
import Lenders from "./Tabs/Lenders";
import FormDrawer from "./Components/FormDrawer";
import LenderDrawer from "./Components/LenderDrawer";
import { commonActions } from "wrappers/NewVersion/commonSlice";

const UserManagement = () => {
  const dispatch = useDispatch();
  const { currentTab, users, lenders, currentUser, currentUserId } = useSelector(({ user }) => {
    return user;
  });

  const handleChange = (e, newVal) => {
    dispatch(setCurrentTab(newVal));
  };
  useEffect(() => {
    const fetchData = async () => {
      await new Promise((res) => setTimeout(res, 100));
      if (currentTab === "users") {
        await dispatch(
          getListOfUsers({
            isActive: users.isActive,
            searchText: users.searchText,
            page: users.currentPage,
            sortColumnName: users.sortColumn.sortColumnName,
            sortOrder: users.sortColumn.sortColumnOrder,
          }),
        ).unwrap();
      } else if (currentTab === "lenders") {
        await dispatch(
          getListOfLenders({
            isActive: lenders.isActive,
            searchText: lenders.searchText,
            page: lenders.currentPage,
            sortColumnName: lenders.sortColumn.sortColumnName,
            sortOrder: lenders.sortColumn.sortColumnOrder,
          }),
        ).unwrap();
      }
    };
    fetchData();
  }, [
    currentTab,
    dispatch,
    lenders.currentPage,
    lenders.isActive,
    lenders.searchText,
    lenders.sortColumn.sortColumnName,
    lenders.sortColumn.sortColumnOrder,
    users.currentPage,
    users.isActive,
    users.searchText,
    users.sortColumn.sortColumnName,
    users.sortColumn.sortColumnOrder,
  ]);
  return (
    <div className="nv-document-management h-100">
      <Header />
      <section className="mainContainer">
        <div className="mainContainer__header">
          <div className="mainContainer__header--top">
            <HomeIcon />
            <div className="h1__wrapper">
              <h1>User Management</h1>
            </div>
            {currentTab === "users" ? (
              <Button
                startIcon={<AddIcon fontSize="20px" />}
                label={"User"}
                onClick={() => dispatch(commonActions.toggleUserFormDrawerVisibility())}
              />
            ) : (
              <Button
                startIcon={<AddIcon fontSize="20px" />}
                label={"Lender"}
                onClick={() => dispatch(commonActions.toggleLenderDrawerVisibility())}
              />
            )}
          </div>
        </div>
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: "#E5E5E5", width: "100%" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Users" value="users" />
              <Tab label="Lender" value="lenders" />
            </TabList>
          </Box>
          <TabPanel value="users">
            <Users />
          </TabPanel>
          <TabPanel value="lenders">
            <Lenders />
          </TabPanel>
        </TabContext>
      </section>
      <FormDrawer id={currentUser} userId={currentUserId} />
      <LenderDrawer id={currentUser} userId={currentUserId} />
    </div>
  );
};

export default UserManagement;
