import ReactFluidScroll from 'react-fluid-scroll';
import { isMobile } from 'react-device-detect';

const ReactFluidScrollComponent = ({ children }) => {
  if (isMobile) return <>{children}</>;

  return <ReactFluidScroll viscosity={0.2}>{children}</ReactFluidScroll>;
};

export default ReactFluidScrollComponent;
