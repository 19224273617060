import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

import { getCreditScore } from "api/creditScore.api";

import ArrowRight from "assets/svg/icons/ArrowRightNormal.svg";
import { ReactComponent as Download } from "assets/svg/icons/download.svg";
import { ReactComponent as Loader } from "assets/svg/icons/Loader.svg";
import Slider1 from "assets/images/Admin/slider-1.jpg";
import Slider2 from "assets/images/Admin/slider-2.jpg";
import Slider3 from "assets/images/Admin/slider-3.jpg";
import { AnimationGlare } from "components/DisplayComponents";
import { CheckBox, TextInput } from "components/InputElements";
import { SlideItem } from "components/DisplayComponents";
import { AppRoute } from "utils/constants/ConstantsUtil";

const CreditScore = () => {
  const [isAuthorised, setAuthorisation] = useState(false);
  const [ssnValue, setSSN] = useState(false);
  const [isCreditScoreLoading, setCreditScoreLoader] = useState(false);
  const [creditResponse, setCreditResponse] = useState(false);

  const loadCreditScoreHandler = () => {
    if (!ssnValue) return;

    setCreditScoreLoader(true);
    getCreditScore(ssnValue)
      .then((data) => {
        setCreditScoreLoader(false);
        setCreditResponse(data);
      })
      .catch((error) => {
        console.log(error);
        setCreditScoreLoader(false);
      });
  };

  return (
    <div className="input_box_wrapper credit_score_form">
      <AnimationGlare type="spotlight" />
      <div className="box white" data-aos="fade-up" data-aos-delay="0" data-aos-duration="700">
        <div className="title_text">Estimated Credit Score</div>
        <p className="grey_text we_need">
          We need to check your credit score to evaluate your loan eligibility
        </p>
        <CheckBox
          label="Authorise Battalion to run a credit score for you"
          onChange={(e) => {
            setAuthorisation(e.currentTarget.checked);
          }}
        />

        {isAuthorised ? (
          <>
            <div className="run_credit flex_box space_between response_stays">
              <TextInput
                label="Social Security Number"
                placeholder="KA-XXXXXX"
                disabled={isCreditScoreLoading}
                onChange={(e) => setSSN(e.currentTarget.value)}
              />
              <button
                className="button small loader_button"
                disabled={isCreditScoreLoading}
                onClick={loadCreditScoreHandler}
              >
                {isCreditScoreLoading ? (
                  <>
                    <Loader />
                    Processing
                  </>
                ) : (
                  "Run Credit"
                )}
              </button>
            </div>
            {creditResponse ? <CreditScoreBox data={creditResponse} /> : <ButtonWrap />}
          </>
        ) : (
          <ButtonWrap />
        )}
      </div>
    </div>
  );
};

const ButtonWrap = () => {
  return (
    <div className="flex_box space_between credit_button_wrap response_stays">
      <Link to={AppRoute.REGISTER} className="skip">
        Skip
        <span>
          <img src={ArrowRight} alt="ArrowRight" />
        </span>
      </Link>
      <Link to={AppRoute.ONBOARDING}>
        <button className="button back">Back</button>
      </Link>
    </div>
  );
};

const CreditScoreBox = ({ data }) => {
  return (
    <div className="credit_notification_wrap">
      <div
        className="credit_score_box"
        data-aos="fade-up"
        data-aos-offset="0"
        data-aos-duration="500"
      >
        <div className="topper">
          <div className="left">
            <div className="head_title">Credit Score</div>
            <div className="value">
              <div style={{ color: data.color }}>{data.score}</div>
              <div className="score_type">{data.scoreType}</div>
            </div>
          </div>
          <div className="right">
            <a href={data.reportUrl} target="_blank" className="button small" rel="noreferrer">
              <Download />
            </a>
          </div>
        </div>
        <div className="botter">
          <div>
            <div className="gradient_strip">
              <div className="circle_dark" style={{ left: "55%" }}></div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex_box next_button response_stays">
        <Link to={AppRoute.ONBOARDING}>
          <button className="button back">Back</button>
        </Link>
        <Link to={AppRoute.REGISTER}>
          <button className="button colored">Next</button>
        </Link>
      </div>
    </div>
  );
};

const CreditScoreSlides = () => {
  const slideData = [
    {
      title: "Technology and Human Intelligence As One",
      description:
        "Benefit from Battalion’s  machine learning algorithms to identify opportunities to finance or refinance your assets and acquire new properties. Our proprietary algorithms help you optimize your portfolio with an outstanding trade-off between risks and returns on investments.",
      image: Slider1,
    },

    {
      title: "Easy and Hassle-Free",
      description:
        "Whether you’re a seasoned or first-time real estate investor, Battalion provides you with the capital, technology, and unmatched in-house expertise to make the process as hassle-free as possible",
      image: Slider2,
    },
    {
      title: "Interest Rates As Low As 5.8% For Bridge Loan",
      description:
        "With interest rates as low as 8.99% for your bridge loan projects and 5.875% for your rental properties, we’ve got you covered.",
      image: Slider3,
    },
  ];
  return (
    <Carousel
      className="black-dots"
      autoPlay={true}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      infiniteLoop={true}
    >
      {slideData.map((item, key) => (
        <SlideItem
          key={key}
          className={"black"}
          image={item.image}
          title={item.title}
          description={item.description}
        />
      ))}
    </Carousel>
  );
};

export { CreditScore, CreditScoreSlides };
