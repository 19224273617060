const BadgeButton = ({ label, endIcon = null, color = "gray", textClassName = "" }) => {
  const badgeStyles = {
    ["gray"]: {
      bg: "background-color-gray-100-2",
      color: "color-gray-700",
    },
    defaulStyles: {
      bg: "background-color-error-50",
      color: "color-error-700",
    },
  }[color ?? "defaultStyles"];

  return (
    <div
      className={`border-radius-4 px-8 py-2 d-flex align-items-center justify-content-center gap-5 ${badgeStyles.bg}`}
    >
      <p className={`font-indivisible ${badgeStyles.color} ${textClassName}`}>{label}</p>
      {endIcon ? endIcon : null}
    </div>
  );
};

export default BadgeButton;
