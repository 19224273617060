import React, { useEffect } from "react";
import { commonActions } from "../../../../wrappers/NewVersion/commonSlice";
import { ReactComponent as CalendarIcon } from "../../../../assets/svg/icons/calendar-borrower.svg";
import { ReactComponent as PassportIcon } from "../../../../assets/svg/icons/passport.svg";
import { ReactComponent as HomeIcon } from "../../../../assets/svg/icons/home-borrower.svg";
import { ReactComponent as ColumnsIcon } from "../../../../assets/svg/icons/columns-icon.svg";
import { ReactComponent as CoinsHandIcon } from "../../../../assets/svg/icons/coins-hand-borrower.svg";
import { ReactComponent as ClockIcon } from "../../../../assets/svg/icons/clock-borrower.svg";
import { Button } from "../../../../pages/Borrower/Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "wrappers/NewVersion/components/Drawer";
import { useParams } from "react-router-dom";
import { formatCurrency } from "../../../../utils/shared/helpers";
import BorrowerDetailEditDrawer from "../BorrowerDetailEditDrawer";
import { useBorrowerContext } from "pages/Borrower/BorrowerDetail";

import "./styles.scss";
import { getTestBorrowerDetailApi } from "pages/Borrower/borrowerDetailSlice";
import dayjs from "dayjs";
import { date } from "yup/lib/locale";

const BorrowerDetailDrawer = () => {
  const dispatch = useDispatch();
  const { canEditDetails } = useBorrowerContext();
  const { borrowerDetails } = useSelector((state) => state.common);
  const { borrowerId } = useParams();

  const {
    testBorrowerDetailData: { data: borrowerDetail },
  } = useSelector(({ borrowerDetail }) => borrowerDetail);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getTestBorrowerDetailApi(borrowerId)).unwrap();
    };
    const timeout = setTimeout(fetchData, 10);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, borrowerId]);

  const handleClose = async () => {
    dispatch(commonActions.toggleBorrowerDetailsVisibility());
  };

  const handleEditDetails = async () => {
    dispatch(commonActions.toggleBorrowerEditDetailsVisibility());
  };

  const {
    dateOfBirth,
    citizenshipDisplayName,
    currentAddress,
    propertyOwnershipDisplayName,
    monthlyRent,
    locationStatusDisplayName,
    previousAddress,
  } = borrowerDetail || {};

  const currAddress = currentAddress?.split(", ");
  const prevAddress = previousAddress?.split(", ");

  const detailData = [
    {
      icon: CalendarIcon,
      title: "Date of Birth",
      value: dateOfBirth && dayjs(dateOfBirth).format("YYYY/MM/DD"),
    },
    {
      icon: PassportIcon,
      title: "Citizenship Status",
      value: citizenshipDisplayName,
    },
    {
      icon: HomeIcon,
      title: "Current Address",
      value: currentAddress && currAddress[0] + " " + currAddress[1],
    },
    {
      icon: ColumnsIcon,
      title: "Own or Rent",
      value: propertyOwnershipDisplayName,
    },
    {
      icon: CoinsHandIcon,
      title: "Monthly Rent",
      value:
        monthlyRent > 0 && propertyOwnershipDisplayName === "Rent" && formatCurrency(monthlyRent),
    },
    {
      icon: ClockIcon,
      title: "Time at this Location",
      value: locationStatusDisplayName,
    },
    {
      icon: HomeIcon,
      title: "Previous Address",
      value:
        previousAddress &&
        locationStatusDisplayName !== "2+ Years" &&
        prevAddress[0] + " " + prevAddress[1],
    },
  ];

  return (
    <>
      <Drawer
        size={"md"}
        isOpen={borrowerDetails?.isVisible}
        header={
          <div className="d-flex flex-column flex-1">
            <h4 className={"text-xl-bold"}>Personal Details</h4>
          </div>
        }
        footer={
          canEditDetails ? (
            <div className="d-flex justify-content-end">
              <Button
                type="contained"
                backgroundColor="white"
                colorTextEnabled="#344054"
                borderColor="#d0d5dd"
                onClick={handleEditDetails}
                disabled={!borrowerDetails?.isVisible}
                label={"Edit Details"}
              />
            </div>
          ) : null
        }
        handleClose={handleClose}
      >
        <div className="mt-32 loanWrapper">
          <div>
            {detailData?.map(({ icon, title, value }) => {
              const Icon = icon ?? null;
              return (
                value && (
                  <div key={title} className="d-flex space-between align-items-center mb-16 px-24">
                    <div className="d-flex align-items-center gap-24">
                      {Icon && (
                        <div className="p-8 background-color-gray-100 border-radius-4">
                          <Icon />
                        </div>
                      )}
                      <h3
                        className={`font-inter ${!icon && "ml-14 p-8"}`}
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        {title}
                      </h3>
                    </div>
                    <p className="font-inter max-w-[300px] text-end">{value}</p>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </Drawer>
      <BorrowerDetailEditDrawer />
    </>
  );
};

export default BorrowerDetailDrawer;
