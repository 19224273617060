import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Agreement from "./pages/Agreement";
import LandingPage from "./pages/LandingPage";
import OnBoarding from "./pages/OnBoarding";
import Policy from "./pages/Policy";
import StartUpPage from "./pages/StartUpPage";
import { Provider } from "./store/Context";
import { AppRoute } from "./utils/constants/ConstantsUtil";

import styled from "styled-components";
import Bridge from "./pages/Programs/Bridge";
import Construction from "./pages/Programs/Construction";
import Investor from "./pages/Programs/Investor";
import Rental from "./pages/Programs/Rental";

import DocumentManagementRoutes from "pages/DocumentManagement/routes/DocumentManagementRoutes";
import "react-toastify/dist/ReactToastify.css";
import BorrowerRoutes from "./pages/Borrower/routes/BorrowerRoutes";
import { Borrower as OldBorrower } from "./pages/BorrowerOld";
import Playground from "./pages/Playground";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import UserManagementRoutes from "./pages/UserManagement/routes/UserManagementRoutes";
import ProtectedRoute from "./routes/ProtectedRoute";
import { UserRoles } from "./utils/constants/ConstantsUtil";
import { NewVersion } from "./wrappers/NewVersion";
import { OldVersion } from "./wrappers/OldVersion";

const StyledContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
    width: 400px;
  }
  .Toastify__toast {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
    gap: 16px;
    height: 64px;
    min-width: 400px;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    box-shadow:
      0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    margin: 0px;
  }
  .Toastify__toast-body {
    padding: 0px;
  }
  .Toastify__progress-bar {
  }
  .Toastify__close-button {
    display: flex;
    height: 100%;
    width: 20px;
    color: #737373;
    justify-content: center;
    align-items: center;
  }
  .Toastify__close-button > svg {
    width: 20px;
    height: 20px;
  }
`;

function App() {
  // useEffect(() => {
  //   console.log("I AM HERE");
  //   const intervalId = setInterval(
  //     async () => {
  //       await refreshAccessToken();
  //     },
  //     15 * 60 * 1000,
  //   );

  //   return () => {
  //     console.log("unmounted");
  //     clearInterval(intervalId);
  //   };
  // }, []);
  return (
    <Provider>
      <div id="div-routing" style={{ height: "100%", width: "100%" }}>
        <Router>
          <StyledContainer
            position="top-right"
            hideProgressBar
            newestOnTop={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <Routes>
            <Route
              exact
              path={AppRoute.ROOT}
              element={
                <OldVersion>
                  <LandingPage />
                </OldVersion>
              }
            />
            <Route
              exact
              path={AppRoute.OLD_LOGIN}
              element={
                <OldVersion>
                  <StartUpPage type={AppRoute.OLD_LOGIN} />
                </OldVersion>
              }
            />
            <Route exact path={AppRoute.NEW_ACCOUNT} element={<OnBoarding step={47} />} />
            <Route exact path={AppRoute.LOGIN} element={<OnBoarding step={49} />} />
            <Route exact path={AppRoute.NEW_LOAN} element={<OnBoarding step={0} newLoan reset />} />
            <Route
              exact
              path={AppRoute.ONBOARDINGFINISH}
              element={
                <OldVersion>
                  <StartUpPage type={AppRoute.ONBOARDINGFINISH} />
                </OldVersion>
              }
            />
            {/*
                        <Route
                          exact
                          path={AppRoute.LOGINSUCCESS}
                          element={<StartUpPage type={AppRoute.LOGINSUCCESS} />}
                        />
                        */}
            <Route
              exact
              path={AppRoute.REGISTER}
              element={
                <OldVersion>
                  <StartUpPage type={AppRoute.REGISTER} />
                </OldVersion>
              }
            />
            <Route
              exact
              path={AppRoute.GETRATES}
              element={
                <OldVersion>
                  <StartUpPage type={AppRoute.GETRATES} />
                </OldVersion>
              }
            />
            <Route
              exact
              path={AppRoute.CREDIT_SCORE}
              element={
                <OldVersion>
                  <StartUpPage type={AppRoute.CREDIT_SCORE} />
                </OldVersion>
              }
            />
            <Route
              exact
              path={AppRoute.ONBOARDING}
              element={<OnBoarding step={0} reset={true} />}
            />
            {/* <Route exact path={AppRoute.COMPANY} element={<Company />} /> */}
            <Route
              exact
              path={AppRoute.AGREEMENT}
              element={
                <OldVersion>
                  <Agreement />
                </OldVersion>
              }
            />
            <Route
              exact
              path={AppRoute.RESET_PASSWORD}
              element={
                <ProtectedRoute validateRole={false} validateLogin>
                  <ResetPassword />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppRoute.POLICY}
              element={
                <OldVersion>
                  <Policy />
                </OldVersion>
              }
            />
            {/* <Route exact path={AppRoute.CONTACT} element={<Contact />} /> */}
            <Route
              exact
              path={AppRoute.RENTAL}
              element={
                <OldVersion>
                  <Rental />
                </OldVersion>
              }
            />
            <Route
              exact
              path={AppRoute.BRIDGE}
              element={
                <OldVersion>
                  <Bridge />
                </OldVersion>
              }
            />
            <Route
              exact
              path={AppRoute.CONSTRUCTION}
              element={
                <OldVersion>
                  <Construction />
                </OldVersion>
              }
            />
            <Route
              exact
              path={AppRoute.INVESTOR}
              element={
                <OldVersion>
                  <Investor />
                </OldVersion>
              }
            />
            <Route
              exact
              path={AppRoute.OLD_BORROWER}
              element={
                <ProtectedRoute validateRole={false} validateLogin>
                  <NewVersion>
                    <OldBorrower />
                  </NewVersion>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppRoute.BORROWER + "/*"}
              element={
                <ProtectedRoute validateRole={false} validateLogin>
                  <NewVersion>
                    <BorrowerRoutes />
                  </NewVersion>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppRoute.DOCUMET_MANAGEMENT + "/*"}
              element={
                <ProtectedRoute validateRole={true} validateLogin requiredRoles={[UserRoles.ADMIN]}>
                  <NewVersion>
                    <DocumentManagementRoutes />
                  </NewVersion>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={AppRoute.USER_MANAGEMENT + "/*"}
              element={
                <ProtectedRoute validateRole={true} validateLogin requiredRoles={[UserRoles.ADMIN]}>
                  <NewVersion>
                    <UserManagementRoutes />
                  </NewVersion>
                </ProtectedRoute>
              }
            />
            <Route exact path={AppRoute.PLAYGROUND + "/*"} element={<Playground />} />
          </Routes>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
