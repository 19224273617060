import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GoogleLogin from "pages/Login/components/GoogleLogin";
import { Link, useNavigate } from "react-router-dom";
import {
  setHeaderQuestion,
  setShowBack,
  setShowCreateNewAccount,
  setShowFooter,
  setShowLogin,
  setShowProgressBar,
  setShowQuestions,
  stepNext,
} from "../onboardingSlice";
import Button from "../Components/Button";
import { ReactComponent as UserIcon } from "assets/svg/icons/user-circle.svg";
import useCheckMobileScreen from "utils/hooks/useCheckMobileScreen";

const Login = ({ isAuthFlow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postOnboardingComplete } = useSelector((state) => state.onboarding.body);
  const isOnMobile = useCheckMobileScreen();

  const header = "Login";

  useEffect(() => {
    dispatch(setHeaderQuestion(header));
    dispatch(setShowQuestions(false));
  }, [dispatch, header]);

  useEffect(() => {
    dispatch(setShowFooter(false));
    dispatch(setShowLogin(false));
    dispatch(setShowCreateNewAccount(false));

    if (isAuthFlow) {
      dispatch(setShowBack(false));
      dispatch(setShowProgressBar(false));
    } else {
      dispatch(setShowBack(false));
    }
  }, [dispatch]);

  return (
    <div className="createAccountContainer">
      <div className="buttonWrap">
        <GoogleLogin buttonText="Login With Google" />
        <Button
          buttonText="Login With Email"
          buttonIcon={<UserIcon />}
          onClick={() => dispatch(stepNext())}
        />
        <div className="crossAuthLinkerWrapper">
          <p>New To Battalion?</p>
          <Link to="/new-account">Sign Up</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
