import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import { Modal } from "../../../../wrappers/NewVersion/components/Modal";
import EmailModal from "./EmailModal";
import PhoneModal from "./PhoneModal";
import { ReactComponent as Avatar } from "assets/svg/Avatar.svg";
import { ReactComponent as CloseIcon } from "assets/svg/icons/x-close.svg";
import { ReactComponent as MailIcon } from "assets/svg/icons/mail-01.svg";
import { ReactComponent as PhoneIcon } from "assets/svg/icons/phone-01.svg";

const NeedHelpModal = () => {
  const dispatch = useDispatch();
  const { needHelpModal } = useSelector((state) => state.common);

  return (
    <Modal isOpen={needHelpModal.isVisible} position={true}>
      <div className="modalContainerWrapper">
        <section className="modalContainer">
          <div className="modalContainerHeader">
            <Avatar />
            <p>We’re here to help</p>
            <div
              className="closeButton"
              onClick={() => dispatch(commonActions.toggleNeedHelpModalVisibility())}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="buttonContainer">
            <div
              className="bigButton"
              onClick={() => dispatch(commonActions.toggleEmailModalVisibility())}
            >
              <p>Email Us</p>
              <MailIcon />
            </div>
            <div
              className="bigButton"
              onClick={() => dispatch(commonActions.togglePhoneModalVisibility())}
            >
              <p>Call Us</p>
              <PhoneIcon />
            </div>
          </div>
        </section>
      </div>
      <EmailModal />
      <PhoneModal />
    </Modal>
  );
};

export default NeedHelpModal;
