export function PropertyAddress({address = ''}) {
    const indexOfFirstComma = address.indexOf(',');
    const title = address.slice(0, indexOfFirstComma);
    const desc = address.slice(indexOfFirstComma + 2, address.length)
    return (<div className="d-flex align-items-center nv-propertyAddress">
            <div className="nv-propertyAddress__img"></div>
            <div>
                <h6 className="label-1 nv-propertyAddress__title">{title}</h6>
                <p className="label-3 nv-propertyAddress__desc">{desc}</p>
            </div>
        </div>)
}