import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { sendMessage } from './api';
import {
  getTransactionTypes as getTransactionTypesApi,
  postOnboarding,
  getPropertyTypes as getPropertyTypesApi,
  getPropertyConditions as getPropertyConditionsApi,
  getOccupancyTypes as getOccupancyTypesApi,
  getContanctTypes as getContanctTypesApi,
  postOnboardingFinal,
  getPeriodTypes as getPeriodTypesApi,
} from "api/onboarding.api";

import { googleAuthAPI } from "api/auth.api";
import { fetchAddressAPI } from "api/addressComponent.api";

export const sendEmail = createAsyncThunk("messages/createMessage", async (messageData) => {
  // const response = await sendMessage(messageData);
  // return response.data;
  return messageData;
});

export const googleLogin = createAsyncThunk("auth/google", async (data) => {
  const response = await googleAuthAPI(data);
  return response;
});

export const getContactTypes = createAsyncThunk("onboarding/getContactTypes", async () => {
  const response = await getContanctTypesApi();
  return response;
});

export const getOccupancyTypes = createAsyncThunk("onboarding/getOccupancyTypes", async () => {
  const response = await getOccupancyTypesApi();
  return response;
});

export const getPropertyConditions = createAsyncThunk(
  "onboarding/getPropertyConditions",
  async () => {
    const response = await getPropertyConditionsApi();
    return response;
  },
);

export const getTransactionTypes = createAsyncThunk(
  "transactionTypes/getTransactionTypes",
  async () => {
    const response = await getTransactionTypesApi();
    return response;
  },
);

export const getAddress = createAsyncThunk("googleAddress/getAddress", async (address) => {
  const response = await fetchAddressAPI(address);

  return response;
});

export const postOnboardingNext = createAsyncThunk("onboarding/postNext", async (data) => {
  const response = await postOnboarding(data);
  return response;
});

export const postOnboardingComplete = createAsyncThunk("onboarding/postFinal", async (data) => {
  const response = await postOnboardingFinal(data);
  return response;
});

export const getPropertyTypes = createAsyncThunk("propertyTypes/getPropertyTypes", async () => {
  const response = await getPropertyTypesApi();
  return response;
});

export const getPeriodTypes = createAsyncThunk("onboarding/getPeriodTypes", async () => {
  const response = await getPeriodTypesApi();
  return response;
});
const initialState = {
  header: {
    email: {
      loading: false,
      error: null,
      data: [],
    },
    question: null,
    subQuestion1: null,
    subQuestion2: null,
    showQuestions: true,
  },
  footer: {
    showFooter: true,
    showProgressBar: true,
    total: 0,
    current: 0,
  },
  body: {
    newLoan: {
      FirstName: null,
      LastName: null,
      Email: null,
      isNewLoan: false,
    },
    sendData: null,
    currentStep: 0,
    showNext: false,
    showBack: true,
    showVerifyEmail: false,
    showLogin: false,
    showCreateNewAccount: false,
    showResendCode: false,
    showVerifyPassword: false,
    showConfirmPassword: false,
    transactionTypes: {
      isLoading: false,
      error: null,
      data: [],
    },
    googleLogin: {
      isLoading: false,
      error: null,
      data: [],
    },
    googleAddress: {
      isLoading: false,
      error: null,
      data: [],
    },
    propertyTypes: {
      isLoading: false,
      error: null,
      data: [],
    },
    postOnboarding: {
      isLoading: false,
      error: null,
      data: [],
    },
    postOnboardingComplete: {
      isLoading: false,
      error: null,
      data: [],
    },
    propertyConditions: {
      isLoading: false,
      error: null,
      data: [],
    },
    occupancyTypes: {
      isLoading: false,
      error: null,
      data: [],
    },
    contactTypes: {
      isLoading: false,
      error: null,
      data: [],
    },
    periodTypes: {
      isLoading: false,
      error: null,
      data: [],
    },
    form: {
      anonymusId: null,
      password: null,
      passwordAgain: null,
      verificationCode: null,
      PropertyAddress: null,
      TransactionType: null,
      PropertyType: null,
      ResidentialUnits: null,
      Condition: null,
      PurchasePrice: null,
      OccupancyType: null,
      OwnedPeriod: null,
      HasMortgage: null,
      MortgageBalance: null,
      EstimatedValue: null,
      LoanAmount: null,
      LTV: null,
      MonthlyRent: null,
      TransactionPast2Years: null,
      EstimatedCreditScore: null,
      HasFinanceSimultaneously: null,
      PropertyCount: null,
      Email: null,
      PhoneNumber: null,
      FirstName: null,
      LastName: null,
      ContactType: null,
      PeriodType: 1,
    },
    valid: {
      EmailIsValid: null,
      PasswordIsValid: null,
      PasswordAgainIsValid: null,
      FirstNameIsValid: null,
      LastNameIsValid: null,
      VerificationCodeIsValid: null,
    },
  },
};
const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setFormToInitialState(state, action) {
      state.body.form = {
        anonymusId: null,
        password: null,
        passwordAgain: null,
        verificationCode: null,
        PropertyAddress: action.payload,
        TransactionType: null,
        PropertyType: null,
        ResidentialUnits: null,
        Condition: null,
        PurchasePrice: null,
        OccupancyType: null,
        OwnedPeriod: null,
        HasMortgage: null,
        MortgageBalance: null,
        EstimatedValue: null,
        LoanAmount: null,
        LTV: null,
        MonthlyRent: null,
        TransactionPast2Years: null,
        EstimatedCreditScore: null,
        HasFinanceSimultaneously: null,
        PropertyCount: null,
        Email: null,
        PhoneNumber: null,
        FirstName: null,
        LastName: null,
        ContactType: null,
        PeriodType: 1,
      };
    },
    setFooterTotal(state, action) {
      state.footer.total = action.payload;
    },
    setFooterCurrent(state, action) {
      state.footer.current = action.payload;
    },
    stepNext(state) {
      state.body.currentStep++;
    },
    stepBack(state) {
      state.body.currentStep--;
    },
    setStep(state, action) {
      state.body.currentStep = action.payload;
    },
    setHeaderQuestion(state, action) {
      state.header.question = action.payload;
    },
    setAddress(state, action) {
      state.body.form.PropertyAddress = action.payload;
    },
    setTransactionType(state, action) {
      state.body.form.TransactionType = action.payload;
    },
    setSubQuestion1(state, action) {
      state.header.subQuestion1 = action.payload;
    },
    setSubQuestion2(state, action) {
      state.header.subQuestion2 = action.payload;
    },
    setShowQuestions(state, action) {
      state.header.showQuestions = action.payload;
    },
    setPurchasePrice(state, action) {
      state.body.form.PurchasePrice = action.payload;
    },
    setShowNext(state, action) {
      state.body.showNext = action.payload;
    },
    setPropertyType(state, action) {
      state.body.form.PropertyType = action.payload;
    },
    setResidentialUnits(state, action) {
      state.body.form.ResidentialUnits = action.payload;
    },
    setCondition(state, action) {
      state.body.form.Condition = action.payload;
    },
    setOccupancyType(state, action) {
      state.body.form.OccupancyType = action.payload;
    },
    setLoanAmount(state, action) {
      state.body.form.LoanAmount = action.payload;
    },
    setLTV(state, action) {
      state.body.form.LTV = action.payload;
    },
    setMonthlyRent(state, action) {
      state.body.form.MonthlyRent = action.payload;
    },
    setTransactionPast2Years(state, action) {
      state.body.form.TransactionPast2Years = action.payload;
    },
    setEstimatedCreditScore(state, action) {
      state.body.form.EstimatedCreditScore = action.payload;
    },
    setHasFinanceSimultaneously(state, action) {
      state.body.form.HasFinanceSimultaneously = action.payload;
    },
    setPropertyCount(state, action) {
      state.body.form.PropertyCount = action.payload;
    },
    setFirstName(state, action) {
      state.body.form.FirstName = action.payload;
    },
    setLastName(state, action) {
      state.body.form.LastName = action.payload;
    },
    setPhoneNumber(state, action) {
      state.body.form.PhoneNumber = action.payload;
    },
    setEmail(state, action) {
      state.body.form.Email = action.payload;
    },
    setContactType(state, action) {
      state.body.form.ContactType = action.payload;
    },
    setShowBack(state, action) {
      state.body.showBack = action.payload;
    },
    setAnonymusId(state, action) {
      state.body.form.anonymusId = action.payload;
    },
    setSendData(state, action) {
      state.body.sendData = action.payload;
    },
    setOwnedPeriod(state, action) {
      state.body.form.OwnedPeriod = action.payload;
    },
    setHasMortgage(state, action) {
      state.body.form.HasMortgage = action.payload;
    },
    setMortgageBalance(state, action) {
      state.body.form.MortgageBalance = action.payload;
    },
    setEstimatedValue(state, action) {
      state.body.form.EstimatedValue = action.payload;
    },
    setPeriodType(state, action) {
      state.body.form.PeriodType = action.payload;
    },
    setShowVerifyEmail(state, action) {
      state.body.showVerifyEmail = action.payload;
    },
    setPassword(state, action) {
      state.body.form.password = action.payload;
    },
    setPasswordAgain(state, action) {
      state.body.form.passwordAgain = action.payload;
    },
    setShowLogin(state, action) {
      state.body.showLogin = action.payload;
    },
    setShowCreateNewAccount(state, action) {
      state.body.showCreateNewAccount = action.payload;
    },
    setShowResendCode(state, action) {
      state.body.showResendCode = action.payload;
    },
    setVerificationCode(state, action) {
      state.body.form.verificationCode = action.payload;
    },
    setEmailValidity(state, action) {
      state.body.valid.EmailIsValid = action.payload;
    },
    setPasswordValidity(state, action) {
      state.body.valid.PasswordIsValid = action.payload;
    },
    setShowVerifyPassword(state, action) {
      state.body.showVerifyPassword = action.payload;
    },
    setPasswordAgainValidity(state, action) {
      state.body.valid.PasswordAgainIsValid = action.payload;
    },
    setFirstNameValidity(state, action) {
      state.body.valid.FirstNameIsValid = action.payload;
    },
    setLastNameValidity(state, action) {
      state.body.valid.LastNameIsValid = action.payload;
    },
    setVerificationCodeValidity(state, action) {
      state.body.valid.VerificationCodeIsValid = action.payload;
    },
    setShowConfirmPassword(state, action) {
      state.body.showConfirmPassword = action.payload;
    },
    setShowFooter(state, action) {
      state.footer.showFooter = action.payload;
    },
    setShowProgressBar(state, action) {
      state.footer.showProgressBar = action.payload;
    },
    resetPostOnboarding(state) {
      state.body.postOnboarding = {
        isLoading: false,
        error: null,
        data: [],
      };
    },
    setNewLoan(state, action) {
      state.body.newLoan = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendEmail.pending, (state) => {
        state.header.email.loading = true;
        state.header.email.error = null;
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.header.email.loading = false;
        state.header.email.data = action.payload;
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.header.email.loading = false;
        state.header.email.error = action.error.message;
      })
      .addCase(getTransactionTypes.pending, (state) => {
        state.body.transactionTypes.loading = true;
        state.body.transactionTypes.error = null;
      })
      .addCase(getTransactionTypes.fulfilled, (state, action) => {
        state.body.transactionTypes.loading = false;
        state.body.transactionTypes.data = action.payload;
      })
      .addCase(getTransactionTypes.rejected, (state, action) => {
        state.body.transactionTypes.loading = false;
        state.body.transactionTypes.error = action.error.message;
      })
      .addCase(getAddress.pending, (state) => {
        state.body.googleAddress.loading = true;
        state.body.googleAddress.error = null;
      })
      .addCase(getAddress.fulfilled, (state, action) => {
        state.body.googleAddress.loading = false;
        state.body.googleAddress.data = action.payload;
      })
      .addCase(getAddress.rejected, (state, action) => {
        state.body.googleAddress.loading = false;
        state.body.googleAddress.error = action.error.message;
      })
      .addCase(postOnboardingNext.pending, (state) => {
        state.body.postOnboarding.isLoading = true;
        state.body.postOnboarding.error = null;
      })
      .addCase(postOnboardingNext.fulfilled, (state, action) => {
        state.body.postOnboarding.isLoading = false;
        state.body.postOnboarding.data = action.payload;
      })
      .addCase(postOnboardingNext.rejected, (state, action) => {
        state.body.postOnboarding.isLoading = false;
        state.body.postOnboarding.error = action.error.message;
      })
      .addCase(getPropertyTypes.pending, (state) => {
        state.body.propertyTypes.isLoading = true;
        state.body.propertyTypes.error = null;
      })
      .addCase(getPropertyTypes.fulfilled, (state, action) => {
        state.body.propertyTypes.loading = false;
        state.body.propertyTypes.data = action.payload;
      })
      .addCase(getPropertyTypes.rejected, (state, action) => {
        state.body.propertyConditions.loading = false;
        state.body.propertyConditions.error = action.error.message;
      })
      .addCase(getPropertyConditions.pending, (state) => {
        state.body.propertyConditions.loading = true;
        state.body.propertyConditions.error = null;
      })
      .addCase(getPropertyConditions.fulfilled, (state, action) => {
        state.body.propertyConditions.loading = false;
        state.body.propertyConditions.data = action.payload;
      })
      .addCase(getPropertyConditions.rejected, (state, action) => {
        state.body.propertyConditions.loading = false;
        state.body.propertyConditions.error = action.error.message;
      })
      .addCase(getOccupancyTypes.pending, (state) => {
        state.body.occupancyTypes.loading = true;
        state.body.occupancyTypes.error = null;
      })
      .addCase(getOccupancyTypes.fulfilled, (state, action) => {
        state.body.occupancyTypes.loading = false;
        state.body.occupancyTypes.data = action.payload;
      })
      .addCase(getOccupancyTypes.rejected, (state, action) => {
        state.body.occupancyTypes.loading = false;
        state.body.occupancyTypes.error = action.error.message;
      })
      .addCase(getContactTypes.pending, (state) => {
        state.body.contactTypes.loading = true;
        state.body.contactTypes.error = null;
      })
      .addCase(getContactTypes.fulfilled, (state, action) => {
        state.body.contactTypes.loading = false;
        state.body.contactTypes.data = action.payload;
      })
      .addCase(getContactTypes.rejected, (state, action) => {
        state.body.contactTypes.loading = false;
        state.body.contactTypes.error = action.error.message;
      })
      .addCase(postOnboardingComplete.pending, (state) => {
        state.body.postOnboardingComplete.loading = true;
        state.body.postOnboardingComplete.error = null;
      })
      .addCase(postOnboardingComplete.fulfilled, (state, action) => {
        state.body.postOnboardingComplete.loading = false;
        state.body.postOnboardingComplete.data = action.payload;
      })
      .addCase(postOnboardingComplete.rejected, (state, action) => {
        state.body.postOnboardingComplete.loading = false;
        state.body.postOnboardingComplete.error = action.error.message;
      })
      .addCase(getPeriodTypes.pending, (state) => {
        state.body.periodTypes.loading = true;
        state.body.periodTypes.error = null;
      })
      .addCase(getPeriodTypes.fulfilled, (state, action) => {
        state.body.periodTypes.loading = false;
        state.body.periodTypes.data = action.payload;
      })
      .addCase(getPeriodTypes.rejected, (state, action) => {
        state.body.periodTypes.loading = false;
        state.body.periodTypes.error = action.error.message;
      })
      .addCase(googleLogin.pending, (state) => {
        state.body.googleLogin.loading = true;
        state.body.googleLogin.error = null;
      })
      .addCase(googleLogin.fulfilled, (state, action) => {
        state.body.googleLogin.loading = false;
        state.body.googleLogin.data = action.payload;
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.body.googleLogin.loading = false;
        state.body.googleLogin.error = action.error.message;
      });
  },
});

export const {
  setFooterTotal,
  setFooterCurrent,
  setHeaderQuestion,
  setAddress,
  setTransactionType,
  stepNext,
  stepBack,
  setSubQuestion1,
  setSubQuestion2,
  setStep,
  setShowQuestions,
  setShowNext,
  setPropertyType,
  setResidentialUnits,
  setCondition,
  setOccupancyType,
  setPurchasePrice,
  setLoanAmount,
  setLTV,
  setMonthlyRent,
  setTransactionPast2Years,
  setEstimatedCreditScore,
  setHasFinanceSimultaneously,
  setPropertyCount,
  setFirstName,
  setEmail,
  setPhoneNumber,
  setLastName,
  setContactType,
  setShowBack,
  setSendData,
  setOwnedPeriod,
  setHasMortgage,
  setMortgageBalance,
  setEstimatedValue,
  setPeriodType,
  setShowVerifyEmail,
  setPassword,
  setPasswordAgain,
  setShowLogin,
  setShowCreateNewAccount,
  setShowResendCode,
  setVerificationCode,
  setEmailValidity,
  setPasswordValidity,
  setShowVerifyPassword,
  setFirstNameValidity,
  setLastNameValidity,
  setPasswordAgainValidity,
  setShowConfirmPassword,
  setVerificationCodeValidity,
  setFormToInitialState,
  setShowFooter,
  setShowProgressBar,
  resetPostOnboarding,
  setNewLoan,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
