import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Avatar } from "@mui/material";
import { NotificationFeed } from "../NotificcationFeed";
import { ReactComponent as Logo } from "../../../../assets/svg/LogoBlack.svg";

import { ProfileMenu } from "../ProfileMenu";
import { Dropdown } from "../Dropdown";
import { SettingsDropDown } from "../SettingsDropDown";
import { KnockFeedProvider } from "@knocklabs/react-notification-feed";
import { SaveChangesDialog } from "../SaveChangesDialog";
import { PasswordChangeModal } from "../PasswordChangeModal";
import { PasswordChangeConfirmation } from "../PasswordChangeConfirmation";
import { isUserAdmin } from "utils/shared/helpers.js";
import { getEndUserProfile, getLenderUser } from "../../../../pages/UserManagement/userSlice";
import { ProfileSettingsDrawer } from "../ProfileSettingsDrawer";

export function Header({ classNames = "" }) {
  const [isVisible, setIsVisible] = useState(false);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const userLocale = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchData = async () => {
      let res = null;
      if (userLocale?.accessType !== "Admin" && userLocale?.accessType !== "Lender") {
        res = await dispatch(getEndUserProfile(userLocale?.dId)).unwrap();
      } else {
        res = await dispatch(getLenderUser(userLocale?.dId)).unwrap();
      }
      setUser(res);
    };
    fetchData();
  }, [dispatch]);

  return (
    <header className={`border-bottom-gray-200 nv-header ${classNames}`}>
      <div className="container">
        <div className="d-flex space-between align-items-center">
          <Link to={"/borrower"} className="d-block w-max-content">
            <Logo />
          </Link>
          <div className="d-flex gap-16">
            <KnockFeedProvider
              userId={user?.id}
              apiKey={process.env.REACT_APP_KNOCK_PUBLIC_API_KEY}
              feedId={process.env.REACT_APP_KNOCK_FEED_CHANNEL_ID}
            >
              <NotificationFeed />
            </KnockFeedProvider>

            {isUserAdmin() && <SettingsDropDown />}
            <Dropdown
              isVisible={isVisible}
              handleClose={() => setIsVisible(false)}
              menu={<ProfileMenu user={user} handleClose={() => setIsVisible(false)} />}
            >
              <Avatar
                src={user?.avatarUrl}
                alt={user?.firstName}
                className="avatar-text background-color-gray-100 cursor-pointer"
                sx={{
                  width: 40,
                  height: 40,
                }}
                onClick={() => setIsVisible((prevState) => !prevState)}
              >
                {user?.firstName[0].toUpperCase()}
                {user?.lastName[0].toUpperCase()}
              </Avatar>
            </Dropdown>
            <ProfileSettingsDrawer
              user={user}
              setUser={setUser}
              accessType={userLocale?.accessType}
            />
            <PasswordChangeModal />
            <PasswordChangeConfirmation />
            <SaveChangesDialog />
          </div>
        </div>
      </div>
    </header>
  );
}
