import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getTestCategories,
  updateCategory,
  deleteCategory,
  createCategory,
  getDocuments,
  archiveDocuments,
  unarchiveDocuments,
  getCategoryList,
  updateDocument,
  createDocument,
  getDocumentSpecificList,
  getTemplateList,
  deleteTemplate,
  updateTemplate,
  createTemplate,
  deleteDocumentTemplate,
  getDocumentsListAutoComplete,
  addDocument,
  addCreateDocument,
  getDummyData,
  createSubDocument,
  deleteSubDocument,
  getSDFStatuses,
  getFileStatuses,
  updateFileStatus,
  updateDocSubStatus,
} from "api/documentManagement.api";
import { getTestLoanDocuments } from "api/loan.api";

export const getTestCategoriesList = createAsyncThunk(
  "documents/getCategoriesList",
  async (data) => {
    const res = await getTestCategories({ params: { ...data } });
    return res;
  },
);

export const updateCategoryApi = createAsyncThunk("documents/updateCategory", async (data) => {
  const res = await updateCategory(data);
  return res;
});

export const deleteCategoryApi = createAsyncThunk("documents/deleteCategory", async (data) => {
  const res = await deleteCategory({ params: { ...data } });
  return res;
});

export const createCategoryApi = createAsyncThunk("documents/createCategory", async (data) => {
  const res = await createCategory(data);
  return res;
});

export const getDocumentsApi = createAsyncThunk("documents/getDocumentsList", async (data) => {
  const res = await getDocuments({ params: { ...data } });
  return res;
});

export const getSDStatusesApi = createAsyncThunk("documents/getSDFStatuses", async () => {
  const res = await getSDFStatuses();
  return res;
});

export const getFileStatusesApi = createAsyncThunk("documents/getFileStatuses", async () => {
  const res = await getFileStatuses();
  return res;
});

export const updateFileStatusApi = createAsyncThunk(
  "documents/updateFileStatuses",
  async (data) => {
    await updateFileStatus(data);
  },
);

export const updateDocSubStatusApi = createAsyncThunk(
  "documents/updateDocSubStatus",
  async (data) => {
    await updateDocSubStatus(data);
  },
);

export const archiveDocumentsApi = createAsyncThunk("documents/deleteDocuments", async (data) => {
  const res = await archiveDocuments({ params: { ...data } });
  return res;
});

export const unArchiveDocumentsApi = createAsyncThunk(
  "documents/unarchieveDocuments",
  async (data) => {
    const res = await unarchiveDocuments(data);
    return res;
  },
);

export const getCategoryListApi = createAsyncThunk("documents/getCategoryList", async () => {
  const res = await getCategoryList();
  return res;
});
export const updateDocumentApi = createAsyncThunk("documents/updateDocument", async (data) => {
  const res = await updateDocument(data);
  return res;
});

export const craeteDocumentApi = createAsyncThunk("documents/createDocument", async (data) => {
  const res = await createDocument(data);
  return res;
});

export const createSubDocumentApi = createAsyncThunk(
  "documents/createSubDocument",
  async (data) => {
    const res = await createSubDocument(data);
    return res;
  },
);

export const deleteSubDocumentApi = createAsyncThunk("documents/deleteSubDocument", async (id) => {
  await deleteSubDocument(id);
});

export const getDocumentSpecificListApi = createAsyncThunk(
  "documents/getDocumentSpecificList",
  async () => {
    const res = await getDocumentSpecificList();
    return res;
  },
);

export const getDocumentsListAutoCompleteApi = createAsyncThunk(
  "documents/getDocumentListAutoComplte",
  async (data) => {
    const res = await getDocumentsListAutoComplete({ params: { ...data } });
    return res;
  },
);

//templates
export const getTemplatesListApi = createAsyncThunk("documents/getTemplateList", async (data) => {
  const res = await getTemplateList({ params: { ...data } });
  return res;
});

export const updateTemplateApi = createAsyncThunk("documents/updateTemplate", async (data) => {
  const res = await updateTemplate(data);
  return res;
});

export const createTemplateApi = createAsyncThunk("documents/createTemplate", async (data) => {
  const res = await createTemplate(data);
  return res;
});

export const deleteTemplateApi = createAsyncThunk("documents/deleteTemplate", async (data) => {
  const res = await deleteTemplate({ params: { ...data } });
  return res;
});

export const deleteDocumentTemplateApi = createAsyncThunk(
  "documents/deleteDocumentTemplate",
  async (data) => {
    const res = await deleteDocumentTemplate(data);
    return res;
  },
);

export const addDocumentTemplateApi = createAsyncThunk(
  "documents/addDocumentTemplate",
  async (data) => {
    const res = await addDocument(data);
    return res;
  },
);

export const addCreateDocumentTemplateApi = createAsyncThunk(
  "documents/addCreateDocumentTemplate",
  async (data) => {
    const res = await addCreateDocument(data);
    return res;
  },
);
//to be deleted

export const dummyDataApi = createAsyncThunk("test", async () => {
  const res = await getDummyData();
  return res;
});
const documentSlice = createSlice({
  name: "document",
  initialState: {
    currentTab: "templates",
    templates: {
      searchText: null,
      currentPage: 1,
      sortColumn: {
        sortColumnName: "name",
        sortColumnOrder: "asc",
      },
      templatesList: {
        isLoading: false,
        error: null,
        data: [],
      },
      deleteTemplate: {
        isLoading: false,
        error: null,
        data: [],
      },
      createTemplate: {
        isLoading: false,
        error: null,
        data: [],
      },
      updateTemplate: {
        isLoading: false,
        error: null,
        data: [],
      },
    },
    documents: {
      isActive: "true",
      searchText: null,
      currentPage: 1,
      sortColumn: {
        sortColumnName: "name",
        sortColumnOrder: "asc",
      },
      documentsList: {
        isLoading: false,
        error: null,
        data: [],
      },
      deleteDocument: {
        isLoading: false,
        error: null,
        data: [],
      },
      unarchiveDocument: {
        isLoading: false,
        error: null,
        data: [],
      },
      updateDocument: {
        isLoading: false,
        error: null,
        data: [],
      },
      createDocument: {
        isLoading: false,
        error: null,
        data: [],
      },
      SDStatuses: {
        isLoading: false,
        error: null,
        data: [],
      },
      FileStatuses: {
        isLoading: false,
        error: null,
        data: [],
      },
    },
    categories: {
      searchText: null,
      currentPage: 1,
      sortColumn: {
        sortColumnName: "name",
        sortColumnOrder: "asc",
      },
      categoriesList: {
        isLoading: false,
        error: null,
        data: [],
      },
      deleteCategory: {
        isLoading: false,
        error: null,
        data: [],
      },
      createCategory: {
        isLoading: false,
        error: null,
        data: [],
      },
      updateCategory: {
        isLoading: false,
        error: null,
        data: [],
      },
    },
  },
  reducers: {
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    setSearchTextCategories(state, action) {
      state.categories.searchText = action.payload;
    },
    setSearchTextDocuments(state, action) {
      state.documents.searchText = action.payload;
    },
    setSearchTextTemplates(state, action) {
      state.templates.searchText = action.payload;
    },
    nextPageCateCategories(state) {
      state.categories.currentPage++;
    },
    nextPageCateDocuments(state) {
      state.documents.currentPage++;
    },
    nextPageTemplates(state) {
      state.templates.currentPage++;
    },
    prevPageCategories(state) {
      state.categories.currentPage--;
    },
    prevPageDocuments(state) {
      state.documents.currentPage--;
    },
    prevPageTemplates(state) {
      state.templates.currentPage--;
    },
    setSortColumnCategories(state, action) {
      state.categories.sortColumn = action.payload;
    },
    setSortColumnDocuments(state, action) {
      state.documents.sortColumn = action.payload;
    },
    setSortColumnTemplates(state, action) {
      state.templates.sortColumn = action.payload;
    },
    setCreateCategoryError(state, action) {
      state.categories.createCategory.error = action.payload;
    },
    setUpdateCategoryError(state, action) {
      state.categories.updateCategory.error = action.payload;
    },
    setIsActiveDocuments(state, action) {
      state.documents.isActive = action.payload;
    },
    setCreateDocumentError(state, action) {
      state.documents.createDocument.error = action.payload;
    },
    setUpdateDocumentError(state, action) {
      state.documents.updateDocument.error = action.payload;
    },
    setDeleteCategoryError(state, action) {
      state.categories.deleteCategory.error = action.payload;
    },
    setUpdateTemplateError(state, action) {
      state.templates.updateTemplate.error = action.payload;
    },
    setCreateTemplateError(state, action) {
      state.templates.createTemplate.error = action.payload;
    },
    setDeleteTemplateError(state, action) {
      state.templates.deleteTemplate.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //categories
      .addCase(getTestCategoriesList.pending, (state) => {
        state.categories.categoriesList.isLoading = true;
        state.categories.categoriesList.error = null;
      })
      .addCase(getTestCategoriesList.fulfilled, (state, action) => {
        state.categories.categoriesList.isLoading = false;
        state.categories.categoriesList.data = action.payload;
      })
      .addCase(getTestCategoriesList.rejected, (state, action) => {
        state.categories.categoriesList.isLoading = false;
        state.categories.categoriesList.error = action.error.message;
      })
      .addCase(updateCategoryApi.pending, (state) => {
        state.categories.categoriesList.isLoading = true;
        state.categories.updateCategory.isLoading = true;
        state.categories.updateCategory.error = null;
      })
      .addCase(updateCategoryApi.fulfilled, (state, action) => {
        state.categories.categoriesList.isLoading = false;
        state.categories.updateCategory.isLoading = false;
        state.categories.updateCategory.data = action.payload;
      })
      .addCase(updateCategoryApi.rejected, (state, action) => {
        state.categories.categoriesList.isLoading = false;
        state.categories.updateCategory.isLoading = false;
        state.categories.updateCategory.error = action.error.message;
      })
      .addCase(deleteCategoryApi.pending, (state) => {
        state.categories.categoriesList.isLoading = true;
        state.categories.deleteCategory.isLoading = true;
        state.categories.deleteCategory.error = null;
      })
      .addCase(deleteCategoryApi.fulfilled, (state, action) => {
        state.categories.categoriesList.isLoading = false;
        state.categories.deleteCategory.isLoading = false;
        state.categories.deleteCategory.data = action.payload;
      })
      .addCase(deleteCategoryApi.rejected, (state, action) => {
        state.categories.categoriesList.isLoading = false;
        state.categories.deleteCategory.isLoading = false;
        state.categories.deleteCategory.error = action.error.message;
      })
      .addCase(createCategoryApi.pending, (state) => {
        state.categories.categoriesList.isLoading = true;
        state.categories.createCategory.isLoading = true;
        state.categories.createCategory.error = null;
      })
      .addCase(createCategoryApi.fulfilled, (state, action) => {
        state.categories.categoriesList.isLoading = false;
        state.categories.createCategory.isLoading = false;
        state.categories.createCategory.data = action.payload;
      })
      .addCase(createCategoryApi.rejected, (state, action) => {
        state.categories.categoriesList.isLoading = false;
        state.categories.createCategory.isLoading = false;
        state.categories.createCategory.error = action.error.message;
      })
      //documents
      .addCase(getDocumentsApi.pending, (state) => {
        state.documents.documentsList.isLoading = true;
        state.documents.documentsList.error = null;
      })
      .addCase(getDocumentsApi.fulfilled, (state, action) => {
        state.documents.documentsList.isLoading = false;
        state.documents.documentsList.data = action.payload;
      })
      .addCase(getDocumentsApi.rejected, (state, action) => {
        state.documents.documentsList.isLoading = false;
        state.documents.documentsList.error = action.error.message;
      })
      .addCase(archiveDocumentsApi.pending, (state) => {
        state.documents.documentsList.isLoading = true;
        state.documents.deleteDocument.isLoading = true;
        state.documents.deleteDocument.error = null;
      })
      .addCase(archiveDocumentsApi.fulfilled, (state, action) => {
        state.documents.documentsList.isLoading = false;
        state.documents.deleteDocument.isLoading = false;
        state.documents.deleteDocument.data = action.payload;
      })
      .addCase(archiveDocumentsApi.rejected, (state, action) => {
        state.documents.documentsList.isLoading = false;
        state.documents.deleteDocument.isLoading = false;
        state.documents.deleteDocument.error = action.error.message;
      })
      .addCase(unArchiveDocumentsApi.pending, (state) => {
        state.documents.documentsList.isLoading = true;
        state.documents.unarchiveDocument.isLoading = true;
        state.documents.unarchiveDocument.error = null;
      })
      .addCase(unArchiveDocumentsApi.fulfilled, (state, action) => {
        state.documents.documentsList.isLoading = false;
        state.documents.unarchiveDocument.isLoading = false;
        state.documents.unarchiveDocument.data = action.payload;
      })
      .addCase(unArchiveDocumentsApi.rejected, (state, action) => {
        state.documents.documentsList.isLoading = false;
        state.documents.unarchiveDocument.isLoading = false;
        state.documents.unarchiveDocument.error = action.error.message;
      })
      .addCase(updateDocumentApi.pending, (state) => {
        state.documents.documentsList.isLoading = true;
        state.documents.updateDocument.isLoading = true;
        state.documents.updateDocument.error = null;
      })
      .addCase(updateDocumentApi.fulfilled, (state, action) => {
        state.documents.documentsList.isLoading = false;
        state.documents.updateDocument.isLoading = false;
        state.documents.updateDocument.data = action.payload;
      })
      .addCase(updateDocumentApi.rejected, (state, action) => {
        state.documents.documentsList.isLoading = false;
        state.documents.updateDocument.isLoading = false;
        state.documents.updateDocument.error = action.error.message;
      })
      .addCase(craeteDocumentApi.pending, (state) => {
        state.documents.documentsList.isLoading = true;
        state.documents.createDocument.isLoading = true;
        state.documents.createDocument.error = null;
      })
      .addCase(craeteDocumentApi.fulfilled, (state, action) => {
        state.documents.documentsList.isLoading = false;
        state.documents.createDocument.isLoading = false;
        state.documents.createDocument.data = action.payload;
      })
      .addCase(craeteDocumentApi.rejected, (state, action) => {
        state.documents.documentsList.isLoading = false;
        state.documents.createDocument.isLoading = false;
        state.documents.createDocument.error = action.error.message;
      })
      .addCase(getSDStatusesApi.pending, (state) => {
        state.documents.SDStatuses.isLoading = true;
        state.documents.SDStatuses.error = null;
      })
      .addCase(getSDStatusesApi.fulfilled, (state, action) => {
        state.documents.SDStatuses.isLoading = false;
        state.documents.SDStatuses.data = action.payload;
      })
      .addCase(getSDStatusesApi.rejected, (state, action) => {
        state.documents.SDStatuses.isLoading = false;
        state.documents.SDStatuses.error = action.error.message;
      })
      .addCase(getFileStatusesApi.pending, (state) => {
        state.documents.FileStatuses.isLoading = true;
        state.documents.FileStatuses.error = null;
      })
      .addCase(getFileStatusesApi.fulfilled, (state, action) => {
        state.documents.FileStatuses.isLoading = false;
        state.documents.FileStatuses.data = action.payload;
      })
      .addCase(getFileStatusesApi.rejected, (state, action) => {
        state.documents.FileStatuses.isLoading = false;
        state.documents.FileStatuses.error = action.error.message;
      })

      //templates
      .addCase(getTemplatesListApi.pending, (state) => {
        state.templates.templatesList.isLoading = true;
        state.templates.templatesList.error = null;
      })
      .addCase(getTemplatesListApi.fulfilled, (state, action) => {
        state.templates.templatesList.isLoading = false;
        state.templates.templatesList.data = action.payload;
      })
      .addCase(getTemplatesListApi.rejected, (state, action) => {
        state.templates.templatesList.isLoading = false;
        state.templates.templatesList.error = action.error.message;
      })
      .addCase(updateTemplateApi.pending, (state) => {
        state.templates.templatesList.isLoading = true;
        state.templates.updateTemplate.isLoading = true;
        state.templates.updateTemplate.error = null;
      })
      .addCase(updateTemplateApi.fulfilled, (state, action) => {
        state.templates.templatesList.isLoading = false;
        state.templates.updateTemplate.isLoading = false;
        state.templates.updateTemplate.data = action.payload;
      })
      .addCase(updateTemplateApi.rejected, (state, action) => {
        state.templates.templatesList.isLoading = false;
        state.templates.updateTemplate.isLoading = false;
        state.templates.updateTemplate.error = action.error.message;
      })
      .addCase(createTemplateApi.pending, (state) => {
        state.templates.templatesList.isLoading = true;
        state.templates.createTemplate.isLoading = true;
        state.templates.createTemplate.error = null;
      })
      .addCase(createTemplateApi.fulfilled, (state, action) => {
        state.templates.templatesList.isLoading = false;
        state.templates.createTemplate.isLoading = false;
        state.templates.createTemplate.data = action.payload;
      })
      .addCase(createTemplateApi.rejected, (state, action) => {
        state.templates.templatesList.isLoading = false;
        state.templates.createTemplate.isLoading = false;
        state.templates.createTemplate.error = action.error.message;
      })
      .addCase(deleteTemplateApi.pending, (state) => {
        state.templates.templatesList.isLoading = true;
        state.templates.deleteTemplate.isLoading = true;
        state.templates.deleteTemplate.error = null;
      })
      .addCase(deleteTemplateApi.fulfilled, (state, action) => {
        state.templates.templatesList.isLoading = false;
        state.templates.deleteTemplate.isLoading = false;
        state.templates.deleteTemplate.data = action.payload;
      })
      .addCase(deleteTemplateApi.rejected, (state, action) => {
        state.templates.templatesList.isLoading = false;
        state.templates.deleteTemplate.isLoading = false;
        state.templates.deleteTemplate.error = action.error.message;
      });
  },
});
export const {
  setCurrentTab,
  setSearchTextCategories,
  setSearchTextTemplates,
  nextPageCateCategories,
  nextPageTemplates,
  prevPageCategories,
  prevPageTemplates,
  setSortColumnCategories,
  setSearchTextDocuments,
  nextPageCateDocuments,
  prevPageDocuments,
  setSortColumnDocuments,
  setCreateCategoryError,
  setUpdateCategoryError,
  setIsActiveDocuments,
  setCreateDocumentError,
  setUpdateDocumentError,
  setDeleteCategoryError,
  setSortColumnTemplates,
  setUpdateTemplateError,
  setCreateTemplateError,
  setDeleteTemplateError,
} = documentSlice.actions;

export default documentSlice.reducer;
