import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profileSettings: {
    isVisible: false,
  },
  changePassword: {
    isVisible: false,
  },
  changePasswordModalConfirm: {
    isVisible: false,
  },
  changePasswordModal: {
    isVisible: false,
  },
  saveChangesModal: {
    isVisible: false,
  },
  rejectionReasonModal: {
    isVisible: false,
    fileId: null,
    statusId: null,
  },
  phoneModal: {
    isVisible: false,
  },
  needHelpModal: {
    isVisible: false,
  },
  emailModal: {
    isVisible: false,
  },
  deleteModal: {
    isVisible: false,
  },
  deleteFilesModal: {
    isVisible: false,
    type: null,
  },
  viewFilesModal: {
    isVisible: false,
    isUpdate: false,
    fileInfo: {},
    allFiles: [],
  },
  deleteModalMessage: {
    isVisible: false,
  },
  confirmModal: {
    isVisible: false,
  },
  archiveModal: {
    isVisible: false,
  },
  docsManagementCategories: {
    isCreate: false,
  },
  docsManagementDocuments: {
    isCreate: false,
  },
  docsManagementTemplates: {
    isCreate: false,
  },
  documentDrawer: {
    isVisible: false,
  },
  userShareAccessDrawer: {
    isVisible: false,
  },
  userFormDrawer: {
    isVisible: false,
  },
  lenderFormDrawer: {
    isVisible: false,
  },
  loansTransactionDetails: {
    isVisible: false,
  },
  loansTransactionEditDetails: {
    isVisible: false,
  },
  borrowerDetails: {
    isVisible: false,
  },
  borrowerEditDetails: {
    isVisible: false,
  },
  accessControlModal: {
    isVisible: false,
  },
  footer: {
    total: 0,
    current: 0,
  },
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    toggleProfileSettingsVisibility(state) {
      state.profileSettings.isVisible = !state.profileSettings.isVisible;
    },
    toggleRejectionReasonModal(state, action) {
      state.rejectionReasonModal.isVisible = action.payload.isVisible;
      state.rejectionReasonModal.fileId = action.payload.fileId;
      state.rejectionReasonModal.statusId = action.payload.statusId;
    },
    toggleLoanTransactionDetailsVisibility(state) {
      state.loansTransactionDetails.isVisible = !state.loansTransactionDetails.isVisible;
    },
    toggleLoanTransactionEditDetailsVisibility(state) {
      state.loansTransactionEditDetails.isVisible = !state.loansTransactionEditDetails.isVisible;
    },
    toggleBorrowerDetailsVisibility(state) {
      state.borrowerDetails.isVisible = !state.borrowerDetails.isVisible;
    },
    toggleBorrowerEditDetailsVisibility(state) {
      state.borrowerEditDetails.isVisible = !state.borrowerEditDetails.isVisible;
    },
    toggleChangePasswordVisibility(state) {
      state.changePassword.isVisible = !state.changePassword.isVisible;
    },
    toggleChangePasswordModalVisibility(state) {
      state.changePasswordModal.isVisible = !state.changePasswordModal.isVisible;
    },
    toggleChangePasswordModalConfirmVisibility(state) {
      state.changePasswordModalConfirm.isVisible = !state.changePasswordModalConfirm.isVisible;
    },
    toggleSaveChangesModalVisibility(state) {
      state.saveChangesModal.isVisible = !state.saveChangesModal.isVisible;
    },
    togglePhoneModalVisibility(state) {
      state.phoneModal.isVisible = !state.phoneModal.isVisible;
    },
    toggleNeedHelpModalVisibility(state) {
      state.needHelpModal.isVisible = !state.needHelpModal.isVisible;
    },
    toggleEmailModalVisibility(state) {
      state.emailModal.isVisible = !state.emailModal.isVisible;
    },
    toggleDeleteModalVisibility(state) {
      state.deleteModal.isVisible = !state.deleteModal.isVisible;
    },
    toggleDeleteModalMessageVisibility(state) {
      state.deleteModalMessage.isVisible = !state.deleteModalMessage.isVisible;
    },
    toggleArchiveModalVisibility(state) {
      state.archiveModal.isVisible = !state.archiveModal.isVisible;
    },
    toggleDocsManagementCategoriesTrue(state) {
      state.docsManagementCategories.isCreate = true;
    },
    toggleDocsManagementCategoriesFalse(state) {
      state.docsManagementCategories.isCreate = false;
    },
    toggleDocsManagementDocumentsTrue(state) {
      state.docsManagementDocuments.isCreate = true;
    },
    toggleDocsManagementDocumentsFalse(state) {
      state.docsManagementDocuments.isCreate = false;
    },
    toggleDocsManagementTemplatesTrue(state) {
      state.docsManagementTemplates.isCreate = true;
    },
    toggleDocsManagementTemplatesFalse(state) {
      state.docsManagementTemplates.isCreate = false;
    },
    toggleDocumentDrawerVisibility(state) {
      state.documentDrawer.isVisible = !state.documentDrawer.isVisible;
    },
    toggleUserShareAccessDrawerVisibility(state) {
      state.userShareAccessDrawer.isVisible = !state.userShareAccessDrawer.isVisible;
    },
    toggleDeleteFilesModalVisibility(state, action) {
      state.deleteFilesModal.isVisible = !state.deleteFilesModal.isVisible;
      state.deleteFilesModal.type = action.payload;
    },
    toggleViewFilesModalVisibility(state, action) {
      state.viewFilesModal.isVisible = action.payload.isVisible;
      state.viewFilesModal.isUpdate = action.payload.isUpdate;
      state.viewFilesModal.fileInfo = action.payload.fileInfo;
      state.viewFilesModal.allFiles = action.payload.allFiles;
    },
    toggleConfirmModalVisibility(state) {
      state.confirmModal.isVisible = !state.confirmModal.isVisible;
    },
    toggleUserFormDrawerVisibility(state) {
      state.userFormDrawer.isVisible = !state.userFormDrawer.isVisible;
    },
    setUserFormDrawerVisibilityClose(state) {
      state.userFormDrawer.isVisible = false;
    },
    toggleLenderDrawerVisibility(state) {
      state.lenderFormDrawer.isVisible = !state.lenderFormDrawer.isVisible;
    },
    toggleLenderDrawerVisibilityClose(state) {
      state.lenderFormDrawer.isVisible = false;
    },
    toggleAccessControlModalVisibility(state, action) {
      state.accessControlModal.isVisible = action.payload;
    },
  },
});

export const commonReducer = commonSlice.reducer;
export const commonActions = commonSlice.actions;
