import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderQuestion,
  setContactType,
  setShowNext,
  setShowQuestions,
  getContactTypes,
  setSendData,
  setShowProgressBar,
} from "../onboardingSlice";
import Button from "../Components/Button";
import { ReactComponent as MailIcon } from "assets/svg/icons/mail-icon-01.svg";
import { ReactComponent as PhoneIcon } from "assets/svg/icons/phone-icon-01.svg";
import { ReactComponent as MessageIcon } from "assets/svg/icons/message-text-circle-01.svg";

const Step15 = () => {
  const dispatch = useDispatch();
  const { ContactType } = useSelector((state) => state.onboarding.body.form);
  const { postOnboarding, contactTypes } = useSelector((state) => state.onboarding.body);
  const question = "What is your preferred way of contact?";

  useEffect(() => {
    dispatch(getContactTypes());
    dispatch(setShowQuestions(true));
    dispatch(setShowProgressBar(true));
  }, [dispatch]);
  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);

  const handleClick = (id) => {
    dispatch(setContactType(id));
  };
  useEffect(() => {
    if (ContactType !== null) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ ContactType }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [ContactType, dispatch, postOnboarding]);

  return (
    <div className="buttonWrap">
      {contactTypes.data.map((type) => {
        return (
          <Button
            key={type.id}
            buttonText={type?.name}
            buttonIcon={
              type?.id === 1 ? (
                <MailIcon />
              ) : type?.id === 2 ? (
                <PhoneIcon />
              ) : type?.id === 3 ? (
                <MessageIcon />
              ) : null
            }
            onClick={() => handleClick(type?.id)}
            active={ContactType === type?.id}
          />
        );
      })}
    </div>
  );
};

export default Step15;
