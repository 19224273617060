import { Routes, Route } from "react-router-dom";
import UserManagement from "../UserManagement";

const UserManagementRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<UserManagement />} />
    </Routes>
  );
};

export default UserManagementRoutes;
