import {
  // useState,
  useEffect,
  useState,
  useMemo,
  createContext,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link } from "react-router-dom";

import { Header } from "wrappers/NewVersion/components/Header/index.jsx";
import PropertiesSection from "./Components/PropertiesSection";
import { Button } from "./Components/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as ChevronLeftIcon } from "assets/svg/icons/chevron-left.svg";
import "./styles.scss";
import {
  getTestBorrowerDetailApi,
  getTestBorrowerDetailLoansApi,
  getTestBorrowerLoanCountByStatusApi,
  setCurrentTab,
  resetState,
  getUserAccessLevel,
} from "./borrowerDetailSlice";
import { AppRoute } from "../../utils/constants/ConstantsUtil";
import { useParams } from "react-router-dom";
import NewLoanModal from "pages/Borrower/Components/NewLoanModal";
import { UserRoles } from "../../utils/constants/ConstantsUtil";

const BorrowerContext = createContext();

export const useBorrowerContext = () => {
  return useContext(BorrowerContext);
};

const BorrowerDetail = () => {
  const { borrowerId } = useParams();
  const dispatch = useDispatch();
  const [isNewLoanModalOpen, setIsNewLoanModalOpen] = useState(false);
  const [accessType, setAccessType] = useState(null);

  const {
    currentTab,
    testBorrowerDetailData: {
      data: { firstName, lastName },
    },
    loanCountByStatus: { isLoading: isLoadingLoanCount, data: loanCount },
  } = useSelector(({ borrowerDetail }) => borrowerDetail);

  const handleChange = (e, newVal) => {
    dispatch(setCurrentTab(newVal));
  };

  const handleNavigateBack = () => {
    dispatch(resetState());
  };

  const getAccessType = async () => {
    const res = await dispatch(getUserAccessLevel(borrowerId)).unwrap();
    setAccessType(res.name);
  };

  useEffect(() => {
    borrowerId && getAccessType();
  }, [borrowerId]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getTestBorrowerDetailApi(borrowerId)).unwrap();
      await dispatch(getTestBorrowerLoanCountByStatusApi(borrowerId)).unwrap();
    };

    fetchData();
  }, [dispatch, borrowerId]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getTestBorrowerDetailLoansApi(borrowerId)).unwrap();
    };

    fetchData();
  }, [dispatch, borrowerId, currentTab]);

  useEffect(() => {
    const parentElement = document.getElementById("div-routing");
    const hasBorrowerDetail = document.getElementById("borrower-detail");

    if (parentElement && hasBorrowerDetail) {
      parentElement.classList.add("h-100");
    }
  }, []);

  const canAddLoan = useMemo(() => {
    const roles = Object.values(UserRoles);
    return roles.filter((item) => item !== UserRoles.VIEWER).includes(accessType);
  }, [accessType]);

  const canEditDetails = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);

  const canViewDetails = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);

  const canViewArchiveTab = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);

  const canArchiveLoan = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);

  const canViewBorrowerList = useMemo(() => {
    return [
      UserRoles.ADMIN,
      UserRoles.LENDER,
      UserRoles.BROKER,
      UserRoles.CO_BORROWER,
      UserRoles.VIEWER,
    ].includes(accessType);
  }, [accessType]);

  return (
    <div id="borrower-detail" className="h-100 d-flex flex-column">
      <BorrowerContext.Provider
        value={{
          canEditDetails,
          canViewDetails,
          canArchiveLoan,
        }}
      >
        <div className="nv-borrower">
          <Header />
        </div>
        <section className="mainContainer flex-1 gap-0">
          <div className="mainContainer__header_borrower_detail mb-32 nv-borrower">
            <div className="mainContainer__header--top">
              <div className="h1__wrapper">
                {canViewBorrowerList && (
                  <Link to={AppRoute.BORROWER}>
                    <ChevronLeftIcon
                      fontSize={"20px"}
                      className="chevron__left"
                      onClick={handleNavigateBack}
                    />
                  </Link>
                )}
                <h1>
                  {firstName} {lastName}
                </h1>
              </div>

              <Button
                startIcon={<AddIcon fontSize="20px" />}
                label={"Loan"}
                onClick={() => setIsNewLoanModalOpen(true)}
              />
            </div>
          </div>
          <TabContext value={currentTab}>
            <Box sx={{ width: "100%" }} className="nv-borrower">
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab
                  label={`Active${
                    isLoadingLoanCount ? "" : loanCount.active ? ` (${loanCount.active})` : " (0)"
                  }`}
                  value="active"
                />
                <Tab
                  label={`Closed${
                    isLoadingLoanCount ? "" : loanCount.closed ? ` (${loanCount.closed})` : " (0)"
                  }`}
                  value="closed"
                  disabled={isLoadingLoanCount || loanCount.closed === 0 ? true : false}
                />
                {canViewArchiveTab && (
                  <Tab
                    label={`Archived${
                      isLoadingLoanCount
                        ? ""
                        : loanCount.archived
                        ? ` (${loanCount.archived})`
                        : " (0)"
                    }`}
                    value="archived"
                    disabled={isLoadingLoanCount || loanCount.archived === 0 ? true : false}
                  />
                )}

                <Tab
                  label={`Show All${
                    isLoadingLoanCount ? "" : loanCount.all ? ` (${loanCount.all})` : " (0)"
                  }`}
                  value="all"
                />
              </TabList>
            </Box>
            <TabPanel value="active">
              <PropertiesSection />
            </TabPanel>
            <TabPanel value="closed">
              <PropertiesSection />
            </TabPanel>
            <TabPanel value="archived">
              <PropertiesSection />
            </TabPanel>
            <TabPanel value="all">
              <PropertiesSection />
            </TabPanel>
          </TabContext>
        </section>
        <NewLoanModal isOpen={isNewLoanModalOpen} closeModal={() => setIsNewLoanModalOpen(false)} />
      </BorrowerContext.Provider>
    </div>
  );
};

export default BorrowerDetail;
