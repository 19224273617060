import React from "react";
import { Controller } from "react-hook-form";
import { ButtonGroup as ButtonGroupMUI } from "@mui/material";

import "./styles.scss";

export default function ButtonGroup({
  options,
  required = false,
  name,
  label,
  control,
  defaultValue,
  setValue,
  hasOptional = true,
}) {
  return (
    <>
      <div className="inputContainer-labelRow mb-2">
        <label htmlFor={name} className={`inputLabel`}>
          {label}
        </label>

        {!required && hasOptional && <span className="inputContainerOptional">optional</span>}
      </div>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <ButtonGroupMUI fullWidth>
            {options.map((opt, index) => (
              <div
                {...field}
                key={index}
                className={`cursor-pointer text-center ${
                  index === 0
                    ? "rounded-s-full"
                    : index === options.length - 1 && "rounded-e-full buttonStyle__right"
                } ${field.value === opt.id ? "buttonStyle__selected" : "buttonStyle"}`}
                onClick={() => setValue(name, opt.id, { shouldValidate: true })}
              >
                {opt.name}
              </div>
            ))}
          </ButtonGroupMUI>
        )}
      />
    </>
  );
}
