import { PropertyAddress } from "../PropertyAddress";
import { PropertyStatusBadge } from "../PropertyStatusBadge";
import { IconButton } from "../IconButton";

import { ReactComponent as ThreeDotsIcon } from "../../../../assets/svg/icons/three-dots-icon.svg";
import { ReactComponent as DownIcon } from "../../../../assets/svg/icons/down-icon.svg";
import moment from "moment/moment";
import { useSortableTable } from "../../../../utils/hooks/useSortableData";
import { useState } from "react";

const columns = [
  {
    title: "Property Address",
    accessor: "propertyAddress",
    isSortable: true,
  },
  {
    title: "Last Updated",
    accessor: "lastUpdated",
    isSortable: true,
  },
  {
    title: "Loan Amount",
    accessor: "loanAmount",
    isSortable: true,
  },
  {
    title: "Status",
    accessor: "status",
    isSortable: true,
  },
];

export function PropertiesTable({ propertyList = [] }) {
  const [order, setOrder] = useState("asc");
  const [sortField, setSortField] = useState("");
  const [tableData, handleSorting] = useSortableTable(propertyList);

  const handleSortingChange = (accessor) => {
    const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  return (
    <table className="nv-propertiesTable w-100">
      <thead className="nv-propertiesTable__head">
        <tr className="nv-propertiesTable__headRow w-100 border-bottom-gray-200">
          {columns.map(({ title, accessor }) => (
            <th
              key={accessor}
              className="text-align-left text-xs-medium cursor-pointer nv-propertiesTable__headItem"
              onClick={() => handleSortingChange(accessor)}
            >
              {title}
              <DownIcon id="down" />
            </th>
          ))}
          <th className="text-align-left text-xs-medium nv-propertiesTable__headItem">Options</th>
        </tr>
      </thead>
      <tbody className="nv-propertiesTable__body">
        {tableData.map((item) => (
          <tr key={item.id} className="nv-propertiesTable__bodyRow w-100 border-bottom-gray-200">
            <td className="nv-propertiesTable__bodyItem">
              <PropertyAddress address={item?.propertyAddress} />
            </td>
            <td className="nv-propertiesTable__bodyItem">
              <p className="label-5-gray-900">{moment(item?.lastUpdated).format("MMM D, YYYY")}</p>
              <p className="label-5-gray-600">{moment(item?.lastUpdated).format("LT")}</p>
            </td>
            <td className="nv-propertiesTable__bodyItem">
              <p className="label-5-gray-600">${item?.loanAmount}</p>
            </td>
            <td className="nv-propertiesTable__bodyItem">
              <PropertyStatusBadge status={item?.status} />
            </td>
            <td className="nv-propertiesTable__bodyItem">
              <IconButton>
                <ThreeDotsIcon />
              </IconButton>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
