import {
  fetchGetTokenized,
  fetchPostTokenized,
  fetchPutTokenized,
  fetchDeleteTokenized,
} from "api/common/callerHelpers";
import { DigitalLoanService } from "configs/axios";

export const getTestCategories = async (data) => {
  return await fetchGetTokenized("/api/categories", data);
};

export const updateCategory = async (data) => {
  return await fetchPutTokenized("/api/categories", data);
};

export const deleteCategory = async (data) => {
  return await fetchDeleteTokenized("/api/categories", data);
};

export const createCategory = async (data) => {
  return await fetchPostTokenized("/api/categories", data);
};

export const getDocuments = async (data) => {
  return await fetchGetTokenized("/api/documents", data);
};

export const archiveDocuments = async (data) => {
  return await fetchDeleteTokenized("/api/documents", data);
};

export const unarchiveDocuments = async (data) => {
  return await fetchPutTokenized(`/api/documents/unarchive/${data}`);
};

export const getCategoryList = async () => {
  return await fetchGetTokenized("/api/categories/list");
};

export const getDocumentSpecificList = async () => {
  return await fetchGetTokenized("/api/document-specifics");
};
export const updateDocument = async (data) => {
  return await fetchPutTokenized("/api/documents", data);
};

export const createDocument = async (data) => {
  return await fetchPostTokenized("/api/documents", data);
};

export const createSubDocument = async (data) => {
  return await fetchPostTokenized("/api/documents/add-sub-document", data);
};

export const deleteSubDocument = async (id) => {
  return await fetchDeleteTokenized(`/api/documents/delete-sub-document?id=${id}`);
};

export const getDocumentsListAutoComplete = async (data) => {
  return await fetchGetTokenized("/api/documents/autocomplete", data);
};

export const getSDFStatuses = async () => {
  return await fetchGetTokenized("/api/document-statuses");
};

export const getFileStatuses = async () => {
  return await fetchGetTokenized("/api/file-statuses");
};

export const updateFileStatus = async (data) => {
  return await fetchPostTokenized("/api/loan-document/file-change-status", data);
};

export const updateDocSubStatus = async (data) => {
  return await fetchPostTokenized("/api/loan-document/document-change-status", data);
};

//templates tab
export const getTemplateList = async (data) => {
  return await fetchGetTokenized("/api/templates", data);
};

export const deleteTemplate = async (data) => {
  return await fetchDeleteTokenized("/api/templates", data);
};

export const updateTemplate = async (data) => {
  return await fetchPutTokenized("/api/templates", data);
};

export const createTemplate = async (data) => {
  return await fetchPostTokenized("/api/templates", data);
};

export const deleteDocumentTemplate = async (data) => {
  return await fetchDeleteTokenized(`/api/templates/remove-document/${data}`);
};

export const addDocument = async (data) => {
  return await fetchPostTokenized("/api/templates/add-document", data);
};

export const addCreateDocument = async (data) => {
  return await fetchPostTokenized("/api/templates/add-create-document", data);
};

//to be deleted
export const getDummyData = async () => {
  return await DigitalLoanService.get("/users/current-user");
};
