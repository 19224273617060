import { useEffect } from "react";

import { Portal } from "../Portal";

import { ReactComponent as CloseIcon } from "../../../../assets/svg/icons/close-icon.svg";

export function Modal({
  children,
  icon = null,
  isOpen = false,
  handleClose = null,
  position = false,
  additionalModalContentClasses = "",
  leftHeaderContent = null,
}) {
  useEffect(() => {
    if (isOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [isOpen]);

  // useEffect(() => {
  //   const closeOnEscapeKey = (e) => (e.key === "Escape" ? handleClose() : null);
  //   document.body?.addEventListener("keydown", closeOnEscapeKey);
  //   return () => {
  //     document.body?.removeEventListener("keydown", closeOnEscapeKey);
  //   };
  // }, [handleClose]);

  return (
    <>
      <Portal wrapperId={"react-portal-modal-container"}>
        <div
          className={`nv-modal ${isOpen ? "nv-modal-enabled" : "nv-modal-disabled"} ${
            position ? "nv-modal-top" : ""
          }`}
        >
          {position ? (
            <>{children}</>
          ) : (
            <div
              className={
                `p-24 background-color-white border-radius-12 nv-modal__content ` +
                additionalModalContentClasses
              }
            >
              <div className={"mb-16 d-flex align-items-start nv-modal__contentHeader"}>
                {icon !== null ? (
                  <div
                    className={
                      "flex-center w-full ml-auto border-radius-10 border-gray-200 nv-modal__iconBox"
                    }
                    style={{ transform: "translate(18px,0px)" }}
                  >
                    {icon}
                  </div>
                ) : (
                  leftHeaderContent
                )}

                <button
                  className={`p-8 ml-auto border-none flex-center background-color-white cursor-pointer nv-modal__closeButton ${
                    !handleClose && "invisible"
                  }`}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </button>
              </div>
              <div className={"nv-modal__contentBody"}>{children}</div>
            </div>
          )}
        </div>
      </Portal>
    </>
  );
}
