import { useEffect, useState } from "react";
import { AnimationGlare } from "components/DisplayComponents";
import { AppRoute } from "../../../utils/constants/ConstantsUtil";

const OnboardingFinish = () => {
  const [countDown, setCountDown] = useState(0);

  function startCountdown(seconds) {
    let counter = seconds;
    setCountDown(seconds);
    const interval = setInterval(() => {
      setCountDown((prev) => prev - 1);
      counter--;

      if (counter === 0) {
        clearInterval(interval);
        window.location.replace(AppRoute.ROOT);
      }
    }, 1000);
  }

  useEffect(() => {
    startCountdown(5);
  }, []);

  return (
    <div className="input_box_wrapper login_form successBox">
      <AnimationGlare type="spotlight" />
      <div
        className="box white flex_box_column justify_center align_center"
        data-aos="fade-up"
        data-aos-delay="0"
        data-aos-duration="700"
      >
        <h1>Welcome to Battalion!</h1>
        <p
          style={{
            textAlign: "center",
          }}
        >
          Thank you, we have received your application. Member from our team will be in touch with
          you shortly
        </p>
        <p className="grey_text">You will be redirected in {countDown} seconds...</p>
      </div>
    </div>
  );
};

export { OnboardingFinish };
