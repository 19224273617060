import { useSelector } from "react-redux";

import "./styles.scss";

const Footer = () => {
  const { footer } = useSelector((state) => state.onboarding);
  const length = (footer.current / footer.total) * 100;
  return (
    <div className="onboardingFooter">
      <div className="footerProgress" style={{ width: `${length}%` }} />
    </div>
  );
};

export default Footer;
