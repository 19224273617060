import profileImgSrc from "../../../../assets/images/img_avatar.png";
import { Avatar } from "@mui/material";
import { ReactComponent as ProfileIcon } from "../../../../assets/svg/icons/profile-icon.svg";
import { ReactComponent as LockIcon } from "../../../../assets/svg/icons/lock-icon.svg";
import { ReactComponent as ExitIcon } from "../../../../assets/svg/icons/exit-icon.svg";
import { useNavigate } from "react-router-dom";
import { removeAuthInfoFromStorage } from "../../utils/helpers";
import { useDispatch } from "react-redux";
import { commonActions } from "../../commonSlice";
import { clearPersistedData } from "configs/store";

export function ProfileMenu({ handleClose, user }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleProfileItemClick = ({ currentTarget }) => {
    if (currentTarget.id === "profileSettings") {
      dispatch(commonActions.toggleProfileSettingsVisibility());
    } else if (currentTarget.id === "changePassword") {
      dispatch(commonActions.toggleChangePasswordModalVisibility());
    }
    handleClose();
  };

  const handleLogout = () => {
    handleClose();
    navigate("/login");
    removeAuthInfoFromStorage();
    clearPersistedData();
  };

  return (
    <div className={"nv-profileMenu w-max-content"}>
      <div className={"nv-profileMenu__head"}>
        <div>
          <Avatar
            src={user?.avatarUrl}
            alt={user?.firstName}
            className="avatar-text background-color-gray-100 cursor-pointer"
            sx={{
              width: 40,
              height: 40,
            }}
          >
            {user?.firstName[0].toUpperCase()}
            {user?.lastName[0].toUpperCase()}
          </Avatar>
        </div>
        <div>
          <h6 className={"text-sm-semi-bold"}>
            {user?.firstName || ""} {user?.lastName || ""}
          </h6>
          <p className={"text-sm-regular"}>{user?.email || ""}</p>
        </div>
      </div>
      <div className={"border-top-gray-200 border-bottom-gray-200 nv-profileMenu__body"}>
        <div
          id={"profileSettings"}
          className={"d-flex gap-10  cursor-pointer align-items-center nv-profileMenu__link"}
          onClick={handleProfileItemClick}
        >
          <div className={"flex-center nv-profileMenu__iconContainer"}>
            <ProfileIcon />
          </div>
          <span className={"text-sm-medium"}>Profile Settings</span>
        </div>
        <div
          id={"changePassword"}
          className={"d-flex gap-10 cursor-pointer align-items-center nv-profileMenu__link"}
          onClick={handleProfileItemClick}
        >
          <div className={"flex-center nv-profileMenu__iconContainer"}>
            <LockIcon />
          </div>
          <span className={"text-sm-medium"}>Change Password</span>
        </div>
      </div>
      <div className={"nv-profileMenu__footer"}>
        <div
          className={
            "d-flex gap-10  cursor-pointer align-items-center nv-profileMenu__link nv-profileMenu__footer"
          }
          onClick={handleLogout}
        >
          <div className={"flex-center nv-profileMenu__iconContainer"}>
            <ExitIcon />
          </div>
          <span className={"text-sm-medium"}>Log out</span>
        </div>
      </div>
    </div>
  );
}
