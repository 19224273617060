import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  setHeaderQuestion,
  setShowBack,
  setShowCreateNewAccount,
  setShowFooter,
  setShowLogin,
  setShowNext,
  setShowProgressBar,
  setShowQuestions,
  setStep,
  stepNext,
} from "../onboardingSlice";
import Button from "../Components/Button";
import { ReactComponent as UserIcon } from "assets/svg/icons/user-circle.svg";
import GoogleLogin from "pages/Login/components/GoogleLogin";
import useCheckMobileScreen from "utils/hooks/useCheckMobileScreen";

const Step16 = ({ isAuthFlow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postOnboardingComplete } = useSelector((state) => state.onboarding.body);
  const isOnMobile = useCheckMobileScreen();
  const header = "Create Battalion Account";

  useEffect(() => {
    dispatch(setHeaderQuestion(header));
    dispatch(setShowQuestions(false));
  }, [dispatch, header]);

  useEffect(() => {
    dispatch(setShowProgressBar(false));
    dispatch(setShowNext(false));
    dispatch(setShowCreateNewAccount(false));
    dispatch(setShowLogin(false));

    if (isAuthFlow) {
      dispatch(setShowBack(false));
      dispatch(setShowFooter(false));
  } else {
      dispatch(setShowLogin(false));
      dispatch(setShowBack(true));
    }
  }, [dispatch]);

  return (
    <div className="createAccountContainer">
      <div className="buttonWrap">
        <GoogleLogin appId={postOnboardingComplete.data.applicationId} />
        <Button
          buttonText="Sign Up With Email"
          buttonIcon={<UserIcon />}
          onClick={() => dispatch(isAuthFlow ? stepNext() : setStep(29))}
        />
       <div className="crossAuthLinkerWrapper">
        <p >
          Already Have A Battalion Account?
        </p>
        <Link to="/login">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Step16;
