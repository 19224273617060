import { useState } from 'react';
import { useSelector } from "react-redux";

import ScoreRange from "../ScoreRange";
import { ReactComponent as ChevronLeftIcon } from "assets/svg/icons/chevron-left.svg";
// import {ReactComponent as RefreshIcon} from "../../../../assets/svg/icons/refresh-cw-05.svg";
import Skeleton from 'react-loading-skeleton';

export function CreditScoreBox() {
    const [isOpen, setIsOpen] = useState(true);
    const {testBorrowerDetailData: {isLoading, data: {creditScore}} } =
    useSelector(({ borrowerDetail }) => borrowerDetail);

    const parsedCreditScore = +creditScore;

    const getCreditScoreLabel = (score) => {
        switch (true) {
          case score >= 800:
            return "Excellent Score";
          case score >= 750 && score <= 799:
            return "Very Good Score";
          case score >= 700 && score <= 749:
            return "Good Score";
          case score >= 650 && score <= 699:
            return "Fair Score";
          case score <= 649:
            return "Poor Score";
          default:
            return "Invalid Score";
        }
      };

    if (!creditScore) return;

    return (
        <>
            <button onClick={() => setIsOpen(!isOpen)} className='button-unstyled p-0 d-flex align-items-center gap-24'>
                <div className="flex-center chevron"><ChevronLeftIcon fontSize={"20px"} className={`${isOpen ? 'chevron__to__bottom' : 'chevron__to__right'}`} /></div>
                <h4 className='font-indivisible'>Credit Score</h4>
            </button>
            {isLoading ?
            <Skeleton count={1} height={10} className={"mb-16"} /> :
            isOpen &&
            <div className={'d-flex space-between align-items-center mt-6'}>
                <div>
                    <div className='d-flex align-items-center gap-8'>
                        <h3 className={'nv-creditScoreBox__score'}>{parsedCreditScore}</h3>
                        {/* <RefreshIcon /> */}
                    </div>
                    <p className={'label-2 color-gray-900 nv-creditScoreBox__scoreState font-indivisible'}>{getCreditScoreLabel(parsedCreditScore)}</p>
                </div>
                <ScoreRange value={parsedCreditScore}/>
            </div>}
        </>
    )
}