import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Header } from "wrappers/NewVersion/components/Header/index.jsx";
import { Button } from "pages/Borrower/Components/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import { commonActions } from "wrappers/NewVersion/commonSlice.js";
import {
  setCurrentTab,
  getTestCategoriesList,
  getTemplatesListApi,
  getDocumentsApi,
} from "./documentSlice";
import { ReactComponent as HomeIcon } from "assets/svg/icons/home-line.svg";
import "./styles.scss";
import CategoriesTab from "./Tabs/Categories/CategoriesTab";
import DocumentsTab from "./Tabs/Documentss/DocumentsTab";
import TemplatesTab from "./Tabs/Templatess/TemplatesTab";

const DocumentManagement = () => {
  const dispatch = useDispatch();
  const { currentTab, categories, documents, templates } = useSelector(
    ({ document }) => document
  );
  const handleChange = (e, newVal) => {
    dispatch(setCurrentTab(newVal));
  };

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((res) => setTimeout(res, 100));

      if (currentTab === "categories") {
        await dispatch(
          getTestCategoriesList({
            page: categories.currentPage,
            searchText: categories.searchText,
            sortColumnName: categories.sortColumn.sortColumnName,
            sortOrder: categories.sortColumn.sortColumnOrder,
          })
        ).unwrap();
      } else if (currentTab === "documents") {
        await dispatch(
          getDocumentsApi({
            isActive: documents.isActive,
            page: documents.currentPage,
            searchText: documents.searchText,
            sortColumnName: documents.sortColumn.sortColumnName,
            sortOrder: documents.sortColumn.sortColumnOrder,
          })
        ).unwrap();
      } else if (currentTab === "templates") {
        await dispatch(
          getTemplatesListApi({
            page: templates.currentPage,
            searchText: templates.searchText,
            sortColumnName: templates.sortColumn.sortColumnName,
            sortOrder: templates.sortColumn.sortColumnOrder,
          })
        ).unwrap();
      }
    };

    fetchData();
  }, [
    currentTab,
    categories.currentPage,
    categories.searchText,
    categories.sortColumn.sortColumnName,
    categories.sortColumn.sortColumnOrder,
    dispatch,
    documents.currentPage,
    documents.searchText,
    documents.sortColumn.sortColumnName,
    documents.sortColumn.sortColumnOrder,
    documents.isActive,
    templates.currentPage,
    templates.searchText,
    templates.sortColumn.sortColumnName,
    templates.sortColumn.sortColumnOrder,
  ]);

  return (
    <div className="nv-document-management h-100">
      <Header />
      <section className="mainContainer">
        <div className="mainContainer__header">
          <div className="mainContainer__header--top">
            <HomeIcon />
            <div className="h1__wrapper">
              <h1>Documents Management</h1>
            </div>
            {currentTab === "categories" ? (
              <Button
                startIcon={<AddIcon fontSize="20px" />}
                label={"Category"}
                onClick={() => {
                  dispatch(commonActions.toggleDocsManagementCategoriesTrue());
                }}
              />
            ) : currentTab === "documents" && documents.isActive === "true" ? (
              <Button
                startIcon={<AddIcon fontSize="20px" />}
                label={"Document"}
                onClick={() =>
                  dispatch(commonActions.toggleDocsManagementDocumentsTrue())
                }
              />
            ) : currentTab === "templates" ? (
              <Button
                startIcon={<AddIcon fontSize="20px" />}
                label={"Template"}
                onClick={() =>
                  dispatch(commonActions.toggleDocsManagementTemplatesTrue())
                }
              />
            ) : null}
          </div>
        </div>
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: "#E5E5E5", width: "100%" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Templates" value="templates" />
              <Tab label="Documents" value="documents" />
              <Tab label="Categories" value="categories" />
            </TabList>
          </Box>
          <TabPanel value="templates">
            <TemplatesTab />
          </TabPanel>
          <TabPanel value="documents">
            <DocumentsTab />
          </TabPanel>
          <TabPanel value="categories">
            <CategoriesTab />
          </TabPanel>
        </TabContext>
      </section>
    </div>
  );
};

export default DocumentManagement;
