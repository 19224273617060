import Footer from 'components/Footer';
import ReactFluidScroll from 'utils/shared/ReactFluidScroll';
import './styles.scss';

const Policy = () => {
  return (
    <ReactFluidScroll>
      <section className='policy_page reading_page'>
        <div className='banner'>
          <h1 data-aos='fade-up' data-aos-delay='0' data-aos-duration='700'>
            Privacy Policy
          </h1>
        </div>
        <div className='content_wrap'>
          <div data-aos='fade-up' data-aos-delay='0' data-aos-duration='850'>
            <h1>We Respect Your Privacy</h1>
            <p>
              At Battalion Lending LLC we take your privacy seriously and do our
              best to protect it. We are required by law to ask for certain
              information in order to perform our services for you, but we are
              committed to protecting your personal and financial information.
              Below is our pledge to you: We don't ask you for personal or
              financial information unless we need it. We don't share your
              personal information with anyone except to comply with the law,
              develop our products, or protect our rights. We aim to make it as
              simple as possible for you to control what's visible to the
              public, seen by search engines, kept private, and permanently
              deleted. We will send you emails relating to specific transactions
              but will keep these emails to a minimum. We will maintain
              appropriate security standards and procedures to protect your
              information. We will respect the trust you place in us and the
              confidential nature of the information you provide through your
              financial relationship with us.
            </p>
            <h1>Our Policy</h1>
            <p>
              Welcome to the Website www.Battalion Lending LLC.com (the
              “Website”) owned and operated by Battalion Lending LLC, Inc.
              (Hereinafter, the “Company”, “Battalion Lending LLC”, “we”, “us”,
              and/or “our”). This Privacy Policy has been created to provide
              information about our company and its financial matchmaking
              services (the “Services”) to all visitors of the Website and the
              users of the Services provided thereon, including, but not limited
              to, investment managers offering an investment (the “Issuer”) and
              investors using the Website to invest (in each case, a “User”, and
              collectively, “Users”). This Privacy Policy sets forth Battalion
              Lending LLC' policy with respect to information, including
              personally identifiable information (“Personal Information”), and
              other information that is collected from Users. By using the
              Website and/or Services, you accept the practices and terms set
              forth in our Privacy Policy as it may be updated from time to time
              in Battalion Lending LLC' sole and absolute discretion.
            </p>
            <h2>What Information We Collect</h2>
            <p>
              When you interact with us through the Website or Services, we may
              collect and process Personal Information and other information
              from you, as further described below: Personal Information That
              You Provide Through the Website: We collect Personal Information
              from you when you voluntarily provide such information such as
              when you:
              <ul>
                <li>
                  Register for access to the Services (for example, contact
                  information such as your name, email address, phone number,
                  mailing address)
                </li>
                <li>Complete certain forms on the Website</li>
                <li>Upload or submit any material through the Website</li>
                <li>Request any information or contact us with inquiries</li>
                <li>
                  Wish to use certain Services (for example financial
                  information such as bank account numbers, your Date of Birth
                  and the last 4 digits of your social security number). This
                  Personal Information is required to transact business, to
                  communicate with you, verify your identity and fulfill legal
                  and regulatory requirements.
                </li>
              </ul>
              By voluntarily providing us with Personal Information, you are
              giving Battalion Lending LLC permission to use and store it
              consistent with this Privacy Policy. We may also obtain your
              personal information from your transactions with us or other Users
              through the Services, or from third parties such as credit
              reporting agencies. If we combine or associate information from
              other sources with personal information that you provide directly
              to us through or in connection with the Services, we will treat
              the combined information as Personal Information in accordance
              with this Privacy Policy.
            </p>
            <p>
              Non-Identifying Information: As is the case with most Websites,
              when you visit the Website or interact with Battalion Lending LLC
              through the Website or Services (and whether or not you register
              for access to the Services), we receive, automatically track and
              store certain non-identifying information about you, including but
              not limited to:
            </p>
            <ul>
              <li>Browser type</li>
              <li>IP address</li>
              <li>Language preference</li>
              <li>Cookies; domain servers</li>
              <li>URL traffic to, through and from the Website</li>
              <li>
                Types of computers and operating systems accessing the Website
              </li>
              <li>
                Any other web Website usage information that we may collect when
                you visit the Website.
              </li>
            </ul>
            <p>
              This information, which may be collected passively using various
              technologies or through other means, cannot be used to
              specifically identify you. Battalion Lending LLC may store such
              information itself or such information may be included in
              databases owned and maintained by Battalion Lending LLC
              affiliates, agents or service providers. The Website may use such
              information and pool it with other information for administrative
              purposes, to assess the usage, value and performance of our
              products and services, to improve your experience with the
              Services and to track, for example, how our Users use and interact
              with the Services. Also, in an ongoing effort to better understand
              and serve the users of the Website and Services, Battalion Lending
              LLC often conducts research on its customer demographics,
              interests and behavior based on the Personal Information and other
              information provided to Battalion Lending LLC by Issuers and
              Users. Battalion Lending LLC may also disclose aggregated user
              statistics in order to describe our Services to current and
              prospective business partners and to other third parties for other
              lawful purposes.
            </p>
            <h2>Technology (Cookies)</h2>
            <p>
              Cookies are small pieces of information which are issued to your
              computer when you visit a Website and which store and sometimes
              track information about your use of the Services. Battalion
              Lending LLC uses cookies to help recognize you as a repeat
              visitor, to improve the quality of our Services, and to try and
              make your browsing experience meaningful. When you enter our
              Website, our web server sends a cookie to your computer which
              allows us to recognize your computer but not specifically who is
              using it. By associating the identification numbers in the cookies
              with other customer information when, for example, you log-in to
              the Website or Services, then we know that the cookie information
              relates to you. Some of the cookies used by our Services are
              served by us, and some are served by third parties who are
              delivering services on our behalf. The use of cookies by any third
              parties, including our affiliates or service providers, is not
              covered by this Privacy Policy. We do not have access or control
              over these cookies.
            </p>
            <p>
              Most web browsers automatically accept cookies but, if you prefer,
              you can change your browser to prevent this or to notify you each
              time a cookie is sent. You can also learn more about cookies by
              visiting www.allaboutcookies.org which includes additional useful
              information on cookies and how to block cookies using different
              browsers. By blocking or deleting cookies used on our Website or
              Services, please note that you may not be able to take full
              advantage of our Services. Therefore, we recommend that you leave
              cookies turned on because they allow you to take advantage of some
              of the Website's features.
            </p>
            <h2>Use of Your Personal Information</h2>
            <p>
              Battalion Lending LLC considers the protection of sensitive
              information to be an important goal. Accordingly, Battalion
              Lending LLC uses the Personal Information you provide in a manner
              that is consistent with this Privacy Policy. If you provide
              Personal Information for a certain reason, we may use the Personal
              Information in connection with the reason for which it was
              provided. For example, we will use the Personal Information you
              provide to: identify you when you sign-in to your account; enable
              us to provide you with the Services; send you information we think
              you may find useful or which you have requested from us; present
              projects or opportunities to you when you use the Services which
              we believe will be of interest based on your geographic location
              and previous use of the Services; notify an Issuer of your
              interest in the event you indicate an interest in making an
              investment with the Issuer through the Services; administer your
              account with us; enable us to contact you regarding any question
              you make through the Services; analyze the use of the Services and
              the people visiting to improve our content and Services; use for
              other purposes that we may disclose to you when we request your
              information.
            </p>
            <p>
              Also, if you provide Personal Information in order to obtain
              access to the Services, we will use your Personal Information to
              provide you with access to such services and to monitor your use
              of such services. We also may obtain information about you from
              third party sources to verify your identity so we can properly
              process your requests and prevent fraud. In addition, Battalion
              Lending LLC and its subsidiaries and affiliates may also use your
              Personal Information and other non-identifiable information
              collected through the Website (collectively, “User Information”)
              to, among other things, improve user experience, customize content
              for our users in order to make the Website more simple to use, and
              to help us identify any other issues with respect to the Services
              or a User's use of the Website or Services. Battalion Lending LLC
              and its subsidiaries and affiliates may also use this information
              to communicate with you regarding the Services. If you object to
              our collection or storage of any User Information mentioned above
              (and any additional information we may choose to gather) you
              should not use the Website.
            </p>
            <h2>Email</h2>
            <p>
              Regarding email contact with and from Battalion Lending LLC,
              please keep in mind the following: we will send you email relating
              to your personal transactions but will keep these emails to a
              minimum; you will receive certain email notifications (forwarded
              messages, etc.) for which you may opt-out; we may send you
              service-related announcements on rare occasions when it is
              necessary or proper to do so; if you email us directly, we may
              email you back and keep records of all correspondence; and we may
              send you emails from time to time letting you know about special
              promotions, featured investments and other Battalion Lending LLC
              news. It is your responsibility to ensure that you maintain a
              current email address with Battalion Lending LLC, and notify us if
              there are any changes
            </p>
            <h2>Third Party Services</h2>
            <p>
              Battalion Lending LLC limits the sharing of your Personal
              Information and strives to keep your Personal Information private
              and confidential. We do not sell, license, lease or otherwise
              disclose your personal information to any unaffiliated third party
              for any reason, except as follows: We may disclose your Personal
              Information when such disclosure is required or appropriate
              pursuant to laws, court orders or securities regulations,
              including responding to court orders and subpoenas, cooperating
              with government agencies, other regulatory bodies and law
              enforcement officials, performing background checks, resolving
              disputes or performing risk-management functions.
            </p>
            <p>
              We may share your Personal Information with companies that provide
              services to us, including outside contractors or agents who help
              us manage our information activities, but they may only use your
              personal information to provide us with a specific service and not
              for any other purpose.
            </p>
            <p>
              To help us improve our services to you, we may engage third
              parties to help us carry out certain internal functions such as
              account processing, client services or other data collection
              relevant to our business. Use of any Personal Information shared
              in connection therewith is strictly limited to the performance of
              the task we request and for no other purpose. In the event you
              indicate an interest in making an investment through our Website,
              we will notify the Issuer of your interest and will provide your
              personal information to the Issuer.
            </p>
            <p>
              We may make certain automatically collected information about your
              interactions and activities on the Services, such as the business
              or investment areas of interest to you or you have invested in,
              and your past activities, projects or proposals, publicly
              available on or through the Services. Any such publicly available
              information will be accessible by other Users. In the event of a
              corporate sale, merger, reorganization, dissolution or similar
              event, Personal Information may be part of the transferred assets
              and you will be notified via email and/or a prominent notice on
              our Website of any change in ownership or uses of your personal
              information, as well as any choices you may have regarding your
              personal information.
            </p>
            <h2>Safeguarding User Information</h2>
            <p>
              The Security of your User Information is important to us, and we
              take commercially reasonable steps to safeguard such information.
              These steps and procedural safeguards are in line with industry
              standards and practices and include: Encryption and authentication
              tools to maintain the confidentiality of User Information
              including secure socket layer technology (SSL);
            </p>
            <p>
              Limits on, to the greatest extent possible, who or what can access
              your User Information; and The use of cookies to help
              (anonymously) recognize you as a repeat visitor and to track
              Website traffic patterns. However, despite these efforts, please
              note that Battalion Lending LLC cannot ensure or guarantee the
              security of your User Information nor do we make any
              representation or warranty regarding the security of your account.
              If we learn of a security systems breach, then we may attempt to
              notify you electronically so that you can take appropriate
              protective steps. We may also post a notice on or through the
              Website and the Services in the event of a security breach.
              Depending on where you live, you may have a legal right to receive
              notice of a security breach in writing.
            </p>
            <h2>Accurate Information</h2>
            <p>
              You are solely responsible for helping us to maintain the accuracy
              and completeness of your personal and other information. We urge
              you to review your information regularly to ensure that it is
              correct and complete. If you believe that any of your information
              is incorrect, please contact us at info@battalion.com. You may, of
              course, decline to share any or all of your Personal Information
              with us or ask us to delete your Personal Information from our
              systems at any time, in which case we may not be able to provide
              to you some of the features and functionality found on or through
              the Website and the Services. We will retain your User Information
              for as long as your account is active or as needed to provide you
              services. You may modify or delete the Personal Information you
              have provided to us by logging in and updating your account. We
              may, however, retain certain information as required by law or for
              necessary business purposes.
            </p>
            <h2>Public Information</h2>
            <p>
              Please keep in mind that any information you post on portions of
              the Website or Services that are open to the public, including but
              not limited to our blog, will be public information available to
              anyone. Information that you provide in these areas may be
              read,collected and used by others who access them. Accordingly,
              you should exercise caution when deciding to disclose your
              personal information in these areas. To request removal of your
              personal information from these publicly accessible areas, contact
              us at info@battalion.com. In some cases, we may not be able to
              remove your personal information and we will let you know if this
              is the case and why.
            </p>
            <h2>Links to Other Websites</h2>
            <p>
              We are not responsible for the practices employed by Websites
              linked to or from our Website or Services nor the information or
              content contained therein (collectively “Third Party Websites”).
              Please remember that when you use a link to go from our Website to
              a Third Party Website, our Privacy Policy is no longer in effect.
              Your browsing and interaction on any Third Party Website,
              including those that have a link on our Website, is subject to
              that Third Party Website's rules and policies. Please read over
              those rules and policies before proceeding.
            </p>
            <h2>International Users</h2>
            <p>
              The Services may be accessed and/or used by Users located outside
              the United States. If you choose to use the Services from the
              European Union or other regions of the world with laws governing
              data collection and use that may differ from U.S. law, then please
              note that you are transferring your personal information outside
              of those regions to the United States and by providing your
              personal information on or through the Services you consent to
              that transfer.
            </p>
            <h2>Updates and Changes to Privacy Policy</h2>
            <p>
              We reserve the right at any time to add to, change, update, or
              modify this Privacy Policy, simply by posting such change, update,
              or modification on the Website and without any other notice to
              you. Any such change, update or modification will be effective
              immediately upon posting on the Website. We encourage you to
              periodically review this page for the latest information on our
              privacy practices.
            </p>
            <h2>Children's Privacy</h2>
            <p>
              Battalion Lending LLC does not knowingly collect Personal
              Information from children under the age of 13 without the consent
              of their legal guardian. If you are under the age of 13, please do
              not submit any Personal Information through the Website. We
              encourage parents and legal guardians to monitor their children's
              Internet usage and to help enforce our Privacy Policy by
              instructing their children never to provide Personal Information
              on this Website without their permission. If you are a parent or
              guardian and discover that your child under the age of 13 has a
              registered account with the Services or Battalion Lending LLC has
              received any Personal Information of that child without your
              consent, you may contact us, and we will endeavor to delete that
              information from our databases.
            </p>
            <h2>Other Terms and Conditions</h2>
            <p>
              Your access to and use of this Website is subject to the Terms of
              Service. <br />
              Contact Battalion Lending LLC
              <br />
              If you have questions or comments about this Privacy Policy, your
              choices with regards to our collection and use of your User
              Information or any other aspect of the Website or Services, please
              feel free to contact us by email at{' '}
              <a href='mailto:info@battalion.com'>info@battalion.com</a> .
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </ReactFluidScroll>
  );
};

export default Policy;
