import { Link } from "react-router-dom";

import { ReactComponent as Investor } from "assets/svg/Heading/home-05.svg";
import { ReactComponent as Something } from "assets/svg/Heading/plus-circle.svg";
import { ReactComponent as Construction } from "assets/svg/Heading/tool-02.svg";
import { ReactComponent as Bridge } from "assets/svg/Heading/currency-dollar-circle.svg";
import { ReactComponent as Rental } from "assets/svg/Heading/building-07.svg";

import { AppRoute } from "utils/constants/ConstantsUtil";

const DropMenu = ({ onClose }) => {
  const goToContact = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  return (
    <div className="mega_menu_main_wrap">
      <div className={`mega_menu`}>
        <LinkCard
          to={AppRoute.RENTAL}
          title="Rental"
          subText="1-4 Unit, Mixed-Use,  Multifamily"
          icon={<Rental />}
          index={2}
          onClose={onClose}
        />
        <LinkCard
          to={AppRoute.BRIDGE}
          title="Bridge"
          subText="Acquisitions, Fix & Flip"
          icon={<Bridge />}
          index={1}
          onClose={onClose}
        />
        <LinkCard
          to={AppRoute.CONSTRUCTION}
          title="Construction"
          subText="Ground Up, Substantial Renovation"
          icon={<Construction />}
          index={1}
          onClose={onClose}
        />
        <LinkCard
          to={AppRoute.INVESTOR}
          title="Investor Second Mortgage"
          subText="1-4 Unit, Townhouses, Condos"
          icon={<Investor />}
          index={2}
          onClose={onClose}
        />
        <LinkCard
          title="Looking for Something Else"
          subText="Contact us to find out how we can work together."
          icon={<Something />}
          index={2}
          onClose={() => {
            goToContact();
            onClose && onClose();
          }}
        />
      </div>
    </div>
  );
};

const LinkCard = ({ to, title, subText, icon, onClose }) => {
  if (!to)
    return (
      <div onClick={onClose} className="link_card">
        <div className="image_wrap">{icon}</div>
        <div className="info_wrap">
          <div className="title">{title}</div>
          <div className="sub_text">{subText}</div>
        </div>
      </div>
    );
  return (
    <Link onClick={onClose} to={to} className="link_card">
      <div className="image_wrap">{icon}</div>
      <div className="info_wrap">
        <div className="title">{title}</div>
        <div className="sub_text">{subText}</div>
      </div>
    </Link>
  );
};
export default DropMenu;
