import { useState, useRef, memo, useEffect } from "react";
import InputMask from "react-input-mask";

import { ReactComponent as AlerIcon } from "assets/svg/icons/alert-circle.svg";
import { ReactComponent as EyePasswordIcon } from "assets/svg/icons/eye-password.svg";
import { ReactComponent as EyeOffPasswordIcon } from "assets/svg/icons/eye-off-password.svg";
import "./styles.scss";

function InputText({
  val = null,
  updateForm = null,
  type = null,
  dollar = null,
  unit = null,
  placeholder = null,
  secondary = null,
  focus = false,
  handleFocus = null,
  label = null,
  numeric = null,
  isValid = true,
  disabled = false,
  notCapitalized = false,
  customNotValidMessage = null,
  limit = null,
  password = false,
  dynamic = false,
  phone = false,
  withValidationErrorHold = true,
  showPassword = false,
  toggleShowPassword = null,
}) {
  const [isFocused, setIsFocused] = useState(false);
  console.log(isFocused, "isFocused");
  const textbox = useRef();
  useEffect(() => {
    setIsFocused(focus);
  }, [focus]);
  const handleKeydown = (e) => {
    const currentValue = textbox.current?.value;

    if (limit && currentValue.length >= limit && e.keyCode !== 8) {
      e.preventDefault();
    }

    if (e.keyCode === 13 || (password && phone && e.keyCode === 32)) {
      e.preventDefault();
    }

    if (numeric && !/^\d+$/.test(e.key) && e.key !== "." && e.keyCode !== 8) {
      e.preventDefault();
      return;
    }
  };
  const handleChange = (e) => {
    const inputValue = e.target.value;
    let formattedValue = inputValue;

    if (numeric) {
      // Remove existing commas and parse the input as a number
      const numericValue = parseFloat(inputValue.replace(/,/g, ""));

      // Check if the numericValue is a valid number
      if (!isNaN(numericValue)) {
        // Format the numericValue with commas as thousand separators
        formattedValue = numericValue.toLocaleString();
      }
    }

    // Update the form with the formatted value
    Boolean(updateForm) && updateForm(formattedValue);
  };

  // const handleChange = (e) => {
  //   Boolean(updateForm) && updateForm(e.target.value);
  // };

  return (
    <div className="megaWrapper">
      {label ? (
        <span className={`inputLabel ${label && val && ("" + val).length > 0 ? "" : "hide"}`}>
          {label}
        </span>
      ) : (
        <span className="inputLabelHold" />
      )}

      <div className="textInputWrapper">
        {val?.length > 0 && dollar && (
          <span
            className={`inputSpan ${type} ${!isValid && !isFocused ? "notValid" : ""} `}
            style={{
              width: "fit-content",
              paddingRight: "5px",
              ...(isFocused ? { borderBottom: "1px solid #424242" } : {}),
            }}
          >
            $
          </span>
        )}
        {phone ? (
          <InputMask
            mask="(999) 999-9999"
            maskChar={null}
            value={val}
            onChange={handleChange}
            onFocus={() => handleFocus(true)}
            onBlur={() => handleFocus(false)}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                ref={textbox}
                type={type}
                className={`inputSpan ${type} ${!isValid && !focus ? "notValid" : ""} ${
                  disabled ? "disabled" : ""
                }`}
                onKeyDown={handleKeydown}
                placeholder={placeholder}
                inputMode={numeric ? "decimal" : "text"}
                autoCapitalize={notCapitalized ? "none" : "sentences"}
                autoFocus={focus}
              />
            )}
          </InputMask>
        ) : (
          <>
            <input
              ref={textbox}
              type={password ? "password" : "text"}
              className={`inputSpan ${type} ${!isValid && !isFocused ? "notValid" : ""} ${
                disabled ? "disabled" : ""
              }`}
              readOnly={disabled}
              onChange={handleChange}
              onKeyDown={handleKeydown}
              placeholder={dollar ? "$" + placeholder : placeholder}
              inputMode={numeric ? "decimal" : "text"}
              autoCapitalize={notCapitalized ? "none" : "sentences"}
              autoFocus={!disabled && focus}
              value={val}
              onFocus={() => {
                if (!disabled) {
                  Boolean(handleFocus) && handleFocus(true);
                  setIsFocused(true);
                }
              }}
              onBlur={() => {
                if (!disabled) {
                  Boolean(handleFocus) && handleFocus(false);
                  setIsFocused(false);
                }
              }}
              maxLength={limit ? limit : 100}
            />
            {toggleShowPassword && (
              <div className={`eyePasswordIcon ${isFocused ? "focused" : ""}`}>
                {showPassword ? (
                  <EyeOffPasswordIcon onClick={toggleShowPassword} />
                ) : (
                  <EyePasswordIcon onClick={toggleShowPassword} />
                )}
              </div>
            )}
          </>
        )}
      </div>
      {withValidationErrorHold ? (
        !isValid && !isFocused ? (
          <div className="validationError">
            <AlerIcon />
            <span>
              {val === "" || val === null
                ? "Compulsory field"
                : customNotValidMessage
                ? customNotValidMessage
                : "Validation Error"}
            </span>
          </div>
        ) : (
          <span className="validationErrorHold" />
        )
      ) : null}
      {Boolean(secondary) ? (
        !isFocused ? (
          <p className="secondaryText">{secondary}</p>
        ) : (
          <span className="secondaryTextHold" />
        )
      ) : null}
    </div>
  );
}

export default memo(InputText);
