import { useState } from "react";
import { ReactComponent as MapMarkerIcon } from "../../../../assets/svg/icons/map-marker-icon.svg";
import { ReactComponent as LetterIcon } from "../../../../assets/svg/icons/message-icon.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/svg/icons/phone-icon.svg";
import { ReactComponent as ChevronLeftIcon } from "assets/svg/icons/chevron-left.svg";
import DetailsButton from "../Button/DetailsButton";
import { ChevronRight } from "@mui/icons-material";
import Skeleton from "react-loading-skeleton";
import { getFormattedPhoneNumber } from "../../../../utils/shared/helpers";
import BorrowerDetailDrawer from "../BorrowerDetailDrawer";

import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import { useBorrowerContext } from "pages/Borrower/BorrowerDetail";

export function BorrowerInfo() {
  const dispatch = useDispatch();
  const { canViewDetails } = useBorrowerContext();
  const { borrowerDetails } = useSelector((state) => state.common);

  // TODO error handling
  const {
    testBorrowerDetailData: {
      isLoading,
      data: { currentAddress, email, phone, propertyOwnershipDisplayName, locationStatus },
    },
  } = useSelector(({ borrowerDetail }) => borrowerDetail);
  const [isOpen, setIsOpen] = useState(true);

  const locationStatusDisplayName = {
    1: "(>2 years)",
    2: "(<2 years)",
  }[locationStatus];

  const currentAddressArray =
    typeof currentAddress === "string" ? currentAddress.split(",").map((item) => item.trim()) : [];

  const handlePreviewDetails = () => {
    console.log(borrowerDetails);
    dispatch(commonActions.toggleBorrowerDetailsVisibility());
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`button-unstyled p-0 d-flex align-items-center gap-24 ${!isOpen && "mb-12"}`}
      >
        <div className="flex-center chevron">
          <ChevronLeftIcon
            fontSize={"20px"}
            className={`${isOpen ? "chevron__to__bottom" : "chevron__to__right"}`}
          />
        </div>
        <h4 className="font-indivisible">Info</h4>
      </button>
      {isLoading ? (
        <Skeleton count={4} height={30} className={"mb-16"} />
      ) : (
        isOpen && (
          <div className="pt-32 pb-48 nv-borrowerInfo">
            {currentAddress && (
              <BorrowerInfoItem className="mb-24" icon={<MapMarkerIcon />}>
                <div className="gap-8 d-flex space-between align-items-start">
                  <p className="label-6 flex-2 font-indivisible">
                    {currentAddressArray[0]} <br /> {currentAddressArray[1]},{" "}
                    {currentAddressArray[2]} {currentAddressArray[4]}
                  </p>
                  {propertyOwnershipDisplayName && (
                    <div className="border-radius-4 nv-rentYear">
                      <p className="label-2 color-gray-700 text-align-center font-indivisible">
                        {propertyOwnershipDisplayName} {locationStatusDisplayName}
                      </p>
                    </div>
                  )}
                </div>
              </BorrowerInfoItem>
            )}
            {email && (
              <BorrowerInfoItem className="mb-24" icon={<LetterIcon />}>
                <p className="label-6 font-indivisible">{email}</p>
              </BorrowerInfoItem>
            )}
            {phone && (
              <BorrowerInfoItem icon={<PhoneIcon />}>
                <p className="label-6 pb-28 font-indivisible">{getFormattedPhoneNumber(phone)}</p>
              </BorrowerInfoItem>
            )}
            {canViewDetails && (
              <DetailsButton
                label={"Details"}
                onClick={handlePreviewDetails}
                endIcon={<ChevronRight fontSize="20px" />}
              />
            )}
          </div>
        )
      )}
      <BorrowerDetailDrawer />
    </>
  );
}

export function BorrowerInfoItem({ icon, className = "", children }) {
  return (
    <div className={`d-flex gap-24 nv-borrowerInfo__item ${className}`}>
      <div className="flex-center border-radius-4 nv-borrowerInfo__iconBox">{icon}</div>
      <div className="flex-1 my-auto">{children}</div>
    </div>
  );
}
