import { useRef, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "components/Footer";
import ReactFluidScroll from "utils/shared/ReactFluidScroll";
import { NumberAnimator, GetStartedBanner } from "components/DisplayComponents";

import Phone from "assets/images/Phone.png";
import Union from "assets/images/Union.svg";
import { ReactComponent as Experience } from "assets/svg/Landing/Experience.svg";
import { ReactComponent as Pricing } from "assets/svg/Landing/Pricing.svg";
import { ReactComponent as Speed } from "assets/svg/Landing/Speed.svg";
import { ReactComponent as Service } from "assets/svg/Landing/Service.svg";
import { ReactComponent as LendAreas } from "assets/svg/Landing/LendAreas.svg";
import "./styles.scss";

const LandingPage = () => {
  const location = useLocation();
  const whereWeLendRef = useRef();
  const detailsItems = [
    {
      icon: <Speed />,
      title: "Speed",
      description:
        "Close a loan in 15 days or less. We remove the hassle of back-and-forth emails and unnecessary paperwork with our technology.",
    },
    {
      icon: <Pricing />,
      title: "Pricing",
      description:
        "The best rates on the market. We have you covered with interest rates as low as 8.99% for bridge loan projects and 5.88% for rental properties.",
    },
    {
      icon: <Experience />,
      title: "Experience",
      description:
        "Humans and AI working for you. We combine our proprietary technology with 20 years of industry experience to optimize your real estate loans portfolio for the greatest returns.",
    },
    {
      icon: <Service />,
      title: "Service",
      description:
        "A relationship that doesn’t end when the loan closes. We are invested in your success and partner with you on all your deals to move your business forward.",
    },
  ];

  useLayoutEffect(() => {
    if (location.hash === "#lend") scrollToWhereWeLend();
  }, [location]);

  useEffect(() => {
    //scroll check on init after the animations are done
    setTimeout(() => {
      if (location.hash === "#lend") scrollToWhereWeLend();
    }, 600);
  });

  const scrollToWhereWeLend = () => {
    if (whereWeLendRef?.current) {
      window?.scrollTo(0, whereWeLendRef.current.offsetTop - 90);
      setTimeout(() => {
        window?.history.replaceState(null, null, " ");
      }, 2000);
    }
  };

  return (
    <ReactFluidScroll>
      <section className="landing_page page_wrapper">
        <div></div>
        <img
          src={Union}
          className="bg_image"
          alt="bg"
          data-aos="fade-up"
          data-aos-delay="0"
          data-aos-duration="200"
        />
        <div className="hero_section">
          <div className="content_wrap">
            <div className="text_wrap">
              <h1 data-aos="fade-up" data-aos-delay="0" data-aos-duration="700">
                Financing <br /> done better
              </h1>
              <p data-aos="fade-up" data-aos-delay="0" data-aos-duration="800">
                Tech powered lending that moves <br /> your real estate business
                forward.
              </p>
            </div>
            <div className="hero_image">
              <img
                src={Phone}
                alt="PhoneImage"
                className="phoneImage"
                data-aos-offset="-500"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="800"
              />
            </div>
          </div>
        </div>
        <div className="site_main_details_section">
          <div className="content_wrap">
            <div className="flex_box response_stays space_between">
              <div className="details_items_wrapper">
                {detailsItems.map((item, index) => (
                  <DetailItem key={index} index={index} {...item} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className="site_stats_section"
          data-aos="fade-up"
          data-aos-delay="0"
          data-aos-duration="600"
          data-aos-once="true"
        >
          <div className="content_wrap">
            <div className="flex_box">
              <div className="stat_item">
                <NumberAnimator number="50" prefix="$" suffix="B+" />
                <p>Team’s Lending Experience</p>
              </div>
              <div className="stat_item">
                <NumberAnimator number="15" suffix=" days" />
                <p>Average Time to Close a Loan</p>
              </div>
              <div className="stat_item">
                <NumberAnimator number="93.2" suffix="%" decimals={true} />
                <p>Repeat Clients</p>
              </div>
            </div>
          </div>
        </div>
        <div className="where_we_lend_section" ref={whereWeLendRef}>
          <div className="content_wrap">
            <div
              className="heading"
              data-aos="fade-up"
              data-aos-delay="0"
              data-aos-duration="600"
            >
              Where We Lend
            </div>
            <div className="flex_box response_stays space_around">
              <LendAreas
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="700"
              />
              <div
                className="legend_wrap"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="750"
              >
                <div className="legend_item green">We Lend Here</div>
                <div className="legend_item">Coming Soon</div>
              </div>
            </div>
          </div>
        </div>
        <GetStartedBanner />
      </section>
      <Footer />
    </ReactFluidScroll>
  );
};

const DetailItem = ({ icon, title, description, index }) => {
  return (
    <div className="detail_item">
      <div
        className="icon"
        data-aos="zoom-in"
        data-aos-offset="-300"
        data-aos-delay={200 + index * 50}
        data-aos-duration="700"
      >
        {icon}
      </div>
      <div
        className="title"
        data-aos="fade-left"
        data-aos-offset="-300"
        data-aos-delay={300 + index * 50}
        data-aos-duration="650"
      >
        {title}
      </div>
      <p
        data-aos="fade-left"
        data-aos-offset="-300"
        data-aos-delay={300 + index * 50}
        data-aos-duration="700"
      >
        {description}
      </p>
    </div>
  );
};

export default LandingPage;
