import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { ReactComponent as NewLoanIcon } from "assets/svg/icons/newLoanIcon.svg";
import { Modal } from "wrappers/NewVersion/components/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar, Grid } from "@mui/material";
import Input from "../Components/Input";
import SearchInput from "./SearchInput";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteMessageModal from "pages/DocumentManagement/Tabs/Categories/DeleteMessageModal";
import { commonActions } from "wrappers/NewVersion/commonSlice.js";
import { validationSchemaNewLoanAnotherUser, validationSchemaNewLoanMyself } from "../constants";
import { useNavigate } from "react-router-dom";
import { getUserAccessControlAutoCompleteApi, postLoanNextApi } from "../loanDetailSlice";
import { ReactComponent as InfoQuadIcon } from "assets/svg/icons/info-quad.svg";
import { UserRoles } from "utils/constants/ConstantsUtil";
import { setNewLoan } from "pages/OnBoarding/onboardingSlice";

const useMessageModalState = () => {
  const [message, setMessage] = useState(null);
  const [header, setHeader] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  return {
    message,
    header,
    buttonText,
    setMessage,
    setHeader,
    setButtonText,
    reset: () => {
      setMessage(null);
      setHeader(null);
      setButtonText(null);
    },
  };
};

const NewLoanModal = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { borrowerId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const messageModal = useMessageModalState();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const {
    testBorrowerDetailData: {
      data: { firstName, lastName, email },
    },
  } = useSelector(({ borrowerDetail }) => borrowerDetail);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
  }, []);

  const isLender = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(user?.accessType);
  }, [user]);
  const [selectedType, setSelectedType] = useState("Myself");
  const [userData, setUserData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [newUserData, setNewUserData] = useState({});
  const [isNewUser, setIsNewUser] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const { register, handleSubmit, formState, setValue, reset, control, watch } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    criteriaMode: "all",
    resolver:
      selectedType === "Another User"
        ? yupResolver(validationSchemaNewLoanAnotherUser)
        : yupResolver(validationSchemaNewLoanMyself),
  });
  const { errors } = formState;

  useEffect(() => {
    if (isLender) {
      setSelectedType("Another User");
      if (borrowerId) {
        setValue("firstName", firstName);
        setValue("lastName", lastName);
        setValue("email", email);
        setUserData({
          firstName,
          lastName,
          email,
          name: firstName + " " + lastName,
        });
      }
    } else if (!borrowerId) {
      setSelectedType("Myself");
    } else if (borrowerId) {
      if (borrowerId == user?.dId) {
        setSelectedType("Myself");
      } else {
        setSelectedType("Another User");
        setValue("firstName", firstName);
        setValue("lastName", lastName);
        setValue("email", email);
        setUserData({
          firstName,
          lastName,
          name: firstName + " " + lastName,
        });
        setNewUserData({});
        setIsEmailValid(true);
        setIsNewUser(false);
      }
      setNewUserData({});
      setIsEmailValid(true);
      setIsNewUser(false);
    }
  }, [isLender, borrowerId, user, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setNewUserData({});
      setIsEmailValid(true);
      setIsNewUser(false);
      reset({
        firstName: "",
        email: "",
        lastName: "",
      });
      setUserData({});
      setSearchText("");
    }
  }, [isOpen]);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const res = await dispatch(
        postLoanNextApi({
          anonymusId: null,
          dataAsJson:
            selectedType === "Myself"
              ? JSON.stringify({
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                })
              : JSON.stringify(
                  !isNewUser && isLender
                    ? {
                        firstName: userData.name.split(" ")[0],
                        lastName: userData.name.split(" ")[1],
                        email: userData.email,
                      }
                    : {
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                        email: data?.email,
                      },
                ),
        }),
      ).unwrap();

      setUserData({});

      dispatch(
        setNewLoan(
          selectedType === "Myself"
            ? {
                FirstName: user?.firstName,
                LastName: user?.lastName,
                Email: user?.email,
                anonymusId: res.anonymusId,
                pathname: location.pathname,
              }
            : isNewUser || !isLender
            ? {
                FirstName: data?.firstName,
                LastName: data?.lastName,
                Email: data?.email,
                anonymusId: res.anonymusId,
                pathname: location.pathname,
              }
            : {
                FirstName: userData.name.split(" ")[0],
                LastName: userData.name.split(" ")[1],
                Email: userData.email,
                anonymusId: res.anonymusId,
                pathname: location.pathname,
              },
        ),
      );

      navigate("/new-loan");
    } catch (e) {
      messageModal.setButtonText("OK");
      messageModal.setHeader("Can't create new loan");
      messageModal.setMessage(e.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
    } finally {
      setIsLoading(false);
    }
  };

  const searchName = async (val) => {
    const res = await dispatch(getUserAccessControlAutoCompleteApi({ searchText: val })).unwrap();
    setSearchText(val);
    return res;
  };

  const handleAddNew = () => {
    setUserData({});
    const isEmail = String(searchText)
      .trim()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
    if (isEmail) {
      setIsNewUser(true);
      setValue("email", searchText);
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };

  const onClose = () => {
    closeModal();
    setUserData({});
    setSearchText("");
  };

  const allFields = watch();

  return (
    <>
      <Modal icon={<NewLoanIcon width={24} height={24} />} isOpen={isOpen} handleClose={onClose}>
        {isOpen && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} rowSpacing={2}>
              <Grid item xs={12}>
                <h2 className="accessModal-title">New Loan </h2>
                {!isLender && (
                  <p className="accessModal-subTitle">Choose who are you applying the loan for</p>
                )}
              </Grid>
              {!isLender && (
                <Grid item xs={12}>
                  <div className={`typeContainer ${!isLender ? "extraMarginBottom" : ""}`}>
                    {["Myself", "Another User"].map((type) => {
                      return (
                        <div
                          key={type}
                          className={`type ${type === selectedType ? "active" : ""}`}
                          onClick={() => setSelectedType(type)}
                        >
                          {type}
                        </div>
                      );
                    })}
                  </div>
                </Grid>
              )}
              {selectedType === "Another User" && (
                <>
                  <Grid item xs={12}>
                    {isLender ? (
                      <SearchInput
                        control={control}
                        name="email"
                        label="Email"
                        searchFunc={searchName}
                        defaultValue={userData?.email || ""}
                        onClick={(data) => {
                          setValue("firstName", data?.name.split(" ")[0].trim());
                          setValue("lastName", data?.name.split(" ")[1].trim());
                          setValue("email", data?.email);
                          setUserData(data);
                          setNewUserData({});
                          setIsEmailValid(true);
                          setIsNewUser(false);
                        }}
                        required
                        type={"search"}
                        errorMessage={!isEmailValid ? "Please enter valid email address" : null}
                        empty={
                          <>
                            <li className="listitem noBackgroundChange">User not found</li>
                            <li
                              className="listitem cursorPointer empty"
                              onClick={() => {
                                setUserData({});
                                handleAddNew();
                              }}
                            >
                              + Add User
                            </li>
                          </>
                        }
                      />
                    ) : (
                      <Input
                        name={"email"}
                        type="email"
                        label={"Email"}
                        register={register}
                        required
                        errorMessage={
                          errors.email?.message || !isEmailValid
                            ? "Please ender valid email address"
                            : null
                        }
                        value={newUserData?.email || userData?.email}
                      />
                    )}
                  </Grid>
                  {["firstName", "lastName"].map((field, index) => (
                    <Grid key={index} item xs={12}>
                      <Input
                        name={field}
                        type="text"
                        label={index === 0 ? "First Name" : "Last Name"}
                        register={register}
                        required
                        errorMessage={errors[field]?.message}
                        disabled={userData?.email}
                      />
                    </Grid>
                  ))}
                  {isLender && isNewUser && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: "24px",
                      }}
                    >
                      <div className="disclaimerNewUser">
                        <div className="disclaimerNewUser--icon">
                          <InfoQuadIcon />
                        </div>
                        <div className="disclaimerNewUser--text">
                          <span className="title">New User</span>
                          <span className="description">
                            This user doesn't exist in the system, you will be creating a new
                            account for them.
                          </span>
                        </div>
                      </div>
                    </Grid>
                  )}
                  {isLender && userData?.email && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: "24px",
                      }}
                    >
                      <div className="selectedUser">
                        <div className="selectedUser--icon">
                          <Avatar
                            sx={{
                              width: 32,
                              height: 32,
                              fontSize: "14px",
                              color: "#525252",
                              bgcolor: "#F5F5F5",
                            }}
                          >
                            {`${userData.name.split(" ")[0].charAt(0)}${userData.name
                              .split(" ")[1]
                              .charAt(0)}`}
                          </Avatar>
                        </div>
                        <div className="selectedUser--text">
                          <span className="title">{userData.name}</span>
                          <span className="description">{userData.email}</span>
                        </div>
                      </div>
                    </Grid>
                  )}
                </>
              )}
              <Grid
                item
                xs={6}
                style={{
                  paddingTop: "32px",
                }}
              >
                <LoadingButton
                  variant="outlined"
                  onClick={onClose}
                  sx={{
                    padding: "10px 18px",
                    width: "100%",
                    height: "44px",
                    background: "#FFFFFF",
                    border: "1px solid #D6D6D6",
                    boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    borderRadius: "32px",
                    fontFamily: "Indivisible",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "1rem",
                    lineHeight: "1.5em",
                    color: "#424242",
                    letterSpacing: "-0.04em",
                    textTransform: "none",
                    "&:hover": {
                      border: "1px solid #D6D6D6",
                      background: "#FFFFFF",
                    },
                  }}
                >
                  Cancel
                </LoadingButton>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  paddingTop: "32px",
                }}
              >
                <LoadingButton
                  variant="contained"
                  type="submit"
                  sx={{
                    padding: "10px 18px",
                    width: "100%",
                    height: "44px",
                    background: "#A459FF",
                    border: "1px solid #A459FF",
                    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    borderRadius: "32px",
                    fontFamily: "Indivisible",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "1rem",
                    lineHeight: "1.5em",
                    color: "#FFFFFF",
                    letterSpacing: "-0.04em",
                    textTransform: "none",

                    "&:hover": {
                      border: "1px solid #A459FF",
                      background: "#A459FF",
                    },

                    "&:disabled": {
                      opacity: "0.5",
                      border: "1px solid #A459FF",
                      background: "#A459FF",
                    },
                  }}
                  loading={isLoading}
                  disabled={
                    selectedType !== "Myself" &&
                    (Object.keys(errors).length > 0 ||
                      !isEmailValid ||
                      allFields.email?.length === 0 ||
                      allFields.firstName?.length === 0 ||
                      allFields.lastName?.length === 0)
                  }
                >
                  Next
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Modal>
      {messageModal.message && (
        <DeleteMessageModal
          message={messageModal.message}
          icon={"inviteUser"}
          header={messageModal.header}
          removeMessage={messageModal.reset}
          buttonText={messageModal.buttonText}
        />
      )}
    </>
  );
};

export default NewLoanModal;
