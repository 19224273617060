import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderQuestion,
  setShowNext,
  setCondition,
  getPropertyConditions,
  setSendData,
} from "../onboardingSlice";
import Button from "../Components/Button";
import { ReactComponent as ThumbsUpIcon } from "assets/svg/icons/thumbs-up.svg";
import { ReactComponent as ThumbsDownIcon } from "assets/svg/icons/thumbs-down.svg";
import { ReactComponent as CircleIcon } from "assets/svg/icons/circle.svg";

const Step5 = () => {
  const dispatch = useDispatch();
  const { Condition } = useSelector((state) => state.onboarding.body.form);
  const { postOnboarding, propertyConditions } = useSelector((state) => state.onboarding.body);
  const question = "What is the current condition of the property?";

  useEffect(() => {
    dispatch(getPropertyConditions());
  }, [dispatch]);
  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);
  const handleClick = (id) => {
    dispatch(setCondition(id));
  };

  useEffect(() => {
    if (Condition !== null) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ Condition }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [Condition, dispatch, postOnboarding]);
  return (
    <div className="buttonWrap">
      {propertyConditions.data.map((type) => {
        return (
          <Button
            key={type?.id}
            buttonText={type.name}
            buttonIcon={
              type?.id === 1 ? (
                <ThumbsUpIcon />
              ) : type?.id === 2 ? (
                <CircleIcon />
              ) : type?.id === 3 ? (
                <ThumbsDownIcon />
              ) : null
            }
            onClick={() => handleClick(type?.id)}
            active={Condition === type?.id}
          />
        );
      })}
    </div>
  );
};

export default Step5;
