import Dropdown from "../Dropdown/Dropdown";
import { usePopper } from "../../hooks/usePopper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as CheckCircle } from "../../../../assets/svg/icons/check-cirlcle-status.svg";
import { ReactComponent as MinusCircle } from "../../../../assets/svg/icons/minus-cirle-status.svg";
import { ReactComponent as ClockIcon } from "../../../../assets/svg/icons/clock-icon-status.svg";
import { ReactComponent as RejectedCirle } from "../../../../assets/svg/icons/rejected-cirle.svg";
import {
  getFileStatusesApi,
  getSDStatusesApi,
  updateDocSubStatusApi,
  updateFileStatusApi,
} from "pages/DocumentManagement/documentSlice";
import { useParams } from "react-router-dom";
import { getTestLoanDocumentsApi } from "pages/Borrower/loanDetailSlice";
import { commonActions } from "wrappers/NewVersion/commonSlice";

const REJECTED_STATUS = 4;

const StatusSDFDropdown = ({ label, isDocument = true, docId, onChangeFunc = null }) => {
  const [showPopper, handlePopper] = usePopper();
  const dispatch = useDispatch();
  const [isUpdate, setIsUpdate] = useState();

  const { loanId } = useParams();

  const {
    testLoanDetailData: { isLoading: isLoadingLoanDocuments },
  } = useSelector(({ loanDetail }) => loanDetail);

  const {
    SDStatuses: { data: SDStatuses },
    FileStatuses: { data: fileStatuses },
  } = useSelector(({ document }) => document.documents);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getSDStatusesApi()).unwrap();
      await dispatch(getFileStatusesApi()).unwrap();
    };
    const timeout = setTimeout(fetchData, 10);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (isUpdate) {
      const fetchData = async () => {
        dispatch(getTestLoanDocumentsApi(loanId));
        onChangeFunc && onChangeFunc();
      };
      setTimeout(() => {
        fetchData();
        setIsUpdate(false);
      }, "1000");
    }
  }, [isUpdate]);

  const statuses = isDocument
    ? SDStatuses.filter((status) => status.id === 3 || status.id === 5)
    : fileStatuses.filter((status) => status.id === 3 || status.id === 2 || status.id === 4);

  const icon = (status) =>
    (isDocument
      ? {
          3: <CheckCircle />,
          5: <MinusCircle />,
        }
      : {
          2: <ClockIcon />,
          3: <CheckCircle />,
          4: <RejectedCirle />,
        })[status];

  const handleStatusChange = (id) => {
    handlePopper(false);

    if (REJECTED_STATUS === id) {
      dispatch(
        commonActions.toggleRejectionReasonModal({
          isVisible: true,
          fileId: docId,
          statusId: statuses.find((status) => status.id === id).id,
        }),
      );
    } else {
      setIsUpdate(true);
      isDocument
        ? dispatch(
            updateDocSubStatusApi({
              loanDocumentId: docId,
              status: statuses.find((status) => status.id === id).id,
            }),
          )
        : dispatch(
            updateFileStatusApi({
              loanDocumentFileId: docId,
              status: statuses.find((status) => status.id === id).id,
              rejectionReason: "",
            }),
          );
    }
  };

  return isLoadingLoanDocuments ? (
    <Skeleton height={30} width={30} style={{ borderRadius: "100%" }} />
  ) : (
    <Dropdown
      placement="bottom-start"
      // isLoading={true}
      showPopper={showPopper}
      handlePopper={handlePopper}
      listContainer="templatelistcontainer"
      maxVisibleItems={5}
      listClassName="mt-6"
      label={label}
    >
      {statuses.map(({ id, name }) => (
        <li
          key={id}
          onClick={() => handleStatusChange(id)}
          className="listitem-template archiveColor cursorPointer d-flex align-items-center gap-8"
        >
          {icon(id)}
          <p>{name}</p>
        </li>
      ))}
    </Dropdown>
  );
};

export default StatusSDFDropdown;
