import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@yisheng90/react-loading";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

import { validationSchemaProfileInfo } from "../constants";
import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";
import { Button } from "pages/Borrower/Components/Button/Button";
import { ImageUploadBox1 } from "pages/UserManagement/Components/ImageUploadBox1";
import { Avatar } from "@mui/material";
import { Grid } from "@mui/material";
import Input from "../Components/Input";
import {
  createEndUser,
  getEndUser,
  setCurrentUser,
  setCurrentUserId,
  updateEndUser,
  getListOfUsers,
  getOwnershipStatuses,
  getLocationStatuses,
  getCitizenshipStatuses,
} from "../userSlice";
import ButtonGroup from "wrappers/NewVersion/components/ButtonGroup";
import DatePicker from "../Components/DatePicker";
import dayjs from "dayjs";
import SearchInput from "../Components/SearchInput";
import { getAddress } from "pages/OnBoarding/onboardingSlice";

const ProfileInfo = ({ id, previewImage, setPreviewImage }) => {
  const dispatch = useDispatch();
  const { users } = useSelector(({ user }) => {
    return user;
  });
  const isOpenDrawer = useSelector(({ common }) => common.userFormDrawer);

  const [userData, setUserData] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [citizenships, setCitizenships] = useState([]);
  const [locations, setLocations] = useState([]);
  const [ownerships, setOwnerships] = useState([]);
  const [file, setFile] = useState(new FormData());

  const getStatuses = async () => {
    const ownerships = await dispatch(getOwnershipStatuses()).unwrap();
    setOwnerships(ownerships);
    const locations = await dispatch(getLocationStatuses()).unwrap();
    setLocations(locations);
    const citizenships = await dispatch(getCitizenshipStatuses()).unwrap();
    setCitizenships(citizenships);
  };

  useEffect(() => {
    getStatuses();
  }, []);

  const { register, handleSubmit, formState, setValue, reset, getValues, control } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    criteriaMode: "all",
    shouldUnregister: false,
    resolver: yupResolver(validationSchemaProfileInfo),
  });
  const { isDirty, isValid, errors } = formState;
  const onSubmit = async (data) => {
    const finalFormData = new FormData();
    finalFormData.append("firstName", data.firstName);
    finalFormData.append("lastName", data.lastName);
    !id && finalFormData.append("password", data.password);
    !id && finalFormData.append("confirmPassword", data.confirmPassword);
    finalFormData.append("phoneNumber", data.phone);
    finalFormData.append("email", data.email);
    if (data.dateOfBirth) {
      const parsedDate = dayjs(data.dateOfBirth);
      finalFormData.append("dateOfBirth", parsedDate);
    }
    data.citizenship && finalFormData.append("citizenship", data.citizenship.id);
    data.currentAddress && finalFormData.append("currentAddress", data.currentAddress);
    data.locationStatus && finalFormData.append("locationStatus", data.locationStatus);
    data.previusAddress && finalFormData.append("previusAddress", data.previusAddress);
    data.ownOrRent && finalFormData.append("ownOrRent", data.ownOrRent);
    if (data.monthlyRent) {
      data.monthlyRent = data.monthlyRent.replace("$", "");
      data.monthlyRent = data.monthlyRent.replaceAll(",", "");
    }
    data.monthlyRent && finalFormData.append("monthlyRent", Number(data.monthlyRent));
    finalFormData.append("avatar", file.get("avatar"));
    setPreviewImage(null);
    setIsLoading(true);
    try {
      if (id) {
        finalFormData.append("id", id);
        await dispatch(updateEndUser(finalFormData)).unwrap();
        setIsUpdate(true);
      } else {
        const res = await dispatch(createEndUser(finalFormData)).unwrap();
        dispatch(setCurrentUser(res.id));
        dispatch(setCurrentUserId(res.userId));
      }
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{id ? "User Updated" : "User Created"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
      setIsLoading(false);

      await dispatch(
        getListOfUsers({
          isActive: users.isActive,
          searchText: users.searchText,
          page: users.currentPage,
          sortColumnName: users.sortColumn.sortColumnName,
          sortOrder: users.sortColumn.sortColumnOrder,
        }),
      ).unwrap();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    const fetchEndUser = async () => {
      if (isUpdate) {
        setIsLoading(true);
        const res = await dispatch(getEndUser(id)).unwrap();
        setValue("firstName", res?.firstName);
        setValue("lastName", res?.lastName);
        setValue("email", res?.email);
        setValue("password", "123456");
        setValue("confirmPassword", "123456");
        res?.phoneNumber && setValue("phone", res?.phoneNumber);
        res?.dateOfBirth && setValue("dateOfBirth", dayjs(res?.dateOfBirth));
        res?.citizenship &&
          setValue("citizenship", {
            id:
              res?.citizenship === "USCitizen"
                ? 1
                : res?.citizenship === "PermanentResident"
                ? 2
                : 3,
            name:
              res?.citizenship === "USCitizen"
                ? "US citizen"
                : res?.citizenship === "PermanentResident"
                ? "Permanent resident"
                : "Non-permanent resident",
          });
        res?.currentAddress && setValue("currentAddress", res?.currentAddress);
        res?.locationStatus &&
          setValue("locationStatus", res?.locationStatus === "Plus2Years" ? 1 : 2);
        res?.previusAddress && setValue("previusAddress", res?.previusAddress);
        res?.ownOrRent &&
          setValue("ownOrRent", res?.ownOrRent === "Own" ? 1 : res?.ownOrRent === "Rent" ? 2 : 3);
        res?.monthlyRent && setValue("monthlyRent", `$${res?.monthlyRent.toLocaleString()}`);
        setUserData(res);
        setIsLoading(false);
        setIsUpdate(false);
      }
    };

    fetchEndUser();
  }, [isUpdate, id, dispatch, setValue]);
  useEffect(() => {
    const fetchEndUser = async () => {
      if (id) {
        setIsLoading(true);
        const res = await dispatch(getEndUser(id)).unwrap();

        setValue("firstName", res?.firstName);
        setValue("lastName", res?.lastName);
        setValue("email", res?.email);
        setValue("password", "123456");
        setValue("confirmPassword", "123456");
        res?.phoneNumber && setValue("phone", res?.phoneNumber);
        res?.phoneNumber && setValue("phone", res?.phoneNumber);
        res?.dateOfBirth && setValue("dateOfBirth", dayjs(res?.dateOfBirth));
        res?.citizenship &&
          setValue("citizenship", {
            id:
              res?.citizenship === "USCitizen"
                ? 1
                : res?.citizenship === "PermanentResident"
                ? 2
                : 3,
            name:
              res?.citizenship === "USCitizen"
                ? "US citizen"
                : res?.citizenship === "PermanentResident"
                ? "Permanent resident"
                : "Non-permanent resident",
          });
        res?.currentAddress && setValue("currentAddress", res?.currentAddress);
        res?.locationStatus &&
          setValue("locationStatus", res?.locationStatus === "Plus2Years" ? 1 : 2);
        res?.previusAddress && setValue("previusAddress", res?.previusAddress);
        res?.ownOrRent &&
          setValue("ownOrRent", res?.ownOrRent === "Own" ? 1 : res?.ownOrRent === "Rent" ? 2 : 3);
        res?.monthlyRent && setValue("monthlyRent", `$${res?.monthlyRent.toLocaleString()}`);
        setUserData(res);
        setIsLoading(false);
      }
    };

    fetchEndUser();
    return () => {
      reset({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
        dateOfBirth: "",
        citizenship: "",
        currentAddress: "",
        locationStatus: "",
        previusAddress: "",
        ownOrRent: "",
        monthlyRent: "",
      });
    };
  }, [id, dispatch, setValue, reset]);

  useEffect(() => {
    if (!isOpenDrawer.isVisible) {
      reset();
      setUserData(null);
      setFile(new FormData());
    }
  }, [isOpenDrawer, reset, isLoading]);

  const searchLocation = async (val) => {
    const res = await dispatch(getAddress(val)).unwrap();
    return res;
  };

  return (
    <div className="d-flex flex-column h-100 nv-profileSettings">
      <div style={{ overflowY: "auto" }} className="p-24">
        {isLoading ? (
          <Grid container spacing={2} rowSpacing={2}>
            <Grid item xs={12}>
              <div className="d-flex flex-row  gap-20">
                <Skeleton circle width={64} />
                <Skeleton height={90} width={"84%"} />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton className="width-100" height={60} />
            </Grid>
          </Grid>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} rowSpacing={2}>
              <Grid item xs={12}>
                <div className="d-flex flex-row gap-20 ">
                  <Avatar
                    src={previewImage !== null ? previewImage : userData?.avatarUrl}
                    alt={userData?.firstName}
                    className="avatar-text background-color-gray-100 cursor-pointer fs-24"
                    sx={{
                      width: 64,
                      height: 64,
                      marginRight: "-2px",
                    }}
                  >
                    {userData?.firstName[0].toUpperCase()}
                    {userData?.lastName[0].toUpperCase()}
                  </Avatar>
                  <ImageUploadBox1
                    className="flex-grow-1"
                    setPreviewUrl={setPreviewImage}
                    setFiles={(data) => setFile(data)}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Input
                  name="firstName"
                  type="text"
                  label="First Name"
                  register={register}
                  required={true}
                  errorMessage={errors?.firstName?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name="lastName"
                  type="text"
                  label="Last Name"
                  register={register}
                  required={true}
                  errorMessage={errors?.lastName?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name="email"
                  label="Email"
                  type={"email"}
                  register={register}
                  required={true}
                  errorMessage={errors?.email?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name="phone"
                  label="Phone"
                  type={"phone"}
                  register={register}
                  errorMessage={errors?.phone?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name="password"
                  label="Password"
                  type={"password"}
                  register={register}
                  required={id ? false : true}
                  disabled={id ? true : false}
                  errorMessage={errors?.password?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name="confirmPassword"
                  label="Confirm Password"
                  type={"password"}
                  register={register}
                  required={id ? false : true}
                  disabled={id ? true : false}
                  errorMessage={errors?.confirmPassword?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  maxDate={dayjs().subtract(18, "year")}
                  name={"dateOfBirth"}
                  label={"Date of Birth"}
                  control={control}
                />
              </Grid>

              <Grid item xs={6}>
                <SearchInput
                  type={"select"}
                  label={"Citizenship"}
                  options={citizenships}
                  form={true}
                  control={control}
                  name="citizenship"
                  onClick={(val) => setValue("citizenship", val)}
                />
              </Grid>

              <Grid item xs={12}>
                <SearchInput
                  type={"search"}
                  label={"Current Address"}
                  itsGoogle
                  control={control}
                  name="currentAddress"
                  searchFunc={searchLocation}
                  defaultValue={getValues()?.currentAddress ?? ""}
                  onClick={(val) => setValue("currentAddress", val)}
                  fullWidthList
                />
              </Grid>
              <Grid item xs={12}>
                <ButtonGroup
                  options={locations}
                  name="locationStatus"
                  label="Time at Location"
                  control={control}
                  defaultValue={null}
                  setValue={setValue}
                />
              </Grid>
              {getValues()?.locationStatus === 2 && (
                <Grid item xs={12}>
                  <SearchInput
                    type={"search"}
                    label={"Previous Address"}
                    itsGoogle
                    control={control}
                    name="previusAddress"
                    searchFunc={searchLocation}
                    defaultValue={getValues()?.previusAddress ?? ""}
                    onClick={(val) => setValue("previusAddress", val)}
                    fullWidthList
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <ButtonGroup
                  options={ownerships}
                  name="ownOrRent"
                  label="Own Or Rent"
                  control={control}
                  defaultValue={null}
                  setValue={setValue}
                />
              </Grid>

              {getValues()?.ownOrRent === 2 && (
                <Grid item xs={12}>
                  <Input
                    name="monthlyRent"
                    label="Montly Rent"
                    type={"currency"}
                    register={register}
                    required={false}
                    firstCurrencyValue={getValues()?.monthlyRent}
                    errorMessage={errors?.monthlyRent?.message}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        )}
      </div>
      <div
        className={
          "py-6 px-24 border-top-gray-200 d-flex gap-12 justify-content-end nv-profileSettings__footer mt-auto mb-[45px]"
        }
      >
        <Button
          type="contained"
          label={id ? "Save" : "Save and Continue"}
          onClick={handleSubmit(onSubmit)}
          disabled={id ? isLoading : !isDirty || !isValid || isLoading}
        />
      </div>
    </div>
  );
};

export default ProfileInfo;
