import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderQuestion,
  getPropertyTypes,
  setPropertyType,
  setShowNext,
  setSendData,
} from "../onboardingSlice";
import { ReactComponent as ResidentialIcon } from "assets/svg/icons/home-04.svg";
import { ReactComponent as MixedIcon } from "assets/svg/icons/building-07.svg";
import Button from "../Components/Button";

const Step3 = () => {
  const dispatch = useDispatch();
  const { PropertyType } = useSelector((state) => state.onboarding.body.form);
  const { postOnboarding, propertyTypes } = useSelector((state) => state.onboarding.body);

  useEffect(() => {
    dispatch(getPropertyTypes());
  }, [dispatch]);

  const question = "What is the current use of the property?";
  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);

  const handleClick = (id) => {
    dispatch(setPropertyType(id));
  };
  useEffect(() => {
    if (PropertyType !== null) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ PropertyType }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [PropertyType, dispatch, postOnboarding]);

  return (
    <div className="buttonWrap">
      {propertyTypes.data.map((type) => {
        return (
          <Button
            key={type?.id}
            buttonText={type?.name}
            buttonIcon={
              type?.id === 1 ? <ResidentialIcon /> : type?.id === 2 ? <MixedIcon /> : null
            }
            onClick={() => handleClick(type?.id)}
            active={PropertyType === type?.id}
          />
        );
      })}
    </div>
  );
};

export default Step3;
