import React from "react";
import { Routes, Route } from "react-router-dom";
import DocumentManagement from "../DocumentManagement";
const DocumentManagementRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<DocumentManagement />} />
    </Routes>
  );
};

export default DocumentManagementRoutes;
