import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as AddUserIcon } from "assets/svg/icons/addUserIcon.svg";
import { Modal } from "wrappers/NewVersion/components/Modal";
import { useForm } from "react-hook-form";
import { validationSchemaInviteUser } from "../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import Input from "../Components/Input";
import SearchInput from "./SearchInput";
import LoadingButton from "@mui/lab/LoadingButton";
import { addAccessControl, bulkAddAccess } from "../userSlice";
import DeleteMessageModal from "pages/DocumentManagement/Tabs/Categories/DeleteMessageModal";
import { commonActions } from "wrappers/NewVersion/commonSlice.js";

const useMessageModalState = () => {
  const [message, setMessage] = useState(null);
  const [header, setHeader] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  return {
    message,
    header,
    buttonText,
    setMessage,
    setHeader,
    setButtonText,
    reset: () => {
      setMessage(null);
      setHeader(null);
      setButtonText(null);
    },
  };
};

const InviteUserModal = ({ isOpen, onClose, roles, loanId, type, loanList, handleThisClose }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const messageModal = useMessageModalState();

  const { register, handleSubmit, formState, setValue, reset, control } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    criteriaMode: "all",
    resolver: yupResolver(validationSchemaInviteUser),
  });
  const { errors } = formState;

  useEffect(() => {
    !isOpen &&
      reset({
        firstName: "",
        lastName: "",
        email: "",
        role: {
          id: 5,
          name: "Viewer",
        },
      });
  }, [isOpen]);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const payload = {
        loanId,
        userId: null,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        role: data.role.id,
      };

      if (type === "single") {
        await dispatch(addAccessControl(payload)).unwrap();
      } else {
        payload.accessControlIds = loanList;
        await dispatch(bulkAddAccess(payload)).unwrap();
      }

      messageModal.setButtonText("Done");
      messageModal.setHeader("User Invited");
      messageModal.setMessage(
        `We have sent an invitation email to ${data.email}. They can accept and join as ${data.role.name} of your loan application.`,
      );
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
    } catch (e) {
      messageModal.setButtonText("OK");
      messageModal.setHeader("Can't Invite User");
      messageModal.setMessage(e.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal icon={<AddUserIcon width={24} height={24} />} isOpen={isOpen} handleClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} rowSpacing={2}>
            <Grid item xs={12}>
              <h2 className="accessModal-title">Invite User </h2>
              <p className="accessModal-subTitle">
                You can invite a new user to create a Battalion account
              </p>
            </Grid>
            <Grid item xs={12}>
              <Input
                name="firstName"
                type="text"
                label="First name"
                register={register}
                required={true}
                errorMessage={errors?.firstName?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="lastName"
                type="text"
                label="Last name"
                register={register}
                required={true}
                errorMessage={errors?.lastName?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <Input
                name="email"
                label="Email"
                type="email"
                register={register}
                required={true}
                errorMessage={errors?.email?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <SearchInput
                type="select"
                options={roles}
                form={true}
                control={control}
                name="role"
                onClick={(val) => setValue("role", val)}
                label="Role"
                required={true}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingTop: "40px" }}>
              <LoadingButton
                variant="outlined"
                onClick={onClose}
                sx={{
                  padding: "10px 18px",
                  width: "100%",
                  height: "44px",
                  background: "#FFFFFF",
                  border: "1px solid #D6D6D6",
                  boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  borderRadius: "32px",
                  fontFamily: "Indivisible",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "1rem",
                  lineHeight: "1.5em",
                  color: "#424242",
                  letterSpacing: "-0.04em",
                  textTransform: "none",
                  "&:hover": {
                    border: "1px solid #D6D6D6",
                    background: "#FFFFFF",
                  },
                }}
              >
                Cancel
              </LoadingButton>
            </Grid>
            <Grid item xs={6} style={{ paddingTop: "40px" }}>
              <LoadingButton
                variant="contained"
                type="submit"
                sx={{
                  padding: "10px 18px",
                  width: "100%",
                  height: "44px",
                  background: "#A459FF",
                  border: "1px solid #A459FF",
                  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                  borderRadius: "32px",
                  fontFamily: "Indivisible",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "1rem",
                  lineHeight: "1.5em",
                  color: "#FFFFFF",
                  letterSpacing: "-0.04em",
                  textTransform: "none",

                  "&:hover": {
                    border: "1px solid #A459FF",
                    background: "#A459FF",
                  },

                  "&:disabled": {
                    opacity: "0.5",
                    border: "1px solid #A459FF",
                    background: "#A459FF",
                  },
                }}
                loading={isLoading}
              >
                Invite
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Modal>
      {messageModal.message && (
        <DeleteMessageModal
          message={messageModal.message}
          icon={"inviteUser"}
          header={messageModal.header}
          removeMessage={() => {
            messageModal.reset;
            onClose();
            handleThisClose();
          }}
          buttonText={messageModal.buttonText}
        />
      )}
    </>
  );
};

export default InviteUserModal;

// import { useState } from "react";
// import { useDispatch } from "react-redux";
// import { ReactComponent as AddUserIcon } from "assets/svg/icons/addUserIcon.svg";
// import { Modal } from "wrappers/NewVersion/components/Modal";
// import { useForm } from "react-hook-form";
// import { validationSchemaProfileInfo } from "../constants";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { Grid } from "@mui/material";
// import Input from "../Components/Input";
// import SearchInput from "./SearchInput";
// import LoadingButton from "@mui/lab/LoadingButton";
// import { addAccessControl, bulkAddAccess } from "../userSlice";
// import DeleteMessageModal from "pages/DocumentManagement/Tabs/Categories/DeleteMessageModal";
// import { commonActions } from "wrappers/NewVersion/commonSlice.js";

// const InviteUserModal = ({ isOpen, onClose, roles, loanId, type, loanList }) => {
//   const dispatch = useDispatch();
//   const [isLoading, setIsLoading] = useState(false);

//   const [messageModalMessage, setMessageModalMessage] = useState(null);
//   const [messageModalHeader, setMessageModalHeader] = useState(null);
//   const [messageModalButton, setMessageModalButton] = useState(null);

//   const { register, handleSubmit, formState, setValue, reset, control } = useForm({
//     mode: "onBlur",
//     reValidateMode: "onBlur",
//     criteriaMode: "all",
//     resolver: yupResolver(validationSchemaProfileInfo),
//   });
//   const { isDirty, isValid, errors } = formState;

//   const onSubmit = async (data) => {
//     try {
//       setIsLoading(true);
//       if (type === "single") {
//         await dispatch(
//           addAccessControl({
//             loanId,
//             userId: null,
//             firstName: data.firstName,
//             lastName: data.lastName,
//             email: data.email,
//             role: data.role.id,
//           }),
//         ).unwrap();
//       } else {
//         await dispatch(
//           bulkAddAccess({
//             accessControlIds: loanList,
//             userId: null,
//             firstName: data.firstName,
//             lastName: data.lastName,
//             email: data.email,
//             role: data.role.id,
//           }),
//         ).unwrap();
//       }

//       setMessageModalButton("Done");
//       setMessageModalHeader("User Invited");
//       setMessageModalMessage(
//         `We have sent an invitation email to ${data.email}. They can accept and join as Viewer of your loan application.`,
//       );
//       dispatch(commonActions.toggleDeleteModalMessageVisibility());
//     } catch (e) {
//       setMessageModalButton("OK");
//       setMessageModalHeader("Can't Invite User");
//       setMessageModalMessage(e.message);
//       dispatch(commonActions.toggleDeleteModalMessageVisibility());
//     } finally {
//       setIsLoading(false);
//     }
//   };
//   return (
//     <>
//       <Modal icon={<AddUserIcon width={24} height={24} />} isOpen={isOpen} handleClose={onClose}>
//         <form onSubmit={handleSubmit(onSubmit)}>
//           <Grid container spacing={2} rowSpacing={2}>
//             <Grid item xs={12}>
//               <h2 className="accessModal-title">Invite User </h2>
//               <p className="accessModal-subTitle">
//                 You can invite a new user to create a Battalion account
//               </p>
//             </Grid>
//             <Grid item xs={12}>
//               <Input
//                 name="firstName"
//                 type="text"
//                 label="First Name"
//                 register={register}
//                 required={true}
//                 errorMessage={errors?.firstName?.message}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Input
//                 name="lastName"
//                 type="text"
//                 label="Last Name"
//                 register={register}
//                 required={true}
//                 errorMessage={errors?.lastName?.message}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Input
//                 name="email"
//                 label="Email"
//                 type={"email"}
//                 register={register}
//                 required={true}
//                 errorMessage={errors?.email?.message}
//               />
//             </Grid>

//             <Grid item xs={12}>
//               <SearchInput
//                 type={"select"}
//                 options={roles}
//                 form={true}
//                 control={control}
//                 name="role"
//                 onClick={(val) => setValue("role", val)}
//                 label={"Role"}
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <LoadingButton
//                 variant="outlined"
//                 onClick={onClose}
//                 sx={{
//                   padding: "10px 18px",
//                   width: "100%",
//                   height: "44px",
//                   background: "#FFFFFF",
//                   border: "1px solid #D6D6D6",
//                   boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
//                   borderRadius: "32px",
//                   fontFamily: "Indivisible",
//                   fontStyle: "normal",
//                   fontWeight: "600",
//                   fontSize: "1rem",
//                   lineHeight: "1.5em",
//                   color: "#424242",
//                   letterSpacing: "-0.04em",
//                   textTransform: "none",
//                   "&:hover": {
//                     border: "1px solid #D6D6D6",
//                     background: "#FFFFFF",
//                   },
//                 }}
//               >
//                 Cancel
//               </LoadingButton>
//             </Grid>
//             <Grid item xs={6}>
//               <LoadingButton
//                 variant="contained"
//                 onClick={handleSubmit(onSubmit)}
//                 sx={{
//                   padding: "10px 18px",
//                   width: "100%",
//                   height: "44px",
//                   background: "#A459FF",
//                   border: "1px solid #A459FF",
//                   boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
//                   borderRadius: "32px",
//                   fontFamily: "Indivisible",
//                   fontStyle: "normal",
//                   fontWeight: "600",
//                   fontSize: "1rem",
//                   lineHeight: "1.5em",
//                   color: "#FFFFFF",
//                   letterSpacing: "-0.04em",
//                   textTransform: "none",

//                   "&:hover": {
//                     border: "1px solid #A459FF",
//                     background: "#A459FF",
//                   },

//                   "&:disabled": {
//                     opacity: "0.5",
//                     border: "1px solid #A459FF",
//                     background: "#A459FF",
//                   },
//                 }}
//                 loading={isLoading}
//               >
//                 Invite
//               </LoadingButton>
//             </Grid>
//           </Grid>
//         </form>
//       </Modal>
//       {messageModalMessage && (
//         <DeleteMessageModal
//           message={messageModalMessage}
//           icon={"inviteUser"}
//           header={messageModalHeader}
//           removeMessage={() => {
//             setMessageModalMessage(null);
//             setMessageModalButton(null);
//             setMessageModalHeader(null);
//           }}
//           buttonText={messageModalButton}
//         />
//       )}
//     </>
//   );
// };

// export default InviteUserModal;
