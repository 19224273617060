import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBorrowersApi, getCurrentUserApi } from "api/borrower.api";

export const getBorrowers = createAsyncThunk("borrower/getBorrowers", async (data) => {
  const res = await getBorrowersApi({ params: { ...data } });
  return res;
});

export const getCurrentUser = createAsyncThunk("borrowers,getCurrentUser", async () => {
  return await getCurrentUserApi();
});

const borrowerSlice = createSlice({
  name: "borrower",
  initialState: {
    searchText: null,
    currentPage: 1,
    currentTab: "active",
    sortColumn: {
      sortColumnName: "fullName",
      sortColumnOrder: "asc",
    },
    testData: {
      isLoading: false,
      error: null,
      data: [],
    },
  },
  reducers: {
    setSearchText(state, action) {
      state.searchText = action.payload;
    },
    nextPage(state) {
      state.currentPage++;
    },
    prevPage(state) {
      state.currentPage--;
    },
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    setSortColumn(state, action) {
      state.sortColumn = action.payload;
    },
    resetState(state) {
      state.searchText = null;
      state.currentPage = 1;
      state.sortColumn = {
        sortColumnName: "fullName",
        sortColumnOrder: "asc",
      };
      state.testData = {
        isLoading: false,
        error: null,
        data: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBorrowers.pending, (state) => {
        state.testData.isLoading = true;
        state.testData.error = null;
      })
      .addCase(getBorrowers.fulfilled, (state, action) => {
        state.testData.isLoading = false;
        state.testData.data = action.payload;
      })
      .addCase(getBorrowers.rejected, (state, action) => {
        state.testData.isLoading = false;
        state.testData.error = action.error.message;
      });
  },
});

export const { setSearchText, nextPage, prevPage, setCurrentTab, setSortColumn, resetState } =
  borrowerSlice.actions;
export default borrowerSlice.reducer;
