import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Skeleton from "@yisheng90/react-loading";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Search from "../../../Borrower/Components/SearchBar/Search";
import Pagination from "../../../Borrower/Components/Pagination/Pagination";
import {
  setSearchTextCategories,
  nextPageCateCategories,
  prevPageCategories,
  updateCategoryApi,
  getTestCategoriesList,
  createCategoryApi,
  setCreateCategoryError,
  setUpdateCategoryError,
  setSortColumnCategories,
  setDeleteCategoryError,
  deleteCategoryApi,
  // dummyDataApi, //to be deleted
} from "../../documentSlice";
import { commonActions } from "wrappers/NewVersion/commonSlice.js";

import { ReactComponent as EditIcon } from "assets/svg/icons/edit-001.svg";
import { ReactComponent as DeleteIcon } from "assets/svg/icons/trash-02.svg";
import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";
import DeleteModal from "./DeleteModal";
import DeleteMessageModal from "./DeleteMessageModal";
import "../styles.css";

const CategoriesTab = () => {
  const newTableRef = useRef(null);
  const [isEditing, setIsEditing] = useState(null);
  const [editText, setEditText] = useState("");
  const [isDeleting, setIsDeleting] = useState(null);
  const [newText, setNewText] = useState("");
  const [messageModalIcon, setMessageModalIcon] = useState(null);
  const [messageModalHeader, setMessageModalHeader] = useState(null);
  const [messageModalMessage, setMessageModalMessage] = useState(null);
  const dispatch = useDispatch();
  const {
    deleteCategory,
    categoriesList,
    currentPage,
    searchText,
    sortColumn,
    createCategory,
    updateCategory,
  } = useSelector(({ document }) => document.categories);

  // useEffect(() => {
  //   console.log(dispatch(dummyDataApi()).unwrap());
  // }, [dispatch]);

  const { docsManagementCategories } = useSelector((state) => state.common);

  const sortColName = useMemo(() => {
    return sortColumn.sortColumnName;
  }, [sortColumn.sortColumnName]);
  const sortColOrder = useMemo(() => {
    return sortColumn.sortColumnOrder;
  }, [sortColumn.sortColumnOrder]);

  const handleArrow = useCallback(
    (colName) => {
      return sortColName === colName && sortColOrder === "asc" ? (
        <ArrowUpwardIcon sx={{ fontSize: "16px", color: "#525252" }} />
      ) : sortColName === colName && sortColOrder === "desc" ? (
        <ArrowDownwardIcon sx={{ fontSize: "16px", color: "#525252" }} />
      ) : null;
    },
    [sortColName, sortColOrder],
  );

  useEffect(() => {
    newTableRef.current?.focus();
  }, [docsManagementCategories.isCreate]);

  useEffect(() => {
    const error = deleteCategory.error;
    if (error) {
      setMessageModalIcon("delete");
      setMessageModalHeader("Can't Delete Category");
      setMessageModalMessage(error);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
    }
    return () => {
      dispatch(setDeleteCategoryError(null));

      dispatch(commonActions.toggleDocsManagementCategoriesFalse());
    };
  }, [deleteCategory, dispatch]);

  const handleKeyDown = (event, type) => {
    if (event.key === "Enter") {
      if (type === "new") {
        newText !== "" && handleCreate();
      } else if (type === "edit") {
        editText !== "" && handleUpdate();
      }
    } else if (event.key === "Escape") {
      if (type === "new") {
        setNewText("");
        dispatch(commonActions.toggleDocsManagementCategoriesFalse());
      } else if (type === "edit") {
        handleClose();
      }
    }
  };

  const handleSearch = (val) => {
    dispatch(setSearchTextCategories(val));
  };
  const handleNext = () => {
    dispatch(nextPageCateCategories());
  };
  const handlePrev = () => {
    dispatch(prevPageCategories());
  };

  const handleHeaderClick = (colName) => {
    if (colName === sortColName) {
      if (sortColOrder === "asc") {
        dispatch(
          setSortColumnCategories({
            sortColumnName: colName,
            sortColumnOrder: "desc",
          }),
        );
      } else {
        dispatch(
          setSortColumnCategories({
            sortColumnName: colName,
            sortColumnOrder: "asc",
          }),
        );
      }
    } else {
      dispatch(
        setSortColumnCategories({
          sortColumnName: colName,
          sortColumnOrder: "asc",
        }),
      );
    }
  };

  const handleShowEdit = (id, txt) => {
    dispatch(setUpdateCategoryError(null));
    setEditText(txt);
    setIsEditing(id);
  };
  const handleClose = () => {
    setEditText(null);
    setIsEditing(null);
  };

  const handleUpdate = async () => {
    try {
      await dispatch(updateCategoryApi({ id: isEditing, name: editText })).unwrap();
      await dispatch(
        getTestCategoriesList({
          page: currentPage,
          searchText: searchText,
          sortColumnName: sortColumn.sortColumnName,
          sortOrder: sortColumn.sortColumnOrder,
        }),
      ).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Category Updated"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
      setIsEditing(null);
      setEditText(null);
    } catch (error) {
      setMessageModalIcon("edit");
      setMessageModalHeader("Can't Update Category");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
      console.log(error);
    }
  };
  const handleDelete = (id) => {
    setIsEditing(null);
    setEditText(null);
    setIsDeleting(id);
    dispatch(commonActions.toggleDeleteModalVisibility());
  };
  const handleCreate = async () => {
    try {
      await dispatch(createCategoryApi({ name: newText })).unwrap();
      dispatch(
        getTestCategoriesList({
          page: currentPage,
          searchText: searchText,
          sortColumnName: sortColumn.sortColumnName,
          sortOrder: sortColumn.sortColumnOrder,
        }),
      ).unwrap();
      dispatch(commonActions.toggleDocsManagementCategoriesFalse());
      setNewText("");
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Category Added"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
    } catch (error) {
      setMessageModalIcon("new");
      setMessageModalHeader("Can't Add Category");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
      console.log(error);
    }
  };

  const handleDeleteModalYes = async () => {
    try {
      await dispatch(
        deleteCategoryApi({
          id: isDeleting,
        }),
      ).unwrap();
      dispatch(
        getTestCategoriesList({
          page: currentPage,
          searchText: searchText,
          sortColumnName: sortColumn.sortColumnName,
          sortOrder: sortColumn.sortColumnOrder,
        }),
      ).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Category Deleted"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="tabsContainer">
      <div className="tabsContainer-utilities">
        <Search handleSearch={handleSearch} placeholder={"Search by category name"} />
        <Pagination
          currentPage={categoriesList.data?.pageIndex}
          totalPage={categoriesList.data?.totalPages}
          handleNext={handleNext}
          handlePrev={handlePrev}
          isLoading={false}
        />
      </div>
      {categoriesList.isLoading ? (
        <>
          <Skeleton height={44} />
          <Skeleton rows={5} height={60} />
        </>
      ) : (
        <div className="tabsContainer-table">
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => handleHeaderClick("name")}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        padding: " 13px 24px",
                        alignItems: "center",
                      }}
                    >
                      <p className="headerCell-text"> Category Name</p>
                      {handleArrow("name")}
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: "116px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        padding: " 13px 0px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p className="headerCell-text">Actions</p>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {docsManagementCategories.isCreate && (
                  <TableRow
                    className={createCategory.error ? "redRow" : ""}
                    style={{ backgroundColor: " #F5F5F5" }}
                    onKeyDown={(e) => handleKeyDown(e, "new")}
                  >
                    <TableCell className={createCategory.error ? "redRowLeft" : ""}>
                      <Box sx={{ display: "flex", padding: "14px 24px" }}>
                        <input
                          className="categoryContainer-input"
                          onChange={(e) => {
                            if (createCategory.error) {
                              dispatch(setCreateCategoryError(null));
                            }
                            setNewText(e.target.value);
                          }}
                          type="text"
                          name="category"
                          placeholder="Enter category name"
                          value={newText}
                          maxLength="100"
                        />
                      </Box>
                    </TableCell>
                    <TableCell className={createCategory.error ? "redRowRight" : ""}>
                      <Box
                        sx={{
                          boxSizing: "border-box",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          padding: "4px",
                          gap: "4px",
                          justifyContent: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setNewText("");
                            dispatch(setCreateCategoryError(null));
                            dispatch(commonActions.toggleDocsManagementCategoriesFalse());
                          }}
                          ref={newTableRef}
                        >
                          <CloseIcon sx={{ fontSize: 20, color: "#525252" }} />
                        </IconButton>
                        <IconButton onClick={handleCreate} disabled={newText === ""}>
                          <CheckIcon sx={{ fontSize: 20, color: "#A459FF" }} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}

                {categoriesList.data.data &&
                  categoriesList.data.data.map((row, index) => (
                    <TableRow
                      key={row.id}
                      style={isEditing === row.id ? { backgroundColor: " #F5F5F5" } : null}
                      onKeyDown={(e) => handleKeyDown(e, "edit")}
                    >
                      <TableCell
                        className={`${
                          index === categoriesList.data.data.length - 1 &&
                          isEditing === row.id &&
                          updateCategory.error
                            ? "roundBorderLeft"
                            : null
                        } ${
                          isEditing === row.id &&
                          index !== categoriesList.data.data.length - 1 &&
                          updateCategory.error
                            ? "redRowLeft"
                            : null
                        } `}
                      >
                        <Box sx={{ display: "flex", padding: "14px 24px" }}>
                          {row.id === isEditing ? (
                            <input
                              className="categoryContainer-input"
                              onChange={(e) => {
                                if (updateCategory.error) {
                                  dispatch(setUpdateCategoryError(null));
                                }
                                setEditText(e.target.value);
                              }}
                              type="text"
                              name="category"
                              placeholder="Enter category name"
                              value={editText}
                              maxLength="100"
                              autoFocus
                            />
                          ) : (
                            <p className="categoryContainer-text">{row.name}</p>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell
                        className={`${
                          index === categoriesList.data.data.length - 1 &&
                          isEditing === row.id &&
                          updateCategory.error
                            ? "roundBorderRight"
                            : null
                        }  ${
                          isEditing === row.id &&
                          index !== categoriesList.data.data.length - 1 &&
                          updateCategory.error
                            ? "redRowRight"
                            : null
                        }`}
                      >
                        <Box
                          sx={{
                            boxSizing: "border-box",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "4px",
                            gap: "4px",
                            justifyContent: "center",
                          }}
                        >
                          {row.id === isEditing ? (
                            <>
                              <IconButton onClick={handleClose}>
                                <CloseIcon sx={{ fontSize: 20, color: "#525252" }} />
                              </IconButton>
                              <IconButton onClick={handleUpdate} disabled={editText === ""}>
                                <CheckIcon sx={{ fontSize: 20, color: "#A459FF" }} />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton onClick={() => handleDelete(row.id)}>
                                <DeleteIcon />
                              </IconButton>
                              <IconButton onClick={() => handleShowEdit(row.id, row.name)}>
                                <EditIcon />
                              </IconButton>
                            </>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      <DeleteModal
        successHandler={handleDeleteModalYes}
        header={"Delete Category"}
        message={"Are you sure you want to delete this category?"}
      />
      <DeleteMessageModal
        message={messageModalMessage}
        icon={messageModalIcon}
        header={messageModalHeader}
      />
    </div>
  );
};

export default CategoriesTab;
// Can't Delete User
//deleteCategory.error
