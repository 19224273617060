import { fetchGet } from "api/common/callerHelpers";

export const fetchAddressAPI = (textToSearch) => {
  if (Boolean(textToSearch)) {
    return fetchGet("/api/properties/search/" + textToSearch);
  }
};

export const fetchValuationAndBBLAPI = (address) => {
  if (Boolean(address)) {
    // we must have a address
    return fetchGet("/api/properties/get-property-info/" + address);
  }
};
