import React, { useState } from "react";
import InputMask from "react-input-mask";
import { ReactComponent as EyeIcon } from "assets/svg/icons/eye.svg";

import "./styles.scss";

const Input = ({ label, type, name, register, required, disabled, errorMessage }) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="inputContainer">
      <div className="inputContainer-labelRow">
        <label htmlFor={name} className={`inputLabel ${disabled ? "disabledInputFormLabel" : ""}`}>
          {label}
        </label>
        {!required && !disabled && <span className="inputContainerOptional">optional</span>}
      </div>

      {type && type === "phone" ? (
        <InputMask
          mask="(999) 999-9999"
          maskChar={null}
          {...(register && register(name, { required }))}
        >
          {(inputProps) => <input {...inputProps} type={type} className="myinput" />}
        </InputMask>
      ) : type === "password" ? (
        <div className="passwordInputContainer">
          <input
            type={showPassword ? "text" : "password"}
            name={name}
            className={`myinput ${disabled ? "disabledIputForm" : ""} ${
              errorMessage ? "inputErrorMode" : ""
            }`}
            {...(register && register(name))}
          />
          {!disabled && (
            <button
              type="button"
              className="togglePasswordButton"
              onClick={togglePasswordVisibility}
            >
              <EyeIcon />
            </button>
          )}
        </div>
      ) : type === "currency" ? (
        <InputMask
          mask="$999999999999"
          maskChar={null}
          placeholder="$0"
          {...(register && register(name, { required }))}
        >
          {(inputProps) => <input {...inputProps} type={type} className="myinput" />}
        </InputMask>
      ) : (
        <input
          type={type ? type : "text"}
          name={name}
          className={`myinput ${disabled ? "disabledIputForm" : ""} ${
            errorMessage ? "inputErrorMode" : ""
          }`}
          {...(register && register(name, { required }))}
        />
      )}
      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
    </div>
  );
};

export default Input;
