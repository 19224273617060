import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GoogleLogin from "pages/Login/components/GoogleLogin";

import InputText from "../Components/InputText";
import {
  setHeaderQuestion,
  setFirstName,
  setLastName,
  setShowVerifyEmail,
  setEmail,
  setPassword,
  setPasswordAgain,
  setShowQuestions,
  setEmailValidity,
  setFirstNameValidity,
  setLastNameValidity,
  setPasswordValidity,
  setPasswordAgainValidity,
  setShowBack,
  setShowLogin,
  setShowCreateNewAccount,
} from "../onboardingSlice";

import { unsetRegisterError } from "pages/Login/loginSlice";
import PasswordValidations from "../Components/PasswordValidations";

const Step21 = ({ isAuthFlow }) => {
  const [nameIsFocus, setNameIsFocus] = useState(false);
  const [lastNameIsFocus, setLastNameIsFocus] = useState(false);
  const [emailIsFocus, setEmailIsFocus] = useState(false);
  const [passwordIsFocus, setPasswordIsFocus] = useState(false);
  const [passwordAgainIsFocus, setPasswordAgainIsFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [nameIsValid, setNameIsValid] = useState(true);
  const [lastNameIsValid, setLastNameIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [passwordAgainIsValid, setPasswordAgainIsValid] = useState(true);

  const [validations, setValidations] = useState({
    hasValidLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  const dispatch = useDispatch();
  const header = "Sign Up With Email";
  const { FirstName, LastName, Email, password, passwordAgain } = useSelector(
    (state) => state.onboarding.body.form,
  );
  const { postOnboardingComplete } = useSelector((state) => state.onboarding.body);
  const { register } = useSelector((state) => state.login);

  useEffect(() => {
    dispatch(setHeaderQuestion(header));
    dispatch(setShowQuestions(false));
    dispatch(setShowCreateNewAccount(false));
    dispatch(setShowLogin(false));

    if (isAuthFlow) {
      dispatch(setShowBack(true));
    } else {
      dispatch(setShowBack(false));
      dispatch(setShowVerifyEmail(true));
    }
  }, [dispatch, header]);

  useEffect(() => {
    if (FirstName !== null && FirstName.length > 0) {
      if (!/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(FirstName)) {
        setNameIsValid(false);
        dispatch(setFirstNameValidity(false));
      } else {
        setNameIsValid(true);
        dispatch(setFirstNameValidity(true));
      }
    }
  }, [FirstName, dispatch]);

  useEffect(() => {
    if (LastName !== null && LastName.length > 0) {
      if (!/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(LastName)) {
        setLastNameIsValid(false);
        dispatch(setLastNameValidity(false));
      } else {
        setLastNameIsValid(true);
        dispatch(setLastNameValidity(true));
      }
    }
  }, [LastName, dispatch]);

  useEffect(() => {
    if (Email !== null && Email.length > 0) {
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          Email,
        ) ||
        register.error
      ) {
        setEmailIsValid(false);
        dispatch(setEmailValidity(false));
      } else {
        setEmailIsValid(true);
        dispatch(setEmailValidity(true));
      }
    }
  }, [Email, register, dispatch]);

  useEffect(() => {
    if (password !== null && password.length >= 0) {
      const lengthRegex = /^.{8,}$/; // Minimum 8 characters
      const uppercaseRegex = /[A-Z]/; // At least 1 uppercase letter
      const lowercaseRegex = /[a-z]/; // At least 1 lowercase letter
      const numberRegex = /[0-9]/; // At least 1 number
      const specialCharRegex = /[!@#$%^&*]/; // At least 1 special character

      const hasValidLength = lengthRegex.test(password);
      const hasUppercase = uppercaseRegex.test(password);
      const hasLowercase = lowercaseRegex.test(password);
      const hasNumber = numberRegex.test(password);
      const hasSpecialChar = specialCharRegex.test(password);

      setValidations({
        hasValidLength,
        hasUppercase,
        hasLowercase,
        hasNumber,
        hasSpecialChar,
      });

      const isValid = hasValidLength && hasUppercase && hasLowercase && hasNumber && hasSpecialChar;

      if (!isValid) {
        setPasswordIsValid(false);
        dispatch(setPasswordValidity(false));
      } else {
        setPasswordIsValid(true);
        dispatch(setPasswordValidity(true));
      }
    }
  }, [password, dispatch]);

  useEffect(() => {
    if (passwordAgain !== null && passwordAgain.length > 0) {
      if (
        !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(passwordAgain) ||
        password !== passwordAgain
      ) {
        setPasswordAgainIsValid(false);
        dispatch(setPasswordAgainValidity(false));
      } else {
        setPasswordAgainIsValid(true);
        dispatch(setPasswordAgainValidity(true));
      }
    }
  }, [passwordAgain, password, dispatch]);

  useEffect(() => {
    dispatch(setShowVerifyEmail(true));
    dispatch(setShowBack(true));

    return () => {
      dispatch(setShowVerifyEmail(false));
      dispatch(setShowBack(false));
    };
  }, [dispatch]);

  const customNotValidMessage = useMemo(() => {
    if (register.error) {
      return "The email has already been taken";
    } else {
      return null;
    }
  }, [register]);

  const updateFirstName = (val) => {
    dispatch(setFirstName(val));
  };
  const updateLastName = (val) => {
    dispatch(setLastName(val));
  };
  const updateEmail = (val) => {
    dispatch(setEmail(val));
    if (register.error) {
      dispatch(unsetRegisterError());
    }
  };
  const updatePassword = (val) => {
    dispatch(setPassword(val));
  };
  const updatePasswordAgain = (val) => {
    dispatch(setPasswordAgain(val));
  };

  const handleFocusName = (val) => {
    setNameIsFocus(val);
  };

  const handleFocusLastName = (val) => {
    setLastNameIsFocus(val);
  };

  const handleFocusEmail = (val) => {
    setEmailIsFocus(val);
  };
  const handleFocusPassword = (val) => {
    setPasswordIsFocus(val);
  };
  const handleFocusPasswordAgain = (val) => {
    setPasswordAgainIsFocus(val);
  };

  return (
    <div className="createAccountContainer step21">
      <div className="formWrapper">
        <div className="row">
          <InputText
            placeholder={"First Name"}
            updateForm={updateFirstName}
            focus={nameIsFocus}
            handleFocus={handleFocusName}
            label="First Name"
            isValid={nameIsValid}
            val={FirstName}
            dynamic={true}
          />
          <InputText
            placeholder={"Last Name"}
            updateForm={updateLastName}
            focus={lastNameIsFocus}
            handleFocus={handleFocusLastName}
            label="Last Name"
            isValid={lastNameIsValid}
            val={LastName}
            dynamic={true}
          />
        </div>
        <InputText
          placeholder={"Email"}
          updateForm={updateEmail}
          focus={emailIsFocus}
          handleFocus={handleFocusEmail}
          label="Email"
          isValid={emailIsValid}
          customNotValidMessage={customNotValidMessage}
          val={Email}
          dynamic={true}
        />

        <PasswordValidations validations={validations} passwordIsFocus={passwordIsFocus} />
        <div className="row">
          <InputText
            placeholder={"Password"}
            updateForm={updatePassword}
            focus={passwordIsFocus}
            handleFocus={handleFocusPassword}
            label="Password"
            isValid={passwordIsValid}
            val={password}
            password={!showPassword}
            dynamic={true}
            showPassword={showPassword}
            toggleShowPassword={() => setShowPassword(!showPassword)}
          />
          <InputText
            placeholder={"Confirm Password"}
            updateForm={updatePasswordAgain}
            focus={passwordAgainIsFocus}
            handleFocus={handleFocusPasswordAgain}
            label="Confirm Password"
            isValid={passwordAgainIsValid}
            val={passwordAgain}
            password={!showConfirmPassword}
            dynamic={true}
            showPassword={showConfirmPassword}
            toggleShowPassword={() => setShowConfirmPassword(!showConfirmPassword)}
          />
        </div>
        <p className="termsAndConditions">
          By clicking Sign Up button I aggree to
          <Link to="/agreement" target="_blank">
            {" "}
            terms and conditions
          </Link>{" "}
          and
          <Link to="/policy" target="_blank">
            {" "}
            privacy policy
          </Link>{" "}
          and signup for Battalion Lending LLC.
        </p>
        <div className="crossAuthLinkerWrapper noPadding">
          <p>Already Have A Battalion Account?</p>
          <Link to="/login">Login</Link>
        </div>
      </div>
    </div>
  );
};

export default Step21;
