import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderQuestion,
  setShowNext,
  setFirstName,
  setLastName,
  setPhoneNumber,
  setEmail,
  setSendData,
} from "../onboardingSlice";
import InputText from "../Components/InputText";

const Step14 = () => {
  const [nameIsFocus, setNameIsFocus] = useState(true);
  const [lastNameIsFocus, setLastNameIsFocus] = useState(false);
  const [emailIsFocus, setEmailIsFocus] = useState(false);
  const [phoneIsFocus, setPhoneIsFocus] = useState(false);
  const [nameIsValid, setNameIsValid] = useState(true);
  const [lastNameIsValid, setLastNameIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const dispatch = useDispatch();
  const { FirstName, LastName, PhoneNumber, Email } = useSelector(
    (state) => state.onboarding.body.form,
  );
  const { postOnboarding } = useSelector((state) => state.onboarding.body);
  const question = "Lastly, please provide us your contact info so we can reach you";

  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [dispatch, question]);

  useEffect(() => {
    if (FirstName !== null) {
      if (!/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(FirstName)) {
        setNameIsValid(false);
      } else {
        setNameIsValid(true);
      }
    }
  }, [FirstName]);

  useEffect(() => {
    if (LastName !== null) {
      if (!/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(LastName)) {
        setLastNameIsValid(false);
      } else {
        setLastNameIsValid(true);
      }
    }
  }, [LastName]);

  useEffect(() => {
    if (Email !== null) {
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          Email,
        )
      ) {
        setEmailIsValid(false);
      } else {
        setEmailIsValid(true);
      }
    }
  }, [Email]);

  useEffect(() => {
    if (PhoneNumber !== null && PhoneNumber.length > 0) {
      if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(PhoneNumber)) {
        setPhoneIsValid(false);
      } else {
        setPhoneIsValid(true);
      }
    }
  }, [PhoneNumber]);

  useEffect(() => {
    if (
      FirstName !== null &&
      LastName !== null &&
      PhoneNumber !== null &&
      Email !== null &&
      nameIsValid &&
      lastNameIsValid &&
      phoneIsValid &&
      emailIsValid
    ) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({
            FirstName,
            LastName,
            Email,
            PhoneNumber,
          }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
    };
  }, [
    FirstName,
    LastName,
    PhoneNumber,
    Email,
    dispatch,
    postOnboarding,
    nameIsValid,
    phoneIsValid,
    emailIsValid,
    lastNameIsValid,
  ]);

  const updateFirstName = (val) => {
    dispatch(setFirstName(val));
  };
  const updateLastName = (val) => {
    dispatch(setLastName(val));
  };
  const updateEmail = (val) => {
    dispatch(setEmail(val));
  };
  const updatePhoneNumber = (val) => {
    dispatch(setPhoneNumber(val));
  };

  const handleFocusName = (val) => {
    setNameIsFocus(val);
  };

  const handleFocusLastName = (val) => {
    setLastNameIsFocus(val);
  };

  const handleFocusEmail = (val) => {
    setEmailIsFocus(val);
  };

  const handlePhoneIsFocus = (val) => {
    setPhoneIsFocus(val);
  };

  return (
    <div className="step14">
      <div className="row">
        <InputText
          placeholder={"First Name"}
          val={FirstName}
          updateForm={updateFirstName}
          focus={nameIsFocus}
          handleFocus={handleFocusName}
          label="First Name"
          isValid={nameIsValid}
          dynamic={true}
        />
        <InputText
          placeholder={"Last Name"}
          val={LastName}
          updateForm={updateLastName}
          focus={lastNameIsFocus}
          handleFocus={handleFocusLastName}
          label="Last Name"
          isValid={lastNameIsValid}
          dynamic={true}
        />
      </div>
      <InputText
        placeholder={"Email"}
        val={Email}
        updateForm={updateEmail}
        focus={emailIsFocus}
        handleFocus={handleFocusEmail}
        label="Email"
        isValid={emailIsValid}
        notCapitalized={true}
        dynamic={true}
      />
      <InputText
        val={PhoneNumber}
        placeholder={"Phone"}
        updateForm={updatePhoneNumber}
        focus={phoneIsFocus}
        handleFocus={handlePhoneIsFocus}
        label="Phone Number"
        isValid={phoneIsValid}
        limit={14}
        phone={true}
        numeric={true}
        type={"tel"}
      />
    </div>
  );
};

export default Step14;
