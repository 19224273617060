const StatusLoanBadge = ({ status, label }) => {
  const badgeStyles = {
    DocsNeeded: {
      bg: "background-color-error-50",
      color: "color-error-700",
      label: label ?? "Docs Needed",
    },
    Review: {
      bg: "background-color-warning-50",
      color: "color-warning-700",
      label: label ?? "Review",
    },
    Lead: {
      bg: "background-color-warning-50",
      color: "color-warning-700",
      label: label ?? "Lead",
    },
    ClearToClose: {
      bg: "background-color-blue-50",
      color: "color-blue-700",
      label: label ?? "Clear To Close",
    },
    Closed: {
      bg: "background-color-success-50",
      color: "color-success-700",
      label: label ?? "Closed",
    },
    Archived: {
      bg: "background-color-gray-100",
      color: "color-gray-700",
      label: label ?? "Archived",
    },
    PendingCancellation: {
      bg: "background-color-error-50",
      color: "color-error-700",
      label: label ?? "Pending Cancellation",
    },
    defaulStyles: {
      bg: "background-color-gray-100",
      color: "color-gray-700",
      label: "N/A",
    },
  };

  const selectedStyles = badgeStyles?.[status] || badgeStyles?.defaulStyles;

  return (
    <div className={`border-radius-4 px-12 py-4 d-flex align-items-center ${selectedStyles.bg}`}>
      <p className={`font-indivisible ${selectedStyles.color}`} style={{ fontSize: "14px" }}>
        {selectedStyles.label}
      </p>
    </div>
  );
};

export default StatusLoanBadge;
