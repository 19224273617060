import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePopper } from "react-popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { IconButton } from "../IconButton";
import { ReactComponent as SettingsIcon } from "assets/svg/icons/settings-02.svg";
import { ReactComponent as FileIcon } from "assets/svg/icons/file-06.svg";
import { ReactComponent as PeopleIcon } from "assets/svg/icons/users-01.svg";
import { AppRoute } from "utils/constants/ConstantsUtil";
import { Stack } from "@mui/material";

import "./styles.css";

export const SettingsDropDown = () => {
  const navigate = useNavigate();
  const { DOCUMET_MANAGEMENT, USER_MANAGEMENT } = AppRoute;

  const [showPopper, setShowPopper] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 12],
        },
      },
    ],
  });
  const handleClick = () => {
    setShowPopper((prev) => !prev);
  };
  const handleItemClick = (indx) => {
    if (indx === 1) {
      navigate(DOCUMET_MANAGEMENT);
    } else if (indx === 2) {
      navigate(USER_MANAGEMENT);
    }
    setShowPopper(false);
  };
  return (
    <>
      <IconButton onClick={handleClick}>
        <SettingsIcon ref={setReferenceElement} />
      </IconButton>
      {showPopper && (
        <ClickAwayListener onClickAway={handleClick}>
          <Stack
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            flex
            flexDirection={"column"}
            className="settingsDropDown--container"
          >
            <Stack
              onClick={() => handleItemClick(1)}
              gap={"8px"}
              direction={"row"}
              alignItems={"center"}
              className="settingsDropDown--item"
            >
              <FileIcon />
              <p className="settingsDropDown--item-p">Manage Documents</p>
            </Stack>
            <Stack
              onClick={() => handleItemClick(2)}
              gap={"8px"}
              direction={"row"}
              alignItems={"center"}
              className="settingsDropDown--item"
            >
              <PeopleIcon />
              <p className="settingsDropDown--item-p">Manage Users</p>
            </Stack>
          </Stack>
        </ClickAwayListener>
      )}
    </>
  );
};
