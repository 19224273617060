import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    PopperProps={{
      popperOptions: {
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              padding: 20,
            },
          },
        ],
      },
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
    fontFamily: ["Indivisible", "sanf-serif"].join(","),
    fontWeight: 600,
    fontStyle: "normal",
    letterSpacing: "-0.48px",
    padding: "8px 12px",
    borderRadius: "8px",
  },
}));

const CustomizedTooltip = ({ title, children, placement = "top", move = false }) => {
  return (
    <BootstrapTooltip title={title} placement={placement} move={move}>
      {children}
    </BootstrapTooltip>
  );
};

export default CustomizedTooltip;
