import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "wrappers/NewVersion/commonSlice.js";
import { Modal } from "wrappers/NewVersion/components/Modal";
import { ReactComponent as TrashIcon } from "assets/svg/icons/trash-02.svg";
import { ReactComponent as EditIcon } from "assets/svg/icons/edit-001.svg";
import { ReactComponent as LockIcon } from "assets/svg/icons/lock-03.svg";
import { ReactComponent as UnlockIcon } from "assets/svg/icons/unlock-icon.svg";
import { ReactComponent as FlipBackwardIcon } from "assets/svg/icons/flip-backward.svg";
import { ReactComponent as KeyIcon } from "assets/svg/icons/key-01.svg";
import { ReactComponent as Revoke } from "assets/svg/icons/user-x-01.svg";
import { ReactComponent as AccesControlIcon } from "assets/svg/icons/accessControl.svg";
import { ReactComponent as AddUserIcon } from "assets/svg/icons/addUserIcon.svg";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const ICON_MAP = {
  new: <AddIcon sx={{ fontSize: 24 }} />,
  edit: <EditIcon width={24} height={24} />,
  lock: <LockIcon width={24} height={24} />,
  unlock: <UnlockIcon width={24} height={24} />,
  activate: <FlipBackwardIcon width={24} height={24} />,
  key: <KeyIcon width={24} height={24} />,
  revoke: <Revoke width={24} height={24} />,
  access: <AccesControlIcon width={24} height={24} />,
  inviteUser: <AddUserIcon width={24} height={24} />,
  successIcon: (
    <CheckCircleOutlineOutlinedIcon
      style={{
        fontSize: "24px",
        color: "#424242",
      }}
    />
  ),
  default: <TrashIcon width={24} height={24} />,
};

const okButtonStyle = {
  padding: "10px 18px",
  width: "100%",
  height: "44px",
  background: "#A459FF",
  border: "1px solid #A459FF",
  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  borderRadius: "32px",
  fontFamily: "Indivisible",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "1rem",
  lineHeight: "1.5em",
  color: "#FFFFFF",
  letterSpacing: "-0.04em",
  "&:hover": {
    border: "1px solid #A459FF",
    background: "#A459FF",
  },
};

const DeleteMessageModal = ({
  message,
  icon,
  header,
  removeMessage,
  buttonText = "OK",
  isHtml = false,
}) => {
  const dispatch = useDispatch();
  const { deleteModalMessage } = useSelector((state) => state.common);

  const handleOk = () => {
    removeMessage && removeMessage();
    dispatch(commonActions.toggleDeleteModalMessageVisibility());
  };

  return (
    <Modal
      icon={ICON_MAP[icon] || ICON_MAP.default}
      isOpen={deleteModalMessage.isVisible}
      handleClose={handleOk}
    >
      <Stack direction="column" spacing={0.5} sx={{ paddingBottom: "32px" }}>
        <h2 className="deleteModal-h2">{header}</h2>
        {isHtml ? (
          <p className="deleteModal-p" dangerouslySetInnerHTML={{ __html: message }} />
        ) : (
          <p className="deleteModal-p">{message}</p>
        )}
      </Stack>
      <Stack direction="row" spacing={1.5}>
        <Button variant="contained" onClick={handleOk} style={okButtonStyle}>
          {buttonText}
        </Button>
      </Stack>
    </Modal>
  );
};

export default DeleteMessageModal;

// import { useDispatch, useSelector } from "react-redux";
// import { commonActions } from "wrappers/NewVersion/commonSlice.js";
// import { Modal } from "wrappers/NewVersion/components/Modal";
// import Button from "@mui/material/Button";
// import Stack from "@mui/material/Stack";
// import AddIcon from "@mui/icons-material/Add";
// import { ReactComponent as TrashIcon } from "assets/svg/icons/trash-02.svg";
// import { ReactComponent as EditIcon } from "assets/svg/icons/edit-001.svg";
// import { ReactComponent as LockIcon } from "assets/svg/icons/lock-03.svg";
// import { ReactComponent as UnlockIcon } from "assets/svg/icons/unlock-icon.svg";
// import { ReactComponent as FlipBackwardIcon } from "assets/svg/icons/flip-backward.svg";
// import { ReactComponent as KeyIcon } from "assets/svg/icons/key-01.svg";
// import { ReactComponent as Revoke } from "assets/svg/icons/user-x-01.svg";
// import { ReactComponent as AccesControlIcon } from "assets/svg/icons/accessControl.svg";

// const DeleteMessageModal = ({ message, icon, header, removeMessage }) => {
//   const dispatch = useDispatch();
//   const { deleteModalMessage } = useSelector((state) => state.common);

//   const handleOk = () => {
//     removeMessage && removeMessage();
//     dispatch(commonActions.toggleDeleteModalMessageVisibility());
//   };

//   return (
//     <Modal
//       icon={
//         icon === "new" ? (
//           <AddIcon sx={{ fontSize: 24 }} />
//         ) : icon === "edit" ? (
//           <EditIcon width={24} height={24} />
//         ) : icon === "lock" ? (
//           <LockIcon width={24} height={24} />
//         ) : icon === "unlock" ? (
//           <UnlockIcon width={24} height={24} />
//         ) : icon === "activate" ? (
//           <FlipBackwardIcon width={24} height={24} />
//         ) : icon === "key" ? (
//           <KeyIcon width={24} height={24} />
//         ) : icon === "revoke" ? (
//           <Revoke width={24} height={24} />
//         ) : icon === "access" ? (
//           <AccesControlIcon width={24} height={24} />
//         ) : (
//           <TrashIcon width={24} height={24} />
//         )
//       }
//       isOpen={deleteModalMessage.isVisible}
//       handleClose={handleOk}
//     >
//       <Stack direction="column" spacing={0.5} sx={{ paddingBottom: "32px" }}>
//         <h2 className="deleteModal-h2">{header} </h2>
//         <p className="deleteModal-p">{message}</p>
//       </Stack>
//       <Stack direction="row" spacing={1.5}>
//         <Button
//           variant="contained"
//           onClick={handleOk}
//           sx={{
//             padding: "10px 18px",
//             width: "100%",
//             height: "44px",
//             background: "#A459FF",
//             border: "1px solid #A459FF",
//             boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
//             borderRadius: "32px",
//             fontFamily: "Indivisible",
//             fontStyle: "normal",
//             fontWeight: "600",
//             fontSize: "1rem",
//             lineHeight: "1.5em",
//             color: "#FFFFFF",
//             letterSpacing: "-0.04em",
//             "&:hover": {
//               border: "1px solid #A459FF",
//               background: "#A459FF",
//             },
//           }}
//         >
//           OK
//         </Button>
//       </Stack>
//     </Modal>
//   );
// };

// export default DeleteMessageModal;
