import { toast } from "react-toastify";
import {
  deleteTestLoanDocumentFileApi,
  testBulkDeleteLoanDocumentFileApi,
  getTestDownloadLoanDocumentFileApi,
  testDownloadAsZipApi,
  testDownloadLoanDocumentAsZipApi,
} from "../loanDetailSlice";
import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";
import { ReactComponent as DownloadIcon } from "assets/svg/icons/download-20.svg";
import { ReactComponent as XCircleIcon } from "assets/svg/icons/x-circle-1.svg";

export const fileRemover =
  (loanId, isBulk = false) =>
  async (dispatch, getState) => {
    const state = getState();

    isBulk
      ? await dispatch(
          testBulkDeleteLoanDocumentFileApi({
            loanDocumentFileIds: state.loanDetail?.loanDocumentSelectedFiles,
            loanId,
          }),
        ).unwrap()
      : await dispatch(
          deleteTestLoanDocumentFileApi({
            fileId: state.loanDetail?.deleteFileId,
            loanId,
          }),
        ).unwrap();

    toast(
      <div className="toastMessageContainer">
        <div className="succesIconBackground1">
          <SuccessIcon />
        </div>
        <span className="toastMessage-text">{isBulk ? "Files" : "File"} Deleted</span>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      },
    );
  };

export const fileDownloader =
  ({ loanDocumentId, fileId, fileName }, isBulk = false) =>
  async (dispatch) => {
    const fileWord = isBulk ? "Files" : "File";
    try {
      const progressToast = toast(
        <div className="toastMessageContainer">
          <div className="iconWrapper" style={{ background: "#D1E9FF" }}>
            <DownloadIcon />
          </div>
          <span className="toastMessage-text">Downloading {fileWord}...</span>
        </div>,
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );

      isBulk
        ? await dispatch(
            loanDocumentId
              ? testDownloadLoanDocumentAsZipApi({
                  loanDocumentId,
                  fileName,
                })
              : testDownloadAsZipApi(),
          ).unwrap()
        : await dispatch(getTestDownloadLoanDocumentFileApi({ fileId, fileName })).unwrap();

      toast.dismiss(progressToast);

      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{fileWord} Downloaded</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
    } catch (error) {
      toast(
        <div className="toastMessageContainer">
          <div className="iconWrapper" style={{ background: "#FEE4E2" }}>
            <XCircleIcon />
          </div>
          <span className="toastMessage-text">Can't download {fileWord}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
    }
  };
