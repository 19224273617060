import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import Skeleton from "@yisheng90/react-loading";

import Stack from "@mui/material/Stack";
import { Modal } from "wrappers/NewVersion/components/Modal";
import { ReactComponent as TrashIcon } from "assets/svg/icons/trash-02.svg";
import { getActiveLoans } from "../userSlice";
import DeleteItem from "./DeleteItem";

const ArchieveLinkedLoansModal = ({ isOpen, handleClose, id, handleDelete }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [activeLoans, setActiveLoans] = useState([]);
  const [selectedLoans, setSelectedLoans] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    const res = await dispatch(getActiveLoans(id)).unwrap();
    console.log(res);
    setActiveLoans(res);
    setIsLoading(false);
  };

  useState(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const handleClick = (id) => {
    if (selectedLoans.includes(id)) {
      setSelectedLoans((prev) => prev.filter((val) => val !== id));
    } else {
      setSelectedLoans((prev) => [...prev, id]);
    }
  };

  console.log(selectedLoans);

  return (
    <Modal icon={<TrashIcon width={24} height={24} />} isOpen={isOpen} handleClose={handleClose}>
      <Stack direction="column" spacing={0.5}>
        <h2 className="accessModal-title">Confirm Delete User </h2>
        <p className="accessModal-subTitle">
          This user currently has <strong> {activeLoans.length}</strong> active loans. To delete
          this user, you must select all of the loans from the list below for archiving and confirm
          your action.
        </p>
      </Stack>
      <Stack direction="column" spacing={1.5} sx={{ marginTop: "32px", maxHeight: "455px" }}>
        {isLoading ? (
          <Skeleton rows={3} height={50} />
        ) : (
          activeLoans.map((loan) => (
            <DeleteItem
              isSelected={selectedLoans.includes(loan.id)}
              address={loan.propertyAddress}
              price={loan.loanAmount}
              onClick={() => handleClick(loan.id)}
            />
          ))
        )}
      </Stack>
      <Stack direction="row" gap={"12px"} sx={{ marginTop: "32px" }}>
        <LoadingButton
          variant="outlined"
          onClick={handleClose}
          sx={{
            padding: "10px 18px",
            width: "100%",
            height: "44px",
            background: "#FFFFFF",
            border: "1px solid #D6D6D6",
            boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            borderRadius: "32px",
            fontFamily: "Indivisible",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "1rem",
            lineHeight: "1.5em",
            color: "#424242",
            letterSpacing: "-0.04em",
            textTransform: "none",
            "&:hover": {
              border: "1px solid #D6D6D6",
              background: "#FFFFFF",
            },
          }}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={() => {
            handleDelete(selectedLoans);
            handleClose();
          }}
          sx={{
            padding: "10px 18px",
            width: "100%",
            height: "44px",
            background: "#A459FF",
            border: "1px solid #A459FF",
            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
            borderRadius: "32px",
            fontFamily: "Indivisible",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "1rem",
            lineHeight: "1.5em",
            color: "#FFFFFF",
            letterSpacing: "-0.04em",
            textTransform: "none",

            "&:hover": {
              border: "1px solid #A459FF",
              background: "#A459FF",
            },

            "&:disabled": {
              opacity: "0.5",
              border: "1px solid #A459FF",
              background: "#A459FF",
            },
          }}
          loading={isLoading}
          disabled={activeLoans.length !== selectedLoans.length}
        >
          Confirm
        </LoadingButton>
      </Stack>
    </Modal>
  );
};

export default ArchieveLinkedLoansModal;
