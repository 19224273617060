import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@mui/material/Box";
import Button from "../Button/Button";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import { Drawer } from "wrappers/NewVersion/components/Drawer";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { ReactComponent as EditIcon } from "../../../../assets/svg/icons/edit-003.svg";
import CloseIcon from "@mui/icons-material/Close";
import Collaborator from "../Collaborator";
import Skeleton from "react-loading-skeleton";
import { useMemo, useRef, useState } from "react";
import { IconButton } from "@material-ui/core";
import BulkDropdownUserAccess from "../Dropdown/BulkDropdownUserAccess";
import Dropdown from "../Dropdown/Dropdown";
import { usePopper } from "../../hooks/usePopper";
import BadgeButton from "../BadgeButton";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getAccessControlRolesApi,
  getUserAccessControlAutoCompleteApi,
  postLoanSaveAccessControlChangesApi,
} from "../../loanDetailSlice";
import { arraysEqual } from "../../../../utils/shared/helpers";
import { toUpperCamelCase } from "../../../../utils/shared/helpers";
import DeleteModal from "pages/DocumentManagement/Tabs/Categories/DeleteModal";
import { ReactComponent as UserXIcon } from "assets/svg/icons/user-x-01.svg";
import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";

import "./styles.scss";
import { toast } from "react-toastify";
import { useLoanContext } from "pages/Borrower/LoanDetail";

const VIEWER_ROLE_ID = 5;
const MODAL_REVOKE_ACCESS = "revoke-access";

const UserAccessDrawer = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { userShareAccessDrawer } = useSelector((state) => state.common);
  const { canChangeRoleShareAccess, canAddUserShareAccess } = useLoanContext();

  const {
    loanAccessControlData: { isLoading, data: collaborators },
    accessControlRolesData: { isLoading: isLoadingRoles, data: roles },
  } = useSelector(({ loanDetail }) => loanDetail);

  const defaultRole = roles.find((role) => role.id === VIEWER_ROLE_ID);
  let formattedCollaborators = useMemo(
    () =>
      collaborators?.map(
        ({
          userId,
          email,
          avatarUrl,
          accessControlRole,
          accessControlRoleId,
          displayName,
          isPending,
        }) => ({
          userId,
          email,
          avatarUrl,
          fullName: displayName,
          role: accessControlRole,
          roleId: accessControlRoleId,
          isPending,
        }),
      ),
    [collaborators],
  );

  const ref = useRef(null);

  const [users, setUsers] = useState([]);
  const [userToAdd, setUserToAdd] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [newUserSelectedRole, setnewUserSelectedRole] = useState(defaultRole);
  const [isDeleting, setIsDeleting] = useState(null);

  const [showPopper, handlePopper] = usePopper();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getAccessControlRolesApi());
    };
    const timeout = setTimeout(fetchData, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch]);

  useEffect(() => {
    let borrower;
    for (var i = 0; i < formattedCollaborators.length; i++) {
      if (formattedCollaborators[i].role === "Borrower") {
        borrower = formattedCollaborators.splice(i, 1);
      }
    }
    if (borrower) {
      formattedCollaborators.unshift(borrower[0]);
    }

    setUsers(formattedCollaborators);
    setnewUserSelectedRole(defaultRole);
  }, [collaborators]);

  const handleClose = () => {
    dispatch(commonActions.toggleUserShareAccessDrawerVisibility());
    handleReset();
  };

  const handleAddUser = () => {
    setIsAdding(true);
  };

  const handleAddCheck = async () => {
    const newUser = {
      ...userToAdd,
      role: newUserSelectedRole?.name,
      avatarUrl: null,
      roleId: newUserSelectedRole.id,
    };

    formattedCollaborators.push({
      userId: newUser?.userId,
      email: newUser.email,
      role: newUser.role,
    });

    await handleSave();
    setUserToAdd(null);
    setIsAdding(false);
    setnewUserSelectedRole(defaultRole);
  };

  const handleAddClose = () => {
    setUserToAdd(null);
    setnewUserSelectedRole(defaultRole);
    setIsAdding(false);
  };

  const handleReset = () => {
    setIsAdding(false);
    setUsers(formattedCollaborators);
    setUserToAdd(null);
    setnewUserSelectedRole(defaultRole);
  };

  const searchUser = async (val) => {
    const res = await dispatch(getUserAccessControlAutoCompleteApi({ searchText: val })).unwrap();
    return res;
  };

  const handleSave = async () => {
    const collaborators = formattedCollaborators.map(({ userId, email, role }) => {
      return {
        userId,
        email,
        role: toUpperCamelCase(role),
      };
    });

    const data = {
      loanId: params?.loanId,
      collaborators: collaborators,
    };

    await dispatch(postLoanSaveAccessControlChangesApi(data)).unwrap();

    toast(
      <div className="toastMessageContainer">
        <div className="succesIconBackground1">
          <SuccessIcon />
        </div>
        <span className="toastMessage-text">Loan's Access Updated</span>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      },
    );

    setIsAdding(false);
    setUserToAdd(null);
    setnewUserSelectedRole(defaultRole);
  };

  const handleChangeControlRole = (role) => {
    setnewUserSelectedRole(role);
    handlePopper(false);
  };

  const handleDelete = (index) => {
    setIsDeleting(index);
    dispatch(commonActions.toggleDeleteFilesModalVisibility(MODAL_REVOKE_ACCESS));
  };

  const handleDeleteModalYes = async () => {
    formattedCollaborators = formattedCollaborators.filter((_, index) => index !== isDeleting);
    await handleSave();
  };

  const isLoadingApis = isLoading || isLoadingRoles;

  return (
    <Drawer
      size={"md"}
      isOpen={userShareAccessDrawer.isVisible}
      header={
        <div className="d-flex flex-column flex-1 gap-4">
          <h4 className="text-xl-bold">Access</h4>
        </div>
      }
      handleClose={handleClose}
    >
      <div className={"d-flex flex-column h-100 nv-profileSettings gap-16"}>
        {canAddUserShareAccess && (
          <div className="d-flex justify-content-end">
            <Button
              onClick={handleAddUser}
              label="Add User"
              type="outlined"
              startIcon={<AddIcon />}
            />
          </div>
        )}

        {isLoadingApis ? (
          <div className="px-24">
            <Skeleton count={4} height={44} className={"mb-16 "} />
          </div>
        ) : (
          <div style={{ maxHeight: "75vh", overflowY: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="border-top-gray-250">
                    <Box
                      sx={{
                        display: "flex",
                        padding: "12px 24px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <p className="headerCell-text">User Name</p>
                    </Box>
                  </TableCell>
                  <TableCell className="border-top-gray-250" style={{ width: "138px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <p className="headerCell-text">Permissions</p>
                    </Box>
                  </TableCell>

                  <TableCell className="border-top-gray-250" style={{ width: "116px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "12px 24px",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <p className="headerCell-text">Actions</p>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isAdding && (
                  <TableRow style={{ height: "64px" }} ref={ref}>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          padding: "0px 24px",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <BulkDropdownUserAccess
                          searchFunc={searchUser}
                          users={users}
                          setUsers={setUsers}
                          roles={roles}
                          loanId={params?.loanId}
                          userToAdd={userToAdd}
                          handleAddList={setUserToAdd}
                          handleUserToAdd={setUserToAdd}
                          cancelAdding={() => setIsAdding(false)}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Dropdown
                          showPopper={showPopper}
                          handlePopper={handlePopper}
                          maxVisibleItems={5}
                          listClassName="mt-6"
                          label={
                            <BadgeButton
                              textClassName="badge-text"
                              label={newUserSelectedRole?.name}
                              endIcon={<EditIcon className="edit-icon" />}
                            >
                              {newUserSelectedRole?.name}
                            </BadgeButton>
                          }
                        >
                          {roles?.length &&
                            roles
                              .filter((role) => role.id !== newUserSelectedRole?.id)
                              .map((role) => (
                                <li
                                  key={role.id}
                                  onClick={() => handleChangeControlRole(role)}
                                  className="listitem-bigger archiveColor cursorPointer"
                                >
                                  {role.name}
                                </li>
                              ))}
                        </Dropdown>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          boxSizing: "border-box",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          gap: "4px",
                          paddingRight: "16px",
                        }}
                      >
                        <IconButton onClick={handleAddClose} style={{ padding: "8px" }}>
                          <CloseIcon sx={{ fontSize: 20, color: "#525252" }} />
                        </IconButton>
                        <IconButton
                          style={{ padding: "8px" }}
                          onClick={handleAddCheck}
                          disabled={!userToAdd}
                        >
                          <CheckIcon sx={{ fontSize: 20, color: "#A459FF" }} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {users?.map((user, index) => (
                  <Collaborator
                    {...user}
                    key={user?.userId ?? user?.email}
                    roles={roles}
                    index={index}
                    users={users}
                    handleUsers={setUsers}
                    handleDelete={handleDelete}
                  />
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
      <DeleteModal
        icon={<UserXIcon />}
        key={MODAL_REVOKE_ACCESS}
        modalType={MODAL_REVOKE_ACCESS}
        type="deleteFilesModal"
        action="toggleDeleteFilesModalVisibility"
        successHandler={handleDeleteModalYes}
        header="Revoke Access"
        message="Are you sure you want to revoke access of this user from this property?"
      />
    </Drawer>
  );
};

export default UserAccessDrawer;
