import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as Facebook } from 'assets/svg/icons/facebook.svg';
import { ReactComponent as Instagram } from 'assets/svg/icons/instagram.svg';
import { ReactComponent as Linkedin } from 'assets/svg/icons/linkedin.svg';
import { ReactComponent as Logo } from 'assets/svg/LogoBlack.svg';
import { ReactComponent as Twitter } from 'assets/svg/icons/twitter.svg';
import { AppRoute } from 'utils/constants/ConstantsUtil';
import './styles.scss';

const Footer = () => {
  const location = useLocation();
  const startUpPages = [];
  const isMinHeightSet = startUpPages.includes(location.pathname);

  // if (isStartUpPages) return '';

  return (
    <footer className='footer_section'>
      <div className='content_wrap'>
        <div className='flex_box footer_wrap space_between align_start response_stays '>
          <div className='left_content'>
            <Logo />
            <p className='we_are'>
              Battalion Lending LLC <br />
              1600 Beverley Road Suite C1 <br />
              Brooklyn, NY 11226
            </p>
          </div>
          <div className='footer_items flex_box align_start response_stays justify_start'>
            <div className='item'>
              <div className='title'>Financing</div>
              <div className='links'>
                <Link to={AppRoute.RENTAL} className='footer_link'>
                  Rental
                </Link>
                <Link to={AppRoute.BRIDGE} className='footer_link'>
                  Bridge
                </Link>
                <Link to={AppRoute.CONSTRUCTION} className='footer_link'>
                  Construction
                </Link>
                <Link to={AppRoute.INVESTOR} className='footer_link'>
                  Investor Second Mortgage
                </Link>
              </div>
            </div>
            <div className='item'>
              <div className='title'>Navigation</div>
              <div className='links'>
                <Link
                  to={{
                    pathname: AppRoute.ROOT,
                    hash: '#lend',
                    state: { fromDashboard: true },
                  }}
                  className='footer_link'>
                  Where We Lend
                </Link>
                <Link to={AppRoute.AGREEMENT} className='footer_link'>
                  Terms and Conditions
                </Link>
                <Link to={AppRoute.POLICY} className='footer_link'>
                  Privacy Policy
                </Link>
              </div>
            </div>
            <div className='item'>
              <div className='title' id='contact'>
                Contact
              </div>
              <div className='links '>
                <a href='tel:+1 718.208.2000' className='footer_link'>
                  +1 718.208.2000
                </a>
                <a href='mailto:infobattalion.com' className='footer_link'>
                  info@battalion.com
                </a>
              </div>
              <div className='social_media_icons'>
                <a
                  href='http://twitter.com/battalion'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <Twitter />
                </a>
                <a
                  href='http://linkedin.com/company/battalion'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <Linkedin />
                </a>
                <a
                  href='http://facebook.com/battalion'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <Facebook />
                </a>
                <a
                  href='http://instagram.com/battalion'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <Instagram />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`copyright_text ${
            isMinHeightSet ? 'set_min_height' : ''
          }`}>
          © 2023 Battalion Lending LLC. All rights reserved.
        </div>
      </div>
    </footer>
  );
};
export default Footer;
