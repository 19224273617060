import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getLenderUserApi,
  getListOfUsersApi,
  deleteUserApi,
  getListOfLendersApi,
  deleteLenderApi,
  lockUserApi,
  unlockUserApi,
  lockLenderApi,
  unlockLenderApi,
  activateLenderApi,
  activateUserApi,
  resetPasswordApi,
  resetPasswordPutApi,
  createEndUserApi,
  getEndUserApi,
  updateEndUserApi,
  getListOfLoansUserApi,
  getListOfRolesApi,
  updateLenderUserApi,
  getLoanAtuocompleteApi,
  postAddNewLoanApi,
  deleteLoanApi,
  changePasswordApi,
  getEndUserProileApi,
  updateEndUserProfileApi,
  putUpdateRoleApi,
  getLenderApi,
  createLenderApi,
  updateLenderApi,
  addAccessControlApi,
  getLenderRolesApi,
  getCitizenshipStatusesApi,
  getOwnershipStatusesApi,
  getLocationStatusesApi,
  bulkChangeRoleApi,
  bulkDeleteAccessApi,
  bulkAddAccessApi,
  getActiveLoansApi,
} from "../../api/user.api";

export const getListOfUsers = createAsyncThunk("users/getListOfUsers", async (data) => {
  const res = await getListOfUsersApi({ params: { ...data } });

  return res;
});

export const getListOfLenders = createAsyncThunk(
  "users/getListOfLenders",
  async (data) => await getListOfLendersApi({ params: { ...data } }),
);

export const getLenderUser = createAsyncThunk(
  "users/getLenderUser",
  async (id) => await getLenderUserApi(id),
);

export const updateLenderUser = createAsyncThunk("users/updateLenderUser", async (data) =>
  updateLenderUserApi(data),
);

export const changePassword = createAsyncThunk("users/changePassword", async (data) =>
  changePasswordApi(data),
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (data) => await deleteUserApi({ data }),
);

export const deleteLender = createAsyncThunk(
  "users/deleteLender",
  async (data) => await deleteLenderApi({ params: { ...data } }),
);

export const unlockUser = createAsyncThunk(
  "users/unlockUser",
  async (data) => await unlockUserApi(data),
);

export const lockUser = createAsyncThunk("users/lockUser", async (data) => await lockUserApi(data));

export const lockLender = createAsyncThunk(
  "users/lockLender",
  async (data) => await lockLenderApi(data),
);

export const unlockLender = createAsyncThunk(
  "users/unlockLender",
  async (data) => await unlockLenderApi(data),
);

export const activateUser = createAsyncThunk(
  "users/activateUser",
  async (data) => await activateUserApi(data),
);

export const activateLender = createAsyncThunk(
  "users/activateLender",
  async (data) => await activateLenderApi(data),
);

export const resetPassword = createAsyncThunk(
  "users/resetPassword",
  async (data) => await resetPasswordApi(data),
);

export const resetPasswordPut = createAsyncThunk(
  "users/resetPasswordPut",
  async (data) => await resetPasswordPutApi({ ...data }),
);

export const createEndUser = createAsyncThunk(
  "users/createaEndUser",
  async (data) => await createEndUserApi(data),
);

export const getEndUser = createAsyncThunk(
  "users/getEndUser",
  async (id) => await getEndUserApi(id),
);

export const getEndUserProfile = createAsyncThunk(
  "users/getEndUserProfile",
  async (id) => await getEndUserProileApi(id),
);

export const updateEndUser = createAsyncThunk(
  "users/updateEndUser",
  async (data) => await updateEndUserApi(data),
);

export const updateEndUserProfile = createAsyncThunk(
  "users/updateEndUserProfile",
  async (data) => await updateEndUserProfileApi(data),
);

export const getListOfLoansUser = createAsyncThunk(
  "users/getListOfLoansUser",
  async (data) => await getListOfLoansUserApi(data.id, { params: { ...data } }),
);

export const getListOfRoles = createAsyncThunk(
  "getListOfRoles",
  async () => await getListOfRolesApi(),
);

export const getLoanAutocomplete = createAsyncThunk(
  "users/getLoanAutocomplete",
  async (data) => await getLoanAtuocompleteApi({ params: { ...data } }),
);

export const addNewLoan = createAsyncThunk(
  "users/addNewLoan",
  async (data) => await postAddNewLoanApi(data),
);

export const deleteLoanAccess = createAsyncThunk(
  "users/deleteLoanAccess",
  async (data) => await deleteLoanApi({ params: { ...data } }),
);

export const updateRole = createAsyncThunk(
  "users/updateRole",
  async (data) => await putUpdateRoleApi(data),
);

export const getLender = createAsyncThunk("users/getLender", async (id) => await getLenderApi(id));

export const createLender = createAsyncThunk(
  "users/createLender",
  async (data) => await createLenderApi(data),
);

export const updateLender = createAsyncThunk(
  "users/updateLender",
  async (data) => await updateLenderApi(data),
);

export const addAccessControl = createAsyncThunk(
  "users/addAccessControl",
  async (data) => await addAccessControlApi(data),
);

export const getLenderRoles = createAsyncThunk(
  "user/getLenderRoles",
  async () => await getLenderRolesApi(),
);

export const getCitizenshipStatuses = createAsyncThunk(
  "user/getCitizenshipStatuses",
  async () => await getCitizenshipStatusesApi(),
);
export const getLocationStatuses = createAsyncThunk(
  "user/getLocationStatuses",
  async () => await getLocationStatusesApi(),
);
export const getOwnershipStatuses = createAsyncThunk(
  "user/getOwnershipStatuses",
  async () => await getOwnershipStatusesApi(),
);

export const bulkChangeRole = createAsyncThunk(
  "users/bulkChangeRole",
  async (data) => await bulkChangeRoleApi(data),
);

export const bulkDeleteAccess = createAsyncThunk(
  "users/bulkDeleteAccess",
  async (data) => await bulkDeleteAccessApi(data),
);

export const bulkAddAccess = createAsyncThunk(
  "users/bulkAddAccess",
  async (data) => await bulkAddAccessApi(data),
);

export const getActiveLoans = createAsyncThunk(
  "users/getActiveLoans",
  async (id) => await getActiveLoansApi(id),
);
const userSlice = createSlice({
  name: "user",
  initialState: {
    currentTab: "users",
    currentUser: null,
    currentUserId: null,
    users: {
      isActive: "true",
      searchText: null,
      currentPage: 1,
      sortColumn: {
        sortColumnName: "fullName",
        sortColumnOrder: "asc",
      },
      usersList: {
        isLoading: false,
        error: null,
        data: [],
      },
    },
    lenders: {
      isActive: "true",
      searchText: null,
      currentPage: 1,
      sortColumn: {
        sortColumnName: "fullName",
        sortColumnOrder: "asc",
      },
      lendersList: {
        isLoading: false,
        error: null,
        data: [],
      },
    },
    lenderUser: null,
    userProfile: null,
  },
  reducers: {
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    userSetSearchText(state, action) {
      state.users.searchText = action.payload;
    },
    userNextPage(state) {
      state.users.currentPage++;
    },
    userPrevPage(state) {
      state.users.currentPage--;
    },
    userSetSortColumn(state, action) {
      state.users.sortColumn = action.payload;
    },
    userSetIsActive(state, action) {
      state.users.isActive = action.payload;
    },
    userResetState(state) {
      state.users.searchText = null;
      state.users.currentPage = 1;
      state.users.sortColumn = {
        sortColumnName: "fullName",
        sortColumnOrder: "asc",
      };
      state.users.usersList = {
        isLoading: false,
        error: null,
        data: [],
      };
    },
    lenderSetSearchText(state, action) {
      state.lenders.searchText = action.payload;
    },
    lenderNextPage(state) {
      state.lenders.currentPage++;
    },
    lenderPrevPage(state) {
      state.lenders.currentPage--;
    },
    lenderSetSortColumn(state, action) {
      state.lenders.sortColumn = action.payload;
    },
    lenderSetIsActive(state, action) {
      state.lenders.isActive = action.payload;
    },
    lenderResetState(state) {
      state.lenders.searchText = null;
      state.lenders.currentPage = 1;
      state.lenders.sortColumn = {
        sortColumnName: "fullName",
        sortColumnOrder: "asc",
      };
      state.lenders.lendersList = {
        isLoading: false,
        error: null,
        data: [],
      };
    },
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    setCurrentUserId(state, action) {
      state.currentUserId = action.payload;
    },
    setLenderUser(state, action) {
      state.lenderUser = action.payload;
    },
    setUserProfile(state, action) {
      state.userProfile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListOfUsers.pending, (state) => {
        state.users.usersList.isLoading = true;
        state.users.usersList.error = null;
      })
      .addCase(getListOfUsers.fulfilled, (state, action) => {
        state.users.usersList.isLoading = false;
        state.users.usersList.data = action.payload;
      })
      .addCase(getListOfUsers.rejected, (state, action) => {
        state.users.usersList.isLoading = false;
        state.users.usersList.error = action.error.message;
      })
      .addCase(getListOfLenders.pending, (state) => {
        state.lenders.lendersList.isLoading = true;
        state.lenders.lendersList.error = null;
      })
      .addCase(getListOfLenders.fulfilled, (state, action) => {
        state.lenders.lendersList.isLoading = false;
        state.lenders.lendersList.data = action.payload;
      })
      .addCase(getListOfLenders.rejected, (state, action) => {
        state.lenders.lendersList.isLoading = false;
        state.lenders.lendersList.error = action.error.message;
      });
  },
});

export const {
  setUserProfile,
  userProfile,
  setLenderUser,
  lenderUser,
  setCurrentTab,
  userSetSearchText,
  userNextPage,
  userPrevPage,
  userSetSortColumn,
  userResetState,
  userSetIsActive,
  lenderSetSearchText,
  lenderNextPage,
  lenderPrevPage,
  lenderSetSortColumn,
  lenderResetState,
  lenderSetIsActive,
  setCurrentUser,
  setCurrentUserId,
} = userSlice.actions;

export default userSlice.reducer;
