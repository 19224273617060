import React, { useState } from "react";

import { ReactComponent as FileQuestionIcon } from "assets/svg/icons/upload-needed-dot.svg";
import { ReactComponent as FileBlueIcon } from "assets/svg/icons/pending-dot.svg";
import { ReactComponent as FileRedIcon } from "assets/svg/icons/error-dot.svg";
import { ReactComponent as FileYellowIcon } from "assets/svg/icons/in-review-dot.svg";
import { ReactComponent as FileGreenIcon } from "assets/svg/icons/accepted-dot.svg";
import { ReactComponent as XCircleIcon } from "assets/svg/icons/error-dot.svg";
import { ReactComponent as ClockIcon } from "assets/svg/icons/pending-dot.svg";
import { ReactComponent as MinusCircleIcon } from "assets/svg/icons/in-review-dot.svg";
import { ReactComponent as CheckCircleIcon } from "assets/svg/icons/accepted-dot.svg";

import "./styles.scss";
import CustomizedTooltip from "../Tooltip/Tooltip";

const DocumentOrFileStatusDot = ({
  isDocument = true,
  status,
  isPDF = false,
  itsButton = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const icon = isDocument
    ? {
        UploadedNeeded: <FileQuestionIcon />,
        PendingReview: <ClockIcon />,
        Accepted: <CheckCircleIcon />,
        Rejected: <XCircleIcon />,
        InReview: <MinusCircleIcon />,
      }[status]
    : {
        PendingReview: <FileBlueIcon />,
        Accepted: <FileGreenIcon />,
        Rejected: <FileRedIcon />,
        InReview: <FileYellowIcon />,
      }[status];

  const border = {
    PendingReview: "border-blue",
    Accepted: "border-green",
    Rejected: "border-red",
    InReview: "border-yellow",
  }[status];

  const statusForTooltip = {
    UploadedNeeded: "Upload Needed",
    PendingReview: "Pending Review",
    Accepted: "Accepted",
    Rejected: "Rejected",
    InReview: "In Review",
  }[status];

  return (
    <CustomizedTooltip title={statusForTooltip}>
      {itsButton ? (
        <button
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`iconWrapper ${isFocused && border} `}
        >
          {icon}
        </button>
      ) : (
        <div className="iconWrapper cursor-default">{icon}</div>
      )}
    </CustomizedTooltip>
  );
};

export default DocumentOrFileStatusDot;
