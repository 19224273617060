import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { IconButton, Box } from "@mui/material";
import { Avatar } from "@material-ui/core";
import BadgeButton from "../BadgeButton";
import { ReactComponent as UserXIcon } from "assets/svg/icons/user-x-01.svg";
import Dropdown from "../Dropdown/Dropdown";
import { ReactComponent as EditIcon } from "../../../../assets/svg/icons/edit-003.svg";
import { usePopper } from "../../hooks/usePopper";
import CustomizedTooltip from "../Tooltip/Tooltip";
import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";

import "./styles.scss";
import { toUpperCamelCase } from "../../../../utils/shared/helpers";
import { useDispatch } from "react-redux";
import { postLoanSaveAccessControlChangesApi } from "../../loanDetailSlice";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useLoanContext } from "pages/Borrower/LoanDetail";

const PENDING_INVITATION = "Pending Invitation";

const Collaborator = ({
  fullName,
  avatarUrl,
  email,
  roles,
  role: roleName,
  roleId,
  users,
  handleDelete,
  userId,
  index,
  isPending,
}) => {
  const [showPopper, handlePopper] = usePopper();
  const { canChangeRoleShareAccess, canRemoveUserShareAccess } = useLoanContext();
  const dispatch = useDispatch();
  const params = useParams();

  const handleChangeControlRole = async (role) => {
    const collaborators = users?.map((user) =>
      user.email === email ? { ...user, role: role.name, roleId: role.id } : user,
    );

    const collabsToSend = collaborators.map(({ userId, email, roleId }) => {
      return {
        userId,
        email,
        role: roleId,
      };
    });

    const data = {
      loanId: params?.loanId,
      collaborators: collabsToSend,
    };

    await dispatch(postLoanSaveAccessControlChangesApi(data)).unwrap();

    toast(
      <div className="toastMessageContainer">
        <div className="succesIconBackground1">
          <SuccessIcon />
        </div>
        <span className="toastMessage-text">Loan's Access Updated</span>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      },
    );
    handlePopper(false);
  };

  const handleDeleteUser = () => {
    handleDelete(index);
  };

  return (
    <TableRow style={{ height: "64px" }}>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            padding: "0px 24px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div className="d-flex align-items-center gap-12">
            <Avatar
              src={avatarUrl}
              alt={fullName}
              className="avatar-text background-color-gray-100"
            >
              {!!fullName
                ? `${fullName?.split(" ")[0][0].toUpperCase()} ${fullName
                    ?.split(" ")[1][0]
                    .toUpperCase()}`
                : email[0].toUpperCase()}
            </Avatar>
            <div>
              <h6 className="fw-500 ff-Indivisible color-gray-900">{fullName}</h6>
              <p className="user-email">{email}</p>
            </div>
            {isPending && <span className="pending-badge">Pending Invite</span>}
          </div>
        </Box>
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Dropdown
            showPopper={showPopper}
            handlePopper={handlePopper}
            maxVisibleItems={5}
            listClassName="mt-6"
            disabled={!canChangeRoleShareAccess || roleName === "Borrower"}
            label={
              <BadgeButton
                disabled={!userId}
                textClassName="badge-text"
                label={roleName}
                endIcon={
                  !(!canChangeRoleShareAccess || roleName === "Borrower") && (
                    <EditIcon className="edit-icon" />
                  )
                }
              >
                {roleName}
              </BadgeButton>
            }
          >
            {roles?.length &&
              roles
                .filter((role) => role.id !== roleId)
                .map((role) => (
                  <li
                    key={role.id}
                    onClick={() => handleChangeControlRole(role)}
                    className="listitem-bigger archiveColor cursorPointer"
                  >
                    {role.name}
                  </li>
                ))}
          </Dropdown>
        </Box>
      </TableCell>
      <TableCell>
        {canRemoveUserShareAccess && (
          <Box
            sx={{
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "4px",
              paddingRight: "16px",
            }}
          >
            {roleName === "Borrower" ? (
              <CustomizedTooltip
                placement="top"
                title={
                  "You can't remove a Borrower unless you assign another Borrower for this Loan."
                }
              >
                <IconButton>
                  <UserXIcon />
                </IconButton>
              </CustomizedTooltip>
            ) : (
              <IconButton onClick={handleDeleteUser}>
                <UserXIcon />
              </IconButton>
            )}
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Collaborator;
