import { Button } from "@property-intel/battalion-ui";
import React from "react";
// import ButtonGroup from "../../wrappers/NewVersion/components/ButtonGroup";
import InputDropdown from "../../wrappers/NewVersion/components/InputDropdown";
import { Grid } from "@mui/material";

const Playground = () => {
  return (
    <div className="mt-10 ml-2">
      <Button variant="primary" size="lg">
        This is a sample button
      </Button>

      <Button className="mt-10" variant="primary" size="sm">
        This is a sample button
      </Button>

      <div className="mt-10 max-w-[500px]">
        {/* <ButtonGroup options={["First", "Fifth"]} /> */}
      </div>
    </div>
  );
};

export default Playground;
