import React from "react";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { createRoot } from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistGate } from "redux-persist/integration/react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BattalionProvider } from "@property-intel/battalion-ui";
import App from "./App";

import "react-loading-skeleton/dist/skeleton.css";
import "./global.scss";

import { store, persistor } from "./configs/store";
import { queryClient } from "./configs/queryClient";

const container = document.getElementById("root");
const root = createRoot(container);

const GOOGLE_CLIENT_KEY = process.env.REACT_APP_GOOGLE_CLIENT_KEY;

root.render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_KEY}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BattalionProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <App />
            </LocalizationProvider>
          </BattalionProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </GoogleOAuthProvider>,
);
