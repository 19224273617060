import { Portal } from "../Portal";
import { useEffect } from "react";

import { ReactComponent as CloseIcon } from "../../../../assets/svg/icons/close-icon.svg";

export const drawerSizes = {
  sm: "sm",
  md: "md",
  lg: "lg",
  elg: "elg",
};

export function Drawer({
  header,
  children,
  footer,
  size = drawerSizes.sm,
  isOpen,
  handleClose,
  hideBackDrop = false,
}) {
  useEffect(() => {
    if (isOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [isOpen]);

  // useEffect(() => {
  //     const closeOnEscapeKey = (e) => (e.key === "Escape" ? handleClose() : null);
  //     document.body?.addEventListener("keydown", closeOnEscapeKey);
  //     return () => {
  //         document.body?.removeEventListener("keydown", closeOnEscapeKey);
  //     };
  // }, [handleClose]);

  return (
    <Portal wrapperId={"react-portal-drawer-container"}>
      <div
        className={`nv-drawer ${hideBackDrop ? "nv-drawer-no-back-drop" : ""} ${
          isOpen ? "nv-drawer-enabled" : "nv-drawer-disabled"
        }`}
      >
        {!hideBackDrop && <div className={"nv-drawer__backdrop"} />}
        <div className={`nv-drawer__content nv-drawer__content-${size}`}>
          <div className={"d-flex nv-drawer__contentHeader"}>
            {header}
            <button className={"nv-drawer__closeButton"} onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>
          <div className={"nv-drawer__contentBody"}>{children}</div>
          {footer ? (
            <div className={"border-top-gray-200 nv-drawer__contentFooter"}>{footer}</div>
          ) : null}
        </div>
      </div>
    </Portal>
  );
}
