import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import Skeleton from "@yisheng90/react-loading";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { IconButton } from "@mui/material";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import { Drawer } from "wrappers/NewVersion/components/Drawer";
import { Button } from "../Button/Button";
import AddIcon from "@mui/icons-material/Add";
import Dropdown from "pages/DocumentManagement/Components/Dropdown/Dropdown.jsx";
import IOSSwitch from "../../../DocumentManagement/Components/Switch/Switch";
import {
  getCategoryListApi,
  postLoanAddDocumentApi,
  getLoanDocumentListApi,
  getTestLoanDocumentsApi,
  getDocumentListAutoCompleteApi,
  deleteDocumentLoanDetailsSidePanel,
  postDocumentLoanDetailsSidePanel,
} from "../../loanDetailSlice";
import { ReactComponent as DeleteIcon } from "assets/svg/icons/trash-02.svg";
import BulkDropdown from "../Dropdown/BulkDropdown";
import DeleteModal from "pages/DocumentManagement/Tabs/Categories/DeleteModal";

const DocumentDrawer = ({ loanId }) => {
  const dispatch = useDispatch();
  const { documentDrawer } = useSelector((state) => state.common);
  const { loanDocumentList } = useSelector(({ loanDetail }) => loanDetail);
  const [documents, setDocuments] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [newDocList, setNewDocList] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [isDeleting, setIsDeleting] = useState(null);

  let formattedDocumentList = useMemo(
    () =>
      loanDocumentList.data?.map((item) => {
        return {
          id: item.documentId,
          name: item.name,
          isLenderOnly: item.isLenderOnly,
          categoryId: item.categoryId,
          deleteId: item.id,
        };
      }),
    [loanDocumentList],
  );

  useEffect(() => {
    setDocuments(formattedDocumentList);
  }, [loanDocumentList]);
  useEffect(() => {
    const fetchCategoryList = async () => {
      const res = await dispatch(getCategoryListApi()).unwrap();
      setCategoryList(res);
    };
    fetchCategoryList();
  }, [dispatch]);

  const handleNewClose = () => {
    setIsNew(false);
    setNewDocList([]);
  };
  const handleDelete = (index) => {
    setIsDeleting(index);
    dispatch(commonActions.toggleDeleteModalVisibility());
  };
  const handleNewCheck = async () => {
    const newData = newDocList.map((item) => {
      return {
        id: item.documentId,
        name: item.documentName,
        isLenderOnly: item.isLenderOnly,
        categoryId: item.category,
      };
    });

    await handleSave(newData);
    handleNewClose();
  };

  const handleDeleteModalYes = async () => {
    try {
      const res = await dispatch(
        deleteDocumentLoanDetailsSidePanel(documents[isDeleting]?.deleteId),
      ).unwrap();
      console.log(res);
      await Promise.all([
        dispatch(getLoanDocumentListApi(loanId)).unwrap(),
        await dispatch(getTestLoanDocumentsApi(loanId)).unwrap(),
      ]);
    } catch (e) {
      console.log(e);
    }
  };
  const searchDocument = async (val) => {
    const res = await dispatch(getDocumentListAutoCompleteApi({ searchText: val })).unwrap();
    return res;
  };
  const handleSave = async (newData) => {
    const data = {
      loanId,
      documents: newData.map((item) => ({
        documentId: item.id,
        isNew: !Boolean(item.id),
        documentName: item.name,
      })),
    };

    await dispatch(postDocumentLoanDetailsSidePanel(data)).unwrap();
    await Promise.all([
      dispatch(getLoanDocumentListApi(loanId)).unwrap(),
      await dispatch(getTestLoanDocumentsApi(loanId)).unwrap(),
    ]);
    setNewDocList([]);
  };
  const onRemove = (index) => {
    setNewDocList((prev) => prev.filter((item, indx) => index !== indx));
  };
  return (
    <Drawer
      size={"md"}
      isOpen={documentDrawer.isVisible}
      header={
        <div className="d-flex flex-column flex-1 gap-4">
          <h4 className={"text-xl-bold"}>Managing Documents</h4>
          <h6 className="fw-400 color-gray-600 ">
            All the documents in the list view are showing here, you can remove them from the view
            or add documents here
          </h6>
        </div>
      }
      handleClose={async () => {
        dispatch(commonActions.toggleDocumentDrawerVisibility());
        setIsNew(false);
        setNewDocList([]);
        setDocuments(
          loanDocumentList.data?.map((item) => {
            return {
              id: item.documentId,
              name: item.name,
              isLenderOnly: item.isLenderOnly,
              categoryId: item.categoryId,
            };
          }),
        );
      }}
    >
      <div className={"d-flex flex-column h-100 nv-profileSettings gap-16"}>
        <div className="d-flex  justify-content-end">
          <Button
            onClick={() => setIsNew(true)}
            label="Add document"
            type="outlined"
            startIcon={<AddIcon />}
          />
        </div>
        {loanDocumentList.isLoading ? (
          <div className="px-24">
            <Skeleton height={44} />
            <Skeleton rows={5} height={60} />
          </div>
        ) : (
          <div style={{ maxHeight: "75vh", overflowY: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="border-top-gray-250">
                    <Box
                      sx={{
                        display: "flex",
                        padding: "12px 24px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <p className="headerCell-text">Document Title</p>
                    </Box>
                  </TableCell>
                  <TableCell className="border-top-gray-250" style={{ width: "118px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "12px 24px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p className="headerCell-text">Lenders Only</p>
                    </Box>
                  </TableCell>
                  <TableCell className="border-top-gray-250" style={{ width: "138px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "12px 24px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <p className="headerCell-text">Category</p>
                    </Box>
                  </TableCell>
                  <TableCell className="border-top-gray-250" style={{ width: "116px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "12px 24px",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <p className="headerCell-text">Actions</p>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isNew && (
                  <TableRow style={{ height: "64px" }}>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          padding: "0px 24px",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <BulkDropdown
                          searchFunc={searchDocument}
                          docList={newDocList}
                          onClick={(data) =>
                            setNewDocList((prev) => {
                              prev.push({
                                ...data,
                                category: categoryList.find((item) => item.name === data.category)
                                  ?.id,
                              });
                              return [...prev];
                            })
                          }
                          onRemove={onRemove}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      ></Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          padding: "0px 24px",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      ></Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          boxSizing: "border-box",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          gap: "4px",
                          paddingRight: "16px",
                        }}
                      >
                        <IconButton onClick={handleNewCheck} disabled={newDocList.length === 0}>
                          <CheckIcon sx={{ fontSize: 20, color: "#A459FF" }} />
                        </IconButton>
                        <IconButton onClick={handleNewClose}>
                          <CloseIcon sx={{ fontSize: 20, color: "#525252" }} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {documents?.map((row, index) => (
                  <TableRow key={"" + row.id + index} style={{ height: "64px" }}>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          padding: "0px 24px",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <h6 className="fw-500 ff-Indivisible color-gray-900">{row.name}</h6>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <IOSSwitch sx={{ m: 1 }} checked={row.isLenderOnly} disabled={true} />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          padding: "0px 24px",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Dropdown
                          options={categoryList}
                          changeHandler={null}
                          currentVal={row.categoryId}
                          edit={false}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          boxSizing: "border-box",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          gap: "4px",
                          paddingRight: "16px",
                        }}
                      >
                        <IconButton onClick={() => handleDelete(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
      <DeleteModal
        successHandler={handleDeleteModalYes}
        header={"Delete Document"}
        message={"Are you sure you want to delete this document?"}
      />
    </Drawer>
  );
};

export default DocumentDrawer;
