import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderQuestion,
  setShowBack,
  setShowResendCode,
  setShowNext,
  setVerificationCode,
  setVerificationCodeValidity,
  setShowProgressBar,
} from "../onboardingSlice";
import { unsetConfirmEmailError } from "pages/Login/loginSlice";
import InputText from "../Components/InputText";
import { ReactComponent as AlertIcon } from "assets/svg/icons/alert-circle.svg";

const Step22 = () => {
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [symbols, setSymbols] = useState(new Array(6).fill(""));
  const inputRefs = useRef(new Array(6).fill(null));
  const [divFcous, setDivFocus] = useState(new Array(6).fill(null));

  const dispatch = useDispatch();
  const header = "Verify Email";
  const { Email } = useSelector((state) => state.onboarding.body.form);
  const { confirmEmail } = useSelector((state) => state.login);

  useEffect(() => {
    if (confirmEmail.error) {
      setIsErrorVisible(true);
    }
  }, [confirmEmail]);

  useEffect(() => {
    if (inputRefs?.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    dispatch(setVerificationCode(symbols.join("")));

    if (symbols.includes(null) || symbols.includes("")) {
      dispatch(setVerificationCodeValidity(false));
    } else {
      dispatch(setVerificationCodeValidity(true));
    }
    return () => {
      dispatch(setVerificationCodeValidity(null));
    };
  }, [symbols, dispatch]);

  useEffect(() => {
    dispatch(setHeaderQuestion(header));
  }, [dispatch, header]);

  useEffect(() => {
    dispatch(setShowBack(true));
    dispatch(setShowResendCode(true));
    dispatch(setShowNext(true));
    dispatch(setShowProgressBar(false));

    return () => {
      dispatch(setShowBack(false));
      dispatch(setShowResendCode(false));
      dispatch(setShowNext(false));
    };
  }, [dispatch]);

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text/plain").slice(0, 6);

    setSymbols((prev) => {
      const newSymbols = pasteData.split("").map((char) => (/^\d$/.test(char) ? char : ""));
      return newSymbols.concat(new Array(6 - newSymbols.length).fill(""));
    });
  };

  const handleChange = (index, e) => {
    const i = e.target.value;

    if (/^\d{1}$/.test(i) && Number(i) && index < inputRefs.current.length) {
      setSymbols((prev) => {
        prev[index] = i;
        return [...prev];
      });

      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }

    if (isErrorVisible) {
      dispatch(unsetConfirmEmailError());
      setIsErrorVisible(false);
    }
  };

  const handleKeyDown = (index, e) => {
    if ((e.keyCode === 8 || e.keyCode === 46) && !e.target.value && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className="createAccountContainer verifyEmail">
      <span>We have sent verification code to your email to verify your email</span>
      <InputText
        placeholder={"Email Address"}
        label="Email Address"
        val={Email}
        disabled={true}
        dynamic={true}
      />
      <div className="boxesContainer">
        <span className="boxLabel">Secure code</span>
        <div className="boxes">
          {symbols.map((symbol, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  className={`box ${
                    symbol !== "" ? "active" : symbols[index - 1] !== "" ? "current active" : null
                  }
                  ${isErrorVisible ? "invalid" : null}`}
                >
                  <input
                    ref={(ref) => (inputRefs.current[index] = ref)}
                    type="tel"
                    maxLength={1}
                    pattern="[0-9]*"
                    focus={index === 0}
                    value={symbol}
                    onPaste={handlePaste}
                    onChange={(e) => handleChange(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    className={`${symbol !== "" ? "active" : null} ${
                      isErrorVisible ? "invalid" : null
                    }`}
                  />
                </div>
                {index === 2 && <span>-</span>}
              </React.Fragment>
            );
          })}
        </div>
        {isErrorVisible && (
          <div className="errorMessage">
            <AlertIcon />
            <span>{confirmEmail.error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step22;
