import React, { useEffect, useState } from "react";
import { commonActions } from "../../../../wrappers/NewVersion/commonSlice";
import { Button } from "../../../../pages/Borrower/Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "wrappers/NewVersion/components/Drawer";
import Skeleton from "@yisheng90/react-loading";
import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";

import "./styles.scss";
import { getTestBorrowerDetailApi, updateBorrowerInfo } from "pages/Borrower/borrowerDetailSlice";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import {
  getCitizenshipStatuses,
  getLocationStatuses,
  getOwnershipStatuses,
} from "pages/UserManagement/userSlice";
import { getAddress } from "pages/OnBoarding/onboardingSlice";
import { Grid } from "@mui/material";
import DatePicker from "pages/UserManagement/Components/DatePicker";
import SearchInput from "pages/UserManagement/Components/SearchInput";
import ButtonGroup from "wrappers/NewVersion/components/ButtonGroup";
import Input from "pages/UserManagement/Components/Input";
import { toast } from "react-toastify";

const BorrowerDetailEditDrawer = () => {
  const [citizenships, setCitizenships] = useState([]);
  const [locations, setLocations] = useState([]);
  const [ownerships, setOwnerships] = useState([]);
  const [isUpdate, setIsUpdate] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const dispatch = useDispatch();
  const { borrowerEditDetails } = useSelector((state) => state.common);

  const {
    testBorrowerDetailData: { data: borrowerDetail },
  } = useSelector(({ borrowerDetail }) => borrowerDetail);

  const { register, handleSubmit, formState, setValue, getValues, control } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    criteriaMode: "all",
    shouldUnregister: false,
  });

  const { errors } = formState;

  const getStatuses = async () => {
    const ownerships = await dispatch(getOwnershipStatuses()).unwrap();
    setOwnerships(ownerships);
    const locations = await dispatch(getLocationStatuses()).unwrap();
    setLocations(locations);
    const citizenships = await dispatch(getCitizenshipStatuses()).unwrap();
    setCitizenships(citizenships);
  };

  useEffect(() => {
    getStatuses();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    borrowerDetail?.dateOfBirth && setValue("dateOfBirth", dayjs(borrowerDetail?.dateOfBirth));
    borrowerDetail?.citizenship &&
      setValue(
        "citizenship",
        citizenships.find((cit) => cit.id === borrowerDetail.citizenship),
      );
    borrowerDetail?.currentAddress && setValue("currentAddress", borrowerDetail?.currentAddress);
    borrowerDetail?.locationStatus && setValue("locationStatus", borrowerDetail?.locationStatus);
    borrowerDetail?.previousAddress && setValue("previousAddress", borrowerDetail?.previousAddress);
    borrowerDetail?.propertyOwnership &&
      setValue("propertyOwnership", borrowerDetail.propertyOwnership);
    borrowerDetail?.monthlyRent &&
      setValue("monthlyRent", `$${borrowerDetail?.monthlyRent.toLocaleString()}`);
    setIsLoading(false);
  }, [dispatch, setValue, borrowerDetail]);

  const handleClose = async () => {
    dispatch(commonActions.toggleBorrowerEditDetailsVisibility());
  };

  const onSubmit = async (data) => {
    if (data.dateOfBirth) {
      data.dateOfBirth = dayjs(data.dateOfBirth);
    }
    if (data.monthlyRent) {
      data.monthlyRent = data.monthlyRent.replace("$", "");
      data.monthlyRent = data.monthlyRent.replaceAll(",", "");
      data.monthlyRent = Number(data.monthlyRent);
    }
    if (data.citizenship) {
      data.citizenship = data.citizenship.id;
    }
    setIsLoading(true);
    try {
      data.id = borrowerDetail?.id;
      await dispatch(updateBorrowerInfo(data)).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Borrower Updated"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
      setIsUpdate(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchBorrower = async () => {
      if (isUpdate) {
        setIsLoading(true);
        const res = await dispatch(getTestBorrowerDetailApi(borrowerDetail.id)).unwrap();
        res?.dateOfBirth && setValue("dateOfBirth", dayjs(res?.dateOfBirth));
        res?.citizenship &&
          setValue(
            "citizenship",
            citizenships.find((cit) => cit.id === res.citizenship),
          );
        res?.currentAddress && setValue("currentAddress", res?.currentAddress);
        res?.locationStatus && setValue("locationStatus", res?.locationStatus);
        res?.previousAddress && setValue("previousAddress", res?.previousAddress);
        res?.propertyOwnership && setValue("propertyOwnership", res.propertyOwnership);
        res?.monthlyRent && setValue("monthlyRent", `$${res?.monthlyRent.toLocaleString()}`);
        setIsLoading(false);
        setIsUpdate(false);
      }
    };

    fetchBorrower();
  }, [isUpdate, dispatch, setValue]);

  const searchLocation = async (val) => {
    const res = await dispatch(getAddress(val)).unwrap();
    return res;
  };

  return (
    <Drawer
      size={"md"}
      isOpen={borrowerEditDetails?.isVisible}
      header={
        <div className="d-flex flex-column flex-1">
          <h4 className={"text-xl-bold"}>Edit Personal Details</h4>
        </div>
      }
      footer={
        <div className="d-flex justify-content-end gap-8">
          <Button
            type="contained"
            backgroundColor="white"
            colorTextEnabled="#344054"
            borderColor="#d0d5dd"
            onClick={handleClose}
            disabled={!borrowerEditDetails?.isVisible}
            label={"Cancel"}
          />
          <Button
            type="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={!borrowerEditDetails?.isVisible}
            label={"Save Changes"}
          />
        </div>
      }
      handleClose={handleClose}
    >
      {isLoading ? (
        <Grid className="max-w-[730] px-6 py-6 mx-auto" container spacing={2} rowSpacing={2}>
          <Grid item xs={6}>
            <Skeleton className="width-100" height={60} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton className="width-100" height={60} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton className="width-100" height={60} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton className="width-100" height={60} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton className="width-100" height={60} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton className="width-100" height={60} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton className="width-100" height={60} />
          </Grid>
        </Grid>
      ) : (
        <form className="max-w-[730px] px-6 py-6 mx-auto" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} rowSpacing={2}>
            <Grid item xs={6}>
              <DatePicker
                maxDate={dayjs().subtract(18, "year")}
                name={"dateOfBirth"}
                label={"Date of Birth"}
                control={control}
                hasOptional={false}
              />
            </Grid>

            <Grid item xs={6}>
              <SearchInput
                type={"select"}
                label={"Citizenship"}
                options={citizenships}
                form={true}
                hasOptional={false}
                control={control}
                name="citizenship"
                onClick={(val) => setValue("citizenship", val)}
              />
            </Grid>

            <Grid item xs={12}>
              <SearchInput
                type={"search"}
                label={"Current Address"}
                itsGoogle
                hasOptional={false}
                control={control}
                name="currentAddress"
                searchFunc={searchLocation}
                defaultValue={getValues()?.currentAddress ?? ""}
                onClick={(val) => setValue("currentAddress", val)}
                fullWidthList
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup
                options={locations}
                name="locationStatus"
                label="Time at Location"
                control={control}
                defaultValue={null}
                setValue={setValue}
                hasOptional={false}
              />
            </Grid>
            {getValues()?.locationStatus === 2 && (
              <Grid item xs={12}>
                <SearchInput
                  type={"search"}
                  label={"Previous Address"}
                  itsGoogle
                  control={control}
                  hasOptional={false}
                  name="previousAddress"
                  searchFunc={searchLocation}
                  defaultValue={getValues()?.previousAddress ?? ""}
                  onClick={(val) => setValue("previousAddress", val)}
                  fullWidthList
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <ButtonGroup
                options={ownerships}
                name="propertyOwnership"
                label="Own Or Rent"
                control={control}
                defaultValue={null}
                setValue={setValue}
                hasOptional={false}
              />
            </Grid>

            {getValues()?.propertyOwnership === 2 && (
              <Grid item xs={12}>
                <Input
                  hasOptional={false}
                  name="monthlyRent"
                  label="Montly Rent"
                  type={"currency"}
                  register={register}
                  required={false}
                  firstCurrencyValue={getValues()?.monthlyRent}
                  errorMessage={errors?.monthlyRent?.message}
                />
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </Drawer>
  );
};

export default BorrowerDetailEditDrawer;
