import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderQuestion,
  setShowNext,
  setResidentialUnits,
  setSendData,
} from "../onboardingSlice";
import Slider from "pages/OnBoarding/Components/Slider/Slider";
const Step4 = () => {
  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();
  const question = "How many residential units are in this property?";
  let { ResidentialUnits } = useSelector((state) => state.onboarding.body.form);
  const { postOnboarding } = useSelector((state) => state.onboarding.body);
  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);

  ResidentialUnits =
    ResidentialUnits === null ? null : Number(ResidentialUnits?.toString().replaceAll(",", ""));

  useEffect(() => {
    if (ResidentialUnits !== null) {
      if (
        !/^\d+$/.test(ResidentialUnits) ||
        Number(ResidentialUnits) < 1 ||
        Number(ResidentialUnits) > 20
      ) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [ResidentialUnits]);

  useEffect(() => {
    if (ResidentialUnits !== null && isValid) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ ResidentialUnits }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [ResidentialUnits, dispatch, postOnboarding, isValid]);

  const updateForm = useCallback(
    (val) => {
      dispatch(setResidentialUnits(val));
    },
    [dispatch],
  );

  return (
    <Slider
      min={1}
      max={20}
      interval={1}
      updateForm={updateForm}
      sliderVal={ResidentialUnits}
      isValid={isValid}
      limit={3}
    />
  );
};

export default Step4;
