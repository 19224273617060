import { Link } from 'react-router-dom';
import { AppRoute } from 'utils/constants/ConstantsUtil';

import ReactFluidScroll from 'utils/shared/ReactFluidScroll';
import InvestorImage from 'assets/images/InvestorBG.jpg';
import { GetStartedBanner } from 'components/DisplayComponents';
import Footer from 'components/Footer';
import './styles.scss';

const Investor = () => {
  return (
    <ReactFluidScroll>
      <section className='investor_wrap program_wrap'>
        <div className='hero_section'>
          <div className='content_wrap'>
            <div className='text_wrap'>
              <h1 data-aos='fade-up' data-aos-delay='0' data-aos-duration='700'>
                Investor Second Mortgage
              </h1>
              <p data-aos='fade-up' data-aos-delay='0' data-aos-duration='800'>
                Tap into your investment property's equity without sacrificing
                your current competitive interest rate. With our exclusive
                investor second mortgage program, we can take your financing to
                a whole new level.
              </p>
              <Link to={AppRoute.ONBOARDING}>
                <button
                  data-aos='fade-up'
                  data-aos-delay='0'
                  data-aos-duration='900'
                  className='button bv3 purple'>
                  Apply Now
                </button>
              </Link>
            </div>
          </div>
          <div className='hero_image'>
            <img src={InvestorImage} alt='hero' />
          </div>
        </div>
        <div className='details_section_wrap'>
          <div className='content_wrap'>
            <div
              className='detail_item_wrap'
              data-aos='fade-up'
              data-aos-delay='0'
              data-aos-duration='700'>
              <div className='title'>Single Family</div>
              <div className='description'>1-4 Unit / Townhouses / Condos</div>
              <ul>
                <li>Loan Amount: up to $500,000</li>
                <li>Max Combined LTV: 80%</li>
                <li>30 Year Fixed</li>
              </ul>
            </div>
          </div>
        </div>
        <GetStartedBanner />
      </section>
      <Footer />
    </ReactFluidScroll>
  );
};

export default Investor;
