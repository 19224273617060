import { Link as ReactLink } from "react-router-dom";

const Link = ({ src, className = "", children }) => {
  return (
    <ReactLink className={`${className} link`} to={src}>
      {children}
    </ReactLink>
  );
};

export default Link;
