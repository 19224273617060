import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { commonActions } from "wrappers/NewVersion/commonSlice.js";
import { Drawer } from "wrappers/NewVersion/components/Drawer";
import Pagination from "../../../Borrower/Components/Pagination/Pagination";
import Skeleton from "@yisheng90/react-loading";
import { ReactComponent as ChevronRight } from "assets/svg/icons/chevron-right-16-gray.svg";

import { toast } from "react-toastify";
import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";
import DocumentOfFileBadge from "../../Components/DocumentOrFileBadge";

import "./styles.css";
import { getDocumentFileForOpening, getLoanDocumentInfo } from "api/loan.api";
import { testUploadLoanDocumentFileViewerApi } from "../../../Borrower/loanDetailSlice";
import StatusSDFDropdown from "pages/Borrower/Components/StatusDSFDropdown";
import { useLoanContext } from "pages/Borrower/LoanDetail";

const ViewFileModal = () => {
  const { loanId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { canEditFile } = useLoanContext();
  let instance;
  const common = useSelector((state) => state.common);
  const { allFiles, fileInfo, isUpdate } = common.viewFilesModal;
  const containerRef = useRef(null);
  const blobCache = useRef({});
  const totalPage = allFiles.length;
  let currentActiveIndex = allFiles.findIndex((f) => f.id === fileInfo?.id);
  if (currentActiveIndex < 0) currentActiveIndex = 0;
  const [currentPage, setCurrentPage] = useState(currentActiveIndex + 1);
  const [currentFile, setCurrentFile] = useState(fileInfo);
  const emptyPayload = { fileInfo: {}, allFiles: [], isVisible: false };
  const { fileName, id } = currentFile || {};
  const [blob, setBlob] = useState(null);
  const handlePageChange = async (newCurrentPage) => {
    setBlob(null);
    if (newCurrentPage > allFiles.length) {
      newCurrentPage = allFiles.length;
    } else if (newCurrentPage < 1) {
      newCurrentPage = 1;
    }
    setCurrentPage(newCurrentPage);
    const newFile = allFiles[newCurrentPage - 1];
    setCurrentFile(newFile);
    await fetchBlob(newFile?.id);
  };

  const fetchBlob = async (fileId) => {
    if (!fileId) return;

    try {
      const fileInfoToSet = await getLoanDocumentInfo(fileId);
      const file = await getDocumentFileForOpening(
        fileInfoToSet.loadUrl.replace(process.env.REACT_APP_BASE_URL, ""),
      );

      const newPayload = {
        fileInfo: { ...fileInfoToSet },
        allFiles: allFiles,
        isVisible: true,
        isUpdate: false,
      };
      dispatch(commonActions.toggleViewFilesModalVisibility(newPayload));

      blobCache.current[fileId] = file; // Cache the blob
      setBlob(file);
    } catch (err) {
      console.log(err);
    }
  };

  const leftHeaderContent = () => {
    return (
      <>
        {canEditFile ? (
          fileInfo?.subDocumentId ? (
            <div className="flex items-center gap-3">
              <DocumentOfFileBadge
                hasSubDocument
                status={fileInfo?.documentStatusDisplayName?.replace(" ", "")}
                name={fileInfo?.documentName}
              />

              <ChevronRight />

              <StatusSDFDropdown
                docId={fileInfo?.subDocumentId}
                label={
                  <DocumentOfFileBadge
                    status={fileInfo?.subDocumentStatusDisplayName?.replace(" ", "")}
                    name={fileInfo?.subDocumentName}
                  />
                }
                onChangeFunc={() => fetchBlob(fileInfo?.id)}
              />
            </div>
          ) : (
            <StatusSDFDropdown
              docId={fileInfo?.documentId}
              label={
                <DocumentOfFileBadge
                  status={fileInfo?.documentStatusDisplayName?.replace(" ", "")}
                  name={fileInfo?.documentName}
                />
              }
              onChangeFunc={() => fetchBlob(fileInfo?.id)}
            />
          )
        ) : fileInfo?.subDocumentId ? (
          <div className="flex items-center gap-3">
            <DocumentOfFileBadge
              hasSubDocument
              status={fileInfo?.documentStatusDisplayName?.replace(" ", "")}
              name={fileInfo?.documentName}
            />

            <ChevronRight />

            <DocumentOfFileBadge
              status={fileInfo?.subDocumentStatusDisplayName?.replace(" ", "")}
              name={fileInfo?.subDocumentName}
            />
          </div>
        ) : (
          <DocumentOfFileBadge
            status={fileInfo?.documentStatusDisplayName?.replace(" ", "")}
            name={fileInfo?.documentName}
          />
        )}
      </>
    );
  };

  const areBlobsEqual = async (blob1, blob2) => {
    const reader1 = new FileReader();
    const reader2 = new FileReader();

    // Read the contents of the first blob
    const readBlob1 = new Promise((resolve, reject) => {
      reader1.onload = () => resolve(reader1.result);
      reader1.onerror = reject;
      reader1.readAsArrayBuffer(blob1);
    });

    // Read the contents of the second blob
    const readBlob2 = new Promise((resolve, reject) => {
      reader2.onload = () => resolve(reader2.result);
      reader2.onerror = reject;
      reader2.readAsArrayBuffer(blob2);
    });

    // Wait for both Blob contents to be read
    const [content1, content2] = await Promise.all([readBlob1, readBlob2]);

    // Compare the contents of the two Blobs
    if (content1.byteLength !== content2.byteLength) {
      return false; // Blobs have different sizes
    }

    const view1 = new DataView(content1);
    const view2 = new DataView(content2);

    for (let i = 0; i < content1.byteLength; i++) {
      if (view1.getUint8(i) !== view2.getUint8(i)) {
        return false; // Blobs differ at position i
      }
    }

    return true; // Blobs are identical
  };

  const updatePdfViewer = async (newDoc) => {
    let newDocumentInfo;
    for (let doc of newDoc.documents) {
      newDocumentInfo = doc.documents.find((d) => d.name === fileInfo.groupName);
      if (newDocumentInfo) break;
    }

    const editedFile = newDocumentInfo.files.find((f) => f.fileName === currentFile.fileName);
    const newAllFiles = allFiles.map((f, i) => {
      const newFile = { ...f };
      if (i === currentPage - 1) newFile.id = editedFile.id;
      return newFile;
    });

    const fileInfoToSet = await getLoanDocumentInfo(currentFile?.id);

    const newPayload = {
      fileInfo: { ...fileInfoToSet },
      allFiles: newAllFiles,
      isUpdate: true,
      isVisible: true,
    };
    dispatch(commonActions.toggleViewFilesModalVisibility(newPayload));
  };

  const saveFile = async () => {
    if (!instance) return;
    const arrayBuffer = await instance.exportPDF();
    const data = new FormData();
    const newBlob = new Blob([arrayBuffer], { type: "application/pdf" });
    const nothingChanged = await areBlobsEqual(newBlob, blob);
    if (nothingChanged) return;
    data.append("LoanDocumentId", fileInfo.groupId);
    const file = new File([newBlob], fileName, { type: newBlob.type, lastModified: Date.now() });
    data.append("id", currentFile.id);
    data.append("File", file);
    const payloadForSave = { loanId, data };

    const newDoc = await dispatch(testUploadLoanDocumentFileViewerApi(payloadForSave)).unwrap();
    toast(
      <div className="toastMessageContainer">
        <div className="succesIconBackground1">
          <SuccessIcon />
        </div>
        <span className="toastMessage-text">Saved successfully</span>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      },
    );
    updatePdfViewer(newDoc);
  };

  useEffect(() => {
    const container = containerRef.current;
    let PSPDFKit;
    let documentBlobObjectUrl;
    (async function () {
      if (!container || !blob) return; // Check for blob as well
      PSPDFKit = await import("pspdfkit");
      PSPDFKit.unload(container);
      documentBlobObjectUrl = URL.createObjectURL(blob);
      const saveItem = {
        type: "custom",
        id: "save-btn",
        title: "Save",
        icon: `<svg width="26" height="26" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_779_16621" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="26">
                <rect width="26" height="26" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_779_16621)">
                <path d="M15.375 5.4V14.025C15.375 14.4 15.2438 14.7188 14.9813 14.9813C14.7188 15.2438 14.4 15.375 14.025 15.375H3.975C3.6 15.375 3.28125 15.2438 3.01875 14.9813C2.75625 14.7188 2.625 14.4 2.625 14.025V3.975C2.625 3.6 2.75625 3.28125 3.01875 3.01875C3.28125 2.75625 3.6 2.625 3.975 2.625H12.6L15.375 5.4ZM14.25 5.8875L12.1125 3.75H3.975C3.9125 3.75 3.8595 3.772 3.816 3.816C3.772 3.8595 3.75 3.9125 3.75 3.975V14.025C3.75 14.0875 3.772 14.1407 3.816 14.1847C3.8595 14.2283 3.9125 14.25 3.975 14.25H14.025C14.0875 14.25 14.1407 14.2283 14.1847 14.1847C14.2283 14.1407 14.25 14.0875 14.25 14.025V5.8875ZM9 12.9562C9.525 12.9562 9.96875 12.7718 10.3312 12.4028C10.6937 12.0343 10.875 11.5938 10.875 11.0812C10.875 10.5562 10.6937 10.1125 10.3312 9.75C9.96875 9.3875 9.525 9.20625 9 9.20625C8.475 9.20625 8.03125 9.3875 7.66875 9.75C7.30625 10.1125 7.125 10.5562 7.125 11.0812C7.125 11.5938 7.30625 12.0343 7.66875 12.4028C8.03125 12.7718 8.475 12.9562 9 12.9562ZM4.78125 7.40625H10.95V4.78125H4.78125V7.40625Z" fill="#3d424e"/>
                </g>
                </svg>
                `,
        onPress: saveFile,
      };
      try {
        instance = await PSPDFKit.load({
          container,
          disableWebAssemblyStreaming: true,
          document: documentBlobObjectUrl,
          licenseKey: process.env.REACT_APP_PSPDF_KEY,
          toolbarItems: [...PSPDFKit.defaultToolbarItems, saveItem],
          baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        });
      } catch (err) {
        console.log({ err });
      }
    })();

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, [blob]); // Dependency on blob

  useEffect(() => {
    if (isUpdate) {
      setBlob(null);
      let newCurrentPage = allFiles.findIndex((f) => f.id === fileInfo?.id) + 1;
      handlePageChange(newCurrentPage);
      const newPayload = {
        fileInfo: { ...fileInfo },
        allFiles: allFiles,
        isVisible: true,
        isUpdate: false,
      };
      dispatch(commonActions.toggleViewFilesModalVisibility(newPayload));
    }
  }, [isUpdate]);

  useEffect(() => {
    dispatch(commonActions.toggleViewFilesModalVisibility(emptyPayload));
  }, [location.pathname]);

  return (
    <Drawer
      isOpen={common.viewFilesModal?.isVisible}
      hideBackDrop={common.viewFilesModal?.isVisible}
      header={leftHeaderContent()}
      size={"elg"}
      handleClose={async () => {
        dispatch(commonActions.toggleViewFilesModalVisibility(emptyPayload));
      }}
    >
      {fileInfo && (
        <div className={"relative w-full mx-auto p-6 pt-0 bg-white rounded-lg shadow-lg"}>
          <div className="flex-space_between mb-24">
            <span className="flex-space_between gap-22">
              {canEditFile ? (
                <StatusSDFDropdown
                  docId={id}
                  isDocument={false}
                  label={
                    <DocumentOfFileBadge
                      isDocument={false}
                      status={fileInfo?.status}
                      name={fileInfo?.fileName}
                      rejectionReason={fileInfo?.rejectionReason}
                    />
                  }
                  onChangeFunc={() => fetchBlob(fileInfo?.id)}
                />
              ) : (
                <DocumentOfFileBadge
                  isDocument={false}
                  status={fileInfo?.status}
                  name={fileInfo?.fileName}
                  rejectionReason={fileInfo?.rejectionReason}
                />
              )}
            </span>

            <div className="paginationContainer">
              <Pagination
                currentPage={currentPage}
                totalPage={totalPage}
                handleNext={() => handlePageChange(currentPage + 1)}
                handlePrev={() => handlePageChange(currentPage - 1)}
                isLoading={false}
                pageType={"File"}
              />
            </div>
          </div>
          <div className="viewer mt-2">
            {blob ? (
              <div style={{ width: "100%", height: "100%" }} ref={containerRef} />
            ) : (
              <Skeleton rows={1} />
            )}
          </div>
        </div>
      )}
    </Drawer>
  );
};
export default ViewFileModal;
