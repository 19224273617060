import { AddButton } from "../AddButton";
import { FilterList } from "../FilterList";
import { useDispatch, useSelector } from "react-redux";
import { borrowerActions } from "../../../../pages/BorrowerOld/borrowerSlice";

export function BorrowerBanner() {
  const dispatch = useDispatch();
  const {
    user,
    filters: { status },
  } = useSelector((state) => state.borrower);

  const handleClick = (status) => {
    dispatch(
      borrowerActions.updateFilters({
        filter: "status",
        value: status,
      })
    );
  };

  return (
    <div className="nv-borrowerBanner">
      <div className="container">
        <div className="d-flex space-between align-items-center">
          <h1>
            {user?.firstName} {user?.lastName}
          </h1>
          <AddButton>Loan</AddButton>
        </div>
        <div>
          <FilterList>
            <FilterList.Item
              isSelected={status === "active"}
              onClick={() => handleClick("active")}
            >
              Active
            </FilterList.Item>
            <FilterList.Item
              isSelected={status === "closed"}
              onClick={() => handleClick("closed")}
            >
              Close
            </FilterList.Item>
            <FilterList.Item
              isSelected={status === "archieved"}
              onClick={() => handleClick("archieved")}
            >
              Archived
            </FilterList.Item>
            <FilterList.Item
              isSelected={status === "all"}
              onClick={() => handleClick("all")}
            >
              Show All
            </FilterList.Item>
          </FilterList>
        </div>
      </div>
    </div>
  );
}
