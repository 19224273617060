

export function FilterList({ children }) {
    return (
        <ul className="d-flex nv-filterList">
            {children}
        </ul>
    )
}

export function FilterListItem({ children, isSelected, ...rest }) {
    return (
        <li className={`cursor-pointer nv-filterList__item ${isSelected ? 'nv-filterList__item-selected' : ''}`} {...rest}>
            <span className="label-4 color-gray-500 nv-filterList__text">{children}</span>
        </li>
    )
}


FilterList.Item = FilterListItem;