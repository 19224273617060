import { Link } from 'react-router-dom';
import { AppRoute } from 'utils/constants/ConstantsUtil';

import RentalImage from 'assets/images/Rental.jpg';
import { GetStartedBanner } from 'components/DisplayComponents';
import ReactFluidScroll from 'utils/shared/ReactFluidScroll';
import Footer from 'components/Footer';
import './styles.scss';

const Rental = () => {
  return (
    <ReactFluidScroll>
      <section className='rental_wrap program_wrap'>
        <div className='hero_section'>
          <div className='content_wrap'>
            <div className='text_wrap'>
              <h1 data-aos='fade-up' data-aos-delay='0' data-aos-duration='700'>
                Rental Financing
              </h1>
              <p data-aos='fade-up' data-aos-delay='0' data-aos-duration='800'>
                At Battalion, we take the time to understand your real estate investment goals. Whether for a purchase or a refinance, we adopt a data-focused, unbiased approach to identify and secure you the best loan options available. Rental property financing made simpler than ever.
              </p>
              <Link to={AppRoute.ONBOARDING}>
                <button
                  className='button bv3 purple'
                  data-aos='fade-up'
                  data-aos-delay='0'
                  data-aos-duration='900'>
                  Apply Now
                </button>
              </Link>
            </div>
          </div>
          <div className='hero_image'>
            <img src={RentalImage} alt='hero' />
          </div>
        </div>
        <div className='details_section_wrap'>
          <div className='content_wrap'>
            <div
              className='detail_item_wrap'
              data-aos='fade-up'
              data-aos-delay='0'
              data-aos-duration='900'>
              <div className='title'>Single Family</div>
              <div className='description'>1-4 Unit / Townhouses / Condos</div>
              <ul>
                <li>Loan Amount: $300,000 - $2,500,000</li>
                <li>Max LTV: 80%</li>
                <li>30 Year Fixed & 5/6 ARM (IO or Fully Amortizing)</li>
              </ul>
            </div>
            <div
              className='detail_item_wrap'
              data-aos='fade-up'
              data-aos-delay='0'
              data-aos-duration='700'>
              <div className='title'>Multi Family</div>
              <div className='description'>5+ Unit Residential & Mixed Use</div>
              <ul>
                <li>Loan Amount: $300,000 - $15,000,000</li>
                <li>Max LTV: 75%</li>
                <li>30 Year Fixed & 5/6 ARM (IO or Fully Amortizing)</li>
              </ul>
            </div>
            <div
              className='detail_item_wrap'
              data-aos='fade-up'
              data-aos-delay='0'
              data-aos-duration='800'>
              <div className='title'>Portfolio</div>
              <div className='description'>1-4 Unit / Townhouses / Condos</div>
              <ul>
                <li>5-20 Unit Properties allowed up to 15% of Loan Amount</li>
                <li>Loan Amount: $300,000 - $15,000,000</li>
                <li>Max LTV: 75%</li>
                <li>30 Year Fixed & 5/6 ARM (IO or Fully Amortizing)</li>
              </ul>
            </div>
          </div>
        </div>
        <GetStartedBanner />
      </section>
      <Footer />
    </ReactFluidScroll>
  );
};

export default Rental;
