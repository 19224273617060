import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderQuestion, setShowNext, setHasMortgage, setSendData } from "../onboardingSlice";
import Button from "../Components/Button";
import { ReactComponent as CheckCircleIcon } from "assets/svg/icons/check-circle.svg";
import { ReactComponent as XCircleIcon } from "assets/svg/icons/x-circle.svg";

const Step18 = () => {
  const dispatch = useDispatch();
  const question = "Do you currently have any existing financing on the property?";

  const { HasMortgage } = useSelector((state) => state.onboarding.body.form);
  const { postOnboarding } = useSelector((state) => state.onboarding.body);

  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);

  const handleClick = (id) => {
    dispatch(setHasMortgage(id));
  };

  useEffect(() => {
    if (HasMortgage !== null) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ HasMortgage }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [HasMortgage, dispatch, postOnboarding]);

  return (
    <div className="buttonWrap">
      <Button
        buttonText={"Yes"}
        buttonIcon={<CheckCircleIcon />}
        onClick={() => handleClick(true)}
        active={HasMortgage}
      />
      <Button
        buttonText={"No"}
        buttonIcon={<XCircleIcon />}
        onClick={() => handleClick(false)}
        active={HasMortgage === false}
      />
    </div>
  );
};

export default Step18;
