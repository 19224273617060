import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderQuestion,
  setShowNext,
  setMortgageBalance,
  // stepNext,
  setSendData,
} from "../onboardingSlice";
import Slider from "pages/OnBoarding/Components/Slider/Slider";

const Step19 = () => {
  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();
  let { MortgageBalance } = useSelector((state) => state.onboarding.body.form);
  const { postOnboarding } = useSelector((state) => state.onboarding.body);
  const question = "What is the outstanding loan balance?";

  MortgageBalance =
    MortgageBalance === null ? null : Number(MortgageBalance?.toString().replaceAll(",", ""));

  useEffect(() => {
    if (MortgageBalance !== null) {
      if (
        !/^\d+$/.test(MortgageBalance) ||
        Number(MortgageBalance) < 300000 ||
        Number(MortgageBalance) > 5000000
      ) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [MortgageBalance]);

  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);

  useEffect(() => {
    if (MortgageBalance !== null && isValid) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ MortgageBalance }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [MortgageBalance, dispatch, postOnboarding, isValid]);

  const updateForm = useCallback(
    (val) => {
      dispatch(setMortgageBalance(val));
    },
    [dispatch],
  );

  return (
    <Slider
      min={300000}
      max={5000000}
      interval={MortgageBalance && MortgageBalance < 1000000 ? 10000 : 25000}
      sliderVal={MortgageBalance}
      updateForm={updateForm}
      isValid={isValid}
      dollar={true}
    />
  );
};

export default Step19;
