import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "wrappers/NewVersion/commonSlice.js";
import { Modal } from "wrappers/NewVersion/components/Modal";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import "./styles.css";
const ConfirmModal = ({ header, message, succesHandler, icon }) => {
  const dispatch = useDispatch();

  const { confirmModal } = useSelector((state) => state.common);

  const handleNo = () => {
    dispatch(commonActions.toggleConfirmModalVisibility());
  };

  const handleYes = async () => {
    if (header?.toLowerCase().includes("password")) {
      await succesHandler();
    } else {
      succesHandler();
    }
    handleNo();
  };

  return (
    <Modal icon={icon} isOpen={confirmModal.isVisible} handleClose={handleNo}>
      <Stack direction="column" spacing={0.5} sx={{ paddingBottom: "32px" }}>
        <h2 className="deleteModal-h2">{header}</h2>
        <p className="deleteModal-p">{message}</p>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Button
          variant="outlined"
          onClick={handleNo}
          sx={{
            padding: "10px,18px",
            width: "170px",
            height: "44px",
            background: "white",
            border: "1px solid #D0D5DD",
            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
            borderRadius: "32px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "1rem",
            lineHeight: "1.5em",
            color: "#344054",
            letterSpacing: "-0.04em",
            "&:hover": {
              border: "1px solid #D0D5DD",
            },
          }}
        >
          No
        </Button>
        <Button
          variant="contained"
          onClick={handleYes}
          sx={{
            padding: "10px,18px",
            width: "170px",
            height: "44px",
            background: "#A459FF",
            border: "1px solid #A459FF",
            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
            borderRadius: "32px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "1rem",
            lineHeight: "1.5em",
            color: "#FFFFFF",
            letterSpacing: "-0.04em",
            "&:hover": {
              border: "1px solid #A459FF",
              background: "#A459FF",
            },
          }}
        >
          {header?.toLowerCase().includes("password") ? "Send" : "Yes"}
        </Button>
      </Stack>
    </Modal>
  );
};

export default ConfirmModal;
