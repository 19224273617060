import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderQuestion,
  setEmail,
  setShowBack,
  setEmailValidity,
  setShowNext,
} from "../onboardingSlice";
import { unsetForgotPasswordError } from "pages/Login/loginSlice";
import InputText from "../Components/InputText";

const ForgotPasswordEmail = () => {
  const [emailIsFocus, setEmailIsFocus] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const dispatch = useDispatch();
  const header = "Forgot password";
  const { Email } = useSelector((state) => state.onboarding.body.form);
  const { forgotPassword } = useSelector((state) => state.login);

  const customNotValidMessage = useMemo(() => {
    if (forgotPassword.error) return "User not found";
    else return null;
  }, [forgotPassword.error]);

  useEffect(() => {
    dispatch(setHeaderQuestion(header));
  }, [dispatch, header]);

  useEffect(() => {
    dispatch(setShowBack(true));
    dispatch(setShowNext(true));

    return () => {
      dispatch(setShowBack(false));
      dispatch(setShowNext(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (Email !== null && Email.length > 0) {
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          Email,
        ) ||
        forgotPassword.error
      ) {
        setEmailIsValid(false);
        dispatch(setEmailValidity(false));
      } else {
        setEmailIsValid(true);
        dispatch(setEmailValidity(true));
      }
    }
  }, [Email, dispatch, forgotPassword]);

  const updateEmail = (val) => {
    dispatch(setEmail(val));
    if (forgotPassword.error) {
      dispatch(unsetForgotPasswordError());
    }
  };
  const handleFocusEmail = (val) => {
    setEmailIsFocus(val);
  };

  return (
    <div className="createAccountContainer forgotPasswordContainer">
      <InputText
        placeholder={"Email Address"}
        updateForm={updateEmail}
        focus={emailIsFocus}
        handleFocus={handleFocusEmail}
        label="Email Address"
        isValid={emailIsValid}
        val={Email}
        dynamic={true}
        customNotValidMessage={customNotValidMessage}
      />
      <span className="disclaimer">
        If your email exists in our system, we will send you a one time code to reset your password
      </span>
    </div>
  );
};

export default ForgotPasswordEmail;
