
import { ReactComponent as ChevronRightIcon } from '../../../../assets/svg/icons/chevron-right-icon.svg'

export function DetailsButton({ className = '', ...rest }) {
    return (
        <button className={`border-gray-300 border-radius-4 cursor-pointer flex-center gap-16 color-gray-700 label-4 color-gray-700  nv-detailsButton ${className}`} {...rest}>
            Details
            <ChevronRightIcon />
        </button>
    )
}