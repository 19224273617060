import CustomizedTooltip from "pages/Borrower/Components/Tooltip/Tooltip";

export const IconButtonV2 = (props) => {
  return (
    <CustomizedTooltip title={props.tooltip}>
      <button
        onClick={props.onClick}
        className="stroke-[#737373] hover:stroke-[#424242] w-9 h-9 flex items-center justify-center"
      >
        {props.children}
      </button>
    </CustomizedTooltip>
  );
};
