import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Drawer } from "wrappers/NewVersion/components/Drawer";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import "./styles.css";
import LenderProfileInfo from "./LenderProfileInfo";
import { setCurrentUser } from "../userSlice";

const LenderDrawer = ({ id, userId }) => {
  const dispatch = useDispatch();

  const { lenderFormDrawer } = useSelector((state) => state.common);
  const [previewImage, setPreviewImage] = useState(null);

  return (
    <Drawer
      size={"md"}
      isOpen={lenderFormDrawer.isVisible}
      header={
        <div className="d-flex flex-column flex-1 gap-4">
          <h4 className={"text-xl-bold"}>Add Lender</h4>
        </div>
      }
      handleClose={async () => {
        dispatch(commonActions.toggleLenderDrawerVisibility());
        dispatch(setCurrentUser(null));
        setPreviewImage(null);
      }}
    >
      {lenderFormDrawer.isVisible && (
        <LenderProfileInfo id={id} previewImage={previewImage} setPreviewImage={setPreviewImage} />
      )}
    </Drawer>
  );
};

export default LenderDrawer;
