import {ScoreRange} from "../ScoreRange";

import {ReactComponent as RefreshIcon} from "../../../../assets/svg/icons/refresh-icon.svg";

export function CreditScoreBox({score = 0}) {
    return (<div className={'mt-32 border-radius-4 border-gray-200 background-color-white nv-creditScoreBox'}>
            <div className={'mb-6 d-flex space-between align-items-center'}>
                <h5>Credit Score</h5>
                <RefreshIcon className={'cursor-pointer nv-creditScoreBox__refreshButton'}/>
            </div>
            <div className={'d-flex space-between align-items-center'}>
                <div>
                    <h3 className={'nv-creditScoreBox__score'}>{score}</h3>
                    <p className={'label-2 color-gray-900 nv-creditScoreBox__scoreState'}>Good score</p>
                </div>
                <ScoreRange/>
            </div>
        </div>)
}