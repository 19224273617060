import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderQuestion,
  setShowNext,
  setHasFinanceSimultaneously,
  setSendData,
} from "../onboardingSlice";
import Button from "../Components/Button";
import { ReactComponent as CheckCircleIcon } from "assets/svg/icons/check-circle.svg";
import { ReactComponent as XCircleIcon } from "assets/svg/icons/x-circle.svg";

const Step12 = () => {
  const dispatch = useDispatch();
  const question = "Are there more properties you’d like to finance?";

  const { HasFinanceSimultaneously } = useSelector((state) => state.onboarding.body.form);
  const { postOnboarding } = useSelector((state) => state.onboarding.body);
  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);

  const handleClick = (id) => {
    dispatch(setHasFinanceSimultaneously(id));
  };
  useEffect(() => {
    if (HasFinanceSimultaneously !== null) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ HasFinanceSimultaneously }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [HasFinanceSimultaneously, dispatch, postOnboarding]);

  return (
    <div className="buttonWrap">
      <Button
        buttonText={"Yes"}
        buttonIcon={<CheckCircleIcon />}
        onClick={() => handleClick(true)}
        active={HasFinanceSimultaneously}
      />
      <Button
        buttonText={"No"}
        buttonIcon={<XCircleIcon />}
        onClick={() => handleClick(false)}
        active={HasFinanceSimultaneously === false}
      />
    </div>
  );
};

export default Step12;
