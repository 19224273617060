import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

import { borrowerReducer as borrowerReducerOld } from "../pages/BorrowerOld/borrowerSlice";
import { commonReducer } from "../wrappers/NewVersion/commonSlice";
import onboardingReducer from "pages/OnBoarding/onboardingSlice";
import loginReducer from "pages/Login/loginSlice";
import borrowerReducer from "pages/Borrower/borrowerSlice";
import documentReducer from "pages/DocumentManagement/documentSlice";
import borrowerDetailReducer from "pages/Borrower/borrowerDetailSlice";
import loanDetailReducer from "pages/Borrower/loanDetailSlice";
import templateReducer from "pages/Borrower/templateSlice";
import userReducer from "pages/UserManagement/userSlice";

const rootReducer = combineReducers({
  borrower: borrowerReducerOld,
  common: commonReducer,
  onboarding: onboardingReducer,
  login: loginReducer,
  newBorrower: borrowerReducer,
  borrowerDetail: borrowerDetailReducer,
  document: documentReducer,
  loanDetail: loanDetailReducer,
  template: templateReducer,
  user: userReducer,
});

const nonPersistentReducers = ["login", "borrower", "newBorrower"];

const persistConfig = {
  key: "root",
  storage: storageSession,
  manualPersist: true,
  blacklist: nonPersistentReducers,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export const resetStore = () => {
  persistor.persist();
};
export const clearPersistedData = () => {
  persistor.purge();
};
