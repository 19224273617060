import { fetchIDPost, fetchGet, fetchGetTokenized } from "api/common/callerHelpers";
import { DigitalLoanService } from "configs/axios";

export const registerAPI = (data) => {
  return fetchIDPost("/User/battalion/register", data);
};

export const makeLoginCall = (data) => {
  return fetchIDPost("/User/login", data);
};

export const forgotPasswordAPI = (data) => {
  return fetchIDPost("/User/forgot-password", data);
};

export const confirmEmailAPI = async (data) => {
  const response = await fetchIDPost("/User/confirm-email", data);
  // TODO: active me
  // await notifiyLenders();
  return response;
};

export const resendVerificationCodeAPI = (data) => {
  return fetchIDPost("/User/resend-verification-code", data);
};

export const googleAuthAPI = (data) => {
  return fetchIDPost("/User/external-login", data);
};

export const notifiyLenders = async () => {
  // notify all lender admins about new user registration
  return await fetchGet("/api/borrowers/notifiy-lenders");
};

export const confirmEmailForgotPasswordAPI = (data) => {
  return fetchIDPost("/User/confirm-email-forgot-password", data);
};

export const resetPasswordAPI = (data) => {
  return fetchIDPost("/User/reset-password", data);
};

//get all the roles
export const getAllRolesAPI = () => {
  return fetchGet("/api/access-control-roles");
};

// export const getUserGrantedRolesAPI = () => {
//   return DigitalLoanService.get("/users/current-user");
// };
export const getUserGrantedRolesAPI = () => {
  return fetchGetTokenized("/api/users/current-user");
};
