import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchemaRejectionReason } from "pages/UserManagement/constants";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import { Modal } from "wrappers/NewVersion/components/Modal";
import { ReactComponent as SaveIcon } from "../../../assets/svg/icons/x-circle-rejection.svg";
import { Grid } from "@mui/material";
import TextArea from "pages/UserManagement/Components/TextArea";
import { Button } from "../../Borrower/Components/Button/Button";

import "./styles.scss";
import { updateFileStatusApi } from "pages/DocumentManagement/documentSlice";
import { getTestLoanDocumentsApi } from "../loanDetailSlice";
import { useParams } from "react-router-dom";
import { getLoanDocumentInfo } from "api/loan.api";

export function RejectionReasonModal() {
  const dispatch = useDispatch();

  const { loanId } = useParams();

  const { rejectionReasonModal, viewFilesModal } = useSelector((state) => state.common);
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, formState, register, reset } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    criteriaMode: "all",
    resolver: yupResolver(validationSchemaRejectionReason),
  });
  const { errors, isDirty } = formState;

  const onSubmit = async ({ rejectionReason }) => {
    setIsLoading(true);
    dispatch(
      updateFileStatusApi({
        loanDocumentFileId: rejectionReasonModal.fileId,
        status: rejectionReasonModal.statusId,
        rejectionReason: rejectionReason,
      }),
    );
    dispatch(
      commonActions.toggleRejectionReasonModal({
        isVisible: false,
        fileId: rejectionReasonModal.fileId,
        statusId: rejectionReasonModal.statusId,
      }),
    );
  };

  useEffect(() => {
    if (isLoading) {
      const fetchData = async () => {
        dispatch(getTestLoanDocumentsApi(loanId));
        const fileInfo = await getLoanDocumentInfo(rejectionReasonModal.fileId);
        if (viewFilesModal.isVisible) {
          const newPayload = {
            fileInfo,
            allFiles: viewFilesModal.allFiles,
            isVisible: viewFilesModal.isVisible,
            isUpdate: true,
          };
          dispatch(commonActions.toggleViewFilesModalVisibility(newPayload));
        }

        dispatch(
          commonActions.toggleRejectionReasonModal({
            isVisible: false,
            fileId: null,
            statusId: null,
          }),
        );
      };

      setTimeout(() => {
        reset({
          rejectionReason: "",
        });
        fetchData();
        setIsLoading(false);
      }, "1000");
    }
  }, [isLoading]);

  return (
    <Modal
      icon={<SaveIcon />}
      isOpen={rejectionReasonModal.isVisible}
      handleClose={() =>
        dispatch(
          commonActions.toggleRejectionReasonModal({
            isVisible: false,
            fileId: null,
            statusId: null,
          }),
        )
      }
    >
      <form className={"nv-changePasswordForm"} onSubmit={handleSubmit(onSubmit)}>
        <div className={"mb-20"}>
          <h3 className={"display-sm-bold text-align-center mb-2"}>Reject Document</h3>
          <p className="textAreaSubTitle">Please enter a rejection reason</p>
        </div>
        <div className="px-10">
          <Grid className="gap-20" container>
            <Grid item xs={12}>
              <TextArea
                name={"rejectionReason"}
                hasOptional={false}
                register={register}
                errorMessage={errors?.rejectionReason?.message}
              />
            </Grid>
          </Grid>
        </div>
      </form>
      <div className={"pt-20 d-flex gap-12 justify-content-center "}>
        <Grid container spacing={2} rowSpacing={4}>
          <Grid item xs={6}>
            <Button
              width="100%"
              type="contained"
              backgroundColor="white"
              colorTextEnabled="#344054"
              borderColor="#d0d5dd"
              label={"Cancel"}
              disabled={!rejectionReasonModal.isVisible}
              onClick={() => {
                dispatch(
                  commonActions.toggleRejectionReasonModal({
                    isVisible: false,
                    fileId: null,
                    statusId: null,
                  }),
                ),
                  reset({
                    rejectionReason: "",
                  });
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Button
              width="100%"
              type="contained"
              label={"Save"}
              disabled={!isDirty}
              onClick={handleSubmit(onSubmit)}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
