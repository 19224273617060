import React, { useMemo } from "react";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setStep } from "../onboardingSlice";
import NeedHelpModal from "./Modals/NeedHelp";
import PhoneModal from "./Modals/PhoneModal";
import useCheckMobileScreen from "utils/hooks/useCheckMobileScreen";

import { ReactComponent as WhiteLogo } from "assets/svg/icons/logoWhite.svg";
import { ReactComponent as WhiteIsoLogo } from "assets/svg/LogoWhite.svg";
import { ReactComponent as PhoneIcon } from "assets/svg/icons/phoneIconWhite.svg";
import { ReactComponent as HelpCircleIcon } from "assets/svg/icons/help-circle.svg";
import { ReactComponent as EditIcon } from "assets/svg/icons/edit-01.svg";
import "./styles.scss";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { question, subQuestion1, subQuestion2, showQuestions } = useSelector(
    (state) => state.onboarding.header,
  );
  const { newLoan } = useSelector((state) => state.onboarding.body);
  const isOnMobile = useCheckMobileScreen();
  const phone = "(718) 208-2000";
  const navigateLink = useMemo(() => {
    return newLoan?.pathname ? newLoan?.pathname : "/";
  }, [newLoan]);
  const handleEdit = () => {
    dispatch(setStep(0));
  };

  return (
    <section className="onBoardingHeader">
      <div className="logBar">
        <button onClick={() => navigate(navigateLink)}>
          {isOnMobile ? <WhiteLogo /> : <WhiteIsoLogo />}
        </button>
        <div className="contactHelp">
          <button
            className="buttonWrapper callLinkContainer"
            {...(isOnMobile && {
              onClick: () => {
                dispatch(commonActions.togglePhoneModalVisibility());
              },
            })}
          >
            <PhoneIcon />
            {!isOnMobile && <a href={`tel:${phone}`}>{phone}</a>}
          </button>
          <button
            className="buttonWrapper helpCircle"
            onClick={() => dispatch(commonActions.toggleNeedHelpModalVisibility())}
          >
            <HelpCircleIcon />
            {!isOnMobile && <span>Need Help</span>}
          </button>
        </div>
      </div>
      <div className={`textSupport ${!showQuestions ? "fullsize" : ""}`}>
        <p>{question}</p>
        {showQuestions && (
          <div className="subTextSupport">
            <button className="firstRow" onClick={handleEdit}>
              {!isOnMobile && <EditIcon />}
              <p className="primaryAddress">{subQuestion1}</p>
              {isOnMobile && <EditIcon />}
            </button>
            <p className="secondaryAddress">{subQuestion2}</p>
          </div>
        )}
      </div>
      <PhoneModal />
      <NeedHelpModal />
    </section>
  );
};

export default Header;
