import React from "react";
import { Routes, Route } from "react-router-dom";
import Borrower from "../Borrower";
import BorrowerDetail from "../BorrowerDetail";
import LoanDetail from "../LoanDetail";

const BorrowerRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Borrower />} />
      <Route path="/:borrowerId" element={<BorrowerDetail />} />
      <Route path="/:borrowerId/loan/:loanId" element={<LoanDetail />} />
    </Routes>
  );
};

export default BorrowerRoutes;
