import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, AvatarGroup, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomizedTooltip from "pages/Borrower/Components/Tooltip/Tooltip.jsx";
import { toast } from "react-toastify";
import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Table from "@material-ui/core/Table";
import Skeleton from "@yisheng90/react-loading";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { Button } from "pages/Borrower/Components/Button/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import AddIcon from "@mui/icons-material/Add";
import Dropdown from "pages/DocumentManagement/Components/Dropdown/Dropdown.jsx";
import CustomizedCheckbox from "../../Borrower/Components/Checkbox/Checkbox";
import { ReactComponent as AccesControlIcon } from "assets/svg/icons/accessControl.svg";
import SearchDropdown from "../../Borrower/Components/Dropdown/SearchDropdown";
import DeleteModal from "pages/DocumentManagement/Tabs/Categories/DeleteModal";
import {
  getListOfLoansUser,
  getListOfRoles,
  getLoanAutocomplete,
  addNewLoan,
  deleteLoanAccess,
  updateRole,
  getListOfUsers,
  bulkChangeRole,
  bulkDeleteAccess,
} from "../userSlice";
import { ReactComponent as XCircleIcon } from "assets/svg/icons/user-x-01.svg";
import { ReactComponent as ShareIcon } from "assets/svg/icons/share-04.svg";
import { ReactComponent as UserEditIcon } from "assets/svg/icons/usersEdit.svg";
import { commonActions } from "wrappers/NewVersion/commonSlice";

import DeleteMessageModal from "pages/DocumentManagement/Tabs/Categories/DeleteMessageModal";
import AccessControlModal from "../Components/AccessControlModal";
import ButtonSelect from "../Components/ButtonSelect";

import "../styles.scss";
const Loans = ({ id, userId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users } = useSelector(({ user }) => {
    return user;
  });

  const [typeFilter, setTypeFilter] = useState("active");
  const [isLoading, setIsLoading] = useState(false);
  const [listOfRoles, setListOfRoles] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loanList, setLoanList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkRemoveAccess, setBulkRemoveAccess] = useState(false);
  const [accessModalType, setAccessModalType] = useState("single");

  const [newAddress, setNewAddress] = useState("");
  const [newAddressId, setNewAddressId] = useState(null);
  const [newRole, setNewRole] = useState(5);
  const [isNewError, setIsNewError] = useState(false);

  const [messageModalIcon, setMessageModalIcon] = useState(null);
  const [messageModalHeader, setMessageModalHeader] = useState(null);
  const [messageModalMessage, setMessageModalMessage] = useState(null);

  const [shareAccessLoanId, setShareAccessLoanId] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    const res = await dispatch(getListOfLoansUser({ id, status: typeFilter })).unwrap();
    const roles = await dispatch(getListOfRoles()).unwrap();
    setLoanList(res);
    setListOfRoles(roles);
    setIsLoading(false);
    setSelectedRows([]);
  };

  useEffect(() => {
    id && fetchData();
  }, [dispatch, id, typeFilter]);

  const onSelectAllClick = (e) => {
    if (e.target.checked) {
      const newSelected = loanList.map((row, index) => index);
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };
  const handleUpdateUsersList = () => {
    dispatch(
      getListOfUsers({
        isActive: users.isActive,
        searchText: users.searchText,
        page: users.currentPage,
        sortColumnName: users.sortColumn.sortColumnName,
        sortOrder: users.sortColumn.sortColumnOrder,
      }),
    );
  };
  const onSelectRowClick = (e, id) => {
    const isSelected = selectedRows.indexOf(id);

    if (isSelected !== -1) {
      setSelectedRows((prev) => {
        const newSelected = prev.filter((item) => item != id);
        return newSelected;
      });
    } else {
      setSelectedRows((prev) => {
        let newSelected = [];
        newSelected = newSelected.concat(prev, id);
        return newSelected;
      });
    }
  };
  const searchAddress = async (val) =>
    await dispatch(getLoanAutocomplete({ searchText: val })).unwrap();
  const handleNewClose = () => {
    setIsNew(false);
    setNewAddress("");
    setNewRole(5);
    setIsNewError(false);
  };

  const handleNewLoan = async () => {
    try {
      await dispatch(
        addNewLoan({
          loanId: newAddressId,
          userId: id,
          role: newRole,
        }),
      ).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"New Loan Added"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );

      handleNewClose();
      handleUpdateUsersList();
      await fetchData();
    } catch (error) {
      setMessageModalIcon("new");
      setMessageModalHeader("Can't add new loan");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
      setIsNewError(true);
    }
  };
  const handleRadioButtonChange = (e) => {
    setTypeFilter(e.target.value);
  };

  const handleDelete = (loanId) => {
    loanId ? setDeleteId(loanId) : setBulkRemoveAccess(true);
    dispatch(commonActions.toggleDeleteModalVisibility());
  };
  const handleDeleteModalYes = async (bulk) => {
    try {
      await dispatch(
        bulk
          ? bulkDeleteAccess(selectedRows.map((row) => loanList[row].id))
          : deleteLoanAccess({
              accessControlId: deleteId,
            }),
      ).unwrap();

      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Loan Revoked"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
      handleUpdateUsersList();
      await fetchData();
    } catch (error) {
      setMessageModalIcon("revoke");
      setMessageModalHeader("Can't Revoke Loan");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
    } finally {
      bulkRemoveAccess && setBulkRemoveAccess(false);
    }
  };

  const handleUpdateRole = async (roleId, loanId) => {
    try {
      await dispatch(
        updateRole({
          id: loanId,
          role: roleId,
        }),
      ).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Permission Updated"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
      handleUpdateUsersList();

      await fetchData();
    } catch (error) {
      setMessageModalIcon("edit");
      setMessageModalHeader("Can't change role");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
    }
  };

  const handleBulkChangeRole = async (role) => {
    try {
      await dispatch(
        bulkChangeRole({
          accessControlsIds: selectedRows.map((row) => loanList[row].id),
          role: role.id,
        }),
      ).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Permissions Updated"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
      handleUpdateUsersList();

      await fetchData();
    } catch (error) {
      setMessageModalIcon("edit");
      setMessageModalHeader("Can't change role");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
    }
  };

  return (
    <div
      className={
        "d-flex flex-column nv-profileSettings gap-16 pt-24 max-h-[84vh] overflow-y-auto h-[100%] "
      }
    >
      <div className="d-flex  justify-content-end min-h-[36px] items-center">
        {selectedRows.length > 0 ? (
          <>
            <div className="mr-auto  pl-[29px] label-3 text-black">
              {selectedRows.length} selected
            </div>
            <div className=" flex pr-6 gap-8">
              <ButtonSelect
                options={
                  // listOfRoles.filter((role) => role.name !== "Borrower")
                  listOfRoles
                }
                onClick={handleBulkChangeRole}
              >
                <LoadingButton
                  variant="outlined"
                  onClick={null}
                  sx={{
                    padding: "8px 14px",
                    height: "36px",
                    background: "#FFFFFF",
                    border: "1px solid #D6D6D6",
                    boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    borderRadius: "32px",
                    fontFamily: "Indivisible",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#424242",
                    letterSpacing: "-0.04em",
                    textTransform: "none",
                    "&:hover": {
                      border: "1px solid #D6D6D6",
                      background: "#FFFFFF",
                    },
                  }}
                >
                  <div className="flex items-center gap-8 ">
                    <UserEditIcon />
                    <span>Change Role</span>
                  </div>
                </LoadingButton>
              </ButtonSelect>

              <LoadingButton
                variant="outlined"
                onClick={() => {
                  setAccessModalType("multiple");
                  dispatch(commonActions.toggleAccessControlModalVisibility(true));
                }}
                sx={{
                  padding: "8px 14px",
                  height: "36px",
                  background: "#FFFFFF",
                  border: "1px solid #D6D6D6",
                  boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  borderRadius: "32px",
                  fontFamily: "Indivisible",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#424242",
                  letterSpacing: "-0.04em",
                  textTransform: "none",
                  "&:hover": {
                    border: "1px solid #D6D6D6",
                    background: "#FFFFFF",
                  },
                }}
              >
                <div className="flex items-center gap-8 ">
                  <AccesControlIcon width={20} height={20} />
                  <span>Access</span>
                </div>
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                onClick={() => handleDelete()}
                sx={{
                  padding: "8px 14px",
                  height: "36px",
                  background: "#FFFFFF",
                  border: "1px solid #FDA29B",
                  boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  borderRadius: "32px",
                  fontFamily: "Indivisible",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#B42318",
                  letterSpacing: "-0.04em",
                  textTransform: "none",
                  "&:hover": {
                    border: "1px solid #FDA29B",
                    background: "#FFFFFF",
                  },
                }}
              >
                <div className="flex items-center gap-8 ">
                  <HighlightOffRoundedIcon style={{ fontSize: "20px", color: "#B42318" }} />
                  <span>Remove Access</span>
                </div>
              </LoadingButton>
            </div>
          </>
        ) : (
          <>
            <div className="mr-auto pl-[29px]">
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={typeFilter}
                  onChange={handleRadioButtonChange}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value={"active"}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 16,
                          },
                          "&.Mui-checked": {
                            color: "#A459FF",
                          },
                        }}
                      />
                    }
                    label={<span className="radioLabel">{"Active"}</span>}
                  />
                  <FormControlLabel
                    value={"closed"}
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 16,
                          },
                          "&.Mui-checked": {
                            color: "#A459FF",
                          },
                        }}
                      />
                    }
                    label={<span className="radioLabel">{"Closed"}</span>}
                  />
                  <FormControlLabel
                    value={"archived"}
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 16,
                          },
                          "&.Mui-checked": {
                            color: "#A459FF",
                          },
                        }}
                      />
                    }
                    label={<span className="radioLabel">{"Archived"}</span>}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <Button
              onClick={() => setIsNew(true)}
              label="Add Loan"
              type="outlined"
              startIcon={<AddIcon />}
            />
          </>
        )}
      </div>

      {isLoading ? (
        <div style={{ padding: "0px 12px 0px 29px" }}>
          <Skeleton height={44} />
          <Skeleton rows={5} height={60} />
        </div>
      ) : (
        <div style={{ maxHeight: "75vh", overflowY: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="border-top-gray-250" style={{ width: "56px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "24px 12px 24px 24px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CustomizedCheckbox
                      indeterminate={
                        selectedRows.length > 0 && selectedRows.length < loanList.length
                      }
                      checked={selectedRows.length > 0 && selectedRows.length === loanList.length}
                      onChange={onSelectAllClick}
                    />
                  </Box>
                </TableCell>
                <TableCell className="border-top-gray-250">
                  <Box
                    sx={{
                      display: "flex",
                      padding: "12px 0px",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <p className="headerCell-text">Property Address</p>
                  </Box>
                </TableCell>

                <TableCell className="border-top-gray-250" style={{ width: "160px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "12px 24px",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <p className="headerCell-text">Role</p>
                  </Box>
                </TableCell>
                <TableCell className="border-top-gray-250" style={{ width: "128px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "12px 24px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p className="headerCell-text">Access</p>
                  </Box>
                </TableCell>
                <TableCell className="border-top-gray-250" style={{ width: "160px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "12px 24px",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <p className="headerCell-text">Actions</p>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isNew && (
                <TableRow style={{ height: "64px", backgroundColor: " #F5F5F5" }}>
                  <TableCell className={isNewError ? "redRowLeft" : ""}></TableCell>
                  <TableCell className={isNewError ? "redRowMiddle" : ""}>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "0px 0px",
                        alignItems: "center",
                      }}
                    >
                      <SearchDropdown
                        searchFunc={searchAddress}
                        onClick={(val, id) => {
                          setNewAddress(val);
                          setNewAddressId(id);
                          setIsNewError(false);
                        }}
                        value={newAddress}
                      />
                    </Box>
                  </TableCell>
                  <TableCell className={isNewError ? "redRowMiddle" : ""}>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "12px 24px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Dropdown
                        options={listOfRoles}
                        changeHandler={(val) => setNewRole(val)}
                        currentVal={newRole}
                        edit={true}
                        emptyState={false}
                      />
                    </Box>
                  </TableCell>
                  <TableCell className={isNewError ? "redRowMiddle" : ""}>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "0px 24px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    ></Box>
                  </TableCell>
                  <TableCell className={isNewError ? "redRowRight" : ""}>
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "4px",
                        paddingRight: "16px",
                      }}
                    >
                      <IconButton onClick={handleNewLoan} disabled={newAddress.length === 0}>
                        <CheckIcon sx={{ fontSize: 20, color: "#A459FF" }} />
                      </IconButton>
                      <IconButton onClick={handleNewClose}>
                        <CloseIcon sx={{ fontSize: 20, color: "#525252" }} />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {loanList?.map((row, index) => {
                const addressList = row.propertyAddress.split(",");
                const address1 = addressList[0];
                const address2 = addressList.slice(1).join(",");
                const isRowSelected = selectedRows.includes(index);
                return (
                  <TableRow key={"" + row.id + index} style={{ height: "64px" }}>
                    <TableCell style={{ width: "56px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          padding: "24px 12px 24px 24px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CustomizedCheckbox
                          checked={isRowSelected}
                          onChange={(e) => onSelectRowClick(e, index)}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          padding: "0px 0px",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h6 className="fw-500 ff-Indivisible color-gray-900">{address1}</h6>
                        <h6 className="fw-400 ff-Indivisible color-gray-600">{address2}</h6>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          padding: "0px 24px",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Dropdown
                          options={listOfRoles}
                          changeHandler={(roleId) => handleUpdateRole(roleId, row.id)}
                          currentVal={row.roleId || 5}
                          edit={row.role === "Borrower" ? false : true}
                          emptyState={false}
                          excludeCurrent={true}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        {!!row.access.length ? (
                          <AvatarGroup
                            slotProps={{
                              additionalAvatar: {
                                sx: { zIndex: 99, width: 36, height: 36, marginRight: "-2px" },
                                className:
                                  "avatar-text background-color-gray-100 cursor-pointer avatar-hover",
                              },
                            }}
                            max={6}
                          >
                            {row.access?.map(
                              ({ firstName, avatarUrl, lastName, userId }, index) => (
                                <CustomizedTooltip
                                  key={userId}
                                  title={
                                    <div>
                                      <h5 className="mb-6 avatar-tooltip">
                                        {lastName + " " + firstName}
                                      </h5>
                                    </div>
                                  }
                                >
                                  <Avatar
                                    src={avatarUrl}
                                    alt={firstName}
                                    className="avatar-text background-color-gray-100 cursor-pointer"
                                    sx={{
                                      zIndex: index,
                                      width: 36,
                                      height: 36,
                                      marginRight: "-2px",
                                    }}
                                  >
                                    {firstName[0].toUpperCase()}
                                    {lastName[0].toUpperCase()}
                                  </Avatar>
                                </CustomizedTooltip>
                              ),
                            )}
                          </AvatarGroup>
                        ) : null}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          boxSizing: "border-box",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          gap: "4px",
                          paddingRight: "16px",
                        }}
                      >
                        {row.role === "Borrower" ? (
                          <CustomizedTooltip
                            title={
                              <div className="mr-[-14px] ">
                                <p className="regular-tooltip">
                                  You can't remove Borrower unless you make someone else borrower in
                                  this loan.
                                </p>
                              </div>
                            }
                            offset={-12}
                          >
                            <IconButton disableRipple disableTouchRipple>
                              <XCircleIcon />
                            </IconButton>
                          </CustomizedTooltip>
                        ) : (
                          <CustomizedTooltip title="Revoke Access">
                            <IconButton onClick={() => handleDelete(row.id)}>
                              <XCircleIcon />
                            </IconButton>
                          </CustomizedTooltip>
                        )}
                        <CustomizedTooltip title="Add User to Loan">
                          <IconButton
                            onClick={() => {
                              setShareAccessLoanId(row.loanId);
                              setAccessModalType("single");
                              dispatch(commonActions.toggleAccessControlModalVisibility(true));
                            }}
                          >
                            <AccesControlIcon width={20} height={20} />
                          </IconButton>
                        </CustomizedTooltip>

                        <CustomizedTooltip title="Open Loan Details Page">
                          <IconButton
                            onClick={() => navigate(`/borrower/${userId}/loan/${row.loanId}`)}
                          >
                            <ShareIcon />
                          </IconButton>
                        </CustomizedTooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}
      <DeleteModal
        successHandler={() => handleDeleteModalYes(bulkRemoveAccess)}
        header={"Revoke Access"}
        message={"Are you sure you want to revoke access of this user from this property?"}
        icon={<XCircleIcon width={24} height={24} />}
      />

      {messageModalMessage && (
        <DeleteMessageModal
          message={messageModalMessage}
          icon={messageModalIcon}
          header={messageModalHeader}
          removeMessage={() => setMessageModalMessage(null)}
        />
      )}
      {(shareAccessLoanId || selectedRows.length > 0) && (
        <AccessControlModal
          loanId={shareAccessLoanId}
          roles={listOfRoles}
          clearLoanId={() => setShareAccessLoanId(null)}
          updateList={async () => await fetchData()}
          loanList={selectedRows.map((item) => loanList[item].id)}
          type={accessModalType}
        />
      )}
    </div>
  );
};

export default Loans;
