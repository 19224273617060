import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputText from "../Components/InputText";
import {
  setHeaderQuestion,
  // setAddress,
  setFormToInitialState,
  getAddress,
  setShowQuestions,
  setShowNext,
  setShowBack,
  setSendData,
  setShowFooter,
  setShowCreateNewAccount,
  setShowLogin,
  setShowVerifyEmail,
  setShowVerifyPassword,
  setShowProgressBar,
} from "../onboardingSlice";
import { ReactComponent as CheckIcon } from "assets/svg/icons/check.svg";

const Step1 = () => {
  const [typeAddress, setTypeAddress] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [hover, setHover] = useState(null);
  const dispatch = useDispatch();
  const question = "Let’s start by entering the property address";
  const { PropertyAddress } = useSelector((state) => state.onboarding.body.form);
  const { googleAddress, postOnboarding } = useSelector((state) => state.onboarding.body);
  const isLoading = googleAddress.isLoading;
  useEffect(() => {
    const val = PropertyAddress?.split(",")[0];
    setTypeAddress(Boolean(val) ? val : "");
  }, [PropertyAddress]);
  useEffect(() => {
    dispatch(getAddress(typeAddress));
  }, [typeAddress, dispatch]);
  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);
  useEffect(() => {
    setHover(Array(googleAddress.data?.length).fill(false));
  }, [googleAddress]);

  useEffect(() => {
    dispatch(setShowQuestions(false));
    dispatch(setShowFooter(true));
    dispatch(setShowProgressBar(true));
  }, [dispatch]);

  useEffect(() => {
    if (PropertyAddress !== null) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ PropertyAddress }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [PropertyAddress, dispatch, postOnboarding]);

  useEffect(() => {
    dispatch(setShowBack(false));
    dispatch(setShowCreateNewAccount(false));
    dispatch(setShowLogin(false));
    dispatch(setShowVerifyEmail(false));
    dispatch(setShowVerifyPassword(false));
  }, [dispatch]);

  const onChange = (address) => {
    setTypeAddress(address);
    if (address !== "") setIsShow(true);
  };

  const handleClick = (address) => {
    dispatch(setFormToInitialState(address));

    setIsShow(false);
    // setIsFocus(false);
  };

  const handleMouseEnter = (idx) => {
    setHover((prev) => {
      prev[idx] = true;
      return [...prev];
    });
  };

  const handleMouseLeave = (idx) => {
    setHover((prev) => {
      prev[idx] = false;
      return [...prev];
    });
  };

  const propertyAddressArray = PropertyAddress?.split(",");

  return (
    <>
      {typeAddress ? <label htmlFor="address">Address</label> : <div className="separator"></div>}
      <InputText
        id="address"
        updateForm={onChange}
        val={
          // PropertyAddress?.split(",")[0] === null ||
          // PropertyAddress?.split(",")[0] === undefined
          //   ? ""
          //   : PropertyAddress?.split(",")[0]
          typeAddress || ""
        }
        placeholder={"Address"}
        secondary={
          PropertyAddress
            ? `${propertyAddressArray[1] || ""}, ${propertyAddressArray[2] || ""} ${
                propertyAddressArray[5] || ""
              }`
            : ""
        }
        focus={true}
        // handleFocus={(val) => setIsFocus(val)}
        // dynamic={true}
        withValidationErrorHold={false}
      />
      <ul className="inputDropDown">
        {isLoading ? (
          <>Loading</>
        ) : (
          isShow &&
          typeAddress !== "" &&
          googleAddress.data?.map((address, index) => {
            const splitAddress = address.split(", ");

            return (
              <li
                className="item"
                key={address}
                onClick={() => handleClick(address)}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <div className="addressItem">
                  <span className="mainText">{splitAddress[0]}</span>
                  <span className="secondaryText">{`${splitAddress[1]}, ${splitAddress[2] || ""} ${
                    splitAddress[5] || ""
                  }`}</span>
                </div>
                {hover[index] && <CheckIcon />}
              </li>
            );
          })
        )}
      </ul>
    </>
  );
};

export default Step1;
