import { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderQuestion,
  setShowNext,
  setOwnedPeriod,
  setSendData,
  getPeriodTypes,
  setPeriodType,
} from "../onboardingSlice";

import Slider from "pages/OnBoarding/Components/Slider/Slider";

const Step17 = () => {
  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();
  const question = "How long have you owned the property?";

  let { OwnedPeriod, PeriodType } = useSelector((state) => state.onboarding.body.form);

  const { postOnboarding, periodTypes } = useSelector((state) => state.onboarding.body);

  OwnedPeriod = OwnedPeriod === null ? null : Number(OwnedPeriod?.toString().replaceAll(",", ""));

  useEffect(() => {
    dispatch(getPeriodTypes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);

  const upperBoundary = useMemo(() => {
    if (PeriodType === 1) return 12;
    return 99;
  }, [PeriodType]);

  useEffect(() => {
    if (OwnedPeriod !== null) {
      if (
        !/^\d+$/.test(OwnedPeriod) ||
        Number(OwnedPeriod) < 1 ||
        Number(OwnedPeriod) > upperBoundary
      ) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [OwnedPeriod, upperBoundary]);

  useEffect(() => {
    if (OwnedPeriod !== null && isValid) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ OwnedPeriod, PeriodType }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [OwnedPeriod, dispatch, postOnboarding, isValid, PeriodType]);

  const updateForm = useCallback(
    (val) => {
      dispatch(setOwnedPeriod(val));
    },
    [dispatch],
  );

  const handlePeriodTypeClick = (id) => {
    dispatch(setPeriodType(id));
  };

  return (
    <Slider
      min={1}
      max={PeriodType === 1 ? 12 : 99}
      interval={1}
      updateForm={updateForm}
      sliderVal={OwnedPeriod}
      isValid={isValid}
      dateType={true}
      periodTypes={periodTypes.data}
      periodTypeOnClick={handlePeriodTypeClick}
      selectedPeriodType={PeriodType}
      limit={4}
    />
  );
};

export default Step17;
