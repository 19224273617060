
import { ReactComponent as MapMarkerIcon } from '../../../../assets/svg/icons/map-marker-icon.svg'
import { ReactComponent as LetterIcon } from '../../../../assets/svg/icons/message-icon.svg'
import { ReactComponent as PhoneIcon } from '../../../../assets/svg/icons/phone-icon.svg'
import {useSelector} from "react-redux";


export function BorrowerInfo() {
    const {user} = useSelector(state => state.borrower);

    return (
        <div className="pt-32 pb-24 nv-borrowerInfo">
            <BorrowerInfoItem
                className='mb-16'
                icon={<MapMarkerIcon />}
            >
                <div className='gap-8 d-flex space-between align-items-start'>
                    <p className='label-6 flex-2'>{user?.currentAddress?.address}</p>
                    <div className='flex-1 border-radius-16 nv-rentYear'>
                        <p className='label-2 color-gray-700 text-align-center'>
                            {'Rent (<2 years)'}
                        </p>
                    </div>
                </div>
            </BorrowerInfoItem>
            <BorrowerInfoItem
                className='mb-16'
                icon={<LetterIcon />}
            >
                <p className='label-6'>{user?.email}</p>
            </BorrowerInfoItem>
            <BorrowerInfoItem
                icon={<PhoneIcon />}
            >
                <p className='label-6'>{user?.phone}</p>
            </BorrowerInfoItem>
        </div>
    )
}


export function BorrowerInfoItem({ icon, className = '', children }) {
    return (
        <div className={`d-flex gap-24 nv-borrowerInfo__item ${className}`}>
            <div className="flex-center border-radius-4 nv-borrowerInfo__iconBox">{icon}</div>
            <div className='flex-1'>
                {children}
            </div>
        </div>
    )
}