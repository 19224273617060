import React from "react";

import "./styles.css";

const TextArea = ({
  label,
  type,
  name,
  register,
  required,
  disabled,
  errorMessage,
  placeholder = "",
  hasOptional = true,
}) => {
  return (
    <div className="inputContainer">
      <div className="inputContainer-labelRow">
        <label htmlFor={name} className={`inputLabel ${disabled ? "disabledInputFormLabel" : ""}`}>
          {label}
        </label>
        {!required && !disabled && hasOptional && (
          <span className="inputContainerOptional">optional</span>
        )}
      </div>

      <textarea
        cols={"10"}
        rows={"10"}
        type={type ? type : "text"}
        name={name}
        placeholder={placeholder}
        className={`input min-h-[200px] outline-none ${disabled ? "disabledIputForm" : ""} ${
          errorMessage ? "inputErrorMode" : ""
        }`}
        {...(register && register(name))}
      />

      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
    </div>
  );
};

export default TextArea;
