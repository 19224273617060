import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ChevronLeftIcon } from "assets/svg/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "assets/svg/icons/chevron-right.svg";
import {
  stepNext,
  stepBack,
  postOnboardingNext,
  postOnboardingComplete as postOnboardingCompleteCall,
  setNewLoan,
} from "./../onboardingSlice";
import {
  register as registerFunc,
  loginCall,
  resendVerificationCode,
  confirmEmail,
  forgotPassword as forgotPasswordFunc,
  confirmEmailForgotPassword as confirmEmailForgotPasswordFunc,
  resetPassword as resetPasswordFunc,
  getUserGrantedRoles,
} from "pages/Login/loginSlice";
import { setTokens } from "utils/shared/helpers";
import { UserRoles } from "utils/constants/ConstantsUtil";
import { resetStore } from "configs/store";
import { setStep, setFormToInitialState } from "./../onboardingSlice";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import DeleteMessageModal from "pages/DocumentManagement/Tabs/Categories/DeleteMessageModal";
import { postLoanNextApi, postLoanSaveCompleteApi } from "pages/Borrower/loanDetailSlice";

const useMessageModalState = () => {
  const [message, setMessage] = useState(null);
  const [header, setHeader] = useState(null);
  const [buttonText, setButtonText] = useState(null);
  const [icon, setIcon] = useState(null);

  return {
    message,
    header,
    buttonText,
    icon,
    setMessage,
    setHeader,
    setButtonText,
    setIcon,
    reset: () => {
      setMessage(null);
      setHeader(null);
      setButtonText(null);
      setIcon(null);
    },
  };
};

const ButtonHolder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isNewLoanSubmitLoading, setIsNewLoanSubmitLoading] = useState(false);

  const messageModal = useMessageModalState();
  const {
    PropertyAddress,
    TransactionType,
    PropertyType,
    ResidentialUnits,
    Condition,
    PurchasePrice,
    OccupancyType,
    OwnedPeriod,
    HasMortgage,
    MortgageBalance,
    EstimatedValue,
    LoanAmount,
    LTV,
    MonthlyRent,
    TransactionPast2Years,
    EstimatedCreditScore,
    HasFinanceSimultaneously,
    PropertyCount,
    Email,
    PhoneNumber,
    FirstName,
    LastName,
    ContactType,
    PeriodType,
    password,
    passwordAgain,
    verificationCode,
  } = useSelector((state) => state.onboarding.body.form);
  const {
    sendData,
    currentStep,
    showNext,
    showBack,
    postOnboarding,
    showVerifyEmail,
    showCreateNewAccount,
    showLogin,
    showResendCode,
    showVerifyPassword,
    postOnboardingComplete,
    showConfirmPassword,
    newLoan,
  } = useSelector((state) => state.onboarding.body);

  const {
    EmailIsValid,
    PasswordIsValid,
    FirstNameIsValid,
    LastNameIsValid,
    PasswordAgainIsValid,
    VerificationCodeIsValid,
  } = useSelector((state) => state.onboarding.body.valid);

  const {
    register,
    // verificationCodeFrom,
    forgotPassword,
    confirmEmailForgotPassword,
    resetPassword,
    userLogin,
  } = useSelector((state) => state.login);

  const { deleteModalMessage } = useSelector((state) => state.common);
  const { showProgressBar } = useSelector((state) => state.onboarding.footer);

  const { header } = useSelector((state) => state.onboarding);

  useEffect(() => {
    if (
      currentStep === 29 &&
      register.error === "User with the given email address already exist."
    ) {
      // messageModal.reset();

      messageModal.setButtonText("Done");
      messageModal.setHeader("Account Detected");
      messageModal.setMessage(
        `Welcome, <span>${FirstName}</span>! We've detected that you already have an account with us. Your application has been successfully submitted, you can now login to your Battalion dashboard to check your application status.`,
      );
      messageModal.setIcon("inviteUser");

      dispatch(commonActions.toggleDeleteModalMessageVisibility());
    }
  }, [currentStep, register.error]);

  const handleNext = useCallback(async () => {
    if (!(currentStep === 14 || currentStep === 27) && currentStep < 27) {
      if (newLoan?.anonymusId) {
        const data = JSON.parse(sendData?.dataAsJson);
        if (
          (currentStep === 11 && HasFinanceSimultaneously === false) ||
          currentStep === 12 ||
          (currentStep === 24 && HasFinanceSimultaneously === false) ||
          currentStep === 25
        ) {
          try {
            setIsNewLoanSubmitLoading(true);
            await dispatch(
              postLoanSaveCompleteApi({
                dataAsJson: JSON.stringify({
                  ...data,
                  FirstName: newLoan?.FirstName,
                  LastName: newLoan?.LastName,
                  Email: newLoan.Email,
                }),
                anonymusId: newLoan?.anonymusId,
              }),
            ).unwrap();

            messageModal.setButtonText("Done");
            messageModal.setHeader("Application Submitted");
            messageModal.setMessage(
              ' Your application has been successfully submitted.  Click "Done" to return to the loan list',
            );
            messageModal.setIcon("successIcon");

            dispatch(commonActions.toggleDeleteModalMessageVisibility());
          } catch (e) {
            console.log(e);
          } finally {
            setIsNewLoanSubmitLoading(false);
          }
        } else {
          dispatch(
            postLoanNextApi({
              dataAsJson: JSON.stringify({
                ...data,
                FirstName: newLoan?.FirstName,
                LastName: newLoan?.LastName,
                Email: newLoan.Email,
              }),
              anonymusId: newLoan?.anonymusId,
            }),
          );
        }
      } else {
        dispatch(postOnboardingNext(sendData));
      }
    }
    if (currentStep === 14) {
      dispatch(
        postOnboardingCompleteCall({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({
            PropertyAddress,
            TransactionType,
            PropertyType,
            ResidentialUnits,
            Condition,
            PurchasePrice,
            OccupancyType,
            OwnedPeriod,
            LoanAmount,
            LTV,
            MonthlyRent,
            TransactionPast2Years,
            EstimatedCreditScore,
            HasFinanceSimultaneously,
            PropertyCount,
            Email,
            PhoneNumber,
            FirstName,
            LastName,
            ContactType,
          }),
        }),
      );
    } else if (currentStep === 27) {
      dispatch(
        postOnboardingCompleteCall({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({
            PropertyAddress,
            TransactionType,
            PropertyType,
            ResidentialUnits,
            Condition,
            OccupancyType,
            OwnedPeriod,
            HasMortgage,
            MortgageBalance,
            EstimatedValue,
            LoanAmount,
            LTV,
            MonthlyRent,
            TransactionPast2Years,
            EstimatedCreditScore,
            HasFinanceSimultaneously,
            PropertyCount,
            Email,
            PhoneNumber,
            FirstName,
            LastName,
            ContactType,
            PeriodType,
          }),
        }),
      );
    }
    if (currentStep === 17 && HasMortgage === false) {
      dispatch(setStep(19));
    } else if (currentStep === 11 && HasFinanceSimultaneously === false) {
      dispatch(setStep(13));
    } else if (currentStep === 30) {
      try {
        await dispatch(
          confirmEmail({
            email: Email,
            verificationCode: verificationCode,
            applicationId: postOnboardingComplete.data?.applicationId,
          }),
        ).unwrap();
        dispatch(setFormToInitialState(null));
        navigate("/login");
      } catch (error) {
        console.log(error);
      }
    } else if (currentStep === 51) {
      if (EmailIsValid) {
        try {
          await dispatch(
            forgotPasswordFunc({
              email: Email,
            }),
          ).unwrap();
          dispatch(stepNext());
        } catch (error) {
          //console.log(error);
        }
      }
    } else {
      if (
        !(
          newLoan?.anonymusId &&
          ((currentStep === 11 && HasFinanceSimultaneously === false) ||
            currentStep === 12 ||
            (currentStep === 24 && HasFinanceSimultaneously === false) ||
            currentStep === 25)
        )
      ) {
        dispatch(stepNext());
      }
    }
  }, [
    newLoan,
    currentStep,
    dispatch,
    sendData,
    HasMortgage,
    HasFinanceSimultaneously,
    postOnboarding.data?.anonymusId,
    PropertyAddress,
    TransactionType,
    PropertyType,
    ResidentialUnits,
    Condition,
    PurchasePrice,
    OccupancyType,
    OwnedPeriod,
    LoanAmount,
    LTV,
    MonthlyRent,
    TransactionPast2Years,
    EstimatedCreditScore,
    PropertyCount,
    Email,
    PhoneNumber,
    FirstName,
    LastName,
    ContactType,
    MortgageBalance,
    EstimatedValue,
    PeriodType,
    verificationCode,
    postOnboardingComplete.data?.applicationId,
    navigate,
    EmailIsValid,
  ]);
  const handleBack = () => {
    switch (currentStep) {
      case 16:
        dispatch(setStep(5));
        break;
      case 13:
        dispatch(setStep(11));
        break;
      case 19:
        dispatch(setStep(17));
        break;
      default:
        dispatch(stepBack());
        break;
    }
  };
  const handleVerifyEmail = async () => {
    try {
      await dispatch(
        registerFunc({
          firstName: FirstName,
          lastName: LastName,
          email: Email,
          password,
          confirmPassword: passwordAgain,
          applicationId: postOnboardingComplete.data?.applicationId,
        }),
      ).unwrap();
      console.log(
        "🚀 ~ file: ButtonHolder.jsx:342 ~ handleVerifyEmail ~ currentStep:",
        currentStep,
      );
      if (currentStep === 48) {
        dispatch(setStep(30));
      } else {
        dispatch(stepNext());
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleVerifyPassword = async () => {
    try {
      await dispatch(
        confirmEmailForgotPasswordFunc({
          email: Email,
          code: verificationCode,
        }),
      ).unwrap();
      dispatch(stepNext());
    } catch (error) {
      //console.log(error);
    }
  };

  const handleLogin = async () => {
    try {
      const res = await dispatch(
        loginCall({
          email: Email,
          password: password,
          clientId: "digitaloan-web",
        }),
      ).unwrap();
      setTokens(res);
      dispatch(setFormToInitialState(null));
      await new Promise((resolve) => setTimeout(resolve, 500));
      const user = await dispatch(getUserGrantedRoles()).unwrap();
      if (user?.accessType === UserRoles.BORROWER) {
        navigate(`/borrower/${user?.dId}`);
      } else {
        navigate("/borrower");
      }
      dispatch(setFormToInitialState(null));
      resetStore();
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendCode = () => {
    dispatch(
      resendVerificationCode({
        email: Email,
      }),
    );
  };

  const handleResetPassword = async () => {
    try {
      await dispatch(
        resetPasswordFunc({
          email: Email,
          password: password,
          confirmPassword: passwordAgain,
          token: confirmEmailForgotPassword.data.Token,
        }),
      ).unwrap();
      alert("password succesfully changed");
      dispatch(setStep(50));
    } catch (error) {
      console.log(error);
    }
  };

  const isOnboardingPage =
    location.pathname.startsWith("/onboarding") || location.pathname.startsWith("/new-loan");
  const isNewAccountPage = location.pathname.startsWith("/new-account");
  const isLoginPage = location.pathname.startsWith("/login");
  const isHeaderQuestionCreateNewAccount =
    header.question === "Lastly, please provide us your contact info so we can reach you";
  const isHeaderSignUpWithEmail = header.question === "Sign Up With Email";
  const isLastStep =
    newLoan?.anonymusId &&
    ((currentStep === 11 && HasFinanceSimultaneously === false) ||
      currentStep === 12 ||
      (currentStep === 24 && HasFinanceSimultaneously === false) ||
      currentStep === 25);

  const isInvalid =
    !FirstNameIsValid ||
    !LastNameIsValid ||
    !PasswordAgainIsValid ||
    !PasswordIsValid ||
    !EmailIsValid ||
    register.isLoading;

  return (
    <>
      <div className={`buttonHolder ${showProgressBar ? "withProgressBar" : ""}`}>
        {isOnboardingPage ? (
          <>
            {currentStep !== 28 && !isHeaderSignUpWithEmail && (
              <div
                className={`nextButtonWrapper ${
                  (!showNext || isNewLoanSubmitLoading) && "disabled"
                }`}
              >
                <button
                  className={`nextButton ${
                    (currentStep === 51 && !EmailIsValid) || forgotPassword.isLoading
                      ? "invalid"
                      : null
                  }`}
                  {...(showNext && { onClick: handleNext })}
                  disabled={
                    forgotPassword.isLoading || postOnboarding.isLoading || isNewLoanSubmitLoading
                  }
                >
                  <span>{isLastStep ? "Submit" : "Next"}</span>
                  {!isLastStep && <ChevronRightIcon />}
                </button>
              </div>
            )}
          </>
        ) : (
          showNext && (
            <div
              className={`nextButtonWrapper ${
                (currentStep === 51 && !EmailIsValid) || forgotPassword.isLoading ? "disabled" : ""
              }`}
            >
              <button
                className={`nextButton ${isLastStep ? "" : "noChevron"} ${
                  (currentStep === 51 && !EmailIsValid) || forgotPassword.isLoading
                    ? "invalid"
                    : null
                }`}
                onClick={handleNext}
                disabled={forgotPassword.isLoading || postOnboarding.isLoading}
              >
                <span>{isLastStep ? "Complete" : "Next"}</span>
                {!isLastStep && <ChevronRightIcon />}
              </button>
            </div>
          )
        )}

        {!isOnboardingPage && showVerifyEmail && (
          <>
            {isNewAccountPage ? (
              <div className={`nextButtonWrapper ${isInvalid ? "disabled" : ""}`}>
                <button
                  className={`nextButton noChevron ${isInvalid ? "invalid" : null}`}
                  onClick={handleVerifyEmail}
                  disabled={isInvalid ? true : false}
                >
                  <span>Verify Email</span>
                </button>
              </div>
            ) : isLoginPage ? (
              <div className="nextButtonWrapper">
                <button className={`noChevron noAccent`} onClick={() => navigate("/new-account")}>
                  <span>Login</span>
                </button>
              </div>
            ) : (
              <div className={`nextButtonWrapper ${isInvalid ? "disabled" : ""}`}>
                <button
                  className={`nextButton ${isInvalid ? "invalid" : null}`}
                  onClick={handleVerifyEmail}
                  disabled={isInvalid ? true : false}
                >
                  <span>Verify Email</span>
                  <ChevronRightIcon />
                </button>
              </div>
            )}
          </>
        )}

        {isOnboardingPage && showVerifyEmail && (
          <div className={`nextButtonWrapper ${isInvalid ? "disabled" : ""}`}>
            <button
              className={`nextButton noChevron ${isInvalid ? "invalid" : null}`}
              onClick={handleVerifyEmail}
              disabled={isInvalid ? true : false}
            >
              <span>Verify Email</span>
            </button>
          </div>
        )}

        {showVerifyPassword && (
          <div
            className={`nextButtonWrapper ${
              !VerificationCodeIsValid || confirmEmailForgotPassword.isLoading ? "disabled" : ""
            }`}
          >
            <button
              className={`nextButton ${
                !VerificationCodeIsValid || confirmEmailForgotPassword.isLoading ? "invalid" : null
              }`}
              disabled={!VerificationCodeIsValid || confirmEmailForgotPassword.isLoading}
              onClick={handleVerifyPassword}
            >
              <span>Next</span>
              <ChevronRightIcon />
            </button>
          </div>
        )}

        {showConfirmPassword && (
          <div
            className={`nextButtonWrapper ${
              !PasswordAgainIsValid || !PasswordIsValid || resetPassword.isLoading ? "disabled" : ""
            }`}
          >
            <button
              className={`nextButton ${
                !PasswordAgainIsValid || !PasswordIsValid || resetPassword.isLoading
                  ? "invalid"
                  : null
              }`}
              onClick={handleResetPassword}
              disabled={!PasswordAgainIsValid || !PasswordIsValid || resetPassword.isLoading}
            >
              <span>Save</span>
              <ChevronRightIcon />
            </button>
          </div>
        )}

        {showLogin && isLoginPage && (
          <div
            className={`nextButtonWrapper ${
              !EmailIsValid || !PasswordIsValid || userLogin.isLoading ? "disabled" : ""
            }`}
          >
            <button
              className={`nextButton noChevron ${
                !EmailIsValid || !PasswordIsValid || userLogin.isLoading ? "invalid" : null
              }`}
              onClick={handleLogin}
              disabled={!EmailIsValid || !PasswordIsValid || userLogin.isLoading}
            >
              <span>Login</span>
            </button>
          </div>
        )}

        {showResendCode && (
          <div className="nextButtonWrapper">
            <button className="nextButton noChevron noAccent" onClick={handleResendCode}>
              <span>Resend Code</span>
            </button>
          </div>
        )}

        <div className="backButtonWrapper">
          {showBack && (
            <button onClick={handleBack}>
              <ChevronLeftIcon />
            </button>
          )}
        </div>
      </div>

      {messageModal.message && (
        <DeleteMessageModal
          message={messageModal.message}
          icon={"inviteUser"}
          header={messageModal.header}
          removeMessage={() => {
            if (newLoan?.anonymusId) {
              navigate(newLoan?.pathname);
              messageModal.reset();
              setNewLoan({});
            } else {
              messageModal.reset();
              dispatch(commonActions.toggleDeleteModalMessageVisibility());
              navigate("/login");
            }
          }}
          buttonText={messageModal.buttonText}
          isHtml
        />
      )}
    </>
  );
};

export default ButtonHolder;
