import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderQuestion,
  setShowNext,
  setTransactionPast2Years,
  setSendData,
} from "../onboardingSlice";
import Slider from "pages/OnBoarding/Components/Slider/Slider";

const Step10 = () => {
  const [isValid, setIsValid] = useState(true);

  const dispatch = useDispatch();
  let { TransactionPast2Years } = useSelector((state) => state.onboarding.body.form);
  const { postOnboarding } = useSelector((state) => state.onboarding.body);

  const question = "How many properties have you financed in the past 2 years?";
  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);

  TransactionPast2Years =
    TransactionPast2Years === null
      ? null
      : Number(TransactionPast2Years?.toString().replaceAll(",", ""));

  useEffect(() => {
    if (TransactionPast2Years !== null) {
      if (
        !/^\d+$/.test(TransactionPast2Years) ||
        Number(TransactionPast2Years) < 1 ||
        Number(TransactionPast2Years) > 50
      ) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [TransactionPast2Years]);

  useEffect(() => {
    if (TransactionPast2Years !== null && isValid) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ TransactionPast2Years }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [TransactionPast2Years, dispatch, postOnboarding, isValid]);

  const updateForm = useCallback(
    (val) => {
      dispatch(setTransactionPast2Years(val));
    },
    [dispatch],
  );

  return (
    <Slider
      min={1}
      max={50}
      interval={1}
      sliderVal={TransactionPast2Years}
      updateForm={updateForm}
      isValid={isValid}
      limit={3}
    />
  );
};

export default Step10;
