import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    user: null, loans: null, filters: {
        status: 'active'
    }
}

export const borrowerSlice = createSlice({
    name: 'borrower', initialState, reducers: {
        setUser(state, action) {
            state.user = action.payload.user;
        }, setLoans(state, action) {
            state.loans = action.payload.loans;
        }, updateFilters(state, action) {
            state.filters[action.payload.filter] = action.payload.value;
        }
    }
});

export const borrowerReducer = borrowerSlice.reducer;
export const borrowerActions = borrowerSlice.actions;