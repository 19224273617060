import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { BorrowerInfo } from "../BorrowerInfo";
// import { PropertiesTable } from "../PropertiesTable";
import { CreditScoreBox } from "../CreditScoreBox";
import Property from "../Property";
import { commonActions } from "wrappers/NewVersion/commonSlice.js";
import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";
import {
  getTestBorrowerDetailLoansApi,
  getTestBorrowerLoanCountByStatusApi,
  archiveLoanApi,
} from "../../borrowerDetailSlice";
import DeleteModal from "../../../DocumentManagement/Tabs/Categories/DeleteModal";
import DeleteMessageModal from "../../../DocumentManagement/Tabs/Categories/DeleteMessageModal";

const PropertiesSection = () => {
  const { borrowerId } = useParams();
  const {
    testBorrowerDetailDataLoans: { isLoading, data },
  } = useSelector(({ borrowerDetail }) => borrowerDetail);

  const [loanIdToBeDeleted, setLoanIdToBeDeleted] = useState(null);
  const [messageModalHeader, setMessageModalHeader] = useState(null);
  const [messageModalMessage, setMessageModalMessage] = useState(null);
  const dispatch = useDispatch();

  const handleArchive = (id) => {
    setLoanIdToBeDeleted(id);
    dispatch(commonActions.toggleDeleteModalVisibility());
  };

  const handleArchiveModalYes = async () => {
    try {
      await dispatch(archiveLoanApi(loanIdToBeDeleted)).unwrap();
      dispatch(getTestBorrowerDetailLoansApi(borrowerId)).unwrap();
      await dispatch(getTestBorrowerLoanCountByStatusApi(borrowerId)).unwrap();

      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Application Archived"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
    } catch (error) {
      setMessageModalHeader("Can't Archive Loan Application");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
      console.log(error);
    }
  };

  return (
    <section className="flex-1 nv-propertiesSection w-100 h-100 pt-32 background-color-gray-50 pb-24">
      <div className="container d-flex h-100 gap-24 nv-borrower_bottom">
        <div className={"nv-propertiesSection__leftSide"}>
          <div className="w-100 h-100 font-indivisible">
            <h4 className="font-indivisible mb-24">Loans</h4>
            {isLoading ? (
              <Skeleton count={5} height={60} className={"mb-16"} />
            ) : data?.length === 0 ? (
              <div className="d-flex flex-column h-90 align-items-center justify-content-center">
                <h5 className="text-align-center mb-4">No Active Loan Applications</h5>
                <p className="text-align-center fs-14 max-width-40ch fw-400">
                  You can start a new loan application by clicking the button below or the one on
                  top-right corner
                </p>
              </div>
            ) : (
              <ul>
                {data?.map((item) => (
                  <Property key={item?.id} handleArchive={handleArchive} {...item} />
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="nv-propertiesSection__rightSide">
          <BorrowerInfo />
          <CreditScoreBox />
        </div>
      </div>
      <DeleteModal
        successHandler={handleArchiveModalYes}
        header={"Archive Application"}
        message={"Are you sure you want to archive this loan application?"}
      />
      <DeleteMessageModal message={messageModalMessage} header={messageModalHeader} />
    </section>
  );
};

export default PropertiesSection;
