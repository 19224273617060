import { DigitalLoanService } from "configs/axios";
import { fetchGetTokenized, fetchPutTokenized } from "api/common/callerHelpers";

export const getBorrower = async () => {
  const res = await DigitalLoanService.get("/borrowers");
  return res;
};

export const getBorrowersApi = async (data) => {
  return await fetchGetTokenized("/api/borrowers/all", data);
};

export const getTestBorrowerDetail = async (id) => {
  return await fetchGetTokenized(`/api/borrowers/${id}`);
};

export const getTestBorrowerLoanCountByStatus = async (data) => {
  return await fetchGetTokenized("/api/loans/status-count", data);
};

export const getTestBorrowerDetailLoans = async (id, data) => {
  return await fetchGetTokenized(`/api/loans/${id}`, data);
};

export const updateLoanStatus = async (id, status) => {
  return await fetchPutTokenized("/api/loans/change-status", { id, status });
};

export const archiveLoan = async (id) => {
  return await fetchPutTokenized(`/api/loans/archive/${id}`);
};

export const updateBorrowerInfoApi = async (id, data) => {
  return await fetchPutTokenized(`/api/borrowers/${id}`, data);
};

export const getCurrentUserApi = async () => {
  return await fetchGetTokenized("/api/users/current-user");
};

export const getUserAccesLevelApi = (id) => {
  return fetchGetTokenized("/api/loans/user-access-level-loan-list/" + id);
};
