import {
  useState,
  useMemo,
  useCallback,
  useEffect,
  // useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Skeleton from "@yisheng90/react-loading";
import { toast } from "react-toastify";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CustomizedTooltip from "pages/Borrower/Components/Tooltip/Tooltip.jsx";

// import CheckIcon from "@mui/icons-material/Check";
// import CloseIcon from "@mui/icons-material/Close";

import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";
import { ReactComponent as UnlockIcon } from "assets/svg/icons/unlock-icon.svg";
// import { ReactComponent as EditIcon } from 'assets/svg/icons/edit-001.svg';
import { ReactComponent as FlipBackwardIcon } from "assets/svg/icons/flip-backward.svg";
import { ReactComponent as DeleteIcon } from "assets/svg/icons/trash-02.svg";
import { ReactComponent as LockIcon } from "assets/svg/icons/lock-03.svg";
import { ReactComponent as ShareIcon } from "assets/svg/icons/share-04.svg";
import { ReactComponent as KyeIcon } from "assets/svg/icons/key-01.svg";
import DeleteModal from "pages/DocumentManagement/Tabs/Categories/DeleteModal";
import DeleteMessageModal from "pages/DocumentManagement/Tabs/Categories/DeleteMessageModal";
import ConfirmModal from "../Components/ConfirmModal";
import Search from "pages/Borrower/Components/SearchBar/Search";
import Pagination from "pages/Borrower/Components/Pagination/Pagination";
import { Avatar } from "@mui/material";

import { getFormattedDate } from "utils/shared/helpers";

import { commonActions } from "wrappers/NewVersion/commonSlice";

import {
  userSetSearchText,
  userNextPage,
  userPrevPage,
  userSetSortColumn,
  deleteUser,
  getListOfUsers,
  userSetIsActive,
  lockUser,
  unlockUser,
  activateUser,
  resetPassword,
  setCurrentUser,
  setCurrentUserId,
} from "../userSlice";
import ArchieveLinkedLoansModal from "../Components/ArchieveLinkedLoansModal";

import "./style.css";

const apiColNames = ["fullName", "currentLoans", "totalLoans", "lastActiveDate"];

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { usersList, sortColumn, currentPage, isActive, searchText } = useSelector(
    ({ user }) => user.users,
  );

  // const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  // const [deleteModalHeader, setDeleteModalHeader] = useState(null);
  // const [deleteModalMessage, setDeleteModalMessage] = useState(null);
  const [messageModalHeader, setMessageModalHeader] = useState(null);
  const [messageModalMessage, setMessageModalMessage] = useState(null);
  const [messageModalIcon, setMessageModalIcon] = useState(null);

  const [confirmModalHeader, setConfirmModalHeader] = useState(null);
  const [confirmModalMessage, setConfirmModalMessage] = useState(null);
  const [confirmModalIcon, setConfirmModalIcon] = useState(null);
  const [confirmModalSuccessHandler, setConfirmModalSuccessHandler] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(null);

  const boolIsActive = isActive === "true";

  useEffect(() => {
    return () => {
      dispatch(commonActions.setUserFormDrawerVisibilityClose());
    };
  }, []);
  const sortColName = useMemo(() => {
    return sortColumn.sortColumnName;
  }, [sortColumn.sortColumnName]);
  const sortColOrder = useMemo(() => {
    return sortColumn.sortColumnOrder;
  }, [sortColumn.sortColumnOrder]);

  const handleArrow = useCallback(
    (colName) => {
      return sortColName === colName && sortColOrder === "asc" ? (
        <ArrowUpwardIcon sx={{ fontSize: "16px", color: "#525252" }} />
      ) : sortColName === colName && sortColOrder === "desc" ? (
        <ArrowDownwardIcon sx={{ fontSize: "16px", color: "#525252" }} />
      ) : null;
    },
    [sortColName, sortColOrder],
  );

  const handleHeaderClick = (colName) => {
    if (colName === sortColName) {
      if (sortColOrder === "asc") {
        dispatch(
          userSetSortColumn({
            sortColumnName: colName,
            sortColumnOrder: "desc",
          }),
        );
      } else {
        dispatch(
          userSetSortColumn({
            sortColumnName: colName,
            sortColumnOrder: "asc",
          }),
        );
      }
    } else {
      dispatch(
        userSetSortColumn({
          sortColumnName: colName,
          sortColumnOrder: "asc",
        }),
      );
    }
  };

  const handleSearch = (val) => {
    dispatch(userSetSearchText(val));
  };
  const handleNext = () => {
    dispatch(userNextPage());
  };
  const handlePrev = () => {
    dispatch(userPrevPage());
  };
  const handleKeyDown = (
    event,
    // , type
  ) => {
    if (event.key === "Enter") {
      //   if (type === "new") {
      //     newText !== "" && handleCreate();
      //   } else if (type === "edit") {
      //     editText !== "" && handleUpdate();
      //   }
    } else if (event.key === "Escape") {
      //   if (type === "new") {
      //     setNewText("");
      //     setNewSwitch(false);
      //     setNewDocSpecific(2);
      //     setNewCategory(null);
      //     dispatch(commonActions.toggleDocsManagementDocumentsFalse());
      //   } else if (type === "edit") {
      //     handleEditClose();
      //   }
    }
  };

  const handleRadioButtonChange = (e) => {
    dispatch(userSetIsActive(e.target.value));
  };

  const handleLockConfirmModalYes = async (id) => {
    try {
      await dispatch(lockUser(id)).unwrap();
      dispatch(
        getListOfUsers({
          isActive: isActive,
          page: currentPage,
          searchText: searchText,
          sortColumnName: sortColumn.sortColumnName,
          sortOrder: sortColumn.sortColumnOrder,
        }),
      ).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"User Locked"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
    } catch (error) {
      dispatch(commonActions.toggleConfirmModalVisibility());
      setMessageModalIcon("lock");
      setMessageModalHeader("Can't Lock User");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
      console.log(error);
    }
  };

  const handleUnlockConfirmModalYes = async (id) => {
    try {
      await dispatch(unlockUser(id)).unwrap();
      dispatch(
        getListOfUsers({
          isActive: isActive,
          page: currentPage,
          searchText: searchText,
          sortColumnName: sortColumn.sortColumnName,
          sortOrder: sortColumn.sortColumnOrder,
        }),
      ).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"User Unlocked"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
    } catch (error) {
      dispatch(commonActions.toggleConfirmModalVisibility());
      setMessageModalIcon("unlock");
      setMessageModalHeader("Can't Unlock User");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
      console.log(error);
    }
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    dispatch(commonActions.toggleDeleteModalVisibility());
  };

  const handleDeleteModalYes = async (loanIds = []) => {
    try {
      await dispatch(
        deleteUser({
          id: deleteId,
          loanIds,
        }),
      ).unwrap();
      dispatch(
        getListOfUsers({
          isActive: isActive,
          page: currentPage,
          searchText: searchText,
          sortColumnName: sortColumn.sortColumnName,
          sortOrder: sortColumn.sortColumnOrder,
        }),
      ).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">
            {loanIds.length === 0 ? (
              "User Deleted"
            ) : (
              <>
                User deleted
                <br />
                and their active loans archived
              </>
            )}
          </span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
    } catch (error) {
      // setMessageModalIcon("delete");
      // setMessageModalHeader("Can't Delete User");
      // setMessageModalMessage(error.message);
      // dispatch(commonActions.toggleDeleteModalMessageVisibility());
      setIsDeleteModalOpen(true);
    }
  };

  const handleActivateConfirmModalYes = async (id) => {
    try {
      await dispatch(activateUser(id)).unwrap();
      dispatch(
        getListOfUsers({
          isActive: isActive,
          page: currentPage,
          searchText: searchText,
          sortColumnName: sortColumn.sortColumnName,
          sortOrder: sortColumn.sortColumnOrder,
        }),
      ).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"User Activated"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
    } catch (error) {
      setMessageModalIcon("activate");
      setMessageModalHeader("Can't Activate User");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
      console.log(error);
    }
  };

  const handleResetPasswordConfirmModalYes = async (userId, email) => {
    try {
      await dispatch(resetPassword(userId)).unwrap();
      setMessageModalIcon("key");
      setMessageModalHeader("Change Password");
      setMessageModalMessage(`A password reset email has been sent to ${email}`);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
    } catch (error) {
      setMessageModalIcon("key");
      setMessageModalHeader("Can't send reset password email");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
      console.log(error);
    }
  };

  const handleLock = (id, type) => {
    if (type) {
      setConfirmModalHeader("Unlock Account");
      setConfirmModalMessage(
        "The user will be able to use this account if you unlock it, are you sure, want to do this?",
      );
      setConfirmModalIcon(<UnlockIcon width={24} height={24} />);
      setConfirmModalSuccessHandler(() => () => handleUnlockConfirmModalYes(id));
      dispatch(commonActions.toggleConfirmModalVisibility());
    } else {
      setConfirmModalHeader("Lock Account");
      setConfirmModalMessage(
        "The user won’t be able to use this account if you lock it, are you sure, want to do this?",
      );
      setConfirmModalIcon(<LockIcon width={24} height={24} />);
      setConfirmModalSuccessHandler(() => () => handleLockConfirmModalYes(id));
      dispatch(commonActions.toggleConfirmModalVisibility());
    }
  };

  const handleActivate = (id) => {
    setConfirmModalHeader("Activate Account");
    setConfirmModalMessage("Are you sure you want to activate this user's account?");
    setConfirmModalIcon(<FlipBackwardIcon width={24} height={24} />);
    setConfirmModalSuccessHandler(() => () => handleActivateConfirmModalYes(id));
    dispatch(commonActions.toggleConfirmModalVisibility());
  };

  const handleResetPassword = (userId, email) => {
    setConfirmModalHeader("Change Password");
    setConfirmModalMessage(`Send password reset email to ${email}`);
    setConfirmModalIcon(<KyeIcon width={24} height={24} />);
    setConfirmModalSuccessHandler(() => () => handleResetPasswordConfirmModalYes(userId, email));
    dispatch(commonActions.toggleConfirmModalVisibility());
  };

  return (
    <div className="tabsContainer">
      <div className="tabsContainer-utilities">
        <div className="tabsContainer-utilities__left">
          <Search handleSearch={handleSearch} placeholder={"Search by name, email"} />
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={isActive}
              onChange={handleRadioButtonChange}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value={true}
                control={
                  <Radio
                    size="small"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 16,
                      },
                      "&.Mui-checked": {
                        color: "#A459FF",
                      },
                    }}
                  />
                }
                label={<span className="radioLabel">{"Active Users"}</span>}
              />
              <FormControlLabel
                value={false}
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 16,
                      },
                      "&.Mui-checked": {
                        color: "#A459FF",
                      },
                    }}
                  />
                }
                label={<span className="radioLabel">{"Deleted Users"}</span>}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <Pagination
          currentPage={usersList.data?.pageIndex}
          totalPage={usersList.data?.totalPages}
          handleNext={handleNext}
          handlePrev={handlePrev}
          isLoading={false}
        />
      </div>
      {usersList.isLoading ? (
        <>
          <Skeleton height={44} />
          <Skeleton rows={5} height={60} />
        </>
      ) : (
        <div className="tabsContainer-table">
          <TableContainer component={Box}>
            <Table
              sx={{
                "&:last-child": {
                  border: "2px slid black",
                  borderRadius: "20px",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => handleHeaderClick(apiColNames[0])}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        padding: " 13px 24px",
                        gap: "4px",
                        alignItems: "center",
                      }}
                    >
                      <p className="headerCell-text">Name</p>
                      {handleArrow(apiColNames[0])}
                    </Box>
                  </TableCell>
                  {boolIsActive && (
                    <TableCell
                      style={{ width: "143px", cursor: "pointer" }}
                      onClick={() => handleHeaderClick(apiColNames[1])}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: " 13px 24px",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p className="headerCell-text">Current Loans</p>
                        {handleArrow(apiColNames[1])}
                      </Box>
                    </TableCell>
                  )}
                  <TableCell
                    style={{ width: "129px", cursor: "pointer" }}
                    onClick={() => handleHeaderClick(apiColNames[2])}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        padding: " 13px 24px",
                        alignItems: "center",
                      }}
                    >
                      <p className="headerCell-text">Total Loans</p>
                      {handleArrow(apiColNames[2])}
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{ width: "127px", cursor: "pointer" }}
                    onClick={() => handleHeaderClick(apiColNames[3])}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        padding: " 13px 24px",
                        alignItems: "center",
                      }}
                    >
                      <p className="headerCell-text">Last Login</p>
                      {handleArrow(apiColNames[3])}
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: `${boolIsActive ? "204px" : "116px"}` }}>
                    <Box
                      sx={{
                        display: "flex",
                        padding: " 13px 24px",
                        alignItems: "center",
                      }}
                    >
                      <p className="headerCell-text">Actions</p>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersList.data?.data?.map(
                  (
                    row,
                    // , index
                  ) => {
                    //   const edit = row.id === editId;
                    //   const lastRow = index === usersList.data.data.length - 1;
                    //   return null;

                    return (
                      <TableRow
                        key={row.id}
                        //   style={edit ? { backgroundColor: " #F5F5F5" } : null}
                        onKeyDown={(e) => handleKeyDown(e, "edit")}
                        onClick={() => {
                          dispatch(setCurrentUser(row.id));
                          dispatch(setCurrentUserId(row.userId));
                          dispatch(commonActions.toggleUserFormDrawerVisibility());
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell
                        // className={`${
                        //   lastRow && edit && updateDocument.error
                        //     ? "roundBorderLeft"
                        //     : null
                        // } ${
                        //   edit && !lastRow && updateDocument.error
                        //     ? "redRowLeft"
                        //     : null
                        // } `}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              padding: " 16px 24px",
                              gap: "12px",
                              alignItems: "center",
                            }}
                          >
                            {/* {edit ? (
                          <input
                            className="categoryContainer-input"
                            onChange={(e) => {
                              if (updateDocument.error) {
                                dispatch(setUpdateDocumentError(null));
                              }
                              setEditText(e.target.value);
                            }}
                            type="text"
                            name="category"
                            placeholder="Enter category name"
                            value={editText}
                          />
                        ) : (
                          <p className="documents--documentTitle">
                            {row.name}
                          </p>
                        )} */}
                            <Avatar
                              src={row.avatarUrl}
                              alt={row.fullName}
                              className="avatar-text background-color-gray-100 cursor-pointer fs-14"
                              sx={{
                                width: "32px",
                                height: "32px",
                                marginRight: "-2px",
                              }}
                            >
                              {row.fullName.split(" ")[0].trim()[0]}
                              {row.fullName.split(" ")[row.fullName.split(" ").length - 1][0]}
                            </Avatar>
                            <div className="fullNameContainer">
                              {/* <Link
                            to={AppRoute.BORROWER + "/" + row.id}
                            className="linkWithoutUnderline"
                          > */}
                              <p className="fullNameContainer-fullName">{`${row.fullName}`}</p>

                              <p className="fullNameContainer-email">{row.email}</p>
                            </div>
                          </Box>
                        </TableCell>
                        {boolIsActive && (
                          <TableCell
                          // className={`${
                          //   lastRow && edit && updateDocument.error
                          //     ? "roundBorderMiddle"
                          //     : null
                          // } ${
                          //   edit && !lastRow && updateDocument.error
                          //     ? "redRowMiddle"
                          //     : null
                          // } `}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                padding: " 16px 24px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <p className="activeLoans-text">{row.currentLoans}</p>
                            </Box>
                          </TableCell>
                        )}

                        <TableCell
                        // className={`${
                        //   lastRow && edit && updateDocument.error
                        //     ? "roundBorderMiddle"
                        //     : null
                        // } ${
                        //   edit && !lastRow && updateDocument.error
                        //     ? "redRowMiddle"
                        //     : null
                        // } `}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              padding: " 16px 24px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {row.totalLoans}
                          </Box>
                        </TableCell>
                        <TableCell
                        // className={`${
                        //   lastRow && edit && updateDocument.error
                        //     ? "roundBorderMiddle"
                        //     : null
                        // } ${
                        //   edit && !lastRow && updateDocument.error
                        //     ? "redRowMiddle"
                        //     : null
                        // } `}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              padding: " 16px 24px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {row.lastActiveDate && (
                              <div className="dateUpdatedContainer">
                                <p className="dateUpdatedContainer-date">
                                  {getFormattedDate(row.lastActiveDate)[0]}
                                </p>
                                <p className="dateUpdatedContainer-time">
                                  {getFormattedDate(row.lastActiveDate)[1]}
                                </p>
                              </div>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell
                          // className={`${
                          //   lastRow && edit && updateDocument.error
                          //     ? "roundBorderRight"
                          //     : null
                          // } ${
                          //   edit && !lastRow && updateDocument.error
                          //     ? "redRowRight"
                          //     : null
                          // } `}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Box
                            sx={{
                              boxSizing: "border-box",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              padding: "4px",
                              gap: "4px",
                              justifyContent: "center",
                            }}
                          >
                            {/* {isActive === "true" ? (
                          row.id === editId ? (
                            <>
                              <IconButton onClick={handleEditClose}>
                                <CloseIcon
                                  sx={{ fontSize: 20, color: "#525252" }}
                                />
                              </IconButton>
                              <IconButton onClick={handleUpdate}>
                                <CheckIcon
                                  sx={{ fontSize: 20, color: "#A459FF" }}
                                />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                onClick={() => handleArchiveClick(row.id)}
                              >
                                <Archieve />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  handleShowEdit(
                                    row.id,
                                    row.name,
                                    row.isLenderOnly,
                                    row.documentSpecific,
                                    row.category
                                  )
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            </>
                          )
                        ) : (
                          <IconButton
                            onClick={() => handleArchiveClick(row.id)}
                          >
                            <UnArchiveIcon width={20} height={20} />
                          </IconButton>
                        )} */}
                            {boolIsActive ? (
                              <>
                                <CustomizedTooltip title={"Delete User's Account"}>
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDelete(row.id);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </CustomizedTooltip>

                                <CustomizedTooltip
                                  title={row.isLocked ? "Unlock Account" : "Lock Account"}
                                >
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleLock(row.id, row.isLocked);
                                    }}
                                  >
                                    {row.isLocked ? <UnlockIcon /> : <LockIcon />}
                                  </IconButton>
                                </CustomizedTooltip>
                                <CustomizedTooltip title="Change Password">
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleResetPassword(row.userId, row.email);
                                    }}
                                  >
                                    <KyeIcon
                                      width={20}
                                      height={20}
                                      style={{ path: { stroke: "#525252" } }}
                                    />
                                  </IconButton>
                                </CustomizedTooltip>

                                <CustomizedTooltip title="Open User's Loan List">
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation(), navigate(`/borrower/${row.id}`);
                                    }}
                                  >
                                    <ShareIcon />
                                  </IconButton>
                                </CustomizedTooltip>
                              </>
                            ) : (
                              <>
                                <CustomizedTooltip title="Activate Account">
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleActivate(row.id);
                                    }}
                                  >
                                    <FlipBackwardIcon />
                                  </IconButton>
                                </CustomizedTooltip>

                                <IconButton>
                                  <ShareIcon />
                                </IconButton>
                              </>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  },
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      <ConfirmModal
        icon={confirmModalIcon}
        header={confirmModalHeader}
        message={confirmModalMessage}
        succesHandler={confirmModalSuccessHandler}
      />
      <DeleteModal
        successHandler={handleDeleteModalYes}
        header={"Delete User"}
        message={"Are you sure you want to delete the User?"}
      />
      <DeleteMessageModal
        message={messageModalMessage}
        icon={messageModalIcon}
        header={messageModalHeader}
      />
      {deleteId && isDeleteModalOpen && (
        <ArchieveLinkedLoansModal
          isOpen={isDeleteModalOpen}
          handleClose={() => {
            setIsDeleteModalOpen(false);
            setDeleteId(null);
          }}
          id={deleteId}
          handleDelete={handleDeleteModalYes}
        />
      )}
    </div>
  );
};

export default Users;
