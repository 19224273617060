import {
  fetchGetTokenized,
  fetchPostTokenized,
  fetchPutTokenized,
  fetchDeleteTokenized,
  fetchPut,
  fetchGet,
} from "api/common/callerHelpers";

export const getListOfUsersApi = async (data) => {
  return await fetchGetTokenized("/api/users/end-users", data);
};

export const deleteUserApi = async (data) => {
  return await fetchDeleteTokenized("/api/users/delete-end-user", data);
};

export const getListOfLendersApi = async (data) => {
  return await fetchGetTokenized("/api/users/lender-users", data);
};

export const getLenderUserApi = async (id) => {
  return await fetchGetTokenized(`/api/users/lender-profile/${id}`);
};

export const updateLenderUserApi = async (data) => {
  return await fetchPutTokenized("/api/users/lender-profile", data, true);
};

export const changePasswordApi = async (data) => {
  return await fetchPutTokenized("/api/users/change-password", data);
};

export const deleteLenderApi = async (data) => {
  return await fetchDeleteTokenized("/api/users/delete-lender", data);
};

export const lockUserApi = async (data) => {
  return await fetchPutTokenized(`/api/users/lock-end-user?id=${data}`);
};

export const unlockUserApi = async (data) => {
  return await fetchPutTokenized(`/api/users/unlock-end-user?id=${data}`);
};

export const lockLenderApi = async (data) => {
  return await fetchPutTokenized(`/api/users/lock-lender?id=${data}`);
};

export const unlockLenderApi = async (data) => {
  return await fetchPutTokenized(`/api/users/unlock-lender?id=${data}`);
};

export const activateUserApi = async (data) => {
  return await fetchPutTokenized(`/api/users/activate-end-user?id=${data}`);
};

export const activateLenderApi = async (data) => {
  return await fetchPutTokenized(`/api/users/activate-lender?id=${data}`);
};

export const resetPasswordApi = async (data) => {
  return await fetchPostTokenized(`/api/users/reset-password?userId=${data}`);
};

export const resetPasswordPutApi = async (data) => {
  return await fetchPut(`/api/users/reset-password`, data);
};

export const createEndUserApi = async (data) => {
  return await fetchPostTokenized(`/api/users/create-end-user`, data, true);
};

export const getEndUserApi = async (id) => {
  return await fetchGetTokenized(`/api/users/end-user/${id}`);
};

export const getEndUserProileApi = async (id) => {
  return await fetchGetTokenized(`/api/users/end-user-profile/${id}`);
};

export const updateEndUserApi = async (data) => {
  return await fetchPutTokenized("/api/users/update-end-user", data, true);
};

export const updateEndUserProfileApi = async (data) => {
  return await fetchPutTokenized("/api/users/end-user-profile", data, true);
};

export const getListOfLoansUserApi = async (id, data) => {
  return await fetchGetTokenized(`/api/users/access-control/${id}`, data);
};

export const getListOfRolesApi = async () => {
  return await fetchGet("/api/access-control-roles");
};

export const getLoanAtuocompleteApi = async (data) => {
  return await fetchGetTokenized("/api/users/loan-autocomplete", data);
};

export const postAddNewLoanApi = async (data) => {
  return await fetchPostTokenized("/api/users/access-control", data);
};

export const deleteLoanApi = async (data) => {
  return await fetchDeleteTokenized("/api/users/access-control", data);
};

export const putUpdateRoleApi = async (data) => {
  return await fetchPutTokenized("/api/users/access-control", data);
};

export const getLenderApi = async (id) => {
  return await fetchGetTokenized("/api/users/lender-user/" + id);
};

export const createLenderApi = async (data) => {
  return await fetchPostTokenized(`/api/users/create-lender`, data, true);
};

export const updateLenderApi = async (data) => {
  return await fetchPutTokenized("/api/users/update-lender", data, true);
};

export const addAccessControlApi = async (data) => {
  return await fetchPostTokenized(`/api/users/add-access-control`, data);
};

export const getLenderRolesApi = async () => {
  return await fetchGet("/api/role-types");
};

export const bulkChangeRoleApi = async (data) => {
  return await fetchPostTokenized("/api/users/access-control-bulk-change", data);
};

export const bulkDeleteAccessApi = async (data) => {
  return await fetchPostTokenized("/api/users/access-control-bulk-remove-access", data);
};

export const getCitizenshipStatusesApi = async (data) => {
  return await fetchGet("/api/citizenships");
};

export const getLocationStatusesApi = async (data) => {
  return await fetchGet("/api/location-statuses");
};

export const getOwnershipStatusesApi = async (data) => {
  return await fetchGet("/api/property-ownership-statuses");
};
export const bulkAddAccessApi = async (data) => {
  return await fetchPostTokenized("/api/users/access-control-bulk-add-access", data);
};

export const getActiveLoansApi = async (id) => {
  return await fetchGetTokenized(`/api/users/active-loans?id=${id}`);
};
