import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderQuestion, setMonthlyRent, setShowNext, setSendData } from "../onboardingSlice";
import Slider from "pages/OnBoarding/Components/Slider/Slider";

const Step9 = () => {
  const [isValid, setIsValid] = useState(true);

  const dispatch = useDispatch();
  let { MonthlyRent } = useSelector((state) => state.onboarding.body.form);
  const { postOnboarding } = useSelector((state) => state.onboarding.body);

  MonthlyRent = MonthlyRent === null ? null : Number(MonthlyRent?.toString().replaceAll(",", ""));

  const question = "What is the current monthly rental income of the property?";
  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);
  useEffect(() => {
    if (MonthlyRent !== null) {
      if (!/^\d+$/.test(MonthlyRent) || Number(MonthlyRent) < 1000 || Number(MonthlyRent) > 50000) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [MonthlyRent]);

  useEffect(() => {
    if (MonthlyRent !== null && isValid) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ MonthlyRent }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [MonthlyRent, dispatch, postOnboarding, isValid]);

  const updateForm = useCallback(
    (val) => {
      dispatch(setMonthlyRent(val));
    },
    [dispatch],
  );

  return (
    <Slider
      dollar={true}
      min={1000}
      max={50000}
      interval={MonthlyRent && MonthlyRent < 5000 ? 250 : 500}
      sliderVal={MonthlyRent}
      updateForm={updateForm}
      isValid={isValid}
      limit={6}
    />
  );
};

export default Step9;
