import { commonActions } from "../../commonSlice";
import { Modal } from "../Modal";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as SaveIcon } from "../../../../assets/svg/icons/save-changes-icon.svg";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Input from "../../../../pages/UserManagement/Components/Input";
import { validationSchemaChangePassword } from "../../../../pages/UserManagement/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Button from "../../../../pages/Borrower/Components/Button/Button";
import { changePassword } from "../../../../pages/UserManagement/userSlice";
import PasswordValidations from "pages/OnBoarding/Components/PasswordValidations";

export function PasswordChangeModal() {
  const dispatch = useDispatch();

  const { changePasswordModal, profileSettings } = useSelector((state) => state.common);
  const [isLoading, setIsLoading] = useState(false);
  const userId = JSON.parse(localStorage.getItem("user"))?.userId;
  const [password, setPassword] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [passwordConfirmFocus, setPasswordConfirmFocus] = useState(false);
  const [validations, setValidations] = useState({
    hasValidLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  const { handleSubmit, formState, getValues, setValue } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    criteriaMode: "all",
    resolver: yupResolver(validationSchemaChangePassword),
  });
  const { errors, isValid, isDirty } = formState;

  const onSubmit = async (data) => {
    setIsLoading(true);
    const newPassword = {
      ...data,
      userId,
    };

    try {
      await dispatch(changePassword(newPassword)).unwrap();
    } catch (e) {
      console.log(e);
    }

    dispatch(commonActions.toggleChangePasswordModalVisibility());
    profileSettings.isVisible && dispatch(commonActions.toggleProfileSettingsVisibility());
    dispatch(commonActions.toggleChangePasswordModalConfirmVisibility());
    setIsLoading(false);
  };

  useEffect(() => {
    if (password !== null && password.length >= 0) {
      const lengthRegex = /^.{8,}$/; // Minimum 8 characters
      const uppercaseRegex = /[A-Z]/; // At least 1 uppercase letter
      const lowercaseRegex = /[a-z]/; // At least 1 lowercase letter
      const numberRegex = /[0-9]/; // At least 1 number
      const specialCharRegex = /[!@#$%^&*]/; // At least 1 special character

      const hasValidLength = lengthRegex.test(password);
      const hasUppercase = uppercaseRegex.test(password);
      const hasLowercase = lowercaseRegex.test(password);
      const hasNumber = numberRegex.test(password);
      const hasSpecialChar = specialCharRegex.test(password);

      setValidations({
        hasValidLength,
        hasUppercase,
        hasLowercase,
        hasNumber,
        hasSpecialChar,
      });

      setValue("password", password, { shouldValidate: true, shouldDirty: true });
    }
  }, [password]);

  useEffect(() => {
    if (passwordConfirm) {
      setValue("confirmPassword", passwordConfirm, { shouldValidate: true, shouldDirty: true });
    }
  }, [passwordConfirm]);

  useEffect(() => {
    setIsLoading(true);
    setValue("password", "");
    setValue("confirmPassword", "");
    setIsLoading(false);
  }, []);

  return (
    <Modal
      icon={<SaveIcon />}
      isOpen={changePasswordModal.isVisible}
      handleClose={() => dispatch(commonActions.toggleChangePasswordModalVisibility())}
    >
      <form className={"nv-changePasswordForm"} onSubmit={handleSubmit(onSubmit)}>
        <div className={"mb-20"}>
          <h3 className={"display-sm-bold text-align-center"}>Change Password</h3>
        </div>
        <div className="px-10">
          <Grid className="gap-20" container>
            <Grid item xs={12}>
              <PasswordValidations validations={validations} passwordIsFocus={passwordFocus} />
              <Input
                name="password"
                label="New Password"
                type={"passwordWithValidation"}
                setPasswordFocus={setPasswordFocus}
                setPassword={setPassword}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="confirmPassword"
                label="Confirm New Password"
                type={"passwordWithValidation"}
                required={true}
                setPasswordFocus={setPasswordConfirmFocus}
                setPassword={setPasswordConfirm}
                errorMessage={errors?.confirmPassword?.message}
              />
            </Grid>
          </Grid>
        </div>
      </form>
      <div className={"pt-20 d-flex gap-12 justify-content-center "}>
        <Grid container spacing={2} rowSpacing={4}>
          <Grid item xs={6}>
            <Button
              width="100%"
              type="contained"
              backgroundColor="white"
              colorTextEnabled="#344054"
              borderColor="#d0d5dd"
              label={"Cancel"}
              disabled={!changePasswordModal.isVisible}
              onClick={() => dispatch(commonActions.toggleChangePasswordModalVisibility())}
            />
          </Grid>

          <Grid item xs={6}>
            <Button
              width="100%"
              type="contained"
              label={"Save"}
              disabled={isLoading || !isDirty || !isValid}
              onClick={handleSubmit(onSubmit)}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
