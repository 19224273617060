import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  stepNext,
  stepBack,
  setFooterCurrent,
  setFooterTotal,
  postOnboardingNext,
  postOnboardingComplete as postOnboardingCompleteCall,
  setStep,
  resetPostOnboarding,
  setFormToInitialState,
} from "../onboardingSlice";
import {
  register as registerFunc,
  loginCall,
  resendVerificationCode,
  confirmEmail,
  forgotPassword as forgotPasswordFunc,
  confirmEmailForgotPassword as confirmEmailForgotPasswordFunc,
  resetPassword as resetPasswordFunc,
} from "pages/Login/loginSlice";
import { setTokens } from "../../../utils/shared/helpers";
import { UserRoles } from "utils/constants/ConstantsUtil";
import { resetStore } from "../../../configs/store";
import Login from "./Login";
import LoginWithEmail from "./LoginWithEmail";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Step9 from "./Step9";
import Step10 from "./Step10";
import Step11 from "./Step11";
import Step12 from "./Step12";
import Step13 from "./Step13";
import Step14 from "./Step14";
import Step15 from "./Step15";
import Step16 from "./Step16";
import Step17 from "./Step17";
import Step18 from "./Step18";
import Step19 from "./Step19";
import Step21 from "./Step21";
import Step20 from "./Step20";
import Step22 from "./Step22";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordEmail from "./ForgotPasswordEmail";
import UpdatePasswords from "./UpdatePasswords";
import { ReactComponent as ChevronLeftIcon } from "assets/svg/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "assets/svg/icons/chevron-right.svg";
import ButtonHolder from "../Components/ButtonHolder";
import "./styles.scss";
import { getUserGrantedRoles } from "../../Login/loginSlice";
import useCheckMobileScreen from "utils/hooks/useCheckMobileScreen";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import DeleteMessageModal from "pages/DocumentManagement/Tabs/Categories/DeleteMessageModal";

const useMessageModalState = () => {
  const [message, setMessage] = useState(null);
  const [header, setHeader] = useState(null);
  const [buttonText, setButtonText] = useState(null);
  const [isEmailExist, setIsEmailExist] = useState(false);

  return {
    message,
    header,
    buttonText,
    setMessage,
    setHeader,
    setButtonText,
    reset: () => {
      setMessage(null);
      setHeader(null);
      setButtonText(null);
    },
  };
};

const Stepper = () => {
  const messageModal = useMessageModalState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isOnMobile = useCheckMobileScreen();
  const isOnboardingPage = useLocation().pathname === "/onboarding";
  const { showProgressBar } = useSelector((state) => state.onboarding.footer);

  const { TransactionType, FirstName } = useSelector((state) => state.onboarding.body.form);
  const { currentStep, newLoan, postOnboarding } = useSelector((state) => state.onboarding.body);
  const { deleteModalMessage } = useSelector((state) => state.common);

  useEffect(() => {
    if (postOnboarding.data?.userIsExist) {
      if (currentStep === 27 || currentStep === 14) {
        messageModal.setButtonText("Done");
        messageModal.setHeader("Application Submitted");
        messageModal.setMessage(
          `Welcome back, <span>${FirstName}</span>! Your application has been successfully submitted, you can now login to your Battalion dashboard to check your application status.`,
        );

        dispatch(commonActions.toggleDeleteModalMessageVisibility());
      } else {
        navigate("/login");
      }
      dispatch(resetPostOnboarding());
    }
  }, [dispatch, postOnboarding, navigate, currentStep, messageModal, FirstName]);

  useEffect(() => {
    switch (currentStep) {
      case 2:
        if (TransactionType === 2) {
          if (newLoan?.anonymusId) {
            dispatch(setFooterTotal(14));
          } else {
            dispatch(setFooterTotal(17));
          }
        } else {
          if (newLoan?.anonymusId) {
            dispatch(setFooterTotal(12));
          } else {
            dispatch(setFooterTotal(14));
          }
        }
        dispatch(setFooterCurrent(currentStep));

        break;
      case 18:
      case 24:
      case 12:
        break;
      case 13:
      case 14:
      case 15:
        dispatch(setFooterCurrent(currentStep));
        break;
      case 16:
      case 17:
        dispatch(setFooterCurrent(currentStep - 9));
        break;
      case 19:
      case 20:
      case 21:
      case 22:
      case 23:
        dispatch(setFooterCurrent(currentStep - 10));
        break;
      case 25:
      case 26:
      case 27:
        dispatch(setFooterCurrent(currentStep - 11));
        break;

      default:
        dispatch(setFooterCurrent(currentStep + 1));
        break;
    }
  }, [dispatch, currentStep, TransactionType]);

  return (
    <>
      <div
        className={`mainPart ${
          currentStep === 0 ? "larger" : currentStep === 48 ? "noMarginBottom" : ""
        }`}
      >
        {(() => {
          switch (currentStep) {
            case 0:
              return <Step1 />;
            case 1:
              return <Step2 />;
            case 2:
              return <Step3 />;
            case 3:
              return <Step4 />;
            case 4:
              return <Step5 />;
            case 5:
              return <Step6 />;
            case 6:
              return <Step7 />;
            case 7:
              return <Step8 />;
            case 8:
              return <Step9 />;
            case 9:
              return <Step10 />;
            case 10:
              return <Step11 />;
            case 11:
              return <Step12 />;
            case 12:
              return <Step13 />;
            case 13:
              return <Step14 />;
            case 14:
              return <Step15 />;
            case 15:
              return <Step16 />;
            case 16:
              return <Step17 />;
            case 17:
              return <Step18 />;
            case 18:
              return <Step19 />;
            case 19:
              return <Step20 />;
            case 20:
              return <Step8 />;
            case 21:
              return <Step9 />;
            case 22:
              return <Step10 />;
            case 23:
              return <Step11 />;
            case 24:
              return <Step12 />;
            case 25:
              return <Step13 />;
            case 26:
              return <Step14 />;
            case 27:
              return <Step15 />;
            case 28:
              return <Step16 />;
            case 29:
              return <Step21 />;
            case 30:
              return <Step22 />;

            case 47:
              return <Step16 isAuthFlow />;
            case 48:
              return <Step21 isAuthFlow />;
            case 49:
              return <Login isAuthFlow />;
            case 50:
              return <LoginWithEmail isAuthFlow />;
            case 51:
              return <ForgotPasswordEmail />;
            case 52:
              return <ForgotPassword />;
            case 53:
              return <UpdatePasswords />;
            default:
              return null;
          }
        })()}
      </div>
      {!isOnMobile && <ButtonHolder />}

      {messageModal.message && (
        <DeleteMessageModal
          message={messageModal.message}
          icon={"inviteUser"}
          header={messageModal.header}
          removeMessage={() => {
            messageModal.reset();
            navigate("/login");
          }}
          buttonText={messageModal.buttonText}
          isHtml
        />
      )}
    </>
  );
};

export default Stepper;
