import React, { useEffect } from "react";
import { commonActions } from "../../../../wrappers/NewVersion/commonSlice";
import { ReactComponent as BancIcon } from "../../../../assets/svg/icons/bank-icon.svg";
import { ReactComponent as UsersIcon } from "../../../../assets/svg/icons/users-icon.svg";
import { ReactComponent as BuildingIcon } from "../../../../assets/svg/icons/building-06.svg";
import { ReactComponent as ColumnsIcon } from "../../../../assets/svg/icons/columns-icon.svg";
import { ReactComponent as StarsIcon } from "../../../../assets/svg/icons/stars-icon.svg";
import { ReactComponent as PieIcon } from "../../../../assets/svg/icons/percent-02.svg";
import { ReactComponent as BankNote } from "../../../../assets/svg/icons/bank-note.svg";
import { ReactComponent as TagIcon } from "../../../../assets/svg/icons/tag-01.svg";
import { ReactComponent as CalendarIcon } from "../../../../assets/svg/icons/calendar-check.svg";
import { ReactComponent as ReceiptIcon } from "../../../../assets/svg/icons/receipt-icon.svg";
import { ReactComponent as CalendarSmall } from "../../../../assets/svg/icons/calendar-small.svg";
import { ReactComponent as CoinsHandIcon } from "../../../../assets/svg/icons/coins-hand-borrower.svg";
import { ReactComponent as CalculatorIcon } from "../../../../assets/svg/icons/calculator.svg";
import { Button } from "../../../../pages/Borrower/Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "wrappers/NewVersion/components/Drawer";
import { useParams } from "react-router-dom";
import { getTestLoanDetailApi } from "../../loanDetailSlice";
import { formatCurrency } from "../../../../utils/shared/helpers";
import LoansTransactionDetailEditDrawer from "../LoansTransactionDetailEditDrawer";

import "./styles.scss";
import { useLoanContext } from "pages/Borrower/LoanDetail";

const LoansTransactionDetailDrawer = () => {
  const { canEditDetails } = useLoanContext();
  const dispatch = useDispatch();
  const { loansTransactionDetails } = useSelector((state) => state.common);
  const { loanId } = useParams();
  const {
    testLoanDetailData: { data: loanDetail },
  } = useSelector(({ loanDetail }) => loanDetail);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getTestLoanDetailApi(loanId)).unwrap();
    };
    const timeout = setTimeout(fetchData, 10);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, loanId]);

  const handleClose = async () => {
    dispatch(commonActions.toggleLoanTransactionDetailsVisibility());
  };

  const handleEditDetails = async () => {
    dispatch(commonActions.toggleLoanTransactionEditDetailsVisibility());
  };

  const {
    loanAmount,
    transactionTypeDisplayName,
    propertyTypeDisplayName,
    periodTypeDisplayName,
    residentialUnits,
    conditionDisplayName,
    occupancyDisplayName,
    estimatedValue,
    termPeriod,
    grossRent,
    loanToValueRatio,
    dscr,
    ownedPeriod,
    mortgageBalance,
  } = loanDetail || {};

  const detailData = [
    {
      icon: BancIcon,
      title: "Loan Purpose",
      value: transactionTypeDisplayName,
    },
    {
      icon: BuildingIcon,
      title: "Property Type",
      value: propertyTypeDisplayName,
    },
    {
      icon: ColumnsIcon,
      title: "Residential Units",
      value: residentialUnits + " Units",
    },
    {
      icon: StarsIcon,
      title: "Property Condition",
      value: conditionDisplayName,
    },
    {
      icon: UsersIcon,
      title: "Occupation at Closing",
      value: occupancyDisplayName,
    },
    {
      icon: CalendarIcon,
      title: "Property Ownership Duration",
      value: ownedPeriod + " " + periodTypeDisplayName,
    },
    {
      icon: ReceiptIcon,
      title: "Current Outstanding Balance",
      value: mortgageBalance && formatCurrency(mortgageBalance),
    },
    {
      icon: CalendarSmall,
      title: "Term",
      value: termPeriod,
    },
    {
      icon: TagIcon,
      title: "Estimated Value",
      value: estimatedValue && formatCurrency(estimatedValue),
    },
    {
      icon: BankNote,
      title: "Loan Amount",
      value: loanAmount && formatCurrency(loanAmount),
    },
    {
      icon: PieIcon,
      title: "LTV",
      value: loanToValueRatio + "%",
    },
    {
      icon: CalculatorIcon,
      title: "DCSR",
      value: dscr,
    },
    {
      icon: CoinsHandIcon,
      title: "Gross Rent",
      value: grossRent && formatCurrency(grossRent),
    },
  ];

  return (
    <>
      <Drawer
        size={"md"}
        isOpen={loansTransactionDetails.isVisible}
        header={
          <div className="d-flex flex-column flex-1">
            <h4 className={"text-xl-bold"}>Property Details</h4>
          </div>
        }
        footer={
          canEditDetails && (
            <div className="d-flex justify-content-end">
              <Button
                type="contained"
                backgroundColor="white"
                colorTextEnabled="#344054"
                borderColor="#d0d5dd"
                onClick={handleEditDetails}
                disabled={!loansTransactionDetails.isVisible}
                label={"Edit Details"}
              />
            </div>
          )
        }
        handleClose={handleClose}
      >
        <div className="mt-32 loanWrapper">
          <div>
            {detailData?.map(({ icon, title, value }) => {
              const Icon = icon ?? null;
              return (
                value && (
                  <div key={title} className="d-flex space-between align-items-center mb-16 px-24">
                    <div className="d-flex align-items-center gap-24">
                      {Icon && (
                        <div className="p-8 background-color-gray-100 border-radius-4">
                          <Icon />
                        </div>
                      )}
                      <h3
                        className={`font-inter ${!icon && "ml-14 p-8"}`}
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        {title}
                      </h3>
                    </div>
                    <p className="font-inter">{value}</p>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </Drawer>
      <LoansTransactionDetailEditDrawer />
    </>
  );
};

export default LoansTransactionDetailDrawer;
