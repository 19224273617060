import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderQuestion,
  setShowNext,
  setPurchasePrice,
  setSendData,
  setStep,
} from "../onboardingSlice";
import Slider from "pages/OnBoarding/Components/Slider/Slider";

const Step7 = () => {
  const [isValid, setIsValid] = useState(true);

  const dispatch = useDispatch();
  let { PurchasePrice, TransactionType } = useSelector((state) => state.onboarding.body.form);
  const { postOnboarding } = useSelector((state) => state.onboarding.body);
  const question = "What is the purchase price of the property?";

  useEffect(() => {
    if (TransactionType === 2) {
      dispatch(setStep(16));
    }
  }, [TransactionType, dispatch]);

  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);

  PurchasePrice =
    PurchasePrice === null ? null : Number(PurchasePrice?.toString().replaceAll(",", ""));
  useEffect(() => {
    if (PurchasePrice !== null) {
      if (
        !/^\d+$/.test(PurchasePrice) ||
        Number(PurchasePrice) < 300000 ||
        Number(PurchasePrice) > 5000000
      ) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [PurchasePrice]);

  useEffect(() => {
    if (PurchasePrice !== null && isValid) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ PurchasePrice }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [PurchasePrice, dispatch, postOnboarding, isValid]);

  const updateForm = useCallback(
    (val) => {
      dispatch(setPurchasePrice(Number(val?.toString().replaceAll(",", ""))));
    },
    [dispatch],
  );

  return (
    <Slider
      dollar={true}
      min={300000}
      max={5000000}
      interval={PurchasePrice && PurchasePrice < 1000000 ? 10000 : 25000}
      sliderVal={PurchasePrice}
      updateForm={updateForm}
      isValid={isValid}
    />
  );
};

export default Step7;
