import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderQuestion,
  setShowNext,
  setEstimatedCreditScore,
  setSendData,
} from "../onboardingSlice";
import Slider from "pages/OnBoarding/Components/Slider/Slider";

const Step11 = () => {
  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();
  let { EstimatedCreditScore } = useSelector((state) => state.onboarding.body.form);
  const { postOnboarding } = useSelector((state) => state.onboarding.body);
  const question = "What is your estimated credit score ?";
  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);

  EstimatedCreditScore =
    EstimatedCreditScore === null
      ? null
      : Number(EstimatedCreditScore?.toString().replaceAll(",", ""));
  useEffect(() => {
    if (EstimatedCreditScore !== null) {
      if (
        !/^\d+$/.test(EstimatedCreditScore) ||
        Number(EstimatedCreditScore) < 600 ||
        Number(EstimatedCreditScore) > 850
      ) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [EstimatedCreditScore]);

  useEffect(() => {
    if (EstimatedCreditScore !== null && isValid) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ EstimatedCreditScore }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [EstimatedCreditScore, dispatch, postOnboarding, isValid]);

  const updateForm = useCallback(
    (val) => {
      dispatch(setEstimatedCreditScore(val));
    },
    [dispatch],
  );

  return (
    <Slider
      min={600}
      max={850}
      interval={5}
      sliderVal={EstimatedCreditScore}
      updateForm={updateForm}
      isValid={isValid}
      limit={4}
    />
  );
};

export default Step11;
