import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { ReactComponent as Logo } from "assets/svg/LogoWhite.svg";
import { ReactComponent as ChevronRightIcon } from "assets/svg/icons/chevron-right.svg";
import { ReactComponent as AlerIcon } from "assets/svg/icons/alert-circle.svg";

import "./styles.scss";
import { resetPasswordPut } from "../UserManagement/userSlice";
const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const password = watch("password", "");
  const onSubmit = async (data) => {
    try {
      await dispatch(
        resetPasswordPut({
          password: data.password,
          confirmPassword: data.confirmPassword,
          email: searchParams.get("email"),
          token: searchParams.get("token"),
        }),
      ).unwrap();
      navigate("/login");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="onboardingContainer">
      <section className="onboardingHeader">
        <div className="logBar">
          <Logo />
        </div>
        <div className="textSupport">
          <p>Reset Password</p>
        </div>
      </section>
      <div className="onboardingMainContent">
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ alignSelf: "stretch", display: "flex", flexDirection: "column", flexGrow: "1" }}
        >
          <div className="mainPart">
            <label htmlFor="password" className="inputLabel">
              Password
            </label>{" "}
            <br />
            <input
              {...register("password", { required: true, minLength: 8 })}
              name="password"
              type="password"
              className="inputSpan password  "
              placeholder="Password"
              inputMode="text"
              autoCapitalize="sentences"
              tabndex="1"
            />
            {errors.password ? (
              <div className="validationError">
                <AlerIcon />
                <span>{errors.password.message}</span>
              </div>
            ) : (
              <div className="validationErrorHold" />
            )}
            <br />
            <label htmlFor="confirmPassword" className="inputLabel">
              Confirm Password
            </label>{" "}
            <br />
            <input
              {...register("confirmPassword", {
                required: true,
                validate: (value) => value === password || "Passwords do not match",
              })}
              name="confirmPassword"
              type="password"
              className="inputSpan password  "
              placeholder="Confirm Password"
              inputMode="text"
              autoCapitalize="sentences"
            />
            {errors.confirmPassword && (
              <div className="validationError">
                <AlerIcon />
                <span>{errors.confirmPassword.message}</span>
              </div>
            )}
          </div>
          <div className="buttonHolder">
            <div className="backButtonWrapper"></div>
            <div className="nextButtonWrapper">
              <button className="nextButton">
                <span>Confirm</span>
                <ChevronRightIcon />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
