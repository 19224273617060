import { useRef, useState } from "react";
import { ReactComponent as ImageUploadIcon } from "assets/svg/icons/image-upload-icon.svg";

export function ImageUploadBox1({ multiple = false, className, setFiles, setPreviewUrl }) {
  const inputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const data = new FormData();
      const filesArr = [...e.dataTransfer.files];

      const imageurl = URL.createObjectURL(filesArr[0]);
      setPreviewUrl(imageurl);

      filesArr.forEach((file) => {
        data.append("avatar", file);
      });

      setFiles(data);
    }
    setDragActive(false);
  };

  const onButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    inputRef.current.click();
  };

  const handleInputChange = ({ target: { files } }) => {
    const data = new FormData();
    const filesArr = [...files];

    const imageurl = URL.createObjectURL(filesArr[0]);
    setPreviewUrl(imageurl);

    filesArr.forEach((file) => {
      data.append("avatar", file);
    });

    setFiles(data);
  };

  return (
    <div
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      onDrop={handleDrop}
      className={`position-relative nv-imageUploadBox ${className}`}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload-111"
        multiple={multiple}
        className={"d-none"}
        onChange={handleInputChange}
      />
      <label
        onClick={onButtonClick}
        id="label-file-upload-111"
        htmlFor="input-file-upload-111"
        className={`py-16 px-24 border-radius-12 background-color-white border-gray-200 d-flex flex-column ${
          dragActive ? "drag-active" : ""
        }`}
      >
        <ImageUploadIcon className={"mb-12 align-self-center"} />
        <div className={"text-align-center"}>
          <p className={"text-sm-regular"}>
            <span className={"text-sm-semi-bold cursor-pointer color-brand"}>Click to upload</span>{" "}
            or drag and drop
          </p>
          <p className={"text-xs-regular"}>SVG, PNG, JPG or GIF (max. 800x400px)</p>
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          className={
            "position-absolute w-100 h-100 top-0 bottom-0 left-0 right-0 border-gray-300 border-radius-12"
          }
        />
      )}
    </div>
  );
}

// import { useRef, useState } from "react";
// import { ReactComponent as ImageUploadIcon } from "assets/svg/icons/image-upload-icon.svg";

// export function ImageUploadBox1({ multiple = false, className, setFiles }) {
//   const inputRef = useRef(null);
//   const [dragActive, setDragActive] = useState(false);

//   const handleDrag = function (e) {
//     console.log("handle drag");
//     e.preventDefault();
//     e.stopPropagation();
//     if (e.type === "dragenter" || e.type === "dragover") {
//       setDragActive(true);
//     } else if (e.type === "dragleave") {
//       setDragActive(false);
//     }
//     handleChange(e);
//   };

//   const handleDrop = function (e) {
//     console.log("handle drop");
//     console.log("handleDrop", e);
//     e.preventDefault();
//     e.stopPropagation();
//     setDragActive(false);
//     if (e.dataTransfer.files && e.dataTransfer.files[0]) {
//       // handleFiles(e.dataTransfer.files);
//     }
//     handleChange(e);
//   };
//   const handleChange = function ({ target: { files } }) {
//     console.log("HERE");
//     const data = new FormData();
//     const filesArr = [...files];

//     filesArr.forEach((file) => {
//       data.append("Files", file);
//     });
//     console.log("Data", data.values);
//     setFiles(data);
//   };

//   const onButtonClick = () => {
//     inputRef.current.click();
//   };

//   console.log("User Management");

//   return (
//     <div onDragEnter={handleDrag} className={`position-relative nv-imageUploadBox ${className}`}>
//       <input
//         ref={inputRef}
//         type="file"
//         id="input-file-upload"
//         multiple={multiple}
//         className={"d-none"}
//         onChange={handleChange}
//       />
//       <label
//         id="label-file-upload"
//         htmlFor="input-file-upload"
//         className={`py-16 px-24 border-radius-12 background-color-white border-gray-200 d-flex flex-column ${
//           dragActive ? "drag-active" : ""
//         }`}
//       >
//         <ImageUploadIcon className={"mb-12 align-self-center"} />
//         <div className={"text-align-center"}>
//           <p className={"text-sm-regular"}>
//             <span
//               className={"text-sm-semi-bold cursor-pointer color-brand"}
//               onClick={onButtonClick}
//             >
//               Click to upload
//             </span>{" "}
//             or drag and drop
//           </p>
//           <p className={"text-xs-regular"}>SVG, PNG, JPG or GIF (max. 800x400px)</p>
//         </div>
//       </label>
//       {dragActive && (
//         <div
//           id="drag-file-element"
//           className={
//             "position-absolute w-100 h-100 top-0 bottom-0 left-0 right-0 border-gray-300 border-radius-12"
//           }
//           onDragEnter={handleDrag}
//           onDragLeave={handleDrag}
//           onDragOver={handleDrag}
//           onDrop={handleDrop}
//         />
//       )}
//     </div>
//   );
// }
