import { IconButton } from "@mui/material";

import { ReactComponent as DownloadIcon } from "assets/svg/icons/download-arrow.svg";
import { ReactComponent as ThreeDotsIcon } from "assets/svg/icons/gray-dots.svg";
import { ReactComponent as InfoCircleIcon } from "assets/svg/icons/info-circle-20.svg";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import { getFormattedDate } from "../../../../utils/shared/helpers";
import CustomizedCheckbox from "../Checkbox/Checkbox";
import DocumentOrFileStatusDot from "../DocumentOrFileStatusDot";

import { getLoanDocumentInfo } from "api/loan.api";
import { useLoanContext } from "pages/Borrower/LoanDetail";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fileDownloader } from "../../actions";
import { usePopper } from "../../hooks/usePopper";
import { selectFiles, setDeleteFileId, unSelectFiles } from "../../loanDetailSlice";
import "../Document/styles.scss";
import Dropdown from "../Dropdown/Dropdown";
import StatusDSFDropdown from "../StatusDSFDropdown";
import CustomizedTooltip from "../Tooltip/Tooltip";
import "./styles.scss";

import { IconButtonV2 } from "components/IconButtonV2";

const MODAL_TYPE = "delete-file";

const File = ({ currentFile = {}, allFiles = [], isInsideSubdocument = false }) => {
  const { id, fileName, dateUpdated, status, rejectionReason } = currentFile;
  const dispatch = useDispatch();
  const { canEditFile, canViewFile, canMultiSelect, canDelete, canDownload } = useLoanContext();
  const [showPopper, handlePopper] = usePopper();

  const { loanDocumentSelectedFiles } = useSelector(({ loanDetail }) => loanDetail);
  const isChecked = useMemo(
    () => loanDocumentSelectedFiles.includes(id),
    [loanDocumentSelectedFiles, id],
  );

  const handleCheck = () => {
    isChecked ? dispatch(unSelectFiles([id])) : dispatch(selectFiles([id]));
  };

  const handleDownload = async () => {
    dispatch(fileDownloader({ fileId: id, fileName }));
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    dispatch(setDeleteFileId(id));
    dispatch(commonActions.toggleDeleteFilesModalVisibility(MODAL_TYPE));
    handlePopper();
  };

  const openViewDocumentModal = async () => {
    const fileInfo = await getLoanDocumentInfo(currentFile.id);
    const payLoad = { fileInfo, allFiles, isUpdate: true, isVisible: true };
    dispatch(commonActions.toggleViewFilesModalVisibility(payLoad));
  };

  const [formattedDate, formattedTime] = getFormattedDate(dateUpdated);

  return (
    <>
      <li
        onClick={canViewFile && openViewDocumentModal}
        className={`${
          isInsideSubdocument ? "pl-[68px] pr-[40px]" : "px-40"
        } h-[36px] grid-cols-4 cursor-pointer hover:bg-[#f5f5f5] transition duration-150`}
      >
        <div
          className={`d-flex align-items-center ${
            isInsideSubdocument ? "gap-[12px]" : "gap-[10px]"
          }`}
        >
          <div style={{ width: canMultiSelect ? "16px" : "25px" }} />
          <div onClick={(e) => e.stopPropagation()}>
            {canMultiSelect && <CustomizedCheckbox checked={isChecked} onChange={handleCheck} />}
          </div>

          <div onClick={(e) => e.stopPropagation()}>
            {canEditFile ? (
              <StatusDSFDropdown
                isDocument={false}
                docId={id}
                label={<DocumentOrFileStatusDot itsButton isDocument={false} status={status} />}
              />
            ) : (
              <DocumentOrFileStatusDot status={status} isDocument={false} />
            )}
          </div>
          <span className={`fileName ${canViewFile ? "cursor-pointer" : undefined}`}>
            {fileName}
          </span>
        </div>
        <div className="d-flex align-items-center">
          {rejectionReason && (
            <div className="d-flex align-items-center gap-8">
              <span className="rejectionReason">Reject Info</span>
              <CustomizedTooltip title={rejectionReason}>
                <InfoCircleIcon />
              </CustomizedTooltip>
            </div>
          )}
        </div>
        <div
          className={`${
            isInsideSubdocument ? "pl-[8px]" : "pl-16"
          } updatedDate d-flex align-items-center`}
        >
          {formattedDate}, {formattedTime}
        </div>
        <div className="d-flex align-items-center gap-4 justify-self-end">
          <div className="d-flex align-items-center gap-4 justify-self-end">
            <div onClick={(e) => e.stopPropagation()}>
              {canDownload && (
                <IconButtonV2 onClick={handleDownload} tooltip="Download File">
                  <DownloadIcon />
                </IconButtonV2>
              )}
            </div>

            <Dropdown
              showPopper={showPopper}
              handlePopper={handlePopper}
              maxVisibleItems={5}
              listContainer="templatelistcontainer"
              listClassName="mt-6"
              label={
                canDelete && (
                  <CustomizedTooltip title={"More"}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePopper(true);
                      }}
                    >
                      <ThreeDotsIcon />
                    </IconButton>
                  </CustomizedTooltip>
                )
              }
            >
              <li
                onClick={(e) => handleDelete(e)}
                className="listitem-template archiveColor cursorPointer"
              >
                Delete
              </li>
            </Dropdown>
          </div>
        </div>
      </li>
    </>
  );
};

export default File;
