import {QueryClient} from "@tanstack/react-query";

export const queryClient = new QueryClient({
    defaultOptions:{
        queries:{
            retry: 0,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchInterval: false,
            cacheTime: 0
        }
    }
});
