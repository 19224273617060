import { useState, useRef } from "react";
import { usePopper } from "react-popper";
import Card from "./Card";
import "./style.css";
import InviteUserModal from "../InviteUserModal";
import { useDispatch } from "react-redux";
import { commonActions } from "wrappers/NewVersion/commonSlice";

const BulkDropdownUserAccess = ({
  searchFunc,
  userToAdd,
  handleUserToAdd,
  users,
  setUsers,
  roles,
  loanId,
  handleAddList,
  cancelAdding,
}) => {
  const newTextInput = useRef(null);
  const [showPopper, setShowPopper] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [searchList, setSearchList] = useState([]);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);

  const onAdd = (user) => {
    handleAddList(user);
    setShowPopper(false);
    setSearchText("");
    setSearchList([]);
  };

  const onRemove = () => {
    handleUserToAdd(null);
  };

  const handleChange = async (e) => {
    const val = e.target.value;
    setSearchText(val);
    await new Promise((res) => setTimeout(res, 100));
    const res = await searchFunc(val);
    setSearchList(
      res.filter(
        (item) =>
          !users.some(
            (user) =>
              user?.fullName?.toLowerCase() === item?.fullName?.toLowerCase() ||
              user?.email?.toLowerCase() === item?.email?.toLowerCase(),
          ),
      ),
    );
    val.length > 0 && setShowPopper(true);
  };

  const handleAddNew = () => {
    const isEmail = String(searchText)
      .trim()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );

    if (!!isEmail) onAdd({ email: searchText.trim(), fullName: null, userId: null });
  };

  const handleClose = () => {
    dispatch(commonActions.toggleAccessControlModalVisibility(false));
    setIsLoading(false);
    setSearchText("");
    setSearchList([]);
    cancelAdding();
  };

  return (
    <div ref={setReferenceElement} className="w-100 h-100 d-flex">
      <Card showX={!!userToAdd} text={userToAdd?.fullName ?? userToAdd?.email} onClick={onRemove} />
      <input
        ref={newTextInput}
        autoComplete="off"
        className="categoryContainer-input"
        onChange={handleChange}
        type="text"
        name="category"
        placeholder={!userToAdd ? "Enter user`s email or first name or last name" : ""}
        value={searchText}
        maxLength={100}
      />
      {showPopper && searchText.length > 0 && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="listwrapper"
        >
          <ul className="listcontainer">
            {searchList.map((option) => (
              <li
                key={option.userId}
                className="cursorPointer list-item"
                onClick={() => onAdd(option)}
              >
                <h6>{option.fullName ?? ""}</h6>
                <p>{option?.email ?? ""}</p>
              </li>
            ))}

            {!searchList.length ? (
              <>
                <li className="listitem noBackgroundChange">User not found.</li>
                <li
                  className="listitem cursorPointer inviteUser"
                  onClick={() => setIsInviteUserModalOpen(true)}
                >
                  + Invite user
                </li>
              </>
            ) : null}
          </ul>
        </div>
      )}
      <InviteUserModal
        isOpen={isInviteUserModalOpen}
        onClose={() => setIsInviteUserModalOpen(false)}
        roles={roles}
        loanId={loanId}
        handleThisClose={handleClose}
        email={searchText.trim()}
        users={users}
        setUsers={setUsers}
      />
    </div>
  );
};

export default BulkDropdownUserAccess;
