import React, { useState } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { usePopper } from "react-popper";

import "./styles.css";

const ButtonSelect = ({ children, options, onClick }) => {
  const [showPopper, setShowPopper] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const handleOnClick = (id, name) => {
    onClick({
      id,
      name,
    });

    setShowPopper(false);
  };
  return (
    <React.Fragment>
      <div ref={setReferenceElement} onClick={() => setShowPopper(true)}>
        {children}
      </div>
      {showPopper && (
        <ClickAwayListener onClickAway={() => setShowPopper(false)}>
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="listwrapper"
          >
            <ul className="listcontainer  ">
              {options.map((option) => {
                return (
                  <li
                    key={option.id}
                    className="listitem cursorPointer"
                    onClick={() => handleOnClick(option.id, option.name)}
                  >
                    {option.name}
                  </li>
                );
              })}
            </ul>
          </div>
        </ClickAwayListener>
      )}
    </React.Fragment>
  );
};

export default ButtonSelect;
