import { DigitalLoanService } from "../../configs/axios";

export function getBorrower() {
  return DigitalLoanService.get("/borrowers").then((response) => response);
}

export function getLoansOfBorrowerByStatus({ id = "", status = "active" }) {
  return DigitalLoanService.get(`/borrowers/${id}/loans?status=${status}`).then(({ data }) => data);
}

export function getCreditScoreOfBorrower(ssn = "") {
  return DigitalLoanService.get(`/borrowers/${ssn}`).then(({ data }) => data);
}
