import { commonActions } from "../../commonSlice";
import { Modal } from "../Modal";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as LockIcon } from "../../../../assets/svg/icons/lock-icon-closed.svg";
import { Grid } from "@mui/material";
import Button from "../../../../pages/Borrower/Components/Button/Button";
import { useNavigate } from "react-router-dom";
import { removeAuthInfoFromStorage } from "../../utils/helpers";
import { clearPersistedData } from "configs/store";

import "./styles.scss";

export function PasswordChangeConfirmation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { changePasswordModalConfirm } = useSelector((state) => state.common);

  const handleLogout = () => {
    dispatch(commonActions.toggleChangePasswordModalConfirmVisibility());
    navigate("/login");
    removeAuthInfoFromStorage();
    clearPersistedData();
  };

  return (
    <Modal icon={<LockIcon />} isOpen={changePasswordModalConfirm?.isVisible}>
      <div>
        <div className={"mb-20"}>
          <h3 className={"display-sm-bold text-align-center"}>Password Changed</h3>
        </div>

        <p className="bodyPasswordModal">
          You will be logged out of the system and would require logging in again.
        </p>
      </div>
      <div className={"pt-32 px-14 gap-12"}>
        <Grid container spacing={2} rowSpacing={4}>
          <Grid item xs={12}>
            <Button
              width="100%"
              onClick={handleLogout}
              type="contained"
              label={"Done"}
              disabled={!changePasswordModalConfirm?.isVisible}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
