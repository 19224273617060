import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Skeleton from "@yisheng90/react-loading";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Collapse, IconButton } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Search from "../../../Borrower/Components/SearchBar/Search";
import SearchDropDown from "../../Components/Dropdown/SearchDropDown";
import Pagination from "../../../Borrower/Components/Pagination/Pagination";
import Dropdown from "../../Components/Dropdown/Dropdown";
import {
  getTemplatesListApi,
  setSearchTextTemplates,
  nextPageTemplates,
  prevPageTemplates,
  setSortColumnTemplates,
  updateTemplateApi,
  setCreateTemplateError,
  createTemplateApi,
  deleteTemplateApi,
  setDeleteTemplateError,
  getCategoryListApi,
  deleteDocumentTemplateApi,
  getDocumentsListAutoCompleteApi,
  addDocumentTemplateApi,
  addCreateDocumentTemplateApi,
} from "../../documentSlice";
import { commonActions } from "wrappers/NewVersion/commonSlice.js";

import { ReactComponent as EditIcon } from "assets/svg/icons/edit-001.svg";
import { ReactComponent as DeleteIcon } from "assets/svg/icons/trash-02.svg";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";
import DeleteModal from "../Categories/DeleteModal";
import DeleteMessageModal from "../Categories/DeleteMessageModal";
import "../styles.css";

const TemplatesTab = () => {
  const dispatch = useDispatch();
  const {
    templatesList,
    currentPage,
    searchText,
    sortColumn,
    createTemplate,
    deleteTemplate,
    updateTemplate,
    setUpdateTemplateError,
  } = useSelector(({ document }) => document.templates);
  const { docsManagementTemplates } = useSelector(({ common }) => common);

  const newTableRef = useRef(null);

  const [categoryList, setCategoryList] = useState([]);

  const [deleteId, setDeleteId] = useState(null);
  const [collapseDeleteId, setCollapseDeleteId] = useState(null);
  const [messageModalHeader, setMessageModalHeader] = useState(null);
  const [messageModalMessage, setMessageModalMessage] = useState(null);
  const [messageModalIcon, setMessageModalIcon] = useState(null);
  const [editId, setEditId] = useState(null);
  const [editTitle, setEditTitle] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [deleteType, setDeleteType] = useState(null);

  const [deleteModalHeader, setDeleteModalHeader] = useState(null);
  const [deleteModalMessage, setDeleteModalMessage] = useState(null);

  const [createSubRow, setCreateSubRow] = useState(
    new Array(templatesList.data.data?.length).fill(false),
  );

  const [openCollapsable, setOpenCollapsable] = useState(
    new Array(templatesList.data.data?.length).fill(false),
  );

  const [subrowSearchSelelectedValue, setSubrowSearchSelectedValue] = useState(
    new Array(templatesList.data.data?.length).fill({}),
  );

  const [subrowCategoryListVal, setSubrowCategoryListVal] = useState(
    new Array(templatesList.data.data?.length).fill(null),
  );

  const [subRowErrowMessage, setSubrowErrorMessages] = useState(
    new Array(templatesList.data.data?.length).fill(null),
  );

  const subRowNewReferences = new Array(templatesList.data.data?.length).fill(useRef(null));
  //sibrow drop down

  const handleSubrowCategorySelect = (val, indx) => {
    setSubrowCategoryListVal((prev) => {
      prev[indx] = val;
      return [...prev];
    });
  };

  const searchDocument = async (val) => {
    const res = await dispatch(getDocumentsListAutoCompleteApi({ searchText: val })).unwrap();
    return res;
  };

  const searchDocumentOnClick = (val, indx) => {
    setSubrowErrorMessages((prev) => {
      prev[indx] = false;
      return [...prev];
    });
    setSubrowSearchSelectedValue((prev) => {
      prev[indx] = val;
      return [...prev];
    });
  };

  const handleOpenCollapse = (index) => {
    setOpenCollapsable((prev) => {
      prev[index] = !prev[index];
      return [...prev];
    });
  };
  const handlePlus = (index) => {
    setCreateSubRow((prev) => {
      prev[index] = true;
      return [...prev];
    });
    setOpenCollapsable((prev) => {
      prev[index] = true;
      return [...prev];
    });
  };

  const handleSubrowCreate = async (index) => {
    try {
      if (subrowSearchSelelectedValue[index].id) {
        await dispatch(
          addDocumentTemplateApi({
            templateId: templatesList.data.data[index].id,
            documentId: subrowSearchSelelectedValue[index].id,
          }),
        ).unwrap();
      } else {
        await dispatch(
          addCreateDocumentTemplateApi({
            templateId: templatesList.data.data[index].id,
            documentName: subrowSearchSelelectedValue[index].name,
            categoryId: subrowCategoryListVal[index],
          }),
        ).unwrap();
      }
      await dispatch(
        getTemplatesListApi({
          page: currentPage,
          searchText: searchText,
          sortColumnName: sortColumn.sortColumnName,
          sortOrder: sortColumn.sortColumnOrder,
        }),
      ).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Document added"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
      setCreateSubRow((prev) => {
        prev[index] = false;
        return [...prev];
      });
      setSubrowSearchSelectedValue((prev) => {
        prev[index] = {};
        return [...prev];
      });
      setSubrowCategoryListVal((prev) => {
        prev[index] = null;
        return [...prev];
      });
    } catch (error) {
      setMessageModalIcon("new");
      setMessageModalHeader("Can't Add Document");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
      setSubrowErrorMessages((prev) => {
        prev[index] = true;
        return [...prev];
      });
      console.log(error);
    }
  };

  const handleCollapseCreateClose = (index, l = 0) => {
    setCreateSubRow((prev) => {
      prev[index] = false;
      return [...prev];
    });
    setSubrowCategoryListVal((prev) => {
      prev[index] = null;
      return [...prev];
    });

    if (l === 0) {
      setOpenCollapsable((prev) => {
        prev[index] = false;
        return [...prev];
      });
    }
  };

  useEffect(() => {
    const fetchCategoryList = async () => {
      const res = await dispatch(getCategoryListApi()).unwrap();
      setCategoryList(res);
    };
    fetchCategoryList();

    return () => {
      dispatch(commonActions.toggleDocsManagementTemplatesFalse());
    };
  }, [dispatch]);

  useEffect(() => {
    const error = deleteTemplate.error;
    if (error) {
      setMessageModalIcon("delete");
      setMessageModalHeader("Can't Delete Template");
      setMessageModalMessage(error);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
    }
    return () => {
      dispatch(setDeleteTemplateError(null));

      dispatch(commonActions.toggleDocsManagementTemplatesFalse());
    };
  }, [deleteTemplate, dispatch]);

  useEffect(() => {
    newTableRef.current?.focus();
  }, [docsManagementTemplates.isCreate]);

  useEffect(() => {
    createSubRow.forEach((item, index) => {
      if (item) {
        subRowNewReferences[index].current?.focus();
      }
    });
  }, [createSubRow, subRowNewReferences]);

  const sortColName = useMemo(() => {
    return sortColumn.sortColumnName;
  }, [sortColumn.sortColumnName]);
  const sortColOrder = useMemo(() => {
    return sortColumn.sortColumnOrder;
  }, [sortColumn.sortColumnOrder]);

  const handleArrow = useCallback(
    (colName) => {
      return sortColName === colName && sortColOrder === "asc" ? (
        <ArrowUpwardIcon sx={{ fontSize: "16px", color: "#525252" }} />
      ) : sortColName === colName && sortColOrder === "desc" ? (
        <ArrowDownwardIcon sx={{ fontSize: "16px", color: "#525252" }} />
      ) : null;
    },
    [sortColName, sortColOrder],
  );

  const handleHeaderClick = (colName) => {
    if (colName === sortColName) {
      if (sortColOrder === "asc") {
        dispatch(
          setSortColumnTemplates({
            sortColumnName: colName,
            sortColumnOrder: "desc",
          }),
        );
      } else {
        dispatch(
          setSortColumnTemplates({
            sortColumnName: colName,
            sortColumnOrder: "asc",
          }),
        );
      }
    } else {
      dispatch(
        setSortColumnTemplates({
          sortColumnName: colName,
          sortColumnOrder: "asc",
        }),
      );
    }
  };

  const handleKeyDown = (event, type) => {
    if (event.key === "Enter") {
      if (type === "new") {
        newTitle !== "" && handleCreate();
      } else if (type === "edit") {
        editTitle !== "" && handleUpdate();
      }
    } else if (event.key === "Escape") {
      if (type === "new") {
        setNewTitle("");
        dispatch(commonActions.toggleDocsManagementTemplatesFalse());
      } else if (type === "edit") {
        handleClose();
      }
    }
  };

  const handleSubrowKeyDown = (event, index) => {
    if (event.key === "Enter") {
      if (
        subrowSearchSelelectedValue[index]?.name === undefined ||
        subrowSearchSelelectedValue[index].name === ""
      ) {
        handleSubrowCreate(index);
      }
    } else if (event.key === "Escape") {
      handleCollapseCreateClose(index, templatesList.data.data[index].documents.length);
    }
  };

  const handleSearch = (val) => {
    dispatch(setSearchTextTemplates(val));
  };
  const handleNext = () => {
    dispatch(nextPageTemplates());
  };
  const handlePrev = () => {
    dispatch(prevPageTemplates());
  };
  const handleShowEdit = (id, txt) => {
    setEditId(id);
    setEditTitle(txt);
  };

  const handleDeleteYes = useCallback(async () => {
    if (deleteType === "main") {
      try {
        await dispatch(
          deleteTemplateApi({
            id: deleteId,
          }),
        ).unwrap();
        dispatch(
          getTemplatesListApi({
            page: currentPage,
            searchText: searchText,
            sortColumnName: sortColumn.sortColumnName,
            sortOrder: sortColumn.sortColumnOrder,
          }),
        ).unwrap();
        toast(
          <div className="toastMessageContainer">
            <div className="succesIconBackground1">
              <SuccessIcon />
            </div>
            <span className="toastMessage-text">{"Template Deleted"}</span>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          },
        );
      } catch (error) {
        console.log(error);
      }
    } else if (deleteType === "collapse") {
      try {
        await dispatch(deleteDocumentTemplateApi(collapseDeleteId)).unwrap();
        dispatch(
          getTemplatesListApi({
            page: currentPage,
            searchText: searchText,
            sortColumnName: sortColumn.sortColumnName,
            sortOrder: sortColumn.sortColumnOrder,
          }),
        ).unwrap();
        toast(
          <div className="toastMessageContainer">
            <div className="succesIconBackground1">
              <SuccessIcon />
            </div>
            <span className="toastMessage-text">{"Document Removed"}</span>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          },
        );
      } catch (error) {
        console.log(error);
      }
    }
  }, [
    collapseDeleteId,
    currentPage,
    deleteId,
    deleteType,
    dispatch,
    searchText,
    sortColumn.sortColumnName,
    sortColumn.sortColumnOrder,
  ]);
  const handleDelete = (id) => {
    setEditId(null);
    setEditTitle("");
    setDeleteId(id);
    setDeleteType("main");
    setDeleteModalHeader("Delete Template");
    setDeleteModalMessage("Are you sure you want to delete this template?");
    dispatch(commonActions.toggleDeleteModalVisibility());
  };
  const handleUpdate = async () => {
    try {
      await dispatch(updateTemplateApi({ id: editId, name: editTitle })).unwrap();
      await dispatch(
        getTemplatesListApi({
          page: currentPage,
          searchText: searchText,
          sortColumnName: sortColumn.sortColumnName,
          sortOrder: sortColumn.sortColumnOrder,
        }),
      ).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Template Updated"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
      setEditId(null);
      setEditTitle(null);
    } catch (error) {
      setMessageModalIcon("edit");
      setMessageModalHeader("Can't Update Template");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
      console.log(error);
    }
  };

  const handleCreate = async () => {
    try {
      await dispatch(createTemplateApi({ name: newTitle })).unwrap();
      dispatch(
        getTemplatesListApi({
          page: currentPage,
          searchText: searchText,
          sortColumnName: sortColumn.sortColumnName,
          sortOrder: sortColumn.sortColumnOrder,
        }),
      ).unwrap();
      dispatch(commonActions.toggleDocsManagementTemplatesFalse());
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Template Added"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
      setNewTitle("");
    } catch (error) {
      setMessageModalIcon("new");
      setMessageModalHeader("Can't Add Template");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
      console.log(error);
    }
  };
  const handleClose = () => {
    setEditTitle(null);
    setEditId(null);
  };

  //subrow functionality

  const handleCollapseDelete = (id) => {
    setCollapseDeleteId(id);
    setDeleteType("collapse");
    setDeleteModalHeader("Remove Document");
    setDeleteModalMessage("Are you sure you want to remove this document?");
    dispatch(commonActions.toggleDeleteModalVisibility());
  };

  return (
    <div className="tabsContainer">
      <div className="tabsContainer-utilities">
        <Search handleSearch={handleSearch} placeholder={"Search by template or document title"} />
        <Pagination
          currentPage={templatesList.data?.pageIndex}
          totalPage={templatesList.data?.totalPages}
          handleNext={handleNext}
          handlePrev={handlePrev}
          isLoading={false}
        />
      </div>
      {templatesList.isLoading ? (
        <>
          <Skeleton height={44} />
          <Skeleton rows={5} height={60} />
        </>
      ) : (
        <div className="tabsContainer-table">
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "56px" }} />
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => handleHeaderClick("name")}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        padding: " 13px 0px",
                        alignItems: "center",
                      }}
                    >
                      <p className="headerCell-text"> Template Name</p>
                      {handleArrow("name")}
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: "160px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        padding: " 13px 0px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p className="headerCell-text">Actions</p>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {docsManagementTemplates.isCreate && (
                  <TableRow
                    style={{ backgroundColor: " #F5F5F5" }}
                    onKeyDown={(e) => handleKeyDown(e, "new")}
                  >
                    <TableCell
                      className={
                        createTemplate.error
                          ? templatesList.data?.data.length === 0
                            ? "roundBorderLeft"
                            : "redRowLeft"
                          : null
                      }
                    />
                    <TableCell
                      className={
                        createTemplate.error
                          ? templatesList.data?.data.length === 0
                            ? "roundBorderMiddle"
                            : "redRowMiddle"
                          : null
                      }
                    >
                      <Box sx={{ display: "flex", padding: "14px 0px" }}>
                        <input
                          className="categoryContainer-input"
                          onChange={(e) => {
                            if (createTemplate.error) {
                              dispatch(setCreateTemplateError(null));
                            }
                            setNewTitle(e.target.value);
                          }}
                          type="text"
                          name="template"
                          placeholder="Enter template name"
                          value={newTitle}
                          maxLength="100"
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      className={
                        createTemplate.error
                          ? templatesList.data?.data.length === 0
                            ? "roundBorderRight"
                            : "redRowRight"
                          : null
                      }
                    >
                      <Box
                        sx={{
                          boxSizing: "border-box",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          padding: "4px",
                          gap: "4px",
                          justifyContent: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setNewTitle("");
                            dispatch(setCreateTemplateError(null));
                            dispatch(commonActions.toggleDocsManagementTemplatesFalse());
                          }}
                          ref={newTableRef}
                        >
                          <CloseIcon sx={{ fontSize: 20, color: "#525252" }} />
                        </IconButton>
                        <IconButton onClick={handleCreate} disabled={newTitle === ""}>
                          <CheckIcon sx={{ fontSize: 20, color: "#A459FF" }} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {templatesList.data.data &&
                  templatesList.data.data.map((row, index) => {
                    const isEditing = row.id === editId;
                    return (
                      <React.Fragment key={row.id}>
                        <TableRow
                          key={row.id}
                          onKeyDown={(e) => handleKeyDown(e, "edit")}
                          // sx={{ "& > *": { borderBottom: "unset" } }}
                        >
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleOpenCollapse(index)}
                                disabled={row.documents.length === 0}
                              >
                                {openCollapsable[index] ? (
                                  <KeyboardArrowDownIcon />
                                ) : (
                                  <KeyboardArrowRightIcon />
                                )}
                              </IconButton>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: "flex", padding: "14px 0px" }}>
                              {isEditing ? (
                                <input
                                  className="categoryContainer-input"
                                  onChange={(e) => {
                                    if (updateTemplate.error) {
                                      dispatch(setUpdateTemplateError(null));
                                    }
                                    setEditTitle(e.target.value);
                                  }}
                                  type="text"
                                  name="template"
                                  placeholder="Enter template name"
                                  value={editTitle}
                                  maxLength="100"
                                  autoFocus
                                />
                              ) : (
                                <Stack direction={"column"}>
                                  <p className="templatesTitle-main--p">{row.name}</p>
                                  <p className="templatesTitle-secondary--p">{`${row.documentsCount} documents`}</p>
                                </Stack>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                boxSizing: "border-box",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "4px",
                                gap: "4px",
                                justifyContent: "center",
                              }}
                            >
                              {isEditing ? (
                                <>
                                  <IconButton onClick={handleClose}>
                                    <CloseIcon sx={{ fontSize: 20, color: "#525252" }} />
                                  </IconButton>
                                  <IconButton onClick={handleUpdate} disabled={editTitle === ""}>
                                    <CheckIcon sx={{ fontSize: 20, color: "#A459FF" }} />
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <IconButton onClick={() => handleDelete(row.id)}>
                                    <DeleteIcon />
                                  </IconButton>
                                  <IconButton onClick={() => handleShowEdit(row.id, row.name)}>
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton onClick={() => handlePlus(index)}>
                                    <AddIcon sx={{ fontSize: 20, color: "#525252" }} />
                                  </IconButton>
                                </>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              paddingBottom: 0,
                              paddingTop: 0,
                              border: "0px solid red",
                            }}
                            colSpan={3}
                          >
                            <Collapse
                              in={openCollapsable[index]}
                              timeout={"auto"}
                              unmountOnExit
                              style={{ width: "100%" }}
                            >
                              <Box>
                                <Table>
                                  <TableBody>
                                    {createSubRow[index] && (
                                      <TableRow
                                        style={{ backgroundColor: "#FAFAFA" }}
                                        tabIndex={0}
                                        onKeyDown={(e) => handleSubrowKeyDown(e, index)}
                                      >
                                        <TableCell
                                          className={subRowErrowMessage[index] ? "redRowLeft" : ""}
                                          style={{
                                            width: "56px",
                                            height: "48px",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <IconButton
                                              aria-label="expand row"
                                              size="small"
                                              disabled
                                            >
                                              <AddIcon
                                                style={{
                                                  color: "#D6D6D6",
                                                  fontSize: "20px",
                                                }}
                                              />
                                            </IconButton>
                                          </Box>
                                        </TableCell>
                                        <TableCell
                                          className={
                                            subRowErrowMessage[index] ? "redRowMiddle" : ""
                                          }
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              padding: "8px 0px",
                                            }}
                                          >
                                            <SearchDropDown
                                              searchFunc={searchDocument}
                                              index={index}
                                              onClick={searchDocumentOnClick}
                                            />
                                          </Box>
                                        </TableCell>
                                        <TableCell
                                          style={{ width: "12%" }}
                                          className={
                                            subRowErrowMessage[index] ? "redRowMiddle" : ""
                                          }
                                        >
                                          <Box
                                            sx={{
                                              boxSizing: "border-box",
                                              alignItems: "center",
                                              justifyContent: "flex-start",
                                            }}
                                          >
                                            {subrowSearchSelelectedValue[index] &&
                                              !subrowSearchSelelectedValue[index]?.id &&
                                              subrowSearchSelelectedValue[index].name && (
                                                <Dropdown
                                                  options={categoryList}
                                                  changeHandler={(val) =>
                                                    handleSubrowCategorySelect(val, index)
                                                  }
                                                  edit={true}
                                                  currentVal={subrowCategoryListVal[index]}
                                                />
                                              )}
                                          </Box>
                                        </TableCell>
                                        <TableCell
                                          style={{ width: "160px" }}
                                          className={subRowErrowMessage[index] ? "redRowRight" : ""}
                                        >
                                          <Box
                                            sx={{
                                              boxSizing: "border-box",
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              padding: "4px",
                                              gap: "4px",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <>
                                              <IconButton
                                                onClick={() =>
                                                  handleCollapseCreateClose(
                                                    index,
                                                    row.documents.length,
                                                  )
                                                }
                                              >
                                                <CloseIcon
                                                  sx={{
                                                    fontSize: 20,
                                                    color: "#525252",
                                                  }}
                                                />
                                              </IconButton>
                                              <IconButton
                                                onClick={() => handleSubrowCreate(index)}
                                                disabled={
                                                  subrowSearchSelelectedValue[index]?.name ===
                                                    undefined ||
                                                  subrowSearchSelelectedValue[index].name === ""
                                                }
                                              >
                                                <CheckIcon
                                                  ref={subRowNewReferences[index]}
                                                  sx={{
                                                    fontSize: 20,
                                                    color: "#A459FF",
                                                  }}
                                                />
                                              </IconButton>
                                            </>
                                          </Box>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                    {row.documents.map((subrow, indx) => {
                                      return (
                                        <TableRow
                                          key={subrow.id}
                                          style={{ backgroundColor: "#FAFAFA" }}
                                        >
                                          <TableCell
                                            style={{
                                              width: "56px",
                                              height: "48px",
                                            }}
                                          ></TableCell>
                                          <TableCell>
                                            <Box padding={"8px 0px"}>
                                              <p className="templateSecondary-title--p">
                                                {subrow.name}
                                              </p>
                                            </Box>
                                          </TableCell>
                                          <TableCell style={{ width: "12%" }}>
                                            <Box
                                              sx={{
                                                boxSizing: "border-box",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                              }}
                                            >
                                              <Dropdown
                                                options={categoryList}
                                                changeHandler={null}
                                                edit={false}
                                                currentVal={
                                                  categoryList.find(
                                                    (item) => item.name === subrow.category,
                                                  )?.id
                                                }
                                              />
                                            </Box>
                                          </TableCell>
                                          <TableCell style={{ width: "160px" }}>
                                            <Box
                                              sx={{
                                                boxSizing: "border-box",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                gap: "4px",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <IconButton
                                                onClick={() => handleCollapseDelete(subrow.id)}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </Box>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      <DeleteModal
        successHandler={handleDeleteYes}
        header={deleteModalHeader}
        message={deleteModalMessage}
      />
      <DeleteMessageModal
        message={messageModalMessage}
        icon={messageModalIcon}
        header={messageModalHeader}
      />
    </div>
  );
};

export default TemplatesTab;
