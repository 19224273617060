import "./styles.scss";
import { CollapsableSection } from "../CollapsableSection";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, AvatarGroup, IconButton } from "@mui/material";
import { ReactComponent as EditIcon } from "../../../../assets/svg/icons/edit-002.svg";
import Link from "../../../../components/Link";
import CustomizedTooltip from "../Tooltip/Tooltip";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import UserAccessDrawer from "../UserAccessDrawer";

export function ShareAccess() {
  const dispatch = useDispatch();

  const {
    loanAccessControlData: { isLoading, data: collaborators },
  } = useSelector(({ loanDetail }) => loanDetail);

  const handleAddCollaborator = async () => {
    dispatch(commonActions.toggleUserShareAccessDrawerVisibility());
  };

  return (
    <>
      <CollapsableSection
        isLoading={isLoading}
        header={<h4 className="font-indivisible">Access</h4>}
        content={
          <div className="mb-24">
            <p className="font-inter text-gray mb-16">
              Give access to your documents and data for third-party managers. Learn more about our{" "}
              <Link src="https://ambitious-moss-054df6b10.3.azurestaticapps.net/policy">
                data privacy policy
              </Link>
            </p>
            <div className="d-flex align-items-center w-100 justify-start gap-4">
              {!!collaborators.length ? (
                <AvatarGroup
                  slotProps={{
                    additionalAvatar: {
                      sx: { zIndex: 99, width: 36, height: 36, marginRight: "-2px" },
                      className:
                        "avatar-text background-color-gray-100 cursor-pointer avatar-hover",
                    },
                  }}
                  max={6}
                >
                  {collaborators?.map(
                    ({ displayName, avatarUrl, accessControlRole, email, id }, index) => (
                      <CustomizedTooltip
                        key={id}
                        title={
                          <div>
                            <h5 className="mb-6 avatar-tooltip">{displayName}</h5>
                            <p className="avatar-tooltip-text">
                              {email}, {accessControlRole}
                            </p>
                          </div>
                        }
                      >
                        <Avatar
                          src={avatarUrl}
                          alt={displayName}
                          className="avatar-text background-color-gray-100 cursor-pointer"
                          sx={{ zIndex: index, width: 36, height: 36, marginRight: "-2px" }}
                        >
                          {displayName.split(" ")[0][0].toUpperCase()}
                          {displayName.split(" ")[1][0].toUpperCase()}
                        </Avatar>
                      </CustomizedTooltip>
                    ),
                  )}
                </AvatarGroup>
              ) : null}
              <CustomizedTooltip title={"Manage Access"}>
                <IconButton
                  className="icon-dashed"
                  onClick={handleAddCollaborator}
                  aria-label="Add More"
                >
                  <EditIcon className="size-24 color-gray-600" />
                </IconButton>
              </CustomizedTooltip>
            </div>
          </div>
        }
      />
      <UserAccessDrawer />
    </>
  );
}
