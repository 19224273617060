import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin as GoogleLoginLibrary } from "react-google-login";
import { useNavigate } from "react-router-dom";

import { googleLogin as loginGoogle, getUserGrantedRoles } from "../loginSlice";
import { ReactComponent as GoogleIcon } from "assets/svg/icons/google-01.svg.svg";
import Button from "pages/OnBoarding/Components/Button";
import { setTokens } from "../../../utils/shared/helpers";
import useCheckMobileScreen from "utils/hooks/useCheckMobileScreen";
import { UserRoles } from "utils/constants/ConstantsUtil";

const GoogleLogin = ({ appId = null, buttonText = "Sign Up With Google" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOnMobile = useCheckMobileScreen();

  const GOOGLE_CLIENT_KEY = process.env.REACT_APP_GOOGLE_CLIENT_KEY;

  const { googleLogin } = useSelector((state) => state.login);

  const handleLoginSucces = async (response) => {
    try {
      const res = await dispatch(
        loginGoogle({
          provider: "GOOGLE",
          idToken: response.tokenId,
          clientId: "digitaloan-web",
          applicationId: appId,
        }),
      ).unwrap();
      setTokens(res);
      console.log(res);
      const user = await dispatch(getUserGrantedRoles()).unwrap();
      if (user?.accessType === UserRoles.BORROWER) {
        navigate(`/borrower/${user?.dId}`);
      } else {
        navigate("/borrower");
      }
      // alert("successfully logged in");
    } catch (error) {
      console.log("google error ", error);
    }
  };

  const handleLoginFailure = (response) => {
    console.error("fail", response);
  };

  return (
    <GoogleLoginLibrary
      clientId={GOOGLE_CLIENT_KEY}
      onSuccess={handleLoginSucces}
      onFailure={handleLoginFailure}
      render={(renderProps) => (
        <Button
          noStroke={true}
          buttonText={buttonText}
          autoFocus
          buttonIcon={
            <GoogleIcon
              {...(isOnMobile
                ? { width: "40px", height: "40px" }
                : { width: "48px", height: "48px" })}
            />
          }
          onClick={renderProps.onClick}
          disabled={googleLogin.isLoading}
        />
      )}
    />
  );
};

export default GoogleLogin;
