import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./styles.css";

const Search = ({ handleSearch, placeholder }) => {
  const [val, setVal] = useState("");
  useEffect(() => {
    handleSearch(val);
  }, [val, handleSearch]);
  return (
    <div className="searchContainer">
      <SearchIcon style={{ color: "#737373", fontSize: "24px" }} />
      <input
        className="searchContainer-input"
        onChange={(e) => setVal(e.target.value)}
        type="text"
        name="search"
        placeholder={placeholder}
        value={val}
      />
    </div>
  );
};

export default Search;
