import React from "react";

import { ReactComponent as ChevronRightGrayIcon } from "assets/svg/icons/chevron-right-16-gray.svg";
import { ReactComponent as ChevronRightIcon } from "assets/svg/icons/chevron-right-16.svg";
import { ReactComponent as ThreeDotsIcon } from "assets/svg/icons/gray-dots.svg";
import { ReactComponent as FilePlus } from "assets/svg/icons/upload-cloud.svg";

import { IconButton } from "@mui/material";
import { getFormattedDate } from "../../../../utils/shared/helpers";
import CustomizedCheckbox from "../Checkbox/Checkbox";
import DocumentOrFileStatusDot from "../DocumentOrFileStatusDot";
import File from "../File/File";

import { getLoanDocumentInfo } from "api/loan.api";
import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";
import { useLoanContext } from "pages/Borrower/LoanDetail";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import { fileDownloader } from "../../actions";
import { usePopper } from "../../hooks/usePopper";
import {
  selectFiles,
  setDocumentsToShow,
  testUploadLoanDocumentFileApi,
  unSelectFiles,
} from "../../loanDetailSlice";
import Dropdown from "../Dropdown/Dropdown";
import StatusDSFDropdown from "../StatusDSFDropdown";
import CustomizedTooltip from "../Tooltip/Tooltip";
import UploadInput from "../UploadInput";
import "./styles.scss";

import { IconButtonV2 } from "components/IconButtonV2";

const Document = ({ id, status, name, fileCount, dateUpdated, isLenderOnly, files }) => {
  const { loanId } = useParams();
  const dispatch = useDispatch();
  const { canAddFile, canViewFile, canMultiSelect, canDownload, canEditFile } = useLoanContext();

  const [showPopper, handlePopper] = usePopper(files.length);

  const { loanDocumentSelectedFiles, documentsToShow } = useSelector(
    ({ loanDetail }) => loanDetail,
  );

  const hasDocumentToShow = documentsToShow.includes(id);

  const openViewDocumentModal = async (e = null) => {
    if (e) {
      e.stopPropagation();
    }
    if (!files?.length) return;
    const fileInfoToSet = await getLoanDocumentInfo(files[0].id);
    const fileInfo = { ...fileInfoToSet };
    const payLoad = { fileInfo, allFiles: files, isUpdate: true, isVisible: true };
    dispatch(commonActions.toggleViewFilesModalVisibility(payLoad));
  };

  const handleShowFiles = (e) => {
    e.stopPropagation();
    dispatch(
      setDocumentsToShow(
        hasDocumentToShow
          ? documentsToShow.filter((document) => document !== id)
          : [...documentsToShow, id],
      ),
    );
  };

  const handleChange = async ({ target: { files } }) => {
    try {
      const data = new FormData();
      data.append("LoanDocumentId", id);

      const filesArr = [...files];

      filesArr.forEach((file) => {
        data.append("Files", file);
      });

      await dispatch(testUploadLoanDocumentFileApi({ loanId, data })).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">
            {filesArr.length > 1 ? "Files" : "File"} Uploaded
          </span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadDocumentFiles = (e) => {
    e.stopPropagation();
    const isBulk = files.length > 1;

    files.length &&
      dispatch(
        fileDownloader(
          {
            loanDocumentId: id,
            fileName: isBulk ? name : files[0]?.fileName,
            fileId: files[0]?.id,
          },
          isBulk,
        ),
      );

    handlePopper();
  };

  const handleCheck = (_e, files) => {
    isChecked
      ? dispatch(unSelectFiles(files.map((file) => file.id)))
      : dispatch(selectFiles(files.map((file) => file.id)));
  };

  const isChecked =
    !!files.length && files.every((file) => loanDocumentSelectedFiles.includes(file.id));

  const isIndeterminate =
    files.some((file) => loanDocumentSelectedFiles.includes(file.id)) && !isChecked;

  const [formattedDate, formattedTime] = getFormattedDate(dateUpdated);

  return (
    <React.Fragment>
      <li
        onClick={() => {
          if (!files.length) {
            document.getElementById(id).click();
          } else if (canViewFile && files.length) {
            openViewDocumentModal();
          }
        }}
        className={`pl-[68px] pr-[40px] h-[36px] grid-cols-4 cursor-pointer hover:bg-[#f5f5f5] transition duration-150`}
      >
        <div className="d-flex align-items-center gap-4 column-width">
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {canMultiSelect && (
              <CustomizedCheckbox
                disabled={!files.length}
                checked={isChecked}
                indeterminate={isIndeterminate}
                onChange={(e) => handleCheck(e, files)}
              />
            )}
          </div>

          {fileCount > 0 ? (
            <>
              <IconButton
                className={`chevron__rotate ${hasDocumentToShow && "open"}`}
                onClick={(e) => handleShowFiles(e)}
              >
                <ChevronRightIcon />
              </IconButton>

              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {canEditFile ? (
                  <StatusDSFDropdown
                    docId={id}
                    label={<DocumentOrFileStatusDot itsButton status={status} />}
                  />
                ) : (
                  <DocumentOrFileStatusDot status={status} />
                )}
              </div>

              <span
                onClick={() => canViewFile && openViewDocumentModal()}
                className={`documentName mx-8 ${canViewFile ? "cursor-pointer" : undefined}`}
              >
                {name}
              </span>

              <div className="border-radius-4 nv-rentYear">
                <p className="label-2 color-gray-700 text-align-center font-indivisible">
                  {fileCount} file{fileCount > 1 && "s"}
                </p>
              </div>
            </>
          ) : (
            <>
              <IconButton className="invisible">
                <ChevronRightGrayIcon />
              </IconButton>
              <DocumentOrFileStatusDot status={status} />
              <span className="documentName mx-8">{name}</span>
            </>
          )}
          {/* {isLenderOnly && (
            <CustomizedTooltip title="Document is for lenders only">
              <EyeIcon />
            </CustomizedTooltip>
          )} */}
        </div>
        <div className="d-flex align-items-center"></div>
        <div className="pl-[8px] updatedDate d-flex align-items-center">
          {formattedDate}, {formattedTime}
        </div>
        <div className="d-flex align-items-center gap-4 justify-self-end">
          <div onClick={(e) => e.stopPropagation()}>
            {canAddFile && (
              <UploadInput id={id} multiple onChange={handleChange} style={{ display: "none" }}>
                <IconButtonV2 onClick={handleChange} tooltip="Upload File">
                  <FilePlus />
                </IconButtonV2>
              </UploadInput>
            )}
          </div>

          <Dropdown
            disabled={!files.length}
            showPopper={showPopper}
            handlePopper={handlePopper}
            maxVisibleItems={5}
            listContainer="templatelistcontainer"
            listClassName="mt-6"
            label={
              canDownload && (
                <CustomizedTooltip title={"More"}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePopper(true);
                    }}
                    disabled={!files.length}
                  >
                    <ThreeDotsIcon />
                  </IconButton>
                </CustomizedTooltip>
              )
            }
          >
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleDownloadDocumentFiles(e);
              }}
              className="listitem-template archiveColor cursorPointer"
            >
              Download
            </li>
          </Dropdown>
        </div>
        <UploadInput id={id} multiple onChange={handleChange} style={{ display: "none" }} />
      </li>
      {hasDocumentToShow && (
        <ul className="background-color-gray-50">
          {files.map((file) => (
            <File
              key={file.id}
              currentFile={{ ...file, groupName: name, groupStatus: status, groupId: id }}
              allFiles={files}
              isInsideSubdocument
            />
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};

export default Document;
