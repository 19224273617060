
export const propertyStatusTypes = {
    review: 'Review',
    closed: 'Closed',
    archived: 'Archived',
    docsNeeded: 'DocsNeeded',
    clearToClose: 'ClearToClose',
}

export const propertyStatuses = {
    [propertyStatusTypes.review]: 'Review',
    [propertyStatusTypes.closed]: 'Closed',
    [propertyStatusTypes.archived]: 'Archived',
    [propertyStatusTypes.docsNeeded]: 'Docs Needed',
    [propertyStatusTypes.clearToClose]: 'Clear To Close',
}


export function PropertyStatusBadge({ status = propertyStatusTypes.closed }) {
    return (
        <div className={`text-align-center border-radius-4 nv-propertyStatusBadge nv-propertyStatusBadge-${status}`}>
            {propertyStatuses[status]}
        </div>
    )
}