import { useMemo, useState, useEffect } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

import "./styles.scss";
import InputText from "../InputText";

const Slider = ({
  sliderVal = "",
  unit = null,
  dollar = null,
  min = 1,
  max = 100,
  interval = 1,
  updateForm = null,
  ltv = null,
  isValid = true,
  dateType = false,
  periodTypes = null,
  periodTypeOnClick = null,
  selectedPeriodType = null,
  customNotValidMessage = null,
  limit = 9,
}) => {
  // const [isFocus, setIsFocus] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState();

  useEffect(() => {
    if (!isValid) {
      const isValGreaterThanMax = sliderVal > max;
      const isValLessThanMin = sliderVal < min;
      setInvalidMessage(
        `Amount must be ${
          isValLessThanMin
            ? `≥ ${min.toLocaleString("en-US")}`
            : isValGreaterThanMax
            ? `≤ ${max.toLocaleString("en-US")}`
            : "number"
        } or use slider`,
      );
    }
  }, [min, max, isValid, sliderVal]);

  const val = useMemo(() => {
    let val = Boolean(sliderVal) ? sliderVal : "";

    if (sliderVal && typeof sliderVal === "number") {
      val = sliderVal.toLocaleString();
    }

    return val;
  }, [sliderVal, isValid]);

  const handleChange = ([, input]) => {
    if (input + interval > max) {
      updateForm(max);
    } else {
      updateForm(input);
    }
  };

  return (
    <div className="sliderContainer">
      <div className="firstRow">
        <InputText
          val={val}
          updateForm={updateForm}
          type={"large"}
          unit={unit}
          dollar={dollar}
          focus={false}
          // handleFocus={handleClick}
          placeholder={dollar ? "0" : min}
          numeric={true}
          isValid={isValid}
          customNotValidMessage={customNotValidMessage || invalidMessage}
          limit={limit}
        />
        {ltv && <span className="ltv">{ltv}% LTV</span>}
        {dateType && (
          <div className="dateTypeContainer ltv">
            {periodTypes.map((type) => {
              return (
                <button
                  key={type.id}
                  className={`dateType ${type.id === selectedPeriodType ? "active" : ""}`}
                  onClick={() => periodTypeOnClick(type.id)}
                >
                  {type.name}
                </button>
              );
            })}
          </div>
        )}
      </div>

      <RangeSlider
        className="single-thumb"
        min={min}
        max={max}
        step={interval}
        thumbDisabled={[true, false]}
        onInput={handleChange}
        rangeSlideDisabled={true}
        value={[0, sliderVal ? (parseFloat(sliderVal) > max ? max : parseFloat(sliderVal)) : 1]}
      />
      <span className="">Tap on the number to change</span>
    </div>
  );
};

export default Slider;
