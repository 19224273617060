import { useState, useEffect, useRef, useMemo } from "react";
import { usePopper } from "react-popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import "./style.css";

const SearchDropdown = ({ searchFunc, onClick, value }) => {
  const newTextInput = useRef(null);
  const [isFocus, setIsFocus] = useState(true);
  const [showPopper, setShowPopper] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [searchText, setSearchText] = useState(value);
  const [searchList, setSearchList] = useState([]);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });
  const addressList = useMemo(() => {
    if (value !== "") {
      const list = value.split(",");
      const main = list[0];
      const rest = list.slice(1).join("");
      return [main, rest];
    }
    return ["", ""];
  }, [value]);

  useEffect(() => {
    isFocus && newTextInput.current.focus();
  }, [isFocus]);
  useEffect(() => {
    setSearchText(value);
  }, [value]);
  const handleChange = async (e, index = null) => {
    const val = e.target.value;
    setSearchText(val);
    await new Promise((res) => setTimeout(res, 100));
    const res = await searchFunc(val);
    setSearchList(res);
    val.length > 0 && setShowPopper(true);
  };

  const handleClick = (id, address) => {
    onClick(address, id);
    setShowPopper(false);
    newTextInput.current.blur();
  };
  return (
    <div
      ref={setReferenceElement}
      className="w-100 h-100 d-flex"
      style={{ flexWrap: "wrap" }}
      onFocus={() => setIsFocus(true)}
      tabIndex={0}
    >
      {isFocus || value === "" ? (
        <input
          ref={newTextInput}
          autoComplete="off"
          className="categoryContainer-input customInput input-placement--main"
          onChange={async (e) => await handleChange(e)}
          type="text"
          name="category"
          placeholder={"Enter address here"}
          value={searchText}
          maxLength={100}
          onBlur={() => setIsFocus(false)}
        />
      ) : (
        <>
          <p className="input-placement--main">{addressList[0]}</p>
          <p className="input-placement--secondary">{addressList[1]}</p>
        </>
      )}

      {showPopper && searchList.length > 0 && (
        <ClickAwayListener onClickAway={() => setShowPopper(false)}>
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="listwrapper"
          >
            <ul className="listcontainer searchDropDown-minWidth">
              {searchList.map((option) => {
                const list = option.propertyAddress.split(",");
                const main = list[0];
                const rest = list.slice(1).join("");
                return (
                  <li
                    key={option.id}
                    className="listitem cursorPointer searchDropDown-listItem"
                    onClick={() => handleClick(option.id, option.propertyAddress)}
                  >
                    <div>
                      <p className="input-placement--main">{main}</p>
                      <p className="input-placement--secondary">{rest}</p>
                    </div>
                    <div className="searchDropDown-loanId">{option.code}</div>
                  </li>
                );
              })}
            </ul>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default SearchDropdown;
