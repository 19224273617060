import React, { useEffect, useState } from "react";
import { loadInitialDataHelper, objectUpdateHelper } from "./helper";

const BattalionContext = React.createContext();
export default BattalionContext;

export function Provider(props) {
  const [isInitialDataLoaded, setInitialDataLoaded] = useState(false);
  const [citizenships, setCitizenships] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [loanTypes, setLoanTypes] = useState([]);
  const [propertyOwnershipStatuses, setPropertyOwnershipStatuses] = useState(
    []
  );
  const [propertyConditions, setPropertyConditions] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);

  const [financingPreferences, setFinancingPreferences] = useState({
    financingType: { id: 1, label: "Term" },
    transactionType: "",
    currentAddress: null,
    propertyAddress: "",
    propertyAddressObj: null,
  });

  const [yourAddress, setYourAddress] = useState({
    // permanentAddress: "",
    // permanentAddressObj: "",
    previousAddress: "",
    previousAddressObj: "",
    currentAddress: "",
    currentAddressObj: "",
    // propertyAddress: "",
    // propertyAddressObj: "",
  });
  const [aboutYouData, setAboutYouData] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    citizenship: "",
    email: "",
    phone: "",
    currentAddress: "",
    livedHere: "",
    propertyOwnership: "",
    monthlyRent: 0, // for personal info
    creditScore: "",
  });

  const [propertyDetails, setPropertyDetails] = useState({
    estimatedValue: 0,
    asIsValue: "",
    constructionBudget: 0,
    estimatedAfterRepairValue: 0,
    purchasePrice: 0,
    propertyType: "",
    residentialUnits: 0,
    commercialUnits: 0,
    propertyCondition: "",
    hasMortgage: false,
    propertyIncome: 0, // for loan details
    mortgageBalance: 0,
    mortgageLender: "",
  });

  const [loanData, setLoanData] = useState({
    closeDate: "",
    loanToValueRatio: 0,
    loanAmount: 0,
    loanToCost: "",
    constructionLoanAmount: 0,
  });

  useEffect(() => {
    (async () => {
      if (!isInitialDataLoaded) {
        await loadInitialDataHelper({
          //setFinancingPreferences,
          setCitizenships,
          setTransactionTypes,
          setPropertyConditions,
          setLoanTypes,
          //setPropertyDetails,
          setPropertyOwnershipStatuses,
          setPropertyTypes,
        });
        setInitialDataLoaded(true);
      }
    })();
  }, [isInitialDataLoaded]);

  const contextData = {
    financingPreferences,
    loanData,
    propertyTypes,
    propertyDetails,
    isInitialDataLoaded,
    citizenships,
    transactionTypes,
    loanTypes,
    propertyConditions,
    aboutYouData,
    propertyOwnershipStatuses,
    yourAddress,

    updateFinancingData: (payload, clearProperty) => {
      objectUpdateHelper(
        clearProperty,
        financingPreferences,
        payload,
        setFinancingPreferences
      );
    },
    updatePropertyData: (payload, clearProperty) => {
      objectUpdateHelper(
        clearProperty,
        propertyDetails,
        payload,
        setPropertyDetails
      );
    },
    updateAboutYouData: (payload, clearProperty) => {
      objectUpdateHelper(clearProperty, aboutYouData, payload, setAboutYouData);
    },
    updateYourAddress: (payload, clearProperty) => {
      objectUpdateHelper(clearProperty, yourAddress, payload, setYourAddress);
    },
    updateLoanData: (payload, clearProperty) => {
      objectUpdateHelper(clearProperty, loanData, payload, setLoanData);
    },
  };

  return (
    <BattalionContext.Provider value={contextData}>
      {props.children}
    </BattalionContext.Provider>
  );
}
