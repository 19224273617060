import { useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Link, useNavigate } from "react-router-dom";
//import { toast } from "react-toastify";

import ArrowRight from "assets/svg/icons/ArrowRightNormal.svg";
import Slider1 from "assets/images/Admin/slider-1.jpg";
import Slider2 from "assets/images/Admin/slider-2.jpg";
import Slider3 from "assets/images/Admin/slider-3.jpg";
import { AnimationGlare } from "components/DisplayComponents";
import { SlideItem } from "components/DisplayComponents";
import { TextInput } from "components/InputElements";
import { AppRoute } from "utils/constants/ConstantsUtil";

import { forgotPasswordAPI, makeLoginCall } from "../../../api/auth.api";
import ReactLoading from "react-loading";

const Login = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorOnlyOnEmail, setShowErrorOnlyOnEmail] = useState(false);
  const [errorText, setErrorText] = useState("");
  const navigate = useNavigate();
  const validationPatternForEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleOnLogin = async () => {
    setShowErrorOnlyOnEmail(false);
    setErrorText("");
    //toast.dismiss();
    if (isAllRequiredFieldFilled()) {
      setShowError(false);
      try {
        setIsLoading(true);
        const response = await makeLoginCall(emailRef.current?.value, passwordRef.current?.value);
        localStorage.setItem("AccessToken", response?.AccessToken);
        localStorage.setItem("ExpiresIn", response?.ExpiresIn);
        localStorage.setItem("RefreshToken", response?.RefreshToken);
        localStorage.setItem("Scope", response?.Scope);
        localStorage.setItem("TokenType", response?.TokenType);
        navigate(AppRoute.BORROWER);
        // toast.success("You Are LogIn !!!", { position: "bottom-left" });
      } catch (error) {
        setIsLoading(false);
        const errorMessage =
          error?.response?.data?.Message ||
          error?.response?.data?.message ||
          "Error please try again";

        setErrorText(errorMessage);
        //toast.error(errorMessage, { position: "bottom-left" });
        console.log(errorMessage);
      }
    } else {
      setShowError(true);
    }
  };

  const handleOnForgotPassword = async () => {
    setShowErrorOnlyOnEmail(true);
    if (!validationPatternForEmail.test(emailRef.current?.value)) return;

    setIsLoading(true);
    try {
      const response = await forgotPasswordAPI({
        email: emailRef.current?.value,
      });
      setErrorText(response.Message);
      //toast.success(response.Message, { position: "bottom-left" });
      console.log("response", response);
    } catch (error) {
      setErrorText(error.response.data.message);
      //toast.error(error.response.data.message, { position: "bottom-left" });
      console.log("error.response", error.response);
    }
    setIsLoading(false);
  };

  // TODO: move this function to helper file (used on index and register file the same fucntion)
  const isAllRequiredFieldFilled = () => {
    const wrapperElement = document.getElementsByClassName("forms_switch_box")[0];
    if (!wrapperElement) return false;

    if (wrapperElement)
      return wrapperElement.getElementsByClassName("to_be_filled_form_element").length === 0;

    return false;
  };

  return (
    <div className="input_box_wrapper login_form">
      <AnimationGlare type="spotlight" />
      <div className="box white" data-aos="fade-up" data-aos-delay="0" data-aos-duration="700">
        <div className="title_text">Login</div>
        <div className="login_input">
          <form className="forms_switch_box">
            <TextInput
              label="Email"
              placeholder="Enter email"
              ref={emailRef}
              isRequired
              showIfError={showError || showErrorOnlyOnEmail}
              errorMessage="enter a valid email address"
              type="email"
            />
            <TextInput
              label="Password"
              placeholder="Enter Password"
              type="password"
              isRequired
              showIfError={showError && !showErrorOnlyOnEmail}
              validationPattern={/^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n]).*$/}
              errorMessage="8-char long with 1 number & 1 symbol"
              ref={passwordRef}
            />
          </form>
        </div>
        {errorText ? (
          <div className="error_text">{errorText}</div>
        ) : (
          <div className="error_text_hold" />
        )}

        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ReactLoading type="spin" color="#a459ff" height="10%" width="10%" />
          </div>
        ) : (
          <div className="login_wrap">
            <div className="forgot_password" onClick={handleOnForgotPassword}>
              Forgot Password
              <span>
                <img src={ArrowRight} alt="ArrowRight" />
              </span>
            </div>
            <button className="button colored" onClick={handleOnLogin}>
              Login
            </button>
          </div>
        )}
      </div>

      <div
        className="box flex_box new_to"
        data-aos="fade-up"
        data-aos-delay="0"
        data-aos-duration="850"
      >
        <p className="title">New to Battalion, you can get started by applying for new loan here</p>
        <Link to={AppRoute.ONBOARDING}>
          <button className="button white">Register</button>
        </Link>
      </div>
    </div>
  );
};

const LoginSlides = () => {
  const slideData = [
    {
      title: "Technology and Human Intelligence As One",
      description:
        "Benefit from Battalion’s  machine learning algorithms to identify opportunities to finance or refinance your assets and acquire new properties. Our proprietary algorithms help you optimize your portfolio with an outstanding trade-off between risks and returns on investments.",
      image: Slider1,
    },
    {
      title: "Easy and Hassle-Free",
      description:
        "Whether you’re a seasoned or first-time real estate investor, Battalion provides you with the capital, technology, and unmatched in-house expertise to make the process as hassle-free as possible",
      image: Slider2,
    },
    {
      title: "Interest Rates As Low As 5.8% For Bridge Loan",
      description:
        "With interest rates as low as 8.99% for your bridge loan projects and 5.875% for your rental properties, we’ve got you covered.",
      image: Slider3,
    },
  ];
  return (
    <Carousel
      autoPlay={true}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      interval={10000}
      transitionTime={400}
      infiniteLoop={true}
    >
      {slideData.map((item, key) => (
        <SlideItem key={key} image={item.image} title={item.title} description={item.description} />
      ))}
    </Carousel>
  );
};

export { Login, LoginSlides };
