import { useState } from "react";
import { ReactComponent as ChevronLeftIcon } from "assets/svg/icons/chevron-left.svg";
import Skeleton from "react-loading-skeleton";

import "./styles.scss";

export function CollapsableSection({ header, content, badge }) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className={`${isOpen ? "pb-24" : "pb-0 mb-24"} w-100`}>
      <div className={`${badge ? "d-flex space-between" : ""}`}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="button-unstyled p-0 d-flex align-items-center gap-8"
        >
          <div className="flex-center chevron">
            <ChevronLeftIcon
              fontSize={"20px"}
              className={`${isOpen ? "chevron__to__bottom" : "chevron__to__right"}`}
            />
          </div>
          {header}
        </button>
        {badge && badge}
      </div>
      <div className="pt-20 nv-borrowerInfo">{isOpen && content}</div>
    </div>
  );
}
