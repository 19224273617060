import { Link } from 'react-router-dom';
import { AppRoute } from 'utils/constants/ConstantsUtil';

import ReactFluidScroll from 'utils/shared/ReactFluidScroll';
import BridgeImage from 'assets/images/bridgeBG.jpg';
import { GetStartedBanner } from 'components/DisplayComponents';
import Footer from 'components/Footer';
import './styles.scss';

const Bridge = () => {
  return (
    <ReactFluidScroll>
      <section className='bridge_wrap program_wrap'>
        <div className='hero_section'>
          <div className='content_wrap'>
            <div className='text_wrap'>
              <h1 data-aos='fade-up' data-aos-delay='0' data-aos-duration='700'>
                Bridge Financing
              </h1>
              <p data-aos='fade-up' data-aos-delay='0' data-aos-duration='800'>
                When time is of the essence or when a conventional loan doesn’t quite fit your needs, a bridge loan might be the right investment property financing solution. Leveraging robust industry knowledge, proprietary tools, and an array of bridge loan programs, we provide you with the flexibility and support to ensure the best outcome for your investment property.
              </p>
              <Link to={AppRoute.ONBOARDING}>
                <button
                  data-aos='fade-up'
                  data-aos-delay='0'
                  data-aos-duration='900'
                  className='button bv3 purple'>
                  Apply Now
                </button>
              </Link>
            </div>
          </div>
          <div className='hero_image'>
            <img src={BridgeImage} alt='hero' />
          </div>
        </div>
        <div className='details_section_wrap'>
          <div className='content_wrap'>
            <div
              className='detail_item_wrap'
              data-aos='fade-up'
              data-aos-delay='0'
              data-aos-duration='700'>
              <div className='title'>Fix & Flip / Fix & Rent</div>
              <div className='description'>Residential 1-4 Units</div>
              <ul>
                <li>Loan Amount: $300,000 - $5,000,000</li>
                <li>
                  Max Loan to Cost: 85% of Purchase Price & 100% of Rehab Costs
                </li>
                <li>Max Loan to ARV: 70%</li>
                <li>Term Length: Up to 18 Months</li>
              </ul>
            </div>
          </div>
        </div>
        <GetStartedBanner />
      </section>
      <Footer />
    </ReactFluidScroll>
  );
};

export default Bridge;
