import { AnimationGlare } from "components/DisplayComponents";

const LoginSuccess = () => {
  return (
    <div className="input_box_wrapper login_form">
      <AnimationGlare type="spotlight" />
      <div className="box white" data-aos="fade-up" data-aos-delay="0" data-aos-duration="700">
        <div className="title_text">
          <h1>Welcome!</h1>
        </div>
        You are logged in, soon the dashboard will be available
      </div>
    </div>
  );
};

export { LoginSuccess };
