import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  googleAuthAPI,
  registerAPI,
  makeLoginCall,
  resendVerificationCodeAPI,
  confirmEmailAPI,
  forgotPasswordAPI,
  confirmEmailForgotPasswordAPI,
  resetPasswordAPI,
  getAllRolesAPI,
  getUserGrantedRolesAPI,
} from "api/auth.api";

export const googleLogin = createAsyncThunk("auth/google", async (data) => {
  const response = await googleAuthAPI(data);
  return response;
});

export const register = createAsyncThunk("auth/register", async (data) => {
  const response = await registerAPI(data);
  return response;
});

export const loginCall = createAsyncThunk("auth/login", async (data) => {
  const response = await makeLoginCall(data);
  return response;
});

export const resendVerificationCode = createAsyncThunk("auth/verificationCode", async (data) => {
  const response = await resendVerificationCodeAPI(data);
  return response;
});

export const confirmEmail = createAsyncThunk("auth/confirmEmail", async (data) => {
  const response = await confirmEmailAPI(data);
  return response;
});

export const forgotPassword = createAsyncThunk("auth/forgotPassword", async (data) => {
  const response = await forgotPasswordAPI(data);
  return response;
});

export const confirmEmailForgotPassword = createAsyncThunk(
  "auth/confirmEmailForgotPassword",
  async (data) => {
    const response = await confirmEmailForgotPasswordAPI(data);
    return response;
  },
);

export const resetPassword = createAsyncThunk("auth/resetPassword", async (data) => {
  const res = await resetPasswordAPI(data);
  return res;
});

export const getAllRoles = createAsyncThunk("auth/getAllRoles", async () => {
  return await getAllRolesAPI();
});

export const getUserGrantedRoles = createAsyncThunk("auth/getUserGrantedRoles", async () => {
  const userGrantedData = await getUserGrantedRolesAPI();
  localStorage.setItem("user", JSON.stringify(userGrantedData));
  return userGrantedData;
});
const loginSlice = createSlice({
  name: "login",
  initialState: {
    googleLogin: {
      isLoading: false,
      error: null,
      data: [],
    },
    register: {
      isLoading: false,
      error: null,
      data: [],
    },
    userLogin: {
      isLoading: false,
      error: null,
      data: [],
    },
    verificationCodeFrom: {
      isLoading: false,
      error: null,
      data: [],
    },
    confirmEmail: {
      isLoading: false,
      error: null,
      data: [],
    },
    forgotPassword: {
      isLoading: false,
      error: null,
      data: [],
    },
    confirmEmailForgotPassword: {
      isLoading: false,
      error: null,
      data: [],
    },
    resetPassword: {
      isLoading: false,
      error: null,
      data: [],
    },
    allRoles: {
      isLoading: false,
      error: null,
      data: [],
    },
    userRoles: {
      isLoading: false,
      error: null,
      data: [],
    },
  },
  reducers: {
    unsetRegisterError(state) {
      state.register.error = null;
    },
    unsetConfirmEmailError(state) {
      state.confirmEmail.error = null;
    },
    unsetUserLoginError(state) {
      state.userLogin.error = null;
    },
    unsetForgotPasswordError(state) {
      state.forgotPassword.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(googleLogin.pending, (state) => {
        state.googleLogin.isLoading = true;
        state.googleLogin.error = null;
      })
      .addCase(googleLogin.fulfilled, (state, action) => {
        state.googleLogin.isLoading = false;
        state.googleLogin.data = action.payload;
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.googleLogin.isLoading = false;
        state.googleLogin.error = action.error.message;
      })
      .addCase(register.pending, (state) => {
        state.register.isLoading = true;
        state.register.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.register.isLoading = false;
        state.register.data = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.register.isLoading = false;
        state.register.error = action.error.message;
      })
      .addCase(loginCall.pending, (state) => {
        state.userLogin.isLoading = true;
        state.userLogin.error = null;
      })
      .addCase(loginCall.fulfilled, (state, action) => {
        state.userLogin.isLoading = false;
        state.userLogin.data = action.payload;
      })
      .addCase(loginCall.rejected, (state, action) => {
        state.userLogin.isLoading = false;
        state.userLogin.error = action.error.message;
      })
      .addCase(resendVerificationCode.pending, (state) => {
        state.verificationCodeFrom.isLoading = true;
        state.verificationCodeFrom.error = null;
      })
      .addCase(resendVerificationCode.fulfilled, (state, action) => {
        state.verificationCodeFrom.isLoading = false;
        state.verificationCodeFrom.data = action.payload;
      })
      .addCase(resendVerificationCode.rejected, (state, action) => {
        state.verificationCodeFrom.isLoading = false;
        state.verificationCodeFrom.error = action.error.message;
      })
      .addCase(confirmEmail.pending, (state) => {
        state.confirmEmail.isLoading = true;
        state.confirmEmail.error = null;
      })
      .addCase(confirmEmail.fulfilled, (state, action) => {
        state.confirmEmail.isLoading = false;
        state.confirmEmail.data = action.payload;
      })
      .addCase(confirmEmail.rejected, (state, action) => {
        state.confirmEmail.isLoading = false;
        state.confirmEmail.error = action.error.message;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPassword.isLoading = true;
        state.forgotPassword.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPassword.isLoading = false;
        state.forgotPassword.data = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.forgotPassword.isLoading = false;
        state.forgotPassword.error = action.error.message;
      })
      .addCase(confirmEmailForgotPassword.pending, (state) => {
        state.confirmEmailForgotPassword.isLoading = true;
        state.confirmEmailForgotPassword.error = null;
      })
      .addCase(confirmEmailForgotPassword.fulfilled, (state, action) => {
        state.confirmEmailForgotPassword.isLoading = false;
        state.confirmEmailForgotPassword.data = action.payload;
      })
      .addCase(confirmEmailForgotPassword.rejected, (state, action) => {
        state.confirmEmailForgotPassword.isLoading = false;
        state.confirmEmailForgotPassword.error = action.error.message;
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetPassword.isLoading = true;
        state.resetPassword.error = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPassword.isLoading = false;
        state.resetPassword.data = action.payload;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPassword.isLoading = false;
        state.resetPassword.error = action.error.message;
      })
      .addCase(getAllRoles.pending, (state) => {
        state.allRoles.isLoading = true;
        state.allRoles.error = null;
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.allRoles.isLoading = false;
        state.allRoles.data = action.payload;
      })
      .addCase(getAllRoles.rejected, (state, action) => {
        state.allRoles.isLoading = false;
        state.allRoles.error = action.error.message;
      })
      .addCase(getUserGrantedRoles.pending, (state) => {
        state.userRoles.isLoading = true;
        state.userRoles.error = null;
      })
      .addCase(getUserGrantedRoles.fulfilled, (state, action) => {
        state.userRoles.isLoading = false;
        state.userRoles.data = action.payload.data;
      })
      .addCase(getUserGrantedRoles.rejected, (state, action) => {
        state.userRoles.isLoading = false;
        state.userRoles.error = action.error.message;
      });
  },
});

export const {
  unsetRegisterError,
  unsetConfirmEmailError,
  unsetUserLoginError,
  unsetForgotPasswordError,
} = loginSlice.actions;

const persistConfig = {
  key: "login",
  storage: storage,
  whitelist: ["userLogin"], // Specify the slices that should be persisted
};

const persistedReducer = persistReducer(persistConfig, loginSlice.reducer);

export default persistedReducer;
