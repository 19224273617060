import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Close } from "assets/svg/icons/close.svg";
import { ReactComponent as Hamburger } from "assets/svg/icons/Hamburger.svg";
import { ReactComponent as CloseWhite } from "assets/svg/icons/closeWhite.svg";
import { ReactComponent as HamburgerWhite } from "assets/svg/icons/HamburgerWhite.svg";
import { ReactComponent as LogoWhite } from "assets/svg/LogoWhite.svg";
import { ReactComponent as Logo } from "assets/svg/Logo.svg";

import { AppRoute } from "utils/constants/ConstantsUtil";
import DropMenu from "./DropMenu";
// import useCheckMobileScreen from 'utils/hooks/useCheckMobileScreen';
import "./styles.scss";

const Header = () => {
  const [isMobMenuVisible, setIsMobMenuVisible] = useState(false);
  // const isOnMobile = useCheckMobileScreen();

  // const startUpPages = [
  //   AppRoute.REGISTER,
  //   AppRoute.GETRATES,
  //   AppRoute.LOGIN,
  //   AppRoute.CREDIT_SCORE,
  // ];
  // const blackversionWithWhiteBGAlways = [AppRoute.POLICY, AppRoute.AGREEMENT];
  // const blackVersionRoutes = [
  //   ...startUpPages,
  //   ...blackversionWithWhiteBGAlways,
  //   AppRoute.COMPANY,
  //   AppRoute.ONBOARDING,
  //   AppRoute.BRIDGE,
  //   AppRoute.CONSTRUCTION,
  //   AppRoute.INVESTOR,
  //   AppRoute.RENTAL,
  // ];
  // const transparentVersionRoutes = AppRoute.ROOT;

  // const blackWhiteVersionRoutes = [...startUpPages];

  const location = useLocation();
  const [isScrolled, setScrolled] = useState(false);
  const isBlackVersion = true; //blackVersionRoutes.includes(location.pathname);
  const isWhiteBG = false; //blackversionWithWhiteBGAlways.includes(location.pathname);
  const isStartUpPages = false; //startUpPages.includes(location.pathname);
  const isBlackWhiteVersion = false; //blackWhiteVersionRoutes.includes(location.pathname);
  const isTransparentVersion = false; //transparentVersionRoutes.includes(location.pathname);
  const isOnboardingPage = useLocation().pathname === "/onboarding";

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const updateScrolledBoolean = () => {
    let headerOffset = 0;
    setScrolled(window.scrollY > headerOffset);
  };

  const goToContact = () => {
    window.scrollTo(0, document.body.scrollHeight);
    setIsMobMenuVisible(false);
  };

  useEffect(() => {
    //scroll to top
    window.scroll(0, 0);
    setIsMobMenuVisible(false);
  }, [location.pathname]);

  useEffect(() => {
    updateScrolledBoolean();

    document.addEventListener("scroll", updateScrolledBoolean);

    return () => {
      document.removeEventListener("scroll", updateScrolledBoolean);
    };
  }, []);

  return (
    <header
      onClick={() => setIsMobMenuVisible((prev) => !prev)}
      className={`${isScrolled || isMobMenuVisible ? " scrolled_header " : ""}
      ${isBlackVersion ? " black_version " : ""} 
      ${isWhiteBG ? " white_background " : ""} 
       ${isBlackWhiteVersion ? " black_white_version" : ""}
       ${isTransparentVersion ? " transparent_version" : ""}
      `}
    >
      <div
        className={`content_wrap ${
          (isTransparentVersion && isScrolled) ||
          (isTransparentVersion && isMobMenuVisible)
            ? "transparent_scrolled"
            : ""
        }`}
      >
        <Link
          to={AppRoute.ROOT}
          onClick={scrollToTop}
          className={`logo_wrap ${
            (isTransparentVersion && isScrolled) ||
            (isTransparentVersion && isMobMenuVisible)
              ? "transparent_scrolled"
              : ""
          }`}
        >
          {isTransparentVersion && !isScrolled && !isMobMenuVisible ? (
            <LogoWhite />
          ) : (
            <Logo />
          )}
        </Link>
        {!isBlackWhiteVersion && (
          <div
            className={`links_wrap ${isMobMenuVisible ? "is_mob_visible" : ""}`}
          >
            {!isOnboardingPage && (
              <>
                <div className="main_link greyed financing_wrap">
                  <span>Financing</span>
                  <DropMenu onClose={() => setIsMobMenuVisible(false)} />
                  {/* <div className='sub_links_wrap'>
                    <Link className='sub_link' to={AppRoute.RENTAL}>
                      Rental
                    </Link>
                    <Link className='sub_link' to={AppRoute.BRIDGE}>
                      Bridge
                    </Link>
                    <Link className='sub_link' to={AppRoute.CONSTRUCTION}>
                      Construction
                    </Link>
                    <Link className='sub_link' to={AppRoute.INVESTOR}>
                      Investor Second Mortgage
                    </Link>
                    <div className='sub_link' onClick={goToContact}>
                      Looking for Something Else
                    </div>
                  </div> */}
                </div>
                <div className="main_link" onClick={goToContact}>
                  <span>Contact</span>
                </div>
              </>
            )}
            {!isStartUpPages && (
              <div className="buttons_wrap">
                <a href={AppRoute.LOGIN}>
                  <button className="simpelest blue small button">Login</button>
                </a>
                {!isOnboardingPage && (
                  <Link to={AppRoute.ONBOARDING}>
                    <button className="simpelest purple small button">
                      Get Started
                    </button>
                  </Link>
                )}
              </div>
            )}
          </div>
        )}
        {!isBlackWhiteVersion && !isTransparentVersion && (
          <button
            className="white square hamburger"
            onClick={() => setIsMobMenuVisible(!isMobMenuVisible)}
          >
            {isMobMenuVisible ? <Close /> : <Hamburger />}
          </button>
        )}
        {((isTransparentVersion && !isScrolled) ||
          (isTransparentVersion && !isMobMenuVisible)) && (
          <button
            className="white square hamburger"
            onClick={() => setIsMobMenuVisible(!isMobMenuVisible)}
          >
            {isMobMenuVisible ? <CloseWhite /> : <HamburgerWhite />}
          </button>
        )}
        {((isTransparentVersion && isScrolled) ||
          (isTransparentVersion && isMobMenuVisible)) && (
          <button
            className="white square hamburger"
            onClick={() => setIsMobMenuVisible(!isMobMenuVisible)}
          >
            {isMobMenuVisible ? <Close /> : <Hamburger />}
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
