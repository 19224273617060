import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderQuestion, setShowNext, setSendData, setEstimatedValue } from "../onboardingSlice";
import Slider from "pages/OnBoarding/Components/Slider/Slider";

const Step20 = () => {
  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();
  let { EstimatedValue } = useSelector((state) => state.onboarding.body.form);
  const { postOnboarding } = useSelector((state) => state.onboarding.body);
  const question = "What is the estimated value of the property";

  EstimatedValue =
    EstimatedValue === null ? null : Number(EstimatedValue?.toString().replaceAll(",", ""));

  useEffect(() => {
    if (EstimatedValue !== null) {
      if (
        !/^\d+$/.test(EstimatedValue) ||
        Number(EstimatedValue) < 300000 ||
        Number(EstimatedValue) > 5000000
      ) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [EstimatedValue]);
  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);

  useEffect(() => {
    if (EstimatedValue !== null && isValid) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ EstimatedValue }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [EstimatedValue, dispatch, postOnboarding, isValid]);

  const updateForm = useCallback(
    (val) => {
      dispatch(setEstimatedValue(Number(val?.toString().replaceAll(",", ""))));
    },
    [dispatch],
  );

  return (
    <Slider
      dollar={true}
      min={300000}
      max={5000000}
      interval={EstimatedValue && EstimatedValue < 1000000 ? 10000 : 25000}
      sliderVal={EstimatedValue}
      updateForm={updateForm}
      isValid={isValid}
    />
  );
};
export default Step20;
