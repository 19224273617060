import { Navigate } from "react-router-dom";

const ProtectedRoute = ({
  children,
  validateLogin = false,
  validateRole = true,
  requiredRoles,
}) => {
  const isLoggedIn = localStorage.getItem("AccessToken");
  const userRoles = [JSON.parse(localStorage.getItem("user"))?.accessType];

  if (validateRole && !userRoles.some((role) => requiredRoles.includes(role))) {
    return <Navigate to="/login" />;
  }

  if (validateLogin && !isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
