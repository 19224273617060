import React, { useRef, useState } from "react";
import InputMask from "react-input-mask";
import { ReactComponent as EyeIcon } from "assets/svg/icons/eye.svg";

import "./styles.css";

const Input = ({
  label,
  type,
  name,
  register,
  required,
  disabled,
  errorMessage,
  value = "",
  firstCurrencyValue,
  placeholder = "",
  customOnChange = null,
  setPassword,
  setPasswordFocus,
  hasOptional = true,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [currencyValue, setCurrencyValue] = useState(firstCurrencyValue);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeCurrency = (e) => {
    const value = e.target.value;

    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));

    if (!isNaN(numericValue)) {
      const formattedValue = `$${numericValue.toLocaleString()}`;
      setCurrencyValue(formattedValue);
    } else {
      setCurrencyValue(value);
    }
  };

  const handleChangeLTV = (e) => {
    const value = e.target.value;

    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));

    if (!isNaN(numericValue)) {
      const formattedValue = `${numericValue.toLocaleString()}%`;
      setCurrencyValue(formattedValue);
    } else {
      setCurrencyValue(value);
    }
  };

  const handleKeyDownCurrency = (e) => {
    const keyCode = e.keyCode || e.which;

    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || keyCode === 8)) {
      e.preventDefault();
    }
  };

  const handleKeyDownLTV = (e) => {
    const keyCode = e.keyCode || e.which;
    const inputValue = e.target.value;

    if (keyCode === 8) {
      if (inputValue.endsWith("%")) {
        e.target.value = inputValue.slice(0, -1);
      } else if (!/^\d*\.?\d*$/.test(inputValue)) {
        e.preventDefault();
      }
    } else if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105))) {
      e.preventDefault();
    }
  };

  const handleChangePassword = (e) => {
    if (setPassword) {
      setPassword(e.target.value);
    }
  };

  const handlePasswordFocus = () => {
    if (setPasswordFocus) {
      setPasswordFocus(true);
    }
  };

  const handleBlur = () => {
    if (setPasswordFocus) {
      setPasswordFocus(false);
    }
  };

  return (
    <div className="inputContainer">
      <div className="inputContainer-labelRow">
        <label htmlFor={name} className={`inputLabel ${disabled ? "disabledInputFormLabel" : ""}`}>
          {label}
        </label>
        {!required && !disabled && hasOptional && (
          <span className="inputContainerOptional">optional</span>
        )}
      </div>

      {type && type === "phone" ? (
        <InputMask
          mask="(999) 999-9999"
          maskChar={null}
          {...(register && register(name, { required }))}
        >
          {(inputProps) => <input {...inputProps} type={type} className="input outline-none" />}
        </InputMask>
      ) : type === "passwordWithValidation" ? (
        <div className="passwordInputContainer">
          <input
            type={showPassword ? "text" : "password"}
            name={name}
            className={`input outline-none ${disabled ? "disabledIputForm" : ""} ${
              errorMessage ? "inputErrorMode" : ""
            }`}
            {...(register && register(name))}
            onChange={handleChangePassword}
            onFocus={handlePasswordFocus}
            onBlur={handleBlur}
          />
          {!disabled && (
            <button
              type="button"
              className="togglePasswordButton"
              onClick={togglePasswordVisibility}
            >
              <EyeIcon />
            </button>
          )}
        </div>
      ) : type === "password" ? (
        <div className="passwordInputContainer">
          <input
            type={showPassword ? "text" : "password"}
            name={name}
            className={`input outline-none ${disabled ? "disabledIputForm" : ""} ${
              errorMessage ? "inputErrorMode" : ""
            }`}
            {...(register && register(name))}
          />
          {!disabled && (
            <button
              type="button"
              className="togglePasswordButton"
              onClick={togglePasswordVisibility}
            >
              <EyeIcon />
            </button>
          )}
        </div>
      ) : type === "currency" ? (
        <input
          type={type ? type : "number"}
          name={name}
          placeholder="$0"
          className={`input outline-none ${disabled ? "disabledIputForm" : ""} ${
            errorMessage ? "inputErrorMode" : ""
          }`}
          value={value ? value : currencyValue}
          onChange={handleChangeCurrency}
          onKeyDown={handleKeyDownCurrency}
          {...(register &&
            register(name, {
              onChange: customOnChange ? customOnChange : handleChangeCurrency,
            }))}
        />
      ) : type === "percentage" ? (
        <input
          type={type ? type : "number"}
          name={name}
          placeholder="0%"
          className={`input outline-none ${disabled ? "disabledIputForm" : ""} ${
            errorMessage ? "inputErrorMode" : ""
          }`}
          value={value ? value : currencyValue}
          onChange={handleChangeLTV}
          onKeyDown={handleKeyDownLTV}
          {...(register &&
            register(name, {
              onChange: customOnChange ? customOnChange : handleChangeLTV,
            }))}
        />
      ) : (
        <input
          type={type ? type : "text"}
          name={name}
          placeholder={placeholder}
          className={`input outline-none ${disabled ? "disabledIputForm" : ""} ${
            errorMessage ? "inputErrorMode" : ""
          }`}
          {...(register && register(name))}
        />
      )}
      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
    </div>
  );
};

export default Input;
