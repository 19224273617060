import { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
// import { ReactComponent as EditIcon } from "assets/svg/icons/edit-001.svg";
import "./style.css";

const Dropdown = ({
  showPopper = false,
  handlePopper,
  clickHandler,
  disabled = false,
  edit = true,
  emptyState = true,
  loanId,
  label,
  maxVisibleItems = 10,
  children,
  listClassName = "",
  className = "",
  listContainer = "listcontainer",
  placement = "bottom-end",
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [popperHeight, setPopperHeight] = useState(null);
  const popperRef = useRef(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -10],
        },
      },
    ],
  });

  const handleClick = () => {
    clickHandler(loanId);
    handlePopper();
  };

  useEffect(() => {
    if (popperRef.current) {
      const liElements = popperRef.current.querySelectorAll("li");
      const visibleItems = Math.min(liElements.length, maxVisibleItems);
      const itemHeight = liElements[0]?.getBoundingClientRect().height || 0;

      setPopperHeight(visibleItems * itemHeight);
    }
  }, [showPopper, maxVisibleItems]);

  return (
    <>
      <div
        className={`${edit && !disabled ? "cursorPointer" : "cursorDisabled"} ${className}`}
        onClick={() => (!disabled ? handlePopper() : null)}
        ref={setReferenceElement}
      >
        {label}
      </div>
      {showPopper && edit && (
        <ClickAwayListener onClickAway={() => handlePopper(false)}>
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className={`mt-16 listwrapper ${listClassName}`}
          >
            <ul
              style={{ maxHeight: `${popperHeight + 10}px` ?? 0 }}
              ref={popperRef}
              className={`${listContainer}`}
            >
              {!children
                ? emptyState && (
                    <li
                      className="listitem-template archiveColor cursorPointer"
                      onClick={() => handleClick()}
                    >
                      Archive
                    </li>
                  )
                : children}
            </ul>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};

export default Dropdown;
