import { Close } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

const RedIconButton = styled(IconButton)({
  "&:hover": {
    backgroundColor: "rgba(255, 0, 0, 0.1)", // Red with a bit of transparency for ripple effect
    transform: "scale(0.8)", // Decrease the size when hovered
  },
  "&:focus": {
    backgroundColor: "rgba(255, 0, 0, 0.1)", // Also apply ripple effect when focused
    transform: "scale(0.8)", // Decrease the size when hovered
  },
});
const Card = ({ text, onClick, showX = true }) => {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <p className="categoryContainer-input bulk-input">{text}</p>
      {showX ? (
        <RedIconButton onClick={onClick}>
          <Close sx={{ fontSize: 14 }} />
        </RedIconButton>
      ) : null}
    </Stack>
  );
};

export default Card;
