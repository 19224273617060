import "./styles.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Login } from "pages/StartUpPage/Sections/Login";
import { CreditScore } from "pages/StartUpPage/Sections/CreditScore";
import { GetRates } from "pages/StartUpPage/Sections/GetRates";
import { OnboardingFinish } from "pages/StartUpPage/Sections/OnboardingFinish";
import { AppRoute } from "utils/constants/ConstantsUtil";
import { LoginSuccess } from "./Sections/LoginSuccess";

const StartUpPage = ({ type }) => {
  let FormComponent = Login;

  switch (type) {
    case AppRoute.LOGIN:
      FormComponent = Login;
      break;
    case AppRoute.LOGINSUCCESS:
      FormComponent = LoginSuccess;
      break;
    case AppRoute.REGISTER:
      FormComponent = null;
      break;
    case AppRoute.GETRATES:
      FormComponent = GetRates;
      break;
    case AppRoute.CREDIT_SCORE:
      FormComponent = CreditScore;
      break;
    case AppRoute.ONBOARDINGFINISH:
      FormComponent = OnboardingFinish;
      break;

    default:
      break;
  }

  return (
    <section className="start_up_pages_wrap">
      <div className="start_up_main_wrap input_box_view">
        <FormComponent />
      </div>
    </section>
  );
};

export default StartUpPage;
