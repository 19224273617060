import Dropdown from "../Dropdown/Dropdown";
import { usePopper } from "../../hooks/usePopper";
import DetailsButton from "../Button/DetailsButton";
import { ReactComponent as ChevronDownIcon } from "assets/svg/icons/chevron-down.svg";
import { useEffect } from "react";
import { getLoanStatusesApi, updateLoanStatusApi } from "../../loanDetailSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import "./styles.scss";

const ARCHIVED_STATUS = "ARCHIVED";

const StatusLoanDropdown = ({ status: statusId, label = "" }) => {
  const [showPopper, handlePopper] = usePopper();
  const dispatch = useDispatch();

  const { loanId } = useParams();

  const {
    loanStatusesData: { data: statuses },
    testLoanDetailData: { isLoading },
    updateLoanStatusData: { isLoading: isLoadingUpdate },
  } = useSelector(({ loanDetail }) => loanDetail);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getLoanStatusesApi()).unwrap();
    };
    const timeout = setTimeout(fetchData, 10);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const clearedStatuses =
    (statuses?.length &&
      statuses.filter(
        (status) =>
          status.name.toLowerCase() !== ARCHIVED_STATUS.toLowerCase() && status.id !== statusId,
      )) ||
    [];

  const background = (status) =>
    ({
      1: "bg-red",
      2: "bg-orange",
      3: "bg-orange",
      4: "bg-blue",
      5: "bg-green",
      6: "bg-gray",
      7: "bg-red",
      default: "bg-red",
    })[status ?? "default"];

  const handleStatusChange = (id) => {
    handlePopper(false);

    const status = {
      1: "DocsNeeded",
      2: "Review",
      3: "Lead",
      4: "ClearToClose",
      5: "Closed",
      6: "Archived",
      7: "PendingCancellation",
    }[id];

    dispatch(updateLoanStatusApi({ id: loanId, status: status }));
  };

  const isDisabled = label.toLowerCase() === ARCHIVED_STATUS.toLowerCase();

  return isLoading || isLoadingUpdate ? (
    <Skeleton count={1} height={30} style={{ width: "150px" }} />
  ) : (
    <Dropdown
      disabled={isDisabled}
      isLoading={true}
      showPopper={showPopper}
      handlePopper={handlePopper}
      listContainer="templatelistcontainer"
      maxVisibleItems={5}
      listClassName="mt-6"
      label={
        <DetailsButton
          disabled={isDisabled}
          startIcon={<span className={`${background(statusId)} dot`} />}
          label={label}
          endIcon={isDisabled ? null : <ChevronDownIcon />}
        />
      }
    >
      {clearedStatuses.map(({ id, name }) => (
        <li
          key={id}
          onClick={() => handleStatusChange(id)}
          className="listitem-template archiveColor cursorPointer d-flex align-items-center gap-8"
        >
          <span className={`${background(id)} dot`} />
          <p>{name}</p>
        </li>
      ))}
    </Dropdown>
  );
};

export default StatusLoanDropdown;
