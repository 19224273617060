import React from "react";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers";
import { ReactComponent as CalendarIcon } from "assets/svg/icons/calendar.svg";
import { Controller } from "react-hook-form";

const DatePicker = ({
  disableFuture = true,
  name,
  control,
  label,
  required = false,
  maxDate,
  hasOptional = true,
}) => {
  return (
    <div className="inputContainer">
      <div className="inputContainer-labelRow">
        <label htmlFor={name} className={`inputLabel ${required ? "required" : ""}`}>
          {label}
        </label>

        {!required && hasOptional && <span className="inputContainerOptional">optional</span>}
      </div>
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        defaultValue={null}
        render={({ field }) => (
          <MUIDatePicker
            {...field}
            maxDate={maxDate}
            disableFuture={disableFuture}
            slots={{
              openPickerIcon: CalendarIcon,
            }}
            views={["year", "day"]}
            slotProps={{
              textField: {
                size: "small",
                variant: "standard",
                readOnly: true,
                InputProps: {
                  disableUnderline: true,
                },
                sx: {
                  width: "100%",
                  padding: "11px 13px",
                  borderRadius: "12px",
                  border: "1px solid  #d6d6d6",
                  fontFamily: "Indivisible",
                  fontWeight: 500,
                  ":focus-within": {
                    boxShadow: "0px 0px 0px 4px #f4ebff",
                    border: "1px solid #d6bbfb",
                  },
                },
              },
            }}
          />
        )}
      />
    </div>
  );
};

export default DatePicker;
