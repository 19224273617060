import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';

import Slider1 from 'assets/images/Admin/slider-1.jpg';
import Slider2 from 'assets/images/Admin/slider-2.jpg';
import Slider3 from 'assets/images/Admin/slider-3.jpg';
import { AnimationGlare } from 'components/DisplayComponents';
import { TextInput, MainSearchBox } from 'components/InputElements';
import { SlideItem } from 'components/DisplayComponents';
import { AppRoute } from 'utils/constants/ConstantsUtil';

const GetRates = () => {
  const [email, setEmail] = useState('');
  let emailValidation = /[a-z0-9]+@[a-z]+.[a-z]{2,3}/;

  return (
    <>
      <div className='animation_glare_layout'>
        <AnimationGlare type='spotlight' />
      </div>
      <div
        className='input_box_wrapper register_form'
        data-aos='fade-up'
        data-aos-delay='0'
        data-aos-duration='700'>
        <div className='box white'>
          <div className='title_text'>Few more things</div>
          <div className='login_input grey_text'>
            <div className='search_box_wrap'>
              <label>Where are you looking?</label>
              <MainSearchBox placeholder='90001' isNormalSize />
            </div>
            <TextInput
              label='How do we reach you?'
              placeholder='Enter email address'
              autoComplete={'off'}
              type={'Email'}
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </div>
          <div className='buttons_wrap'>
            <Link to={AppRoute.ROOT}>
              <button className='button back'>Back</button>
            </Link>
            <button
              className='button colored '
              disabled={!emailValidation.test(email)}>
              Show Rates
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const GetRatesSlides = () => {
  const slideData = [
    {
      title: 'Technology and Human Intelligence As One',
      description:
        'Benefit from Battalion’s  machine learning algorithms to identify opportunities to finance or refinance your assets and acquire new properties. Our proprietary algorithms help you optimize your portfolio with an outstanding trade-off between risks and returns on investments.',
      image: Slider1,
    },
    {
      title: 'Easy and Hassle-Free',
      description:
        'Whether you’re a seasoned or first-time real estate investor, Battalion provides you with the capital, technology, and unmatched in-house expertise to make the process as hassle-free as possible',
      image: Slider2,
    },
    {
      title: 'Interest Rates As Low As 5.8% For Bridge Loan',
      description:
        'With interest rates as low as 8.99% for your bridge loan projects and 5.875% for your rental properties, we’ve got you covered.',
      image: Slider3,
    },
  ];
  return (
    <Carousel
      className='black-dots'
      autoPlay={true}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      interval={10000}
      transitionTime={400}
      infiniteLoop={true}>
      {slideData.map((item, key) => (
        <SlideItem
          key={key}
          className={'black'}
          image={item.image}
          title={item.title}
          description={item.description}
        />
      ))}
    </Carousel>
  );
};

export { GetRates, GetRatesSlides };
