import React from "react";
import "./styles.css";
const Status = ({ type, label }) => {
  return (
    <div className={` statusContainer statusContainer-${type}`}>
      <p className={`statusContainer-label statusContainer-label-${type}`}>
        {label}
      </p>
    </div>
  );
};

export default Status;
