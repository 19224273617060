import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputText from "../Components/InputText";

import {
  setHeaderQuestion,
  setPassword,
  setPasswordAgain,
  setPasswordValidity,
  setPasswordAgainValidity,
  setShowBack,
  setShowConfirmPassword,
} from "../onboardingSlice";

const UpdatePasswords = () => {
  const [passwordIsFocus, setPasswordIsFocus] = useState(false);
  const [passwordAgainIsFocus, setPasswordAgainIsFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [passwordAgainIsValid, setPasswordAgainIsValid] = useState(true);

  const dispatch = useDispatch();
  const header = "Set New Password";

  const { password, passwordAgain } = useSelector((state) => state.onboarding.body.form);

  useEffect(() => {
    dispatch(setHeaderQuestion(header));
  }, [dispatch, header]);
  useEffect(() => {
    if (password !== null && password.length > 0) {
      if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(password)) {
        setPasswordIsValid(false);
        dispatch(setPasswordValidity(false));
      } else {
        setPasswordIsValid(true);
        dispatch(setPasswordValidity(true));
      }
    }
  }, [password, dispatch]);

  useEffect(() => {
    if (passwordAgain !== null && passwordAgain.length > 0) {
      if (
        !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(passwordAgain) ||
        password !== passwordAgain
      ) {
        setPasswordAgainIsValid(false);
        dispatch(setPasswordAgainValidity(false));
      } else {
        setPasswordAgainIsValid(true);
        dispatch(setPasswordAgainValidity(true));
      }
    }
  }, [passwordAgain, password, dispatch]);

  useEffect(() => {
    dispatch(setShowConfirmPassword(true));
    dispatch(setShowBack(true));

    return () => {
      dispatch(setShowConfirmPassword(false));
      dispatch(setShowBack(false));
    };
  }, [dispatch]);

  const updatePassword = (val) => {
    dispatch(setPassword(val));
  };
  const updatePasswordAgain = (val) => {
    dispatch(setPasswordAgain(val));
  };

  const handleFocusPassword = (val) => {
    setPasswordIsFocus(val);
  };
  const handleFocusPasswordAgain = (val) => {
    setPasswordAgainIsFocus(val);
  };

  return (
    <div className="createAccountContainer">
      <div className="row">
        <InputText
          placeholder={"Password"}
          updateForm={updatePassword}
          focus={passwordIsFocus}
          handleFocus={handleFocusPassword}
          label="Password"
          isValid={passwordIsValid}
          val={password}
          password={!showPassword}
          dynamic={true}
          showPassword={showPassword}
          toggleShowPassword={() => setShowPassword(!showPassword)}
        />
        <InputText
          placeholder={"Confirm Password"}
          updateForm={updatePasswordAgain}
          focus={passwordAgainIsFocus}
          handleFocus={handleFocusPasswordAgain}
          label="Confirm Password"
          isValid={passwordAgainIsValid}
          val={passwordAgain}
          password={!showConfirmPassword}
          dynamic={true}
          showPassword={showConfirmPassword}
          toggleShowPassword={() => setShowConfirmPassword(!showConfirmPassword)}
        />
      </div>
    </div>
  );
};

export default UpdatePasswords;
