// import { Button as MuiButton } from "@mui/material";

// export const Button = ({
//   startIcon = null,
//   label,
//   onClick = null,
//   type = "contained",
//   disabled = false,
// }) => {
//   return (
//     <MuiButton
//       variant={type}
//       startIcon={startIcon}
//       style={
//         type === "contained"
//           ? {
//               backgroundColor: " #A459FF",
//               border: "1px solid  #A459FF",
//               padding: "0.711em 1.141em",
//               borderRadius: "2em",
//               boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
//               fontFamily: "Indivisible",
//               fontStyle: "normal",
//               fontWeight: "600",
//               fontSize: "0.875rem",
//               lineHeight: "1.25rem",
//               letterSpacing: "-0.04rem",
//               textTransform: "none",
//             }
//           : {
//               fontFamily: "Indivisible",
//               fontStyle: "normal",
//               fontWeight: "600",
//               fontSize: "0.875rem",
//               lineHeight: "1.25rem",
//               letterSpacing: "-0.04rem",
//               color: "#A459FF",
//               textTransform: "none",
//               border: "0px solid",
//               padding: "8px 14px",
//             }
//       }
//       onClick={onClick}
//       disabled={disabled}
//     >
//       {label}
//     </MuiButton>
//   );
// };

// export default Button;
import { Button as MuiButton } from "@mui/material";

export const Button = ({
  startIcon = null,
  backgroundColor = "#A459FF",
  borderColor = "#A459FF",
  colorTextEnabled = "white",
  label,
  onClick = null,
  hasBorder = true,
  type = "contained",
  disabled = false,
  width = "",
}) => {
  return (
    <MuiButton
      variant={type}
      startIcon={startIcon}
      sx={{
        "&.Mui-disabled": {
          background: "#e9d7fe !important",
          color: "#ffffff !important",
          border: "1px solid #e9d7fe !important",
        },
      }}
      style={
        type === "contained"
          ? {
              width: width,
              backgroundColor: backgroundColor,
              border: `1px solid ${borderColor}`,
              padding: "0.711em 1.141em",
              borderRadius: hasBorder && "32px",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              fontFamily: "Indivisible",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              letterSpacing: "-0.04rem",
              textTransform: "none",
              color: disabled ? "gray" : colorTextEnabled, // Change text color when disabled
              pointerEvents: disabled ? "none" : "auto", // Disable pointer events when disabled
              opacity: disabled ? 0.5 : 1, // Reduce opacity when disabled
            }
          : {
              fontFamily: "Indivisible",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              letterSpacing: "-0.04rem",
              color: "#A459FF",
              textTransform: "none",
              border: "0px solid",
              padding: "8px 14px",
              // pointerEvents: disabled ? "none" : "auto",
              // opacity: disabled ? 0.5 : 1,
            }
      }
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </MuiButton>
  );
};

export default Button;
