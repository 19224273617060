import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTestLoanDetail,
  getTestLoanDocuments,
  testUploadLoanDocumentFile,
  postTestLoanTemplate,
  getLoanDocumentList,
  getCategoryList,
  getTestDownloadLoanDocumentFile,
  deleteTestLoanDocumentFile,
  postLoanAddDocument,
  getDocumentAutoComplete,
  testBulkDeleteLoanDocumentFile,
  testDownloadAsZip,
  getLoanAccessControl,
  testDownloadLoanDocumentAsZip,
  getLoanStatuses,
  getAccessControlRoles,
  getUserAccessControlAutoComplete,
  postLoanSaveAccessControlChanges,
  postLoanNext,
  postLoanSaveComplete,
  getAccessTypeApi,
  getPropertyTypes,
  getPropertyConditions,
  getOccupancyTypes,
  getPeriodTypes,
  getTransactionTypes,
  updateLoan,
  uploadLoanDocumentFileViewer,
  deleteDocumentLoanDetailsSidePanelApi,
  postDocumentLoanDetailsSidePanelApi,
} from "api/loan.api";

import { updateLoanStatus } from "api/borrower.api";

export const getPropertyTypesApi = createAsyncThunk(
  "loanDetail/getPropertyTypes",
  async () => await getPropertyTypes(),
);

export const getPropertyConditionsApi = createAsyncThunk(
  "loanDetail/getPropertyConditions",
  async () => await getPropertyConditions(),
);

export const updateLoanApi = createAsyncThunk(
  "loanDetail/updateLoan",
  async (data) => await updateLoan(data),
);

export const getOccupancyTypesApi = createAsyncThunk(
  "loanDetail/getOccupancyTypes",
  async () => await getOccupancyTypes(),
);

export const getPeriodTypesApi = createAsyncThunk(
  "loanDetail/getPeriodTypes",
  async () => await getPeriodTypes(),
);

export const getTransactionTypesApi = createAsyncThunk(
  "loanDetail/getTransactionTypes",
  async () => await getTransactionTypes(),
);

export const getTestLoanDetailApi = createAsyncThunk("loanDetail/getLoanDetail", async (id) => {
  const res = await getTestLoanDetail(id);
  return res;
});

export const getTestLoanDocumentsApi = createAsyncThunk(
  "loanDetail/getLoanDocuments",
  async (id, { getState }) => {
    const { currentTab } = getState().loanDetail;
    const res = await getTestLoanDocuments(id, {
      params: { status: currentTab },
    });
    return res;
  },
);

export const testUploadLoanDocumentFileApi = createAsyncThunk(
  "loanDetail/uploadLoanDocumentFile",
  async ({ loanId, data }, { getState }) => {
    const { currentTab } = getState().loanDetail;

    await testUploadLoanDocumentFile(data);

    const res = await getTestLoanDocuments(loanId, {
      params: { status: currentTab },
    });

    return res;
  },
);

export const testUploadLoanDocumentFileViewerApi = createAsyncThunk(
  "loanDetail/uploadLoanDocumentFileViewer",
  async ({ loanId, data }, { getState }) => {
    const { currentTab } = getState().loanDetail;

    await uploadLoanDocumentFileViewer(data);

    const res = await getTestLoanDocuments(loanId, {
      params: { status: currentTab },
    });

    return res;
  },
);

export const postTestLoanTemplateApi = createAsyncThunk(
  "loanDetail/postLoanTemplate",
  async (data) => {
    await postTestLoanTemplate(data);

    const res = await getTestLoanDocuments(data.loanId);
    return res;
  },
);

export const getLoanDocumentListApi = createAsyncThunk(
  "loanDetail/getLoanDocumentList",
  async (id) => {
    const res = await getLoanDocumentList(id);
    return res;
  },
);
export const getCategoryListApi = createAsyncThunk("loanDetail/getCategoryList", async () => {
  return await getCategoryList();
});

export const testDownloadAsZipApi = createAsyncThunk(
  "loanDetail/downloadAsZip",
  async (_, { getState }) => {
    const { loanDetail } = getState();
    const res = await testDownloadAsZip(loanDetail.loanDocumentSelectedFiles);
    const url = window.URL.createObjectURL(res);
    const link = document.createElement("a");

    link.download = "files";
    link.href = url;
    link.click();
    link.remove();
  },
);

export const getTestDownloadLoanDocumentFileApi = createAsyncThunk(
  "loanDetail/downloadFile",
  async ({ fileId, fileName }) => {
    const res = await getTestDownloadLoanDocumentFile(fileId);
    const url = window.URL.createObjectURL(res);
    const link = document.createElement("a");

    link.href = url;
    link.download = fileName;
    link.click();
    link.remove();
  },
);

export const testDownloadLoanDocumentAsZipApi = createAsyncThunk(
  "loanDetail/downloadLoanDocumentFiles",
  async ({ loanDocumentId, fileName }) => {
    const res = await testDownloadLoanDocumentAsZip(loanDocumentId);
    const url = window.URL.createObjectURL(res);
    const link = document.createElement("a");

    link.href = url;
    link.download = fileName;
    link.click();
    link.remove();
  },
);

export const deleteTestLoanDocumentFileApi = createAsyncThunk(
  "loanDetail/deleteLoanDocumentFile",
  async (data) => {
    await deleteTestLoanDocumentFile(data.fileId);

    const res = await getTestLoanDocuments(data.loanId);
    return { res, file: data.fileId };
  },
);

export const testBulkDeleteLoanDocumentFileApi = createAsyncThunk(
  "loanDetail/testBulkDeleteLoanDocumentFile",
  async ({ loanDocumentFileIds, loanId }) => {
    await testBulkDeleteLoanDocumentFile(loanDocumentFileIds);

    const res = await getTestLoanDocuments(loanId);
    return { res, files: loanDocumentFileIds };
  },
);

export const postLoanAddDocumentApi = createAsyncThunk(
  "loanDetail/postLoanAddDocument",
  async (data) => {
    return await postLoanAddDocument(data);
  },
);

export const getDocumentListAutoCompleteApi = createAsyncThunk(
  "loanDetail/documentAutoComplete",
  async (data) => {
    const res = await getDocumentAutoComplete({ params: { ...data } });
    return res;
  },
);

export const getUserAccessControlAutoCompleteApi = createAsyncThunk(
  "loanDetail/userAccessControlAutoComplete",
  async (data) => {
    const res = await getUserAccessControlAutoComplete({ params: { ...data } });
    return res;
  },
);

export const getTestLoanAccessControlApi = createAsyncThunk(
  "loanDetail/loanAccessControl",
  async (id) => {
    const res = await getLoanAccessControl(id);
    return res;
  },
);

export const postLoanNextApi = createAsyncThunk("loanDetail/postLoanNext", async (data) => {
  const res = await postLoanNext(data);
  return res;
});

export const postLoanSaveCompleteApi = createAsyncThunk(
  "loanDetail/postLoanSaveComplete",
  async (data) => {
    const res = await postLoanSaveComplete(data);
    return res;
  },
);

export const getLoanStatusesApi = createAsyncThunk("loanDetail/loanStatuses", async () => {
  const res = await getLoanStatuses();
  return res;
});

export const updateLoanStatusApi = createAsyncThunk(
  "borrowerDetail/updateLoanStatus",
  async (data) => {
    await updateLoanStatus(data.id, data.status);

    const res = await getTestLoanDetail(data.id);
    return res;
  },
);

export const getAccessControlRolesApi = createAsyncThunk(
  "loanDetail/accessControlRoles",
  async () => {
    const res = await getAccessControlRoles();
    return res;
  },
);

export const postLoanSaveAccessControlChangesApi = createAsyncThunk(
  "loanDetail/saveAccessControlChanges",
  async (data) => {
    await postLoanSaveAccessControlChanges(data);

    const res = await getLoanAccessControl(data.loanId);
    return res;
  },
);

export const getAccessType = createAsyncThunk("loanDetail/AccessTypessss", async (id) => {
  return await getAccessTypeApi(id);
});

export const addAccessControlLoan = createAsyncThunk(
  "loanDetail/addAccessControlLoan",
  async (data) => await postLoanSaveAccessControlChanges(data),
);

export const deleteDocumentLoanDetailsSidePanel = createAsyncThunk(
  "loanDetail/DeleteDocument",
  async (id) => {
    return await deleteDocumentLoanDetailsSidePanelApi(id);
  },
);

export const postDocumentLoanDetailsSidePanel = createAsyncThunk(
  "loanDetail/addUpdateNewDocument",
  async (data) => {
    return await postDocumentLoanDetailsSidePanelApi(data);
  },
);

const initialState = {
  currentTab: "requiresAttention",
  loanDocumentSelectedFiles: [],
  deleteFileId: null,
  documentsToShow: [],
  testLoanDetailData: {
    isLoading: true,
    error: null,
    data: {},
  },
  loanAccessControlData: {
    isLoading: true,
    error: null,
    data: [],
  },
  testLoanDocumentsData: {
    isLoading: true,
    error: null,
    data: {},
  },
  testUploadLoanDocumentFileData: {
    isLoading: false,
    error: null,
  },
  testLoanTemplateData: {
    isLoading: false,
    error: null,
  },
  loanDocumentList: {
    isLoading: true,
    error: null,
    data: [],
  },
  testDownloadAsZipData: {
    isLoading: false,
    error: null,
  },
  downloadLoanDocumentFileData: {
    isLoading: false,
    error: null,
  },
  testDeleteLoanDocumentFileData: {
    isLoading: false,
    error: null,
  },
  testBulkDeleteLoanDocumentFileData: {
    isLoading: false,
    error: null,
  },
  postLoanDocument: {
    isLoading: false,
    error: null,
    data: [],
  },
  loanStatusesData: {
    isLoading: true,
    error: null,
    data: [],
  },
  postLoanNextData: {
    isLoading: true,
    error: null,
    data: [],
  },
  postLoanSaveCompleteData: {
    isLoading: true,
    error: null,
    data: [],
  },
  updateLoanStatusData: {
    isLoading: false,
    error: null,
  },
  accessControlRolesData: {
    isLoading: true,
    error: null,
    data: [],
  },
  saveAccessControlChangesData: {
    isLoading: true,
    error: null,
  },
};

const loanDetailSlice = createSlice({
  name: "loanDetail",
  initialState,
  reducers: {
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
      state.loanDocumentSelectedFiles = [];
    },
    setDeleteFileId(state, action) {
      state.deleteFileId = action.payload;
    },
    resetState(state) {
      state.currentTab = initialState.currentTab;
      state.testLoanDetailData = initialState.testLoanDetailData;
      state.testLoanDocumentsData = initialState.testLoanDocumentsData;
      state.loanDocumentSelectedFiles = [];
    },
    selectFiles(state, action) {
      const uniqueFiles = [...new Set([...state.loanDocumentSelectedFiles, ...action.payload])];
      state.loanDocumentSelectedFiles = [...uniqueFiles];
    },
    unSelectFiles(state, action) {
      state.loanDocumentSelectedFiles = state.loanDocumentSelectedFiles.filter(
        (file) => !action.payload.includes(file),
      );
    },
    setDocumentsToShow(state, action) {
      state.documentsToShow = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTestLoanDetailApi.pending, (state) => {
        state.testLoanDetailData.isLoading = true;
        state.testLoanDetailData.error = null;
      })
      .addCase(getTestLoanDetailApi.fulfilled, (state, action) => {
        state.testLoanDetailData.isLoading = false;
        state.testLoanDetailData.data = action.payload;
      })
      .addCase(getTestLoanDetailApi.rejected, (state, action) => {
        state.testLoanDetailData.isLoading = false;
        state.testLoanDetailData.error = action.error.message;
      })
      .addCase(getTestLoanDocumentsApi.pending, (state) => {
        state.testLoanDocumentsData.isLoading = true;
        state.testLoanDocumentsData.error = null;
      })
      .addCase(getTestLoanDocumentsApi.fulfilled, (state, action) => {
        state.testLoanDocumentsData.isLoading = false;
        state.testLoanDocumentsData.data = action.payload;
      })
      .addCase(getTestLoanDocumentsApi.rejected, (state, action) => {
        state.testLoanDocumentsData.isLoading = false;
        state.testLoanDocumentsData.error = action.error.message;
      })
      .addCase(testUploadLoanDocumentFileApi.pending, (state) => {
        state.testLoanDocumentsData.isLoading = true;
        state.testUploadLoanDocumentFileData.isLoading = true;
        state.testUploadLoanDocumentFileData.error = null;
      })
      .addCase(testUploadLoanDocumentFileApi.fulfilled, (state, action) => {
        state.testUploadLoanDocumentFileData.isLoading = false;
        state.testLoanDocumentsData.isLoading = false;
        state.testLoanDocumentsData.data = action.payload;
      })
      .addCase(testUploadLoanDocumentFileApi.rejected, (state, action) => {
        state.testLoanDocumentsData.isLoading = false;
        state.testUploadLoanDocumentFileData.isLoading = false;
        state.testUploadLoanDocumentFileData.error = action.error.message;
      })
      .addCase(postTestLoanTemplateApi.pending, (state) => {
        state.testLoanTemplateData.isLoading = true;
        state.testLoanDocumentsData.isLoading = true;
        state.testLoanTemplateData.error = null;
      })
      .addCase(postTestLoanTemplateApi.fulfilled, (state, action) => {
        state.testLoanTemplateData.isLoading = false;
        state.testLoanDocumentsData.isLoading = false;
        state.testLoanDocumentsData.data = action.payload;
      })
      .addCase(postTestLoanTemplateApi.rejected, (state, action) => {
        state.testLoanTemplateData.isLoading = false;
        state.testLoanDocumentsData.isLoading = false;
        state.testLoanTemplateData.error = action.error.message;
      })
      .addCase(getLoanDocumentListApi.pending, (state) => {
        state.loanDocumentList.isLoading = true;
        state.loanDocumentList.error = null;
      })
      .addCase(getLoanDocumentListApi.fulfilled, (state, action) => {
        state.loanDocumentList.isLoading = false;
        state.loanDocumentList.data = action.payload;
      })
      .addCase(getLoanDocumentListApi.rejected, (state, action) => {
        state.loanDocumentList.isLoading = false;
        state.loanDocumentList.error = action.error.message;
      })
      .addCase(testDownloadAsZipApi.pending, (state) => {
        state.testDownloadAsZipData.isLoading = true;
        state.testDownloadAsZipData.error = null;
      })
      .addCase(testDownloadAsZipApi.fulfilled, (state) => {
        state.testDownloadAsZipData.isLoading = false;
      })
      .addCase(testDownloadAsZipApi.rejected, (state, action) => {
        state.testDownloadAsZipData.isLoading = false;
        state.testDownloadAsZipData.error = action.error.message;
      })
      .addCase(getTestDownloadLoanDocumentFileApi.pending, (state) => {
        state.downloadLoanDocumentFileData.isLoading = true;
        state.downloadLoanDocumentFileData.error = null;
      })
      .addCase(getTestDownloadLoanDocumentFileApi.fulfilled, (state) => {
        state.downloadLoanDocumentFileData.isLoading = false;
      })
      .addCase(getTestDownloadLoanDocumentFileApi.rejected, (state, action) => {
        state.downloadLoanDocumentFileData.isLoading = false;
        state.downloadLoanDocumentFileData.error = action.error.message;
      })
      .addCase(deleteTestLoanDocumentFileApi.pending, (state) => {
        state.testLoanDocumentsData.isLoading = true;
        state.testDeleteLoanDocumentFileData.isLoading = true;
        state.testDeleteLoanDocumentFileData.error = null;
      })
      .addCase(deleteTestLoanDocumentFileApi.fulfilled, (state, action) => {
        state.testDeleteLoanDocumentFileData.isLoading = false;
        state.testLoanDocumentsData.isLoading = false;
        state.deleteFileId = null;
        state.testLoanDocumentsData.data = action.payload.res;
        state.loanDocumentSelectedFiles = state.loanDocumentSelectedFiles.filter(
          (file) => file !== action.payload.file,
        );
      })
      .addCase(deleteTestLoanDocumentFileApi.rejected, (state, action) => {
        state.testLoanDocumentsData.isLoading = false;
        state.testDeleteLoanDocumentFileData.isLoading = false;
        state.testDeleteLoanDocumentFileData.error = action.error.message;
      })
      .addCase(testBulkDeleteLoanDocumentFileApi.pending, (state) => {
        state.testLoanDocumentsData.isLoading = true;
        state.testBulkDeleteLoanDocumentFileData.isLoading = true;
        state.testBulkDeleteLoanDocumentFileData.error = null;
      })
      .addCase(testBulkDeleteLoanDocumentFileApi.fulfilled, (state, action) => {
        state.testBulkDeleteLoanDocumentFileData.isLoading = false;
        state.testLoanDocumentsData.isLoading = false;
        state.testLoanDocumentsData.data = action.payload.res;
        state.loanDocumentSelectedFiles = state.loanDocumentSelectedFiles.filter(
          (file) => !action.payload.files.includes(file),
        );
      })
      .addCase(testBulkDeleteLoanDocumentFileApi.rejected, (state, action) => {
        state.testLoanDocumentsData.isLoading = false;
        state.testBulkDeleteLoanDocumentFileData.isLoading = false;
        state.testBulkDeleteLoanDocumentFileData.error = action.error.message;
      })
      .addCase(postLoanAddDocumentApi.pending, (state) => {
        state.loanDocumentList.isLoading = true;
        state.postLoanDocument.isLoading = true;
        state.postLoanDocument.error = null;
      })
      .addCase(postLoanAddDocumentApi.fulfilled, (state, action) => {
        state.loanDocumentList.isLoading = false;
        state.postLoanDocument.isLoading = false;
        state.postLoanDocument.data = action.payload;
      })
      .addCase(postLoanAddDocumentApi.rejected, (state, action) => {
        state.loanDocumentList.isLoading = false;
        state.postLoanDocument.isLoading = false;
        state.postLoanDocument.error = action.error.message;
      })
      .addCase(getTestLoanAccessControlApi.pending, (state) => {
        state.loanAccessControlData.isLoading = true;
        state.loanAccessControlData.error = null;
      })
      .addCase(getTestLoanAccessControlApi.fulfilled, (state, action) => {
        state.loanAccessControlData.isLoading = false;
        state.loanAccessControlData.data = action.payload;
      })
      .addCase(getTestLoanAccessControlApi.rejected, (state, action) => {
        state.loanAccessControlData.isLoading = false;
        state.loanAccessControlData.error = action.error.message;
      })
      .addCase(getLoanStatusesApi.pending, (state) => {
        state.loanStatusesData.isLoading = true;
        state.loanStatusesData.error = null;
      })
      .addCase(getLoanStatusesApi.fulfilled, (state, action) => {
        state.loanStatusesData.isLoading = false;
        state.loanStatusesData.data = action.payload;
      })
      .addCase(getLoanStatusesApi.rejected, (state, action) => {
        state.loanStatusesData.isLoading = false;
        state.loanStatusesData.error = action.error.message;
      })
      .addCase(postLoanNextApi.pending, (state) => {
        state.postLoanNextData.isLoading = true;
        state.postLoanNextData.error = null;
      })
      .addCase(postLoanNextApi.fulfilled, (state, action) => {
        state.postLoanNextData.isLoading = false;
        state.postLoanNextData.data = action.payload;
      })
      .addCase(postLoanNextApi.rejected, (state, action) => {
        state.postLoanNextData.isLoading = false;
        state.postLoanNextData.error = action.error.message;
      })
      .addCase(postLoanSaveCompleteApi.pending, (state) => {
        state.postLoanSaveCompleteData.isLoading = true;
        state.postLoanSaveCompleteData.error = null;
      })
      .addCase(postLoanSaveCompleteApi.fulfilled, (state, action) => {
        state.postLoanSaveCompleteData.isLoading = false;
        state.postLoanSaveCompleteData.data = action.payload;
      })
      .addCase(postLoanSaveCompleteApi.rejected, (state, action) => {
        state.postLoanSaveCompleteData.isLoading = false;
        state.postLoanSaveCompleteData.error = action.error.message;
      })
      .addCase(updateLoanStatusApi.pending, (state) => {
        state.testLoanDetailData.isLoading = true;
        state.updateLoanStatusData.isLoading = true;
        state.updateLoanStatusData.error = null;
      })
      .addCase(updateLoanStatusApi.fulfilled, (state, action) => {
        state.updateLoanStatusData.isLoading = false;
        state.testLoanDetailData.isLoading = false;
        state.testLoanDetailData.data = action.payload;
      })
      .addCase(updateLoanStatusApi.rejected, (state, action) => {
        state.testLoanDetailData.isLoading = false;
        state.updateLoanStatusData.isLoading = false;
        state.updateLoanStatusData.error = action.error.message;
      })
      .addCase(getAccessControlRolesApi.pending, (state) => {
        state.accessControlRolesData.isLoading = true;
        state.accessControlRolesData.error = null;
      })
      .addCase(getAccessControlRolesApi.fulfilled, (state, action) => {
        state.accessControlRolesData.isLoading = false;
        state.accessControlRolesData.data = action.payload;
      })
      .addCase(getAccessControlRolesApi.rejected, (state, action) => {
        state.accessControlRolesData.isLoading = false;
        state.accessControlRolesData.error = action.error.message;
      })
      .addCase(postLoanSaveAccessControlChangesApi.pending, (state) => {
        state.loanAccessControlData.isLoading = true;
        state.saveAccessControlChangesData.isLoading = true;
        state.saveAccessControlChangesData.error = null;
      })
      .addCase(postLoanSaveAccessControlChangesApi.fulfilled, (state, action) => {
        state.saveAccessControlChangesData.isLoading = false;
        state.loanAccessControlData.isLoading = false;
        state.loanAccessControlData.data = action.payload;
      })
      .addCase(postLoanSaveAccessControlChangesApi.rejected, (state, action) => {
        state.loanAccessControlData.isLoading = false;
        state.saveAccessControlChangesData.isLoading = false;
        state.saveAccessControlChangesData.error = action.error.message;
      });
  },
});

export const {
  setCurrentTab,
  resetState,
  setDeleteFileId,
  selectFiles,
  unSelectFiles,
  setDocumentsToShow,
} = loanDetailSlice.actions;
export default loanDetailSlice.reducer;
