import {
    NotificationFeedPopover,
} from "@knocklabs/react-notification-feed";
import { IconButton } from "../IconButton";
import { useState, useRef } from "react";
import { ReactComponent as NotificationIcon } from "../../../../assets/svg/icons/notification-bell.svg";

export const NotificationFeed = () => {
    const [isVisible, setIsVisible] = useState(false);
    const notifButtonRef = useRef(null);

    return (
        <>
            <IconButton>
                <NotificationIcon
                    ref={notifButtonRef}
                    onClick={(e) => setIsVisible(!isVisible)}
                />
            </IconButton>
            <NotificationFeedPopover
                buttonRef={notifButtonRef}
                isVisible={isVisible}
                onClose={() => setIsVisible(false)}
            />
        </>
    );
};



