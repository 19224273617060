import { fetchPost, fetchGet } from "api/common/callerHelpers";

export const saveBorrowerDetailsAPI = (data) => {
  return fetchPost("/api/borrowers", data);
};

export const saveLoanDetailsAPI = (data) => {
  return fetchPost("/api/loans", data);
};

export const getCitizenships = () => {
  return fetchGet("/api/citizenships");
};

export const getTransactionTypes = () => {
  return fetchGet("/api/transaction-types");
};

export const getLoanTypes = () => {
  return fetchGet("/api/loan-types");
};

export const getPropertyConditions = () => {
  return fetchGet("/api/property-conditions");
};

export const getPropertyOwnershipStatuses = () => {
  return fetchGet("/api/property-ownership-statuses");
};

export const getLendingBanks = (bankName) => {
  if (bankName) {
    return fetchGet(`/api/lending-banks?keyword=${bankName}`);
  }
};

export const getPropertyTypes = () => {
  return fetchGet("/api/property-types");
};

export const postOnboarding = (data) => {
  return fetchPost("/api/onboarding/next", data);
};

export const postOnboardingFinal = (data) => {
  return fetchPost("/api/onboarding/complete", data);
};

export const getOccupancyTypes = () => {
  return fetchGet("/api/occupancy-types");
};

export const getContanctTypes = () => {
  return fetchGet("/api/contact-types");
};

export const getPeriodTypes = () => {
  return fetchGet("/api/period-types");
};
