
import { ReactComponent as PlusIcon } from '../../../../assets/svg/icons/plus-icon.svg'

export function AddButton({ children, ...rest }) {
    return (
        <button className={'flex-center cursor-pointer border-brand nv-addButton'} {...rest}>
            <div className='flex-center nv-addButton__iconWrapper'>
                <PlusIcon />
            </div>
            {children}
        </button >
    )
}