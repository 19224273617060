const UploadInput = ({ value, id, onChange, children, ...props }) => {
  return (
    <label htmlFor={id}>
      <input
        hidden
        value={value}
        id={id}
        type="file"
        onChange={onChange}
        accept="application/pdf, .xls, xlsx, .XLS, image/png, image/jpg, image/jpeg, .doc, .docx"
        {...props}
      />
      {children}
    </label>
  );
};

export default UploadInput;
