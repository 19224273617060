import React, { useState } from "react";
import { usePopper } from "react-popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import { ReactComponent as EditIcon } from "assets/svg/icons/edit-001.svg";
import "./style.css";
const Dropdown = ({
  options,
  changeHandler,
  currentVal,
  edit = false,
  emptyState = true,
  excludeCurrent = false,
}) => {
  // debugger;
  const [showPopper, setShowPopper] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const handleChange = (val) => {
    changeHandler(val);
    setShowPopper(false);
    referenceElement?.focus();
  };

  return (
    <>
      <div
        ref={setReferenceElement}
        className={`dropDownButton ${edit ? "cursorPointer" : null}`}
        tabIndex={0}
        onClick={() => {
          edit && setShowPopper(true);
          referenceElement?.focus();
        }}
        // onKeyDown={null}
      >
        <span className="dropDownSpan">
          {Boolean(currentVal) && currentVal !== ""
            ? options?.find((el) => "" + el.id === "" + currentVal)?.name
            : emptyState
            ? "None"
            : "None"}
        </span>
        {edit && showPopper && <ExpandLessIcon style={{ fontSize: 14, color: "#737373" }} />}
        {edit && !showPopper && <ExpandMoreIcon style={{ fontSize: 14, color: "#737373" }} />}
      </div>
      {showPopper && edit && (
        <ClickAwayListener onClickAway={() => setShowPopper(false)}>
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="listwrapper"
          >
            <ul className="listcontainer">
              {emptyState && (
                <li
                  className="listitem removeCategoryColor cursorPointer"
                  onClick={() => handleChange(null)}
                >
                  None
                </li>
              )}
              {options.map((option) => {
                if (excludeCurrent && option.id === currentVal) {
                  return null;
                } else {
                  return (
                    <li
                      key={option.id}
                      className="listitem cursorPointer"
                      onClick={() => handleChange(option.id)}
                    >
                      {option.name}
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};

export default Dropdown;
