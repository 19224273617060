import {
  getCitizenships,
  getTransactionTypes,
  getLoanTypes,
  getPropertyConditions,
  getPropertyOwnershipStatuses,
  getPropertyTypes,
} from "api/onboarding.api";

export const loadInitialDataHelper = async ({
  setCitizenships,
  setTransactionTypes,
  setPropertyConditions,
  setLoanTypes,
  setPropertyOwnershipStatuses,
  setPropertyTypes,
}) => {
  await getCitizenships().then((res) => {
    if (res) setCitizenships(checkedBoxMapHelper(res));
  });
  await getTransactionTypes().then((res) => {
    if (res) setTransactionTypes(checkedBoxMapHelper(res));
  });
  await getPropertyConditions().then((res) => {
    if (res) setPropertyConditions(checkedBoxMapHelper(res));
  });
  await getLoanTypes().then((res) => {
    if (res) setLoanTypes(checkedBoxMapHelper(res));
  });
  await getPropertyOwnershipStatuses().then((res) => {
    if (res) setPropertyOwnershipStatuses(checkedBoxMapHelper(res));
  });
  await getPropertyTypes().then((res) => {
    if (res) setPropertyTypes(checkedBoxMapHelper(res));
  });
};

export const objectUpdateHelper = (
  clearProperty,
  currentData,
  newData,
  setData
) => {
  // payload expected as a Key-Value pair
  const clearObject = {};
  if (clearProperty) {
    clearObject[clearProperty] = null;
  }
  setData((prevState) => ({
    ...prevState,
    ...newData,
    ...clearObject,
  }));
};

export const checkedBoxMapHelper = (data) =>
  data.map((item) => {
    return { value: item.id, label: item.name };
  });

export const ifObjectIsEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};
