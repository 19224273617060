import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import BattalionContext from "store/Context";
import { ReactComponent as Curve } from "assets/svg/Battalion House Curve.svg";
import { AppRoute } from "../../utils/constants/ConstantsUtil";
import "./styles.scss";

const Portal = ({ children, className = "root-portal", element = "div" }) => {
  const [container] = useState(() => {
    const el = document.createElement(element);
    el.classList.add(className);
    return el;
  });

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return ReactDOM.createPortal(children, container);
};

const AnimationGlare = ({ type }) => {
  return (
    <div className={`animation_glare ${type ? type : ""}`}>
      <div className="glare1"></div>
      <div className="glare2"></div>
      <div className="glare3"></div>
    </div>
  );
};

const SlideItem = ({ title, image, description, className }) => {
  return (
    <div className={`slide_item ${className ? className : ""}`}>
      <img src={image} alt={title} />
      <div className="para_wrap">
        <p className="larger">{title}</p>
        <p className="smaller">{description}</p>
      </div>
    </div>
  );
};

const Progress = ({ text, progress, reverseOrder }) => {
  return (
    <div className={`progress_wrap ${reverseOrder ? "reverse" : ""}`}>
      <div className="text">{text}</div>
      <div className="progress_bar">
        <div
          className="current_progress"
          style={{ width: progress + "%" }}
        ></div>
      </div>
    </div>
  );
};

const NumberAnimator = ({ number, prefix, suffix, decimals }) => {
  const [active, setActive] = useState(true);
  return (
    <VisibilitySensor
      partialVisibility
      offset={{ bottom: -300 }}
      active={active}
    >
      {({ isVisible }) => (
        <div style={{ height: 72 }}>
          {isVisible ? (
            <CountUp
              className="number_animator"
              end={number}
              onEnd={() => {
                setActive(false);
              }}
              prefix={prefix || ""}
              suffix={suffix || ""}
              decimals={decimals || 0}
            />
          ) : (
            "."
          )}
        </div>
      )}
    </VisibilitySensor>
  );
};

const FormattedData = (
  preFix = "",
  data,
  maximumFractionDigits = 5,
  postFix = ""
) => {
  const value = Number(data).toLocaleString("en", {
    maximumFractionDigits: maximumFractionDigits,
  });
  return preFix + value + postFix;
};

const GetStartedBanner = () => (
  <div className="get_started_banner_section">
    <Curve />
    <div className="content_wrap">
      <div
        className="title"
        data-aos="fade-up"
        data-aos-delay="0"
        data-aos-duration="750"
      >
        Your investment property financing partner
      </div>
      <div
        className="btn"
        data-aos="fade-up"
        data-aos-delay="0"
        data-aos-duration="850"
      >
        <Link to={AppRoute.ONBOARDING}>
          <button className="button bv3">Get Started</button>
        </Link>
      </div>
    </div>
  </div>
);

const AddressBar = ({ step, updateStepHandler }) => {
  const context = useContext(BattalionContext);
  const formData = context.financingPreferences;

  const handleChange = () => {
    // TODO: i don't like this function, is better to send the user to the start of the form
    step === 1
      ? updateStepHandler(-1)
      : step === 2
      ? updateStepHandler(-2)
      : updateStepHandler(-3);
  };

  return (
    <>
      <div className="address-bar">
        <div className="address-bar__container">
          <div className="address-bar__address">
            <p>{formData.propertyAddressObj?.address},</p>
            <p>
              {formData.propertyAddressObj?.city} {""}{" "}
              {formData.propertyAddressObj?.state} {""}{" "}
              {formData.propertyAddressObj?.zipcode}
            </p>
          </div>
          <button className="button purple" onClick={handleChange}>
            Change
          </button>
        </div>
      </div>
    </>
  );
};

const ProgressBar = ({ step }) => {
  return (
    <div className="progressWrapper">
      <div
        className={`progressStep ${step === 1 && "current"}`}
        data-aos="fade-up"
        data-aos-delay="0"
        data-aos-duration="600"
      >
        <div className="progressStep__number">1</div>
        <div className="progressStep__name">Property Details</div>
      </div>
      <div className="progress__separator"></div>
      <div
        className={`progressStep ${step === 2 && "current"}`}
        data-aos="fade-up"
        data-aos-delay="0"
        data-aos-duration="600"
      >
        <div className="progressStep__number">2</div>
        <div className="progressStep__name">Loan Details</div>
      </div>
      <div className="progress__separator"></div>
      <div
        className={`progressStep ${step === 3 && "current"}`}
        data-aos="fade-up"
        data-aos-delay="0"
        data-aos-duration="600"
      >
        <div className="progressStep__number">3</div>
        <div className="progressStep__name">Personal Details</div>
      </div>
    </div>
  );
};

const RegistrationPopup = () => {
  return (
    <div className="registrationPopup">
      <div className="registrationPopup__content">
        <div className="registrationPopup__top">
          <figure>
            <img alt="" />
          </figure>
        </div>
        <div className="registrationPopup__bottom">
          <h2>Welcome to Battalion!</h2>
          <p>Your Battalion account is successfully created.</p>
        </div>
      </div>
    </div>
  );
};

export {
  Portal,
  AnimationGlare,
  SlideItem,
  Progress,
  NumberAnimator,
  FormattedData,
  GetStartedBanner,
  AddressBar,
  ProgressBar,
  RegistrationPopup,
};
