import {
  fetchGetTokenized,
  fetchPostTokenized,
  fetchDeleteTokenized,
  fetchPutTokenized,
  fetchDelete,
} from "api/common/callerHelpers";

export const getTestLoanDetail = async (loanId) => {
  return await fetchGetTokenized(`/api/loans/detail/${loanId}`);
};

export const getTestLoanDocuments = async (loanId, data) => {
  return await fetchGetTokenized(`/api/loan-document/group/${loanId}`, data);
};

export const testUploadLoanDocumentFile = (data) => {
  return fetchPostTokenized("/api/loan-document/upload", data, true);
};

export const uploadLoanDocumentFileViewer = (data) => {
  return fetchPostTokenized("/api/loan-document/save-pspdf-file", data, true);
};

export const getLoanDocumentList = async (id) => {
  return await fetchGetTokenized(`/api/loan-document/list/${id}`);
};

export const getCategoryList = async () => {
  return await fetchGetTokenized(`/api/categories/list`);
};

export const postTestLoanTemplate = async (data) => {
  return await fetchPostTokenized("/api/loan-document/add-template", data);
};

export const getPropertyTypes = async () => {
  return await fetchGetTokenized("/api/property-types");
};

export const getPropertyConditions = async () => {
  return await fetchGetTokenized("/api/property-conditions");
};

export const getOccupancyTypes = async () => {
  return await fetchGetTokenized("/api/occupancy-types");
};

export const getPeriodTypes = async () => {
  return await fetchGetTokenized("/api/period-types");
};

export const getTransactionTypes = async () => {
  return await fetchGetTokenized("/api/transaction-types");
};

export const testDownloadAsZip = async (loanDocumentFileIds) => {
  const queryParams = loanDocumentFileIds.map((id) => `loanDocumentFileIds=${id}`).join("&");
  return await fetchGetTokenized(`/api/loan-document/download-as-zip?${queryParams}`, {
    responseType: "blob",
  });
};

export const getTestDownloadLoanDocumentFile = async (fileId) => {
  return await fetchGetTokenized(`/api/loan-document/download/${fileId}`, {
    responseType: "blob",
  });
};

export const getLoanDocumentInfo = async (fileId) => {
  return await fetchGetTokenized(`/api/loan-document/file-detail`, {
    params: {
      loanDocumentFileId: fileId,
    },
  });
};

export const getDocumentFileForOpening = async (url) => {
  return await fetchGetTokenized(url, {
    responseType: "blob",
  });
};

export const testDownloadLoanDocumentAsZip = async (loanDocumentId) => {
  return await fetchGetTokenized("/api/loan-document/download-as-zip-single-document", {
    responseType: "blob",
    params: {
      loanDocumentId,
    },
  });
};

export const deleteTestLoanDocumentFile = async (fileId) => {
  return await fetchDeleteTokenized(`/api/loan-document/file/${fileId}`);
};

export const testBulkDeleteLoanDocumentFile = async (data) => {
  return await fetchPostTokenized("/api/loan-document/delete-files", data);
};

export const postLoanAddDocument = async (data) => {
  return await fetchPostTokenized("/api/loan-document/add-document", data);
};

export const getDocumentAutoComplete = async (data) => {
  return await fetchGetTokenized("/api/documents/autocomplete", data);
};

export const getUserAccessControlAutoComplete = async (data) => {
  return await fetchGetTokenized("/api/loans/access-control-search", data);
};

export const getLoanAccessControl = async (loanId) => {
  return await fetchGetTokenized(`/api/loans/${loanId}/access-control`);
};

export const postLoanNext = async (data) => {
  return await fetchPostTokenized("/api/loans/next", data);
};

export const postLoanSaveComplete = async (data) => {
  return await fetchPostTokenized("/api/loans/save-complete", data);
};

export const getLoanStatuses = async () => {
  return await fetchGetTokenized("/api/loan-statuses");
};

export const getAccessControlRoles = async () => {
  return await fetchGetTokenized("/api/access-control-roles");
};

export const postLoanSaveAccessControlChanges = async (data) => {
  return await fetchPostTokenized("/api/loans/access-control", data);
};

export const getAccessTypeApi = async (id) => {
  return await fetchGetTokenized("/api/loans/user-access-level-loan-details/" + id);
};

export const updateLoan = async (data) => {
  return await fetchPutTokenized("/api/loans", data);
};

export const deleteDocumentLoanDetailsSidePanelApi = async (id) => {
  return await fetchDeleteTokenized("/api/loan-document?id=" + id);
};

export const postDocumentLoanDetailsSidePanelApi = async (data) => {
  return await fetchPostTokenized("/api/loan-document", data);
};
