import AddIcon from "@mui/icons-material/Add";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import NewLoanModal from "pages/Borrower/Components/NewLoanModal";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserRoles } from "utils/constants/ConstantsUtil";
import { Header } from "wrappers/NewVersion/components/Header";
import { Button } from "./Components/Button/Button";
import { getBorrowers, getCurrentUser, setCurrentTab } from "./borrowerSlice";

import "./styles.scss";

import ActiveTab from "./Tabs/activeTab/ActiveTab";

const Borrower = () => {
  const dispatch = useDispatch();
  const [accessType, setAccessType] = useState(null);
  const [isNewLoanModalOpen, setIsNewLoanModalOpen] = useState(false);
  const [borrowerData, setBorrowerData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const currentRequestId = useRef();
  const currentAbortController = useRef();

  const { searchText, currentPage, currentTab, sortColumn } = useSelector(
    ({ newBorrower }) => newBorrower,
  );

  const handleChange = (e, newVal) => {
    dispatch(setCurrentTab(newVal));
  };
  const getRole = async () => {
    const res = await dispatch(getCurrentUser()).unwrap();
    setAccessType(res?.accessType);
  };

  useEffect(() => {
    getRole();
  }, []);

  const fetchData = async () => {
    if (!searchText && searchText !== "") return;

    const requestId = Date.now();
    currentRequestId.current = requestId;

    const abortController = new AbortController();
    const { signal } = abortController;
    currentAbortController.current = abortController;

    try {
      setIsLoading(true);

      const res = await dispatch(
        getBorrowers({
          page: currentPage,
          searchText: searchText,
          status: currentTab,
          sortColumnName: sortColumn.sortColumnName,
          sortOrder: sortColumn.sortColumnOrder,
        }),
        { signal },
      ).unwrap();

      if (currentRequestId.current === requestId) {
        setBorrowerData(res);
      }
    } catch (e) {
      if (!signal.aborted) {
        console.log(e);
      }
    } finally {
      if (currentRequestId.current === requestId) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      currentAbortController.current?.abort();
    };
  }, [dispatch, searchText, currentPage, currentTab, sortColumn]);

  const canAppear = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);

  return (
    <>
      <div className="nv-borrower h-100">
        <Header />
        <section className="mainContainer">
          <div className="mainContainer__header">
            <div className="mainContainer__header--top">
              <div className="h1__wrapper">
                <h1>Borrowers</h1>
              </div>

              <Button
                startIcon={<AddIcon fontSize="20px" />}
                label={"Loan"}
                onClick={() => setIsNewLoanModalOpen(true)}
              />
            </div>
          </div>
          <TabContext value={currentTab}>
            <Box sx={{ borderBottom: 1, borderColor: "#E5E5E5", width: "100%" }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Active" value="active" />
                <Tab label="Closed" value="closed" />
                {canAppear && <Tab label="Archived" value="archived" />}
                <Tab label="Show All" value="all" />
              </TabList>
            </Box>
            <TabPanel value="active">
              <ActiveTab isLoading={isLoading} borrowerData={borrowerData} />
            </TabPanel>
            <TabPanel value="closed">
              <ActiveTab isLoading={isLoading} borrowerData={borrowerData} />
            </TabPanel>
            <TabPanel value="archived">
              <ActiveTab isLoading={isLoading} borrowerData={borrowerData} />
            </TabPanel>
            <TabPanel value="all">
              <ActiveTab isLoading={isLoading} borrowerData={borrowerData} />
            </TabPanel>
          </TabContext>
        </section>
      </div>
      <NewLoanModal isOpen={isNewLoanModalOpen} closeModal={() => setIsNewLoanModalOpen(false)} />
    </>
  );
};

export default Borrower;
