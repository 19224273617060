import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStep, setFormToInitialState, setNewLoan } from "./onboardingSlice";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Stepper from "./Steps";
import ButtonHolder from "./Components/ButtonHolder";
import "./styles.scss";
import useCheckMobileScreen from "utils/hooks/useCheckMobileScreen";
import { useLocation } from "react-router-dom";

const OnBoarding = ({ step = null, reset = false, newLoan = false }) => {
  const dispatch = useDispatch();
  const { showFooter, showProgressBar } = useSelector((state) => state.onboarding.footer);
  const isOnMobile = useCheckMobileScreen();
  const { currentStep } = useSelector((state) => state.onboarding.body);
  console.log("🚀 ~ file: index.jsx:16 ~ OnBoarding ~ currentStep:", currentStep);
  const isOnboardingPage = useLocation().pathname === "/onboarding";

  useEffect(() => {
    if (reset) {
      dispatch(setFormToInitialState(null));
    }
  }, [dispatch, reset]);
  useEffect(() => {
    if (step || step === 0) {
      dispatch(setStep(step));
    }
  }, [dispatch, step]);

  useEffect(() => {
    !newLoan && dispatch(setNewLoan({}));
  }, [newLoan]);

  return (
    <>
      <div className="onboardingContainer">
        <Header />
        <div className="onboardingMainContent">
          <Stepper />
        </div>
      </div>

      <div>
        {isOnMobile && <ButtonHolder />}
        {showProgressBar && showFooter && <Footer />}
      </div>
    </>
  );
};

export default OnBoarding;
