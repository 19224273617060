import * as yup from "yup";

export const validationSchemaEmailModal = yup.object().shape({
  email: yup
    .string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email format")
    .required("Email is required"),
  message: yup
    .string()
    .required("Message is required"),
});
