import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputText from "../Components/InputText";
import GoogleLogin from "pages/Login/components/GoogleLogin";

import {
  setHeaderQuestion,
  setEmail,
  setPassword,
  setShowLogin,
  setShowCreateNewAccount,
  setEmailValidity,
  setPasswordValidity,
  stepNext,
  setShowQuestions,
  setShowBack,
  setShowFooter,
} from "../onboardingSlice";

import { unsetUserLoginError } from "pages/Login/loginSlice";
import { Link } from "react-router-dom";

const LoginWithEmail = ({ isAuthFlow }) => {
  const [emailIsFocus, setEmailIsFocus] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(true);

  const [passwordIsFocus, setPasswordIsFocus] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(true);

  const [emailCustomMessage, setEmailCustomMessage] = useState(null);
  const [passwordCustomMessage, setPasswordCustomMessage] = useState(null);

  const dispatch = useDispatch();
  const header = "Login";
  const { Email, password } = useSelector((state) => state.onboarding.body.form);

  const { userLogin } = useSelector((state) => state.login);

  useEffect(() => {
    dispatch(setShowBack(false));
    dispatch(setShowFooter(false));
  }, [dispatch]);

  useEffect(() => {
    if (userLogin.error) {
      if (userLogin.error.includes("@")) {
        setEmailCustomMessage("The user does not exists");
      } else if (userLogin.error.includes("locked")) {
        setPasswordCustomMessage("This account has been locked");
        setPasswordIsValid(false);
        dispatch(setPasswordValidity(false));
      } else {
        setPasswordCustomMessage("The password is incorrect");
        setPasswordIsValid(false);
        dispatch(setPasswordValidity(false));
      }
    }
  }, [userLogin.error, dispatch]);

  useEffect(() => {
    dispatch(setShowQuestions(false));
    dispatch(setShowLogin(false));
    dispatch(setShowCreateNewAccount(false));

    if (isAuthFlow) {
      dispatch(setShowBack(true));
    } else {
      dispatch(setShowBack(false));
    }

    return () => {
      dispatch(setShowLogin(false));
      dispatch(setShowCreateNewAccount(false));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setHeaderQuestion(header));

    return () => {
      // dispatch(setEmail(null));
      // dispatch(setPassword(null));
      dispatch(setHeaderQuestion(""));
    };
  }, [dispatch, header]);

  useEffect(() => {
    if (Email !== null && Email.length > 0) {
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          Email,
        ) ||
        (userLogin.error && userLogin.error.includes("@"))
      ) {
        setEmailIsValid(false);
        dispatch(setEmailValidity(false));
      } else {
        setEmailIsValid(true);
        dispatch(setEmailValidity(true));
      }
    }
    if (Email === null || Email.length === 0) {
      setEmailIsValid(true);
    }
  }, [Email, dispatch, userLogin]);

  useEffect(() => {
    if (password === null || password.length === 0) {
      dispatch(setPasswordValidity(false));
    } else {
      dispatch(setPasswordValidity(true));
    }
  }, [password, dispatch]);

  useEffect(() => {
    dispatch(setShowLogin(true));
    return () => {
      dispatch(setShowLogin(false));
    };
  }, [password, Email, emailIsValid, dispatch]);
  const updateEmail = (val) => {
    if (val !== Email) {
      dispatch(setEmail(val));
      if (userLogin.error) {
        dispatch(unsetUserLoginError());
      }
    }
  };

  const handleFocusEmail = (val) => {
    setEmailIsFocus(val);
  };

  const updatePassword = (val) => {
    if (val !== password) {
      dispatch(setPassword(val));
      if (userLogin.error) {
        dispatch(unsetUserLoginError());
        setPasswordIsValid(true);
        dispatch(setPasswordValidity(true));
      }
    }
  };

  const handleFocusPassword = (val) => {
    setPasswordIsFocus(val);
  };
  return (
    <div className="createAccountContainer loginContainer">
      <div className="formWrapper">
        <InputText
          placeholder={"Email"}
          updateForm={updateEmail}
          focus
          handleFocus={handleFocusEmail}
          label="Email"
          isValid={emailIsValid}
          val={Email}
          dynamic={true}
          customNotValidMessage={emailCustomMessage}
        />
        <InputText
          placeholder={"Password"}
          updateForm={updatePassword}
          focus={passwordIsFocus}
          handleFocus={handleFocusPassword}
          label="Password"
          val={password}
          password={true}
          isValid={passwordIsValid}
          customNotValidMessage={passwordCustomMessage}
        />
      </div>
      <button className="forgotPassword" onClick={() => dispatch(stepNext())}>
        Forgot password?
      </button>
      <div className="crossAuthLinkerWrapper noPadding">
        <p>New To Battalion?</p>
        <Link to="/new-account">Sign Up</Link>
      </div>
    </div>
  );
};

export default LoginWithEmail;
