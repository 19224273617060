import { IconButton } from "@mui/material";
import { ReactComponent as ChevronDoubleIcon } from "assets/svg/icons/chevron-right-double.svg";
import { useLoanContext } from "pages/Borrower/LoanDetail";
import { useDispatch, useSelector } from "react-redux";
import { setDocumentsToShow } from "../../loanDetailSlice";
import CustomizedCheckbox from "../Checkbox/Checkbox";
import Document from "../Document/Document";
import CustomizedTooltip from "../Tooltip/Tooltip";

const Category = ({ category, documents, index, handleCheck, isChecked, isIndeterminate }) => {
  const dispatch = useDispatch();
  const { canMultiSelect } = useLoanContext();
  const { documentsToShow } = useSelector(({ loanDetail }) => loanDetail);

  const getAllDocuments = () => {
    return documents.flatMap((doc) => {
      if (doc.subDocuments.length) {
        const result = [doc];
        const subDocumentsWithFiles = doc.subDocuments.filter((sub) => sub.files.length > 0);
        result.push(...subDocumentsWithFiles);
        return result;
      } else if (doc.files.length) {
        return doc;
      }
      return [];
    });
  };
  const allDocuments = getAllDocuments();

  console.log(allDocuments);

  const hasDocumentToShow =
    allDocuments.length &&
    allDocuments.map((document) => document.id).every((doc) => documentsToShow.includes(doc));

  const handleCategoryDisplay = () => {
    dispatch(
      setDocumentsToShow(
        hasDocumentToShow
          ? documentsToShow.filter(
              (document) => !allDocuments.map((doc) => doc.id).includes(document),
            )
          : [...documentsToShow, ...allDocuments.map((document) => document.id)],
      ),
    );
  };

  const getCategoryFiles = (documents) =>
    documents.length
      ? documents.reduce((acc, document) => {
          if (document.subDocuments.length) {
            document.subDocuments.forEach((sub) => {
              acc.push(...sub.files);
            });
          } else {
            acc.push(...document.files);
          }
          return acc;
        }, [])
      : [];

  return (
    <>
      {category !== "" && (
        <li
          className={`${
            index !== 0 ? "pt-[8px]" : "pt-[8px]"
          } py-[8px] mt-[8px] d-flex align-items-center gap-8 px-40 border-b border-[#E5E5E5]`}
        >
          {canMultiSelect && (
            <CustomizedCheckbox
              disabled={!getCategoryFiles(documents).length}
              checked={isChecked(getCategoryFiles(documents))}
              indeterminate={isIndeterminate(getCategoryFiles(documents))}
              id="selectAllFromCategory"
              onChange={() => handleCheck(getCategoryFiles(documents))}
            />
          )}

          <CustomizedTooltip
            title={`${
              hasDocumentToShow ? "Hide All Files In Category" : "Show All Files In Category"
            }`}
          >
            <IconButton
              disabled={!allDocuments.length}
              className={`doublechevron_rotate ${hasDocumentToShow && "open"}`}
              onClick={handleCategoryDisplay}
            >
              <ChevronDoubleIcon />
            </IconButton>
          </CustomizedTooltip>
          <span className="documentCategory">{category.toUpperCase()}</span>
        </li>
      )}
      {documents.length > 0 ? (
        <ul>
          {documents?.map((doc) => (
            <Document key={doc.id} {...doc} />
          ))}
        </ul>
      ) : null}
    </>
  );
};

export default Category;
