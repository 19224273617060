import {createPortal} from "react-dom";
import {useLayoutEffect, useState} from "react";

function createWrapperAndAppendToBody(wrapperId) {
    const wrapperElement = document.createElement('div');
    wrapperElement.setAttribute("id", wrapperId);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
}

export function Portal({children, wrapperId}){
    const [wrapperElement, setWrapperElement] = useState(null);

    useLayoutEffect(() => {
        let isCreated = false;
        let wrapper = document.getElementById(wrapperId);

        if(!wrapper){
            isCreated = true;
            wrapper = createWrapperAndAppendToBody(wrapperId);
        }
        setWrapperElement(wrapper)

        return () => {
            if(isCreated && wrapper.parentNode) {
                wrapper.parentNode.removeChild(wrapper);
            }
        }
    },[wrapperId])

    if(wrapperElement === null) return null;

    return createPortal(children, wrapperElement);
}