import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { Drawer } from "wrappers/NewVersion/components/Drawer";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import "./styles.css";
import ProfileInfo from "../Tabs/ProfileInfo";
import CustomizedTooltip from "../../Borrower/Components/Tooltip/Tooltip";
import { setCurrentUser } from "../userSlice";
import Loans from "../Tabs/Loans";

const FormDrawer = ({ id, userId }) => {
  const dispatch = useDispatch();

  const { userFormDrawer } = useSelector((state) => state.common);
  const [currentTabDrawer, setCurrentTabDrawer] = useState("users");
  const [previewImage, setPreviewImage] = useState(null);

  const handleChange = (e, newVal) => {
    id && setCurrentTabDrawer(newVal);
  };
  return (
    <Drawer
      size={"md"}
      isOpen={userFormDrawer.isVisible}
      header={
        <div className="d-flex flex-column flex-1 gap-4">
          <h4 className={"text-xl-bold"}>Add User</h4>
        </div>
      }
      handleClose={async () => {
        dispatch(commonActions.toggleUserFormDrawerVisibility());
        dispatch(setCurrentUser(null));
        setCurrentTabDrawer("users");
        setPreviewImage(null);
      }}
    >
      {userFormDrawer.isVisible && (
        <TabContext value={currentTabDrawer}>
          <Box sx={{ borderBottom: 1, borderColor: "#E5E5E5", width: "100%" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="Profile Info"
                value="users"
                sx={{
                  flex: 1,
                  textAlign: "center",
                  minWidth: "50%",
                  paddingBottom: "4px !important",
                }}
              />

              <Tab
                disableRipple
                label={
                  id ? (
                    <div
                      className="w-100"
                      style={{
                        color: `${currentTabDrawer === "lenders" ? "#a459ff" : "#737373"}`,
                      }}
                    >
                      Loan Access
                    </div>
                  ) : (
                    <CustomizedTooltip
                      title={
                        <div>
                          <p style={{ fontSize: "12px", lineHeight: "12px" }}>
                            Activate the 'Loan Access' tab after saving Personal Info to grant
                            access to loans
                          </p>
                        </div>
                      }
                    >
                      <div className="w-100" style={{ color: " #737373" }}>
                        Loan Access
                      </div>
                    </CustomizedTooltip>
                  )
                }
                value="lenders"
                sx={{
                  flex: 1,
                  textAlign: "center",
                  minWidth: "50%",
                  paddingBottom: "4px !important",
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="users">
            <ProfileInfo id={id} previewImage={previewImage} setPreviewImage={setPreviewImage} />
          </TabPanel>
          <TabPanel value="lenders">
            <Loans id={userId} userId={id} />
          </TabPanel>
        </TabContext>
      )}
    </Drawer>
  );
};

export default FormDrawer;
