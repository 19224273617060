import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderQuestion, setShowNext, setPropertyCount, setSendData } from "../onboardingSlice";
import Slider from "pages/OnBoarding/Components/Slider/Slider";

const Step13 = () => {
  const [isValid, setIsValid] = useState(true);

  const dispatch = useDispatch();
  let { PropertyCount } = useSelector((state) => state.onboarding.body.form);
  const { postOnboarding } = useSelector((state) => state.onboarding.body);

  const question = "How many properties would you like to finance?";

  PropertyCount =
    PropertyCount === null ? null : Number(PropertyCount?.toString().replaceAll(",", ""));

  useEffect(() => {
    if (PropertyCount !== null) {
      if (!/^\d+$/.test(PropertyCount) || Number(PropertyCount) < 1 || Number(PropertyCount) > 50) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [PropertyCount]);
  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);

  useEffect(() => {
    if (PropertyCount !== null && isValid) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ PropertyCount }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [PropertyCount, dispatch, postOnboarding, isValid]);

  const updateForm = useCallback(
    (val) => {
      dispatch(setPropertyCount(val));
    },
    [dispatch],
  );

  return (
    <Slider
      min={1}
      max={50}
      interval={1}
      sliderVal={PropertyCount}
      updateForm={updateForm}
      isValid={isValid}
      limit={3}
    />
  );
};

export default Step13;
