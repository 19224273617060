import React, { useEffect, useState } from "react";
import { commonActions } from "../../../../wrappers/NewVersion/commonSlice";

import { Button } from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "wrappers/NewVersion/components/Drawer";
import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";

import {
  getOccupancyTypesApi,
  getPeriodTypesApi,
  getPropertyConditionsApi,
  getPropertyTypesApi,
  getTestLoanDetailApi,
  getTransactionTypesApi,
  updateLoanApi,
} from "../../loanDetailSlice";

import "./styles.scss";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import Skeleton from "@yisheng90/react-loading";
import ButtonGroup from "wrappers/NewVersion/components/ButtonGroup";
import Input from "pages/UserManagement/Components/Input";
import SearchInput from "pages/UserManagement/Components/SearchInput";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchemaLoanDetails } from "pages/UserManagement/constants";
import InputDropdown from "wrappers/NewVersion/components/InputDropdown";

const LoansTransactionDetailEditDrawer = () => {
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertyConditions, setPropertyConditions] = useState([]);
  const [occupancyTypes, setOccupancyTypes] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [periodTypes, setPeriodTypes] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [isUpdate, setIsUpdate] = useState([]);

  const getStatuses = async () => {
    const propertyTypes = await dispatch(getPropertyTypesApi()).unwrap();
    setPropertyTypes(propertyTypes);
    const propertyConditions = await dispatch(getPropertyConditionsApi()).unwrap();
    setPropertyConditions(propertyConditions);
    const occupancyTypes = await dispatch(getOccupancyTypesApi()).unwrap();
    setOccupancyTypes(occupancyTypes);
    const transactionTypes = await dispatch(getTransactionTypesApi()).unwrap();
    setTransactionTypes(transactionTypes);
    const periodTypes = await dispatch(getPeriodTypesApi()).unwrap();
    setPeriodTypes(periodTypes);
  };

  useEffect(() => {
    getStatuses();
  }, []);

  const dispatch = useDispatch();
  const { loansTransactionEditDetails } = useSelector((state) => state.common);
  const {
    testLoanDetailData: { data: loanDetail },
  } = useSelector(({ loanDetail }) => loanDetail);

  const { register, handleSubmit, formState, setValue, getValues, control } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    criteriaMode: "all",
    shouldUnregister: false,
    resolver: yupResolver(validationSchemaLoanDetails),
  });

  const { errors } = formState;

  useEffect(() => {
    setIsLoading(true);
    if (loanDetail) {
      loanDetail?.transactionType && setValue("transactionType", loanDetail?.transactionType);
      loanDetail?.propertyType && setValue("propertyType", loanDetail.propertyType);
      loanDetail?.residentialUnits && setValue("residentialUnits", loanDetail?.residentialUnits);
      loanDetail?.condition && setValue("condition", loanDetail?.condition);
      loanDetail?.occupancyType && setValue("occupancyType", loanDetail?.occupancyType);

      loanDetail?.ownedPeriod && setValue("ownedPeriod", loanDetail.ownedPeriod);
      loanDetail?.periodType;
      setValue("periodType", {
        id: loanDetail.periodType,
        name: loanDetail.periodTypeDisplayName,
      });
      loanDetail?.mortgageBalance &&
        setValue("mortgageBalance", `$${loanDetail?.mortgageBalance.toLocaleString()}`);
      loanDetail?.purchasePrice &&
        setValue("purchasePrice", `$${loanDetail?.purchasePrice.toLocaleString()}`);
      loanDetail?.estimatedValue &&
        setValue("estimatedValue", `$${loanDetail?.estimatedValue.toLocaleString()}`);
      loanDetail?.loanAmount &&
        setValue("loanAmount", `$${loanDetail?.loanAmount.toLocaleString()}`);
      loanDetail?.loanToValueRatio &&
        setValue("loanToValueRatio", `${loanDetail.loanToValueRatio}%`);
      loanDetail?.termPeriod && setValue("termPeriod", loanDetail.termPeriod);
      loanDetail?.termPeriodType &&
        setValue("termPeriodType", {
          id: loanDetail.termPeriodType,
          name: loanDetail.termPeriodTypeDisplayName,
        });
      loanDetail?.dscr && setValue("dscr", loanDetail.dscr);
      loanDetail?.grossRent && setValue("grossRent", `$${loanDetail?.grossRent.toLocaleString()}`);
      setIsLoading(false);
    }
  }, [dispatch, setValue, loanDetail]);

  const onSubmit = async (dataToSend) => {
    if (dataToSend.loanAmount) {
      dataToSend.loanAmount = dataToSend.loanAmount.replace("$", "");
      dataToSend.loanAmount = dataToSend.loanAmount.replaceAll(",", "");
      dataToSend.loanAmount = Number(dataToSend.loanAmount);
    } else {
      dataToSend.loanAmount = null;
    }

    if (dataToSend.mortgageBalance) {
      dataToSend.mortgageBalance = dataToSend.mortgageBalance.replace("$", "");
      dataToSend.mortgageBalance = dataToSend.mortgageBalance.replaceAll(",", "");
      dataToSend.mortgageBalance = Number(dataToSend.mortgageBalance);
    } else {
      dataToSend.mortgageBalance = null;
    }
    if (dataToSend.loanToValueRatio) {
      dataToSend.loanToValueRatio = dataToSend.loanToValueRatio.replace("%", "");
      dataToSend.loanToValueRatio = Number(dataToSend.loanToValueRatio);
    } else {
      dataToSend.loanToValueRatio = null;
    }
    if (dataToSend.periodType) {
      dataToSend.periodType = dataToSend.periodType.id;
    }
    if (dataToSend.termPeriodType) {
      dataToSend.termPeriodType = dataToSend.termPeriodType.id;
    }
    if (dataToSend.dscr) {
      dataToSend.dscr = Number(dataToSend.dscr);
    } else {
      dataToSend.dscr = null;
    }

    if (dataToSend.ownedPeriod) {
      dataToSend.ownedPeriod = Number(dataToSend.ownedPeriod);
    } else {
      dataToSend.ownedPeriod = null;
      dataToSend.periodType = null;
    }

    if (dataToSend.termPeriod) {
      dataToSend.termPeriod = Number(dataToSend.termPeriod);
    } else {
      dataToSend.termPeriod = null;
      dataToSend.termPeriodType = null;
    }

    setIsLoading(true);
    try {
      dataToSend.id = loanDetail?.id;
      await dispatch(updateLoanApi(dataToSend)).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Property Details updated"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
      setIsUpdate(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchLoan = async () => {
      if (isUpdate) {
        const res = await dispatch(getTestLoanDetailApi(loanDetail.id)).unwrap();
        res?.transactionType && setValue("transactionType", res?.transactionType);
        res?.propertyType && setValue("propertyType", res.propertyType);
        res?.residentialUnits && setValue("residentialUnits", res?.residentialUnits);
        res?.condition && setValue("condition", res?.condition);
        res?.occupancyType && setValue("occupancyType", res?.occupancyType);

        res?.ownedPeriod && setValue("ownedPeriod", res.ownedPeriod);
        res?.periodType &&
          setValue(
            "periodType",
            periodTypes.find((period) => period.id === res.periodType),
          );
        res?.mortgageBalance &&
          setValue("mortgageBalance", `$${res?.mortgageBalance.toLocaleString()}`);
        res?.purchasePrice && setValue("purchasePrice", `$${res?.purchasePrice.toLocaleString()}`);
        res?.estimatedValue &&
          setValue("estimatedValue", `$${res?.estimatedValue.toLocaleString()}`);
        res?.loanAmount && setValue("loanAmount", `$${res?.loanAmount.toLocaleString()}`);
        res?.loanToValueRatio && setValue("loanToValueRatio", `${res.loanToValueRatio}%`);
        res?.termPeriod && setValue("termPeriod", res.termPeriod);
        res?.termPeriodType &&
          setValue(
            "termPeriodType",
            periodTypes.find((period) => period.id === res.termPeriodType),
          );
        res?.dscr && setValue("dscr", res.dscr);
        res?.grossRent && setValue("grossRent", `$${res?.grossRent.toLocaleString()}`);
        setIsLoading(false);
        setIsUpdate(false);
      }
    };

    fetchLoan();
  }, [isUpdate, dispatch, setValue]);

  const handleClose = async () => {
    dispatch(commonActions.toggleLoanTransactionEditDetailsVisibility());
    setIsUpdate(true);
  };

  const handleUpdateLoanAmount = (e) => {
    const value = e.target.value;

    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));

    const loanToValueRatio =
      (numericValue /
        (getValues()?.transactionType === 1
          ? parseFloat(getValues()?.purchasePrice.replace(/[^0-9.]/g, ""))
          : parseFloat(getValues()?.estimatedValue.replace(/[^0-9.]/g, "")))) *
      100;

    if (!isNaN(numericValue)) {
      const formattedValue = `$${numericValue.toLocaleString()}`;
      setValue("loanAmount", formattedValue, { shouldValidate: true });
      setValue("loanToValueRatio", `${Math.ceil(loanToValueRatio)?.toLocaleString()}%`, {
        shouldValidate: true,
      });
    } else {
      setValue("loanAmount", value, { shouldValidate: true });
    }
  };

  const handleUpdateLTV = (e) => {
    const value = e.target.value;

    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));

    const loanAmount =
      (numericValue / 100) *
      (getValues()?.transactionType === 1
        ? parseFloat(getValues()?.purchasePrice.replace(/[^0-9.]/g, ""))
        : parseFloat(getValues()?.estimatedValue.replace(/[^0-9.]/g, "")));

    if (!isNaN(numericValue)) {
      const formattedValue = `${numericValue.toLocaleString()}%`;
      setValue("loanToValueRatio", formattedValue, { shouldValidate: true });
      setValue("loanAmount", `$${Math.ceil(loanAmount)?.toLocaleString()}`, {
        shouldValidate: true,
      });
    } else {
      setValue("loanToValueRatio", value, { shouldValidate: true });
    }
  };

  const handleUpdatePurchasePrice = (e) => {
    const value = e.target.value;

    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));

    const loanAmount =
      numericValue * (parseFloat(getValues()?.loanToValueRatio.replace(/[^0-9.]/g, "")) / 100);

    if (!isNaN(numericValue)) {
      const formattedValue = `$${numericValue.toLocaleString()}`;
      setValue("purchasePrice", formattedValue, { shouldValidate: true });
      setValue("loanAmount", `$${Math.ceil(loanAmount)?.toLocaleString()}`, {
        shouldValidate: true,
      });
    } else {
      setValue("purchasePrice", value, { shouldValidate: true });
    }
  };

  const handleUpdateEstimatedValue = (e) => {
    const value = e.target.value;

    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));

    const loanAmount =
      numericValue * (parseFloat(getValues()?.loanToValueRatio.replace(/[^0-9.]/g, "")) / 100);

    if (!isNaN(numericValue)) {
      const formattedValue = `$${numericValue.toLocaleString()}`;
      setValue("estimatedValue", formattedValue, { shouldValidate: true });
      setValue("loanAmount", `$${Math.ceil(loanAmount)?.toLocaleString()}`, {
        shouldValidate: true,
      });
    } else {
      setValue("estimatedValue", value, { shouldValidate: true });
    }
  };

  return (
    <Drawer
      size={"md"}
      isOpen={loansTransactionEditDetails.isVisible}
      header={
        <div className="d-flex flex-column flex-1">
          <h4 className={"text-xl-bold"}>Edit Property Details</h4>
        </div>
      }
      handleClose={handleClose}
    >
      <div className="d-flex flex-column h-100 nv-profileSettings">
        <div style={{ minHeight: "83vh", maxHeight: "83vh", overflowY: "auto" }} className="p-24">
          {isLoading ? (
            <Grid className="max-w-[730] px-6 py-6 mx-auto" container spacing={2} rowSpacing={2}>
              <Grid item xs={12}>
                <Skeleton className="width-100" height={60} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton className="width-100" height={60} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton className="width-100" height={60} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton className="width-100" height={60} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton className="width-100" height={60} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton className="width-100" height={60} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton className="width-100" height={60} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton className="width-100" height={60} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton className="width-100" height={60} />
              </Grid>
            </Grid>
          ) : (
            <form className="max-w-[730px] px-6 py-6 mx-auto" onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} rowSpacing={2}>
                <Grid item xs={12}>
                  <ButtonGroup
                    options={transactionTypes}
                    name="transactionType"
                    label="Loan Purpose"
                    control={control}
                    defaultValue={null}
                    setValue={setValue}
                    hasOptional={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonGroup
                    options={propertyTypes}
                    name="propertyType"
                    label="Property Type"
                    control={control}
                    defaultValue={null}
                    setValue={setValue}
                    hasOptional={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    name="residentialUnits"
                    label="Residential Units"
                    type={"number"}
                    placeholder="Enter # of Residential Units"
                    register={register}
                    hasOptional={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonGroup
                    options={propertyConditions}
                    name="condition"
                    label="Property Condition"
                    control={control}
                    defaultValue={null}
                    setValue={setValue}
                    hasOptional={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonGroup
                    options={occupancyTypes}
                    name="occupancyType"
                    label="Occupancy Type"
                    control={control}
                    defaultValue={null}
                    setValue={setValue}
                    hasOptional={false}
                  />
                </Grid>

                {getValues()?.transactionType === 2 && (
                  <Grid item xs={12}>
                    <InputDropdown
                      inputName="ownedPeriod"
                      register={register}
                      hasOptional={false}
                      label={"Property Ownership Duration"}
                      options={periodTypes}
                      defaultSelectValue={getValues()?.periodType}
                      onClick={(val) => setValue("periodType", val)}
                    />
                  </Grid>
                )}

                {getValues()?.transactionType === 2 && (
                  <Grid item xs={12}>
                    <Input
                      name="mortgageBalance"
                      label="Current Outstanding Balance"
                      type={"currency"}
                      register={register}
                      hasOptional={false}
                      required={false}
                      firstCurrencyValue={getValues()?.mortgageBalance}
                      errorMessage={errors?.mortgageBalance?.message}
                    />
                  </Grid>
                )}

                {getValues()?.transactionType === 1 && (
                  <Grid item xs={12}>
                    <Input
                      name="purchasePrice"
                      label="Purchase Price"
                      type={"currency"}
                      register={register}
                      hasOptional={false}
                      required={false}
                      value={getValues()?.purchasePrice}
                      customOnChange={handleUpdatePurchasePrice}
                      errorMessage={errors?.purchasePrice?.message}
                    />
                  </Grid>
                )}

                {getValues()?.transactionType === 2 && (
                  <Grid item xs={12}>
                    <Input
                      name="estimatedValue"
                      label="Estimated Value"
                      type={"currency"}
                      register={register}
                      hasOptional={false}
                      required={false}
                      value={getValues()?.estimatedValue}
                      customOnChange={handleUpdateEstimatedValue}
                      errorMessage={errors?.estimatedValue?.message}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Input
                    name="loanAmount"
                    label="Loan Amount"
                    type={"currency"}
                    register={register}
                    hasOptional={false}
                    required={false}
                    value={getValues()?.loanAmount}
                    customOnChange={handleUpdateLoanAmount}
                    errorMessage={errors?.loanAmount?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Input
                    name="loanToValueRatio"
                    label="LTV (Loan to Value Ratio)"
                    type={"percentage"}
                    register={register}
                    hasOptional={false}
                    required={false}
                    value={getValues().loanToValueRatio}
                    customOnChange={handleUpdateLTV}
                    errorMessage={errors?.loanToValueRatio?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputDropdown
                    inputName="termPeriod"
                    register={register}
                    hasOptional={false}
                    label={"Term Period"}
                    options={periodTypes}
                    defaultSelectValue={getValues()?.termPeriodType}
                    onClick={(val) => setValue("termPeriodType", val)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Input
                    type={"number"}
                    name="dscr"
                    label="DSCR"
                    register={register}
                    hasOptional={false}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Input
                    name="grossRent"
                    label="Gross Rent"
                    type={"currency"}
                    register={register}
                    hasOptional={false}
                    required={false}
                    firstCurrencyValue={getValues()?.grossRent}
                    errorMessage={errors?.grossRent?.message}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </div>
        <div
          className={
            "py-6 px-24 border-top-gray-200 d-flex gap-12 justify-content-end nv-profileSettings__footer"
          }
        >
          <Button
            type="contained"
            backgroundColor="white"
            colorTextEnabled="#344054"
            borderColor="#d0d5dd"
            onClick={handleClose}
            disabled={!loansTransactionEditDetails?.isVisible}
            label={"Cancel"}
          />
          <Button
            type="contained"
            label={"Save"}
            onClick={handleSubmit(onSubmit)}
            disabled={!loansTransactionEditDetails.isVisible}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default LoansTransactionDetailEditDrawer;
