// import { useMemo } from "react";
// import { ReactComponent as Check } from "assets/svg/icons/checkFilled.svg";

// import "./styles.css";

// const DeleteItem = ({ address, isSelected, price }) => {
//   if (!address) return null;
//   const splitAddress = useMemo(() => {
//     return address.split(",") || [];
//   }, [address]);
//   return (
//     <div className={`deleteItem-wrapper ${isSelected ? "deleteItem-wrapper-selected" : undefined}`}>
//       <div className="first-row">
//         <div className="leftSide">
//           <p className={`mainText ${isSelected ? "selected" : undefined}`}>{splitAddress[0]}</p>
//           <p className={`secondaryText ${isSelected ? "selected" : undefined}`}>
//             {`${split[1]}, ${split[2]} ${split[4]}`}
//           </p>
//         </div>
//         {isSelected ? <Check /> : <div className="emptyCircle" />}
//       </div>
//       {price && <p className>${price}</p>}
//     </div>
//   );
// };

// export default DeleteItem;

import { useMemo } from "react";
import { ReactComponent as Check } from "assets/svg/icons/checkFilled.svg";

import "./styles.css";

const DeleteItem = ({ address, isSelected, price, onClick }) => {
  if (!address) return null;

  const splitAddress = useMemo(() => address.split(","), [address]);

  const [main = "", city = "", state = "", country = "", zip = ""] = splitAddress;

  return (
    <div
      className={`deleteItem-wrapper ${isSelected ? "deleteItem-wrapper-selected" : ""}`}
      onClick={onClick}
    >
      <div className="firstRow">
        <div className="leftSide">
          <p className={`mainText ${isSelected ? "selected" : ""}`}>{main}</p>
          <p className={`secondaryText ${isSelected ? "selected" : ""}`}>
            {`${city.trim()}, ${state.trim()} ${zip.trim()}`}
          </p>
        </div>
        {isSelected ? <Check /> : <div className="emptyCircle" />}
      </div>
      {price && (
        <p className={`secondaryText ${isSelected ? "selected" : ""}`}>${price.toLocaleString()}</p>
      )}
    </div>
  );
};

export default DeleteItem;
