import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../wrappers/NewVersion/components/Modal";
import { ReactComponent as LogoPurple } from "assets/svg/icons/logoPurple.svg";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import { ReactComponent as CloseIcon } from "assets/svg/icons/x-close.svg";
import { useForm } from "react-hook-form";
import { sendEmail } from "pages/OnBoarding/onboardingSlice";
import useCheckMobileScreen from "utils/hooks/useCheckMobileScreen";
import { validationSchemaEmailModal } from "./constants";
import { TextInput } from "components/InputElements";
import { yupResolver } from "@hookform/resolvers/yup";

const EmailModal = () => {
  const dispatch = useDispatch();
  const { emailModal } = useSelector((state) => state.common);
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    criteriaMode: "all",
    resolver: yupResolver(validationSchemaEmailModal),
  });
  const [isSendSucces, setIsSendSuccess] = useState(false);
  const isOnMobile = useCheckMobileScreen();

  const onSubmit = async (data) => {
    try {
      const foo = await dispatch(sendEmail(data));
      console.log(foo);
      reset({});
      setIsSendSuccess(true);
    } catch (error) {
      alert("Error sending message: " + error.message);
      console.error(error);
    }
  };

  useEffect(() => {
    if (emailModal.isVisible) {
      reset({});
      setIsSendSuccess(false);
    }
  }, [emailModal.isVisible, reset]);

  return (
    <Modal isOpen={emailModal.isVisible} position={true}>
      {isSendSucces ? (
        <div className="modalContainerWrapper">
          <div className="modalContainer">
            <div className="modalContainerHeader">
              <LogoPurple />
              <div>
                <p>We will reach out</p>
                <span>Thanks for writing to us, we will reach out.</span>
              </div>
              <div
                className="closeButton"
                onClick={() => {
                  dispatch(commonActions.toggleEmailModalVisibility());
                  dispatch(commonActions.toggleNeedHelpModalVisibility());
                }}
              >
                <CloseIcon />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="modalContainerWrapper">
          <div className="modalContainer">
            <div className="modalContainerHeader">
              <LogoPurple />
              <div>
                <p>Email Us</p>
                <span>Send us a message and we will get back to you in 24 hours</span>
              </div>
              <div
                className="closeButton"
                onClick={() => dispatch(commonActions.toggleEmailModalVisibility())}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="modalContainerContent">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="inputField">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    autoComplete="off"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[\w-.]+@([\w-]+\.)?[\w-]{2,4}$/,
                        message: "Please enter a valid email address",
                      },
                    })}
                  />
                  {errors.email && <span className="inputError">{errors.email.message}</span>}
                </div>

                <div className="inputField">
                  <label htmlFor="message">Message</label>
                  <textarea
                    id="message"
                    {...register("message")}
                    lines={isOnMobile ? 4 : 5}
                    required
                  />
                </div>
                <div className="submitArea">
                  <button type="submit">
                    <span>Send</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default EmailModal;

// import { useDispatch } from 'react-redux';
// import { useForm } from 'react-hook-form';
// import { createMessage } from './messageSlice';

// function ContactForm() {
//   const dispatch = useDispatch();
//   const { register, handleSubmit } = useForm();

//   const onSubmit = async (data) => {
//     try {
//       await dispatch(createMessage(data));
//       alert('Message sent successfully!');
//     } catch (error) {
//       alert('Error sending message: ' + error.message);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <label htmlFor="email">Email Address</label>
//       <input type="email" id="email" {...register('email')} />

//       <label htmlFor="message">Message</label>
//       <textarea id="message" {...register('message')} />

//       <button type="submit">Submit</button>
//     </form>
//   );
// }

// export default ContactForm;
