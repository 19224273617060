import { IconButton as MUIIconButton } from "@mui/material";

export const IconButton = ({ icon, onClick = null }) => {
  return (
    <MUIIconButton
      style={{
        display: "flex",
        padding: "10px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        borderRadius: "32px",
        border: "1px solid #D6D6D6",
        background: "#FFF",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      }}
      onClick={onClick}
    >
      {icon}
    </MUIIconButton>
  );
};

export default IconButton;
