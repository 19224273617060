import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Box from "@mui/material/Box";
import Skeleton from "@yisheng90/react-loading";
import { ReactComponent as MailIcon } from "assets/svg/icons/mail-01-purple.svg";
import { ReactComponent as PhoneIcon } from "assets/svg/icons/phoneBorrowerList.svg";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../../../utils/constants/ConstantsUtil";
import { getFormattedDate, getFormattedPhoneNumber } from "../../../../utils/shared/helpers";
import Pagination from "../../Components/Pagination/Pagination";
import Search from "../../Components/SearchBar/Search";
import Status from "../../Components/Status/Status";
import { nextPage, prevPage, resetState, setSearchText, setSortColumn } from "../../borrowerSlice";

import { Avatar, Grid } from "@mui/material";
import "../styles.css";

//move to constants folder
const apiColNames = ["fullName", "activeLoansCount", "totalAmount", "dateUpdated", "status"];

const ActiveTab = ({ isLoading, borrowerData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sortColumn, currentTab } = useSelector(({ newBorrower }) => newBorrower);
  const loansColName = useMemo(() => {
    switch (currentTab) {
      case "active":
        return "Active Loans";
      case "closed":
        return "Closed Loans";
      case "archived":
        return "Archived Loans";
      case "all":
        return "Total Loans";
      default:
        return null;
    }
  }, [currentTab]);
  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);
  const sortColName = useMemo(() => {
    return sortColumn.sortColumnName;
  }, [sortColumn.sortColumnName]);
  const sortColOrder = useMemo(() => {
    return sortColumn.sortColumnOrder;
  }, [sortColumn.sortColumnOrder]);

  const handleArrow = useCallback(
    (colName) => {
      return sortColName === colName && sortColOrder === "asc" ? (
        <ArrowUpwardIcon sx={{ fontSize: "16px", color: "#525252" }} />
      ) : sortColName === colName && sortColOrder === "desc" ? (
        <ArrowDownwardIcon sx={{ fontSize: "16px", color: "#525252" }} />
      ) : null;
    },
    [sortColName, sortColOrder],
  );
  const handleSearch = (val) => {
    dispatch(setSearchText(val));
  };
  const handleNext = () => {
    dispatch(nextPage());
  };
  const handlePrev = () => {
    dispatch(prevPage());
  };
  const handleHeaderClick = (colName) => {
    if (colName === sortColName) {
      if (sortColOrder === "asc") {
        dispatch(setSortColumn({ sortColumnName: colName, sortColumnOrder: "desc" }));
      } else {
        dispatch(setSortColumn({ sortColumnName: colName, sortColumnOrder: "asc" }));
      }
    } else {
      dispatch(setSortColumn({ sortColumnName: colName, sortColumnOrder: "asc" }));
    }
  };

  return (
    <div className="tabsContainer">
      <div className="tabsContainer-utilities">
        <Search handleSearch={handleSearch} placeholder={"Search for borrowers or addresses"} />
        <Pagination
          currentPage={borrowerData?.pageIndex}
          totalPage={borrowerData?.totalPages}
          handleNext={handleNext}
          handlePrev={handlePrev}
          isLoading={isLoading}
        />
      </div>
      {isLoading ? (
        <>
          <Skeleton height={44} />
          <Skeleton rows={5} height={60} />
        </>
      ) : (
        borrowerData?.data && (
          <div className="tabsContainer-table">
            <TableContainer component={Box}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => handleHeaderClick(apiColNames[0])}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: " 13px 24px",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <p className="headerCell-text">Borrower's Name</p>
                        {handleArrow(apiColNames[0])}
                      </Box>
                    </TableCell>
                    <TableCell style={{ width: "220px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          padding: " 13px 24px",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p className="headerCell-text">Documents</p>
                      </Box>
                    </TableCell>
                    <TableCell
                      style={{ width: "100px", cursor: "pointer" }}
                      onClick={() => handleHeaderClick(apiColNames[1])}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: " 13px 24px",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p className="headerCell-text">{loansColName}</p>
                        {handleArrow(apiColNames[1])}
                      </Box>
                    </TableCell>
                    <TableCell
                      style={{ width: "132px", cursor: "pointer" }}
                      onClick={() => handleHeaderClick(apiColNames[2])}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: " 13px 24px",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <p className="headerCell-text">Total Loan Amount</p>
                        {handleArrow(apiColNames[2])}
                      </Box>
                    </TableCell>
                    <TableCell
                      style={{ width: "124px", cursor: "pointer" }}
                      onClick={() => handleHeaderClick(apiColNames[3])}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: " 13px 24px",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p className="headerCell-text">Last Updated</p>
                        {handleArrow(apiColNames[3])}
                      </Box>
                    </TableCell>
                    <TableCell style={{ width: "156px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          padding: " 13px 24px",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <p className="headerCell-text">Contact</p>
                      </Box>
                    </TableCell>
                    <TableCell
                      style={{ width: "96px", cursor: "pointer" }}
                      onClick={() => handleHeaderClick(apiColNames[4])}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: " 13px 24px",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <p className="headerCell-text">Status</p>
                        {handleArrow(apiColNames[4])}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {borrowerData?.data?.map((row, index) => (
                    <TableRow
                      key={row.id}
                      style={{ cursor: "pointer" }}
                      className="hover:bg-[#f5f5f5] transition duration-150"
                    >
                      <TableCell onClick={() => navigate(AppRoute.BORROWER + "/" + row.id)}>
                        <Box
                          sx={{
                            display: "flex",
                            padding: " 16px 24px",
                            gap: "12px",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            src={row?.avatarUrl}
                            alt={row?.firstName}
                            className="avatar-text background-color-gray-100 cursor-pointer"
                            sx={{
                              width: 40,
                              height: 40,
                            }}
                          >
                            {row?.firstName[0].toUpperCase()}
                            {row?.lastName[0].toUpperCase()}
                          </Avatar>

                          <div className="fullNameContainer">
                            <p className="fullNameContainer-fullName">{`${
                              row.lastName + ", " + row.firstName
                            }`}</p>

                            {/* <p className="fullNameContainer-email">
                              {row.email}
                            </p> */}
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell onClick={() => navigate(AppRoute.BORROWER + "/" + row.id)}>
                        <Grid container spacing={2} rowSpacing={2}>
                          <Grid item xs={4}>
                            {Boolean(row.documentsAcceptCount) && (
                              <div className="documentsContainer">
                                <CheckCircleOutlineIcon sx={{ fontSize: 20, color: "#62D1A4" }} />
                                <p className="documentsContainer-number">
                                  {row.documentsAcceptCount}
                                </p>
                              </div>
                            )}
                          </Grid>

                          <Grid item xs={4}>
                            {Boolean(row.documentsReviewCount) && (
                              <div className="documentsContainer">
                                <RemoveCircleOutlineIcon sx={{ fontSize: 20, color: "#DC6803" }} />
                                <p className="documentsContainer-number">
                                  {row.documentsReviewCount}
                                </p>
                              </div>
                            )}
                          </Grid>

                          <Grid item xs={4}>
                            {Boolean(row.documentsRejectCount) && (
                              <div className="documentsContainer">
                                <HighlightOffIcon sx={{ fontSize: 20, color: "#D92D20" }} />
                                <p className="documentsContainer-number">
                                  {row.documentsRejectCount}
                                </p>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell onClick={() => navigate(AppRoute.BORROWER + "/" + row.id)}>
                        <Box
                          sx={{
                            display: "flex",
                            padding: " 16px 24px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p className="activeLoans-text">{row.activeLoansCount}</p>
                        </Box>
                      </TableCell>
                      <TableCell onClick={() => navigate(AppRoute.BORROWER + "/" + row.id)}>
                        <Box
                          sx={{
                            display: "flex",
                            padding: " 16px 24px",
                            alignItems: "center",
                          }}
                        >
                          <p className="totalLoanCount-text">
                            {row.totalAmount && "$" + row.totalAmount.toLocaleString()}
                          </p>
                        </Box>
                      </TableCell>
                      <TableCell onClick={() => navigate(AppRoute.BORROWER + "/" + row.id)}>
                        <Box
                          sx={{
                            display: "flex",
                            padding: " 16px 24px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="dateUpdatedContainer">
                            <p className="dateUpdatedContainer-date">
                              {getFormattedDate(row.dateUpdated)[0]}
                            </p>
                            <p className="dateUpdatedContainer-time">
                              {getFormattedDate(row.dateUpdated)[1]}
                            </p>
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            padding: " 16px 24px",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                        >
                          {row.phone && (
                            <div className="phoneContainer">
                              <PhoneIcon width={16} height={16} />
                              <a className="phoneContainer-text" href={`tel:${row.phone}`}>
                                {getFormattedPhoneNumber(row.phone)}
                              </a>
                            </div>
                          )}
                          <div className="phoneContainer">
                            <MailIcon width={16} height={16} />
                            <p className="fullNameContainer-email">{row.email}</p>
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell onClick={() => navigate(AppRoute.BORROWER + "/" + row.id)}>
                        <Box
                          sx={{
                            display: "flex",
                            padding: " 16px 24px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Status type={row.status} label={row.status} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )
      )}
    </div>
  );
};

export default ActiveTab;
