import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTestBorrowerDetail,
  getTestBorrowerDetailLoans,
  getTestBorrowerLoanCountByStatus,
  updateBorrowerInfoApi,
  updateLoanStatus,
  getUserAccesLevelApi,
  archiveLoan,
} from "api/borrower.api";
import { getTestLoanDocuments } from "api/loan.api";

export const getTestBorrowerDetailApi = createAsyncThunk(
  "borrowerDetail/getTestData",
  async (id) => {
    const res = await getTestBorrowerDetail(id);
    return res;
  },
);

export const getTestBorrowerDetailLoansApi = createAsyncThunk(
  "borrowerDetail/getTestDataLoans",
  async (id, { getState }) => {
    const { currentTab } = getState().borrowerDetail;
    const res = await getTestBorrowerDetailLoans(id, {
      params: { status: currentTab },
    });
    return res;
  },
);

export const updateLoanStatusApi = createAsyncThunk(
  "borrowerDetail/updateLoanStatus",
  async (data) => {
    const res = await updateLoanStatus(data.id, data.status);
    return res;
  },
);

export const archiveLoanApi = createAsyncThunk(
  "borrowerDetail/archiveLoan",
  async (id) => await archiveLoan(id),
);

export const updateBorrowerInfo = createAsyncThunk(
  "borrowerDetail/updateBorrowerInfoApi",
  async ({ id, ...data }) => {
    await updateBorrowerInfoApi(id, data);
  },
);

export const getTestBorrowerLoanCountByStatusApi = createAsyncThunk(
  "borrowerDetail/getTestBorrowerLoanCountByStatus",
  async (borrowerId) => {
    const res = await getTestBorrowerLoanCountByStatus({
      params: { borrowerId },
    });
    return res;
  },
);

export const getLoanDocumentsApi = createAsyncThunk(
  "loanDetail/getLoanDocuments",
  async (data = {}) => {
    const res = await getTestLoanDocuments(data.id, {
      params: { status: data.status },
    });
    return { ...res, loanId: data.id };
  },
);

export const getUserAccessLevel = createAsyncThunk("borrowerDetail/accessLevel", async (id) => {
  return await getUserAccesLevelApi(id);
});

const initialState = {
  currentTab: "active",
  testBorrowerDetailData: {
    isLoading: true,
    error: null,
    data: {},
  },
  testBorrowerDetailDataLoans: {
    isLoading: true,
    error: null,
    data: [],
  },
  updateLoanStatus: {
    isLoading: true,
    error: null,
    data: [],
  },
  loanCountByStatus: {
    isLoading: true,
    error: null,
    data: [],
  },
  loansDocuments: {
    isLoading: true,
    error: null,
    data: [],
  },
};

const borrowerDetailSlice = createSlice({
  name: "borrowerDetail",
  initialState,
  reducers: {
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    resetState(state) {
      state.currentTab = initialState.currentTab;
      state.testBorrowerDetailData = initialState.testBorrowerDetailData;
      state.testBorrowerDetailDataLoans = initialState.testBorrowerDetailDataLoans;
      state.updateLoanStatus = initialState.updateLoanStatus;
      state.loanCountByStatus = initialState.loanCountByStatus;
      state.loansDocuments = initialState.loansDocuments;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTestBorrowerDetailApi.pending, (state) => {
        state.testBorrowerDetailData.isLoading = true;
        state.testBorrowerDetailData.error = null;
      })
      .addCase(getTestBorrowerDetailApi.fulfilled, (state, action) => {
        state.testBorrowerDetailData.isLoading = false;
        state.testBorrowerDetailData.data = action.payload;
      })
      .addCase(getTestBorrowerDetailApi.rejected, (state, action) => {
        state.testBorrowerDetailData.isLoading = false;
        state.testBorrowerDetailData.error = action.error.message;
      })
      .addCase(getTestBorrowerDetailLoansApi.pending, (state) => {
        state.testBorrowerDetailDataLoans.isLoading = true;
        state.testBorrowerDetailDataLoans.error = null;
      })
      .addCase(getTestBorrowerDetailLoansApi.fulfilled, (state, action) => {
        state.testBorrowerDetailDataLoans.isLoading = false;
        state.testBorrowerDetailDataLoans.data = action.payload;
      })
      .addCase(getTestBorrowerDetailLoansApi.rejected, (state, action) => {
        state.testBorrowerDetailDataLoans.isLoading = false;
        state.testBorrowerDetailDataLoans.error = action.error.message;
      })
      .addCase(updateLoanStatusApi.pending, (state) => {
        state.updateLoanStatus.isLoading = true;
        state.updateLoanStatus.error = null;
      })
      .addCase(updateLoanStatusApi.fulfilled, (state, action) => {
        state.updateLoanStatus.isLoading = false;
        state.updateLoanStatus.data = action.payload;
      })
      .addCase(updateLoanStatusApi.rejected, (state, action) => {
        state.updateLoanStatus.isLoading = false;
        state.updateLoanStatus.error = action.error.message;
      })
      .addCase(getTestBorrowerLoanCountByStatusApi.pending, (state) => {
        state.loanCountByStatus.isLoading = true;
        state.loanCountByStatus.error = null;
      })
      .addCase(getTestBorrowerLoanCountByStatusApi.fulfilled, (state, action) => {
        state.loanCountByStatus.isLoading = false;
        state.loanCountByStatus.data = action.payload;
      })
      .addCase(getTestBorrowerLoanCountByStatusApi.rejected, (state, action) => {
        state.loanCountByStatus.isLoading = false;
        state.loanCountByStatus.error = action.error.message;
      })
      .addCase(getLoanDocumentsApi.pending, (state) => {
        state.loansDocuments.isLoading = true;
        state.loansDocuments.data = [];
        state.loansDocuments.error = null;
      })
      .addCase(getLoanDocumentsApi.fulfilled, (state, action) => {
        state.loansDocuments.isLoading = false;
        state.loansDocuments.data.push(action.payload);
      })
      .addCase(getLoanDocumentsApi.rejected, (state, action) => {
        state.loansDocuments.isLoading = false;
        state.loansDocuments.error = action.error.message;
      });
  },
});

export const { setCurrentTab, resetState } = borrowerDetailSlice.actions;
export default borrowerDetailSlice.reducer;
