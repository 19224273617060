import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import { Modal } from "wrappers/NewVersion/components/Modal";
import { commonActions } from "wrappers/NewVersion/commonSlice.js";
import { ReactComponent as AccesControlIcon } from "assets/svg/icons/accessControl.svg";
import { getUserAccessControlAutoCompleteApi } from "pages/Borrower/loanDetailSlice";
import { addAccessControl, bulkAddAccess } from "../userSlice";
import SearchInput from "./SearchInput";
import DeleteMessageModal from "pages/DocumentManagement/Tabs/Categories/DeleteMessageModal";
import InviteUserModal from "./InviteUserModal";

const DEFAULT_ROLE = "Viewer";

const AccessControlModal = ({ loanId, roles, clearLoanId, updateList, type, loanList }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [messageModalMessage, setMessageModalMessage] = useState(null);
  const { accessControlModal } = useSelector((state) => state.common);
  const [userData, setUserData] = useState({});
  const [role, setRole] = useState({
    id: 5,
    name: DEFAULT_ROLE,
  });
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);

  const handleClose = () => {
    dispatch(commonActions.toggleAccessControlModalVisibility(false));
    setIsLoading(false);
    setMessageModalMessage(null);
    setUserData({});
    setRole({
      id: 5,
      name: DEFAULT_ROLE,
    });
    clearLoanId();
  };

  const searchName = async (val) => {
    const res = await dispatch(getUserAccessControlAutoCompleteApi({ searchText: val })).unwrap();
    return res;
  };

  const handleGrantAccess = async () => {
    try {
      setIsLoading(true);
      if (type === "single") {
        await dispatch(
          addAccessControl({
            loanId,
            userId: userData.id,
            firstName: userData.name.split(" ")[0],
            lastName: userData.name.split(" ")[1],
            email: userData.email,
            role: role.id,
          }),
        ).unwrap();
      } else {
        await dispatch(
          bulkAddAccess({
            accessControlIds: loanList,
            userId: userData.id,
            firstName: userData.name.split(" ")[0],
            lastName: userData.name.split(" ")[1],
            email: userData.email,
            role: role.id,
          }),
        ).unwrap();
      }

      await updateList();
      handleClose();
    } catch (err) {
      setMessageModalMessage(err.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        icon={<AccesControlIcon width={24} height={24} />}
        isOpen={accessControlModal.isVisible}
        handleClose={handleClose}
      >
        <Stack direction="column" spacing={0.5}>
          <h2 className="accessModal-title">Access </h2>
          <p className="accessModal-subTitle">
            Assign a role to another user so they can access your loans. Enter their email to give
            them access.
          </p>
          <Stack direction="column" paddingBottom={"32px"} gap={"8px"} paddingTop={"24px"}>
            <SearchInput
              label="Email / Name"
              searchFunc={searchName}
              onClick={(data) => setUserData(data)}
              type={"search"}
              required={true}
              empty={
                <>
                  <li className="listitem noBackgroundChange">User not found</li>
                  <li
                    className="listitem cursorPointer empty"
                    onClick={() => {
                      setIsInviteUserModalOpen(true);
                    }}
                  >
                    + Invite User
                  </li>
                </>
              }
            />
            <SearchInput
              type={"select"}
              options={roles}
              label="Role"
              onClick={(role) => setRole(role)}
              defaultValue={DEFAULT_ROLE}
              required={true}
            />
          </Stack>
          <Stack direction="row" gap={"12px"}>
            <LoadingButton
              variant="outlined"
              onClick={handleClose}
              sx={{
                padding: "10px 18px",
                width: "100%",
                height: "44px",
                background: "#FFFFFF",
                border: "1px solid #D6D6D6",
                boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                borderRadius: "32px",
                fontFamily: "Indivisible",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "1rem",
                lineHeight: "1.5em",
                color: "#424242",
                letterSpacing: "-0.04em",
                textTransform: "none",
                "&:hover": {
                  border: "1px solid #D6D6D6",
                  background: "#FFFFFF",
                },
              }}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={handleGrantAccess}
              sx={{
                padding: "10px 18px",
                width: "100%",
                height: "44px",
                background: "#A459FF",
                border: "1px solid #A459FF",
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                borderRadius: "32px",
                fontFamily: "Indivisible",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "1rem",
                lineHeight: "1.5em",
                color: "#FFFFFF",
                letterSpacing: "-0.04em",
                textTransform: "none",

                "&:hover": {
                  border: "1px solid #A459FF",
                  background: "#A459FF",
                },

                "&:disabled": {
                  opacity: "0.5",
                  border: "1px solid #A459FF",
                  background: "#A459FF",
                },
              }}
              disabled={!userData.name}
              loading={isLoading}
            >
              Give Access
            </LoadingButton>
          </Stack>
        </Stack>
      </Modal>
      {messageModalMessage && (
        <DeleteMessageModal
          message={messageModalMessage}
          icon={"access"}
          header={"Can't give access"}
          removeMessage={() => setMessageModalMessage(null)}
        />
      )}
      <InviteUserModal
        isOpen={isInviteUserModalOpen}
        onClose={() => setIsInviteUserModalOpen(false)}
        roles={roles}
        loanId={loanId}
        type={type}
        loanList={loanList}
        handleThisClose={handleClose}
      />
    </>
  );
};

export default AccessControlModal;
