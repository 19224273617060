import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderQuestion,
  setLoanAmount,
  setLTV,
  setShowNext,
  setSendData,
} from "../onboardingSlice";
import Slider from "pages/OnBoarding/Components/Slider/Slider";

const Step8 = () => {
  const [isValid, setIsValid] = useState(true);
  const [customNotValidMessage, setCustomNotValidMessage] = useState(null);

  const dispatch = useDispatch();
  let { LoanAmount, LTV, PurchasePrice, EstimatedValue, TransactionType } = useSelector(
    (state) => state.onboarding.body.form,
  );

  const value = TransactionType === 1 ? PurchasePrice : EstimatedValue;
  const { postOnboarding } = useSelector((state) => state.onboarding.body);
  const question = "What is your preferred loan amount?";
  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);

  LoanAmount = LoanAmount === null ? null : Number(LoanAmount?.toString().replaceAll(",", ""));
  useEffect(() => {
    if (LoanAmount !== null) {
      if (
        !/^\d+$/.test(LoanAmount.toString().replaceAll(",", "")) ||
        Number(LoanAmount) < 250000 ||
        Number(LoanAmount) > value
      ) {
        if (Number(LoanAmount) > value && TransactionType === 1) {
          setCustomNotValidMessage("Loan amount cannot be more than the value of the property");
        } else if (Number(LoanAmount) > value && TransactionType === 2) {
          setCustomNotValidMessage("Loan amount cannot be more than the estimated value");
        }
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [LoanAmount, value, TransactionType]);
  useEffect(() => {
    if (LoanAmount !== null && isValid) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ LoanAmount, LTV }),
        }),
      );
    }

    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [LoanAmount, dispatch, LTV, postOnboarding, isValid]);

  useEffect(() => {
    if (value && Number(LoanAmount) && isValid) {
      const ltv = Math.ceil((Number(LoanAmount) / value) * 100);
      dispatch(setLTV(ltv));
    }
  }, [dispatch, value, LoanAmount, isValid]);

  const updateForm = useCallback(
    (val) => {
      dispatch(setLoanAmount(val));
    },
    [dispatch],
  );
  return (
    <Slider
      dollar={true}
      min={250000}
      max={value}
      interval={LoanAmount && LoanAmount < 1000000 ? 10000 : 25000}
      sliderVal={LoanAmount}
      updateForm={updateForm}
      ltv={LTV}
      isValid={isValid}
      customNotValidMessage={customNotValidMessage}
    />
  );
};

export default Step8;
