import React, { useState } from "react";

const Button = ({
  buttonText,
  buttonIcon,
  onClick,
  active = false,
  noStroke = false,
  autoFocus,
}) => {
  const [isActive, setIsActive] = useState(false);
  const handleMouseDown = () => {
    setIsActive(true);
    onClick && onClick();
  };
  const handleMouseUp = () => {
    setIsActive(false);
  };

  return (
    <button
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onTouchCancel={handleMouseUp}
      autoFocus={autoFocus}
      onClick={onClick}
      className={`onboardingButton ${active ? "active" : ""} ${isActive ? "active" : ""}  ${
        !noStroke ? "addStroke" : ""
      }`}
    >
      <span className="buttonText">{buttonText}</span>
      {buttonIcon}
    </button>
  );
};

export default Button;
