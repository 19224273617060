export const AppRoute = {
  ROOT: "/",
  OLD_LOGIN: "/old_login",
  LOGINSUCCESS: "/loginSuccess",
  REGISTER: "/register",
  GETRATES: "/get-rates",
  CREDIT_SCORE: "/credit-score",
  COMPANY: "/company",
  BROKERS: "/brokers",
  CONTACT: "/contact",
  RENTAL: "/rental",
  BRIDGE: "/bridge",
  CONSTRUCTION: "/construction",
  INVESTOR: "/investor",
  ONBOARDING: "/onboarding",
  ONBOARDINGFINISH: "/onboarding-finish",
  AGREEMENT: "/agreement",
  POLICY: "/policy",
  OLD_BORROWER: "/old_borrower",
  LOGIN: "/login",
  NEW_ACCOUNT: "/new-account",
  BORROWER: "/borrower",
  DOCUMET_MANAGEMENT: "/document-management",
  USER_MANAGEMENT: "/user-management",
  RESET_PASSWORD: "/reset-password",
  PLAYGROUND: "/playground",
  NEW_LOAN: "/new-loan",
};

export const UserRoles = {
  ADMIN: "Admin",
  BORROWER: "Borrower",
  CO_BORROWER: "CoBorrower",
  LENDER: "Lender",
  BROKER: "Broker",
  VIEWER: "Viewer",
  OTHER: "Other",
};
