import { getFormattedDate } from "../../../../utils/shared/helpers";
import DocumentOrFileStatusIcon from "../DocumentOrFileStatusIcon/DocumentOrFileStatusIcon";

const BorrowerLoanDocument = ({ status, name, fileCount, dateUpdated }) => {
  const [formattedDate, formattedTime] = getFormattedDate(dateUpdated);

  return (
    <>
      <div className="px-40 py-10 d-flex gap-8 align-items-center space-between">
        <div className="d-flex align-items-center gap-4 column-width">
          {fileCount > 0 ? (
            <>
              <DocumentOrFileStatusIcon status={status} />
              <span className="documentName mx-8">{name}</span>
              <div className="border-radius-4 nv-rentYear">
                <p className="label-2 color-gray-700 text-align-center font-indivisible">
                  {fileCount} file{fileCount > 1 && "s"}
                </p>
              </div>
            </>
          ) : (
            <>
              <DocumentOrFileStatusIcon status={status} />
              <span className="documentName mx-8">{name}</span>
            </>
          )}
        </div>
        <p className="updatedDate flex-basis-2">
          {formattedDate}, {formattedTime}
        </p>
      </div>
    </>
  );
};

export default BorrowerLoanDocument;
