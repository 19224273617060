import OutsideClickHandler from "react-outside-click-handler/esm/OutsideClickHandler";

export function Dropdown({children, menu, isVisible = false, handleClose}) {
    return (<OutsideClickHandler onOutsideClick={handleClose}>
            <div className={'position-relative nv-dropdown'}>
                {children}
                <div
                    className={`position-absolute background-color-white border-gray-200 border-radius-8 z-index-1 nv-dropdown__menu nv-dropdown__menu-${isVisible ? 'visible' : 'unvisible'}`}>{menu}</div>
            </div>
        </OutsideClickHandler>

    )
}