import { Link } from 'react-router-dom';
import { AppRoute } from 'utils/constants/ConstantsUtil';

import ReactFluidScroll from 'utils/shared/ReactFluidScroll';
import ConstructionImage from 'assets/images/constructionBG.jpg';
import { GetStartedBanner } from 'components/DisplayComponents';
import Footer from 'components/Footer';
import './styles.scss';

const Construction = () => {
  return (
    <ReactFluidScroll>
      <section className='construction_wrap program_wrap'>
        <div className='hero_section'>
          <div className='content_wrap'>
            <div className='text_wrap'>
              <h1 data-aos='fade-up' data-aos-delay='0' data-aos-duration='700'>
                Construction Financing
              </h1>
              <p data-aos='fade-up' data-aos-delay='0' data-aos-duration='800'>
                You have the blueprints, we have the capital to bring your idea to life. When working on construction financing, you need a lending partner who will dive deep into the nuts and bolts with you. At Battalion, we stand by your side from Day 1 to get your project funded from land acquisition to permanent financing.
              </p>
              <Link to={AppRoute.ONBOARDING}>
                <button
                  data-aos='fade-up'
                  data-aos-delay='0'
                  data-aos-duration='900'
                  className='button bv3 purple'>
                  Apply Now
                </button>
              </Link>
            </div>
          </div>
          <div className='hero_image sub_hero_image'>
            <img src={ConstructionImage} alt='hero' />
          </div>
        </div>
        <div className='details_section_wrap'>
          <div className='content_wrap'>
            <div
              className='detail_item_wrap'
              data-aos='fade-up'
              data-aos-delay='0'
              data-aos-duration='700'>
              <div className='title'>Ground-up Construction</div>
              <div className='description'>Residential 1-4 Units</div>
              <ul>
                <li>Loan Amount: $300,000 - $5,000,000</li>
                <li>Max Loan to Cost (Total): 80%</li>
                <li>Max Loan to ARV: 70%</li>
                <li>Term Length: Up to 18 Months</li>
              </ul>
            </div>
          </div>
        </div>
        <GetStartedBanner />
      </section>
      <Footer />
    </ReactFluidScroll>
  );
};

export default Construction;
