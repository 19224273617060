import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import { UserRoles } from "utils/constants/ConstantsUtil.js";

export const setTokens = (response) => {
  localStorage.setItem("AccessToken", response?.AccessToken);
  localStorage.setItem("ExpiresIn", response?.ExpiresIn);
  localStorage.setItem("RefreshToken", response?.RefreshToken);
  localStorage.setItem("Scope", response?.Scope);
  localStorage.setItem("TokenType", response?.TokenType);
};

export const converPhoneNumber = (input) => {
  const numericString = input.replace(/\D/g, "");

  if (numericString.length === 10) {
    return numericString;
  } else {
    return "Invalid phone number format";
  }
};

export const getFormattedPhoneNumber = (p) => {
  const temp = p;
  if (temp.replace(/\D/g, "").length === 10) {
    return p;
  }
  let reversed = p.split("").reverse().join("");

  let firstPart = reversed.substring(0, 10);

  let formatted = `${firstPart.substring(0, 4)}-${firstPart.substring(4, 7)} )${firstPart.substring(
    7,
    10,
  )}(`;

  let finalString = formatted.split("").reverse().join("");

  return finalString;
};

export const formatCurrency = (num) => {
  let str = num.toString();
  let groups = [];

  while (str.length > 3) {
    groups.unshift(str.slice(-3));
    str = str.slice(0, -3);
  }

  groups.unshift(str);
  return `$${groups.join(",")}`;
};

export const getFormattedDate = (d) => {
  const date = new Date(d);
  const options = { month: "short", day: "numeric", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  return [formattedDate, formattedTime];
};

export const isUserAdmin = () => {
  const { ADMIN } = UserRoles;
  const user = JSON.parse(localStorage.getItem("user"))?.accessType;
  return user === ADMIN;
};

export const arraysEqual = (arr1, arr2) =>
  arr1.length === arr2.length &&
  arr1.every((obj, index) => JSON.stringify(obj) === JSON.stringify(arr2[index]));

export const toUpperCamelCase = (str) => camelCase(str).replace(/^\w/, (c) => upperFirst(c));
