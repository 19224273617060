import React from 'react';

function ScoreRange({ value = null, min = 600, max = 850 }) {
  return (
    <input
      type="range"
      className="nv-scoreRange"
      value={value}
      min={min}
      max={max}
      readOnly
      disabled
      style={{ cursor: 'default' }}
    />
  );
}

export default ScoreRange;