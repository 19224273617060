import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../wrappers/NewVersion/components/Modal";
import { ReactComponent as LogoPurple } from "assets/svg/icons/logoPurple.svg";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import { ReactComponent as CloseIcon } from "assets/svg/icons/x-close.svg";

const PhoneModal = () => {
  const dispatch = useDispatch();
  const { phoneModal } = useSelector((state) => state.common);
  const phone = "(718) 208-2000";

  return (
    <Modal isOpen={phoneModal.isVisible} position={true}>
      <div className="modalContainerWrapper">
        <section className="modalContainer">
          <div className="modalContainerHeader">
            <LogoPurple />
            <p>Call us now</p>
            <div
              className="closeButton"
              onClick={() => dispatch(commonActions.togglePhoneModalVisibility())}
            >
              <CloseIcon />
            </div>
          </div>
          <p className="customMargin">{phone}</p>
          <div className="modalContainerAction">
            <a href={`tel:${phone}`}>Call Us</a>
          </div>
        </section>
      </div>
    </Modal>
  );
};

export default PhoneModal;
