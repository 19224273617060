import { commonActions } from "../../commonSlice";
import { Modal } from "../Modal";

import { ReactComponent as SaveIcon } from "../../../../assets/svg/icons/save-changes-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateLenderUser } from "../../../../pages/UserManagement/userSlice";

export function SaveChangesDialog() {
  const dispatch = useDispatch();
  const { saveChangesModal } = useSelector((state) => state.common);
  const { lenderUser } = useSelector(({ user }) => {
    return user;
  });

  const handleUpdateUser = () => {
    dispatch(commonActions.toggleSaveChangesModalVisibility());
    dispatch(commonActions.toggleProfileSettingsVisibility());
    dispatch(updateLenderUser(lenderUser));
  };

  return (
    <Modal
      icon={<SaveIcon />}
      isOpen={saveChangesModal.isVisible}
      handleClose={() => dispatch(commonActions.toggleSaveChangesModalVisibility())}
    >
      <div>
        <div className={"mb-20"}>
          <h3 className={"display-sm-bold text-align-center"}>Save Changes</h3>
          <p className={"text-sm-regular text-align-center"}>
            Are you sure you want to save profile changes?
          </p>
        </div>
        <div className={"d-flex gap-12"}>
          <button
            onClick={() => dispatch(commonActions.toggleSaveChangesModalVisibility())}
            className={
              "flex-1 text-sm-semi-bold background-color-white cursor-pointer border-gray-300 border-radius-4 nv-button"
            }
          >
            No
          </button>
          <button
            onClick={handleUpdateUser}
            className={
              "flex-1 text-sm-semi-bold cursor-pointer background-color-brand color-white border-radius-4 border-transparent nv-button"
            }
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
}
