import { Button as MuiButton } from "@mui/material";

export const DetailsButton = ({ endIcon = null, label, onClick = null, ...props }) => {
  return (
    <MuiButton
      variant="contained"
      endIcon={endIcon}
      style={{
        backgroundColor: " #FFF",
        border: "1px solid  #D6D6D6",
        padding: "0.5em 1.0em",
        borderRadius: "2em",
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        fontFamily: "Indivisible",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "0.875rem",
        color: "#424242",
        lineHeight: "1.25rem",
        letterSpacing: "-0.04rem",
        textTransform: "none",
      }}
      onClick={onClick}
      {...props}
    >
      {label}
    </MuiButton>
  );
};

export default DetailsButton;
