import DetailsButton from "../Button/DetailsButton";
import { ChevronRight } from "@mui/icons-material";

import "./styles.scss";
import { CollapsableSection } from "../CollapsableSection";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrency } from "../../../../utils/shared/helpers";
import { ReactComponent as BancIcon } from "../../../../assets/svg/icons/bank-32.svg";
import { ReactComponent as Calendar } from "../../../../assets/svg/icons/calendar-32.svg";
import { ReactComponent as BankNote } from "../../../../assets/svg/icons/bank-note-03-32.svg";
import { ReactComponent as Percent } from "../../../../assets/svg/icons/percent-02-32.svg";
import { ReactComponent as Calculator } from "../../../../assets/svg/icons/calculator-32.svg";
import { ReactComponent as CoinsHand } from "../../../../assets/svg/icons/coins-hand-32.svg";
import StatusLoanDropdown from "../StatusLoanDropdown";
import { commonActions } from "../../../../wrappers/NewVersion/commonSlice";
import LoansTransactionDetailDrawer from "../LoansTransactionDetailDrawer";
import StatusLoanBadge from "../StatusLoanBadge";
import { useLoanContext } from "pages/Borrower/LoanDetail";

export function TransactionInfo() {
  const { canEditStatus, canEditDetails } = useLoanContext();
  const dispatch = useDispatch();
  const {
    /* currentTab, */
    testLoanDetailData: { isLoading, data },
  } = useSelector(({ loanDetail }) => loanDetail);

  const handlePreviewDetails = () => {
    dispatch(commonActions.toggleLoanTransactionDetailsVisibility());
  };

  const {
    loanAmount,
    transactionTypeDisplayName,
    term,
    grossRent,
    loanToValueRatio,
    dscr,
    statusDisplayName,
    status,
  } = data || {};

  const detailData = [
    {
      icon: BancIcon,
      title: "Loan Purpose",
      value: transactionTypeDisplayName,
    },
    {
      icon: Calendar,
      title: "Term",
      value: term,
    },
    {
      icon: BankNote,
      title: "Loan Amount",
      value: loanAmount && formatCurrency(loanAmount),
    },
    {
      icon: Percent,
      title: "LTV",
      value: `${loanToValueRatio}%`,
    },
    {
      icon: Calculator,
      title: "DSCR",
      value: dscr,
    },
    {
      icon: CoinsHand,
      title: "Gross Rent",
      value: grossRent && formatCurrency(grossRent),
    },
  ];

  return (
    <>
      <div className="d-flex">
        <CollapsableSection
          header={<h4 className="font-indivisible">Transaction Info</h4>}
          badge={
            canEditStatus ? (
              <StatusLoanDropdown status={status} label={statusDisplayName} />
            ) : (
              <StatusLoanBadge status={statusDisplayName?.replace(" ", "")} />
            )
          }
          content={
            <>
              <div className="mb-24">
                {detailData?.map(({ icon, title, value }) => {
                  const Icon = icon;
                  return (
                    value && (
                      <div key={title} className="d-flex space-between align-items-center mb-16">
                        <div className="d-flex align-items-center gap-24">
                          <Icon />
                          <h3
                            className="font-inter"
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            {title}
                          </h3>
                        </div>
                        <p className="font-inter">{value}</p>
                      </div>
                    )
                  );
                })}
              </div>

              <DetailsButton
                onClick={handlePreviewDetails}
                label={"Details"}
                endIcon={<ChevronRight fontSize="20px" />}
              />
            </>
          }
        />
      </div>
      <LoansTransactionDetailDrawer />
    </>
  );
}
