import {useDispatch} from "react-redux";
import {useQuery} from "@tanstack/react-query";

import {getBorrower} from "./services";
import {borrowerActions} from "./borrowerSlice";

import {Header} from "../../wrappers/NewVersion/components/Header"
import {BorrowerBanner} from "../../wrappers/NewVersion/components/BorrowerBanner";
import {ContentWrapper} from "../../wrappers/NewVersion/components/ContentWrapper";
import {PropertiesSection} from "../../wrappers/NewVersion/components/PropertiesSection";

export function Borrower() {
    const dispatch = useDispatch();

    useQuery({
        queryKey: ['get_borrower'], queryFn: getBorrower, onSuccess(response) {
            dispatch(borrowerActions.setUser({user: response.data}))
        }
    });


    return (<>
        <div className="nv-borrower h-100">
            <Header/>
            <ContentWrapper>
                <BorrowerBanner/>
                <PropertiesSection/>
            </ContentWrapper>
        </div>
    </>)
}