import React from "react";
import CustomizedTooltip from "pages/Borrower/Components/Tooltip/Tooltip";
import { ReactComponent as CheckIcon } from "../../../assets/svg/icons/check-filled-white.svg";
import { ReactComponent as EmptyCircle } from "../../../assets/svg/icons/empty-circle.svg";

const PasswordValidations = ({ passwordIsFocus, validations }) => {
  return (
    <div className="tooltip">
      {passwordIsFocus && (
        <div className="tooltiptext">
          <div className="mb-1 flex gap-2">
            {validations.hasValidLength ? <CheckIcon /> : <EmptyCircle />}
            <p>Minimum 8 characters</p>
          </div>
          <div className="mb-1 flex gap-2">
            {validations.hasUppercase ? <CheckIcon /> : <EmptyCircle />}
            <p>At least 1 uppercase letter</p>
          </div>
          <div className="mb-1 flex gap-2">
            {validations.hasLowercase ? <CheckIcon /> : <EmptyCircle />}

            <p>At least 1 lowercase letter</p>
          </div>
          <div className="mb-1 flex gap-2">
            {validations.hasNumber ? <CheckIcon /> : <EmptyCircle />}
            <p>At least 1 number</p>
          </div>
          <div className="mb-1 flex gap-2">
            {validations.hasSpecialChar ? <CheckIcon /> : <EmptyCircle />}
            <p>At least 1 special character</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PasswordValidations;
