import { forwardRef, useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { createPortal } from "react-dom";
import Input from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import Select from "react-select";
import { Portal } from "../DisplayComponents";
import moment from "moment";

import CalendarIcon from "assets/images/icons/purpleCalendar.png";
import { ReactComponent as ArrowDown } from "assets/svg/icons/ArrowDown.svg";
import { ReactComponent as Edit } from "assets/svg/icons/edit.svg";
import { ReactComponent as Location } from "assets/svg/icons/locationIcon.svg";
import { ReactComponent as Save } from "assets/svg/icons/save.svg";
import { ReactComponent as SearchIcon } from "assets/svg/icons/Search.svg";
import { ReactComponent as Tick } from "assets/svg/icons/Tick.svg";

import cities from "json/cities.json";
import "./styles.scss";

const portalize = (isVisible, component, mountPoint) => {
  return (
    document.getElementById(mountPoint) &&
    isVisible &&
    createPortal(component, document.getElementById(mountPoint))
  );
};

const MainSearchBox = ({
  onChange,
  placeholder = "Brooklyn, New York",
  isNormalSize,
  showSelectedPeek,
  showIfError,
  isRequired,
}) => {
  const MAX_SUGGESTIONS = 5;
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isScrolledToTop, setScrollTop] = useState(window.scrollY <= 20);
  const [selectedCity, setSelectedCity] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const inputBoxElement = useRef(null);
  const [cordinates, setCordinates] = useState({ top: 0, left: 0 });

  const onTextEnterHandler = (e) => {
    setSelectedCity(e.target.value);
  };

  const onSuggestionClickHandler = (e) => {
    setSelectedCity(e.target.innerText);
    setShowSuggestions(false);
  };

  const updateCoordinates = () => {
    const { top, left, width } = inputBoxElement.current.getBoundingClientRect();
    const adjustedTop = top + inputBoxElement.current.clientHeight + 10;
    setCordinates({ top: adjustedTop, left, maxWidth: width });
  };

  const Suggestions = ({ showSuggestions, suggestions, onSuggestionClickHandler, cordinates }) => {
    return (
      <>
        {showSuggestions && suggestions.length > 0 && (
          <div
            className="suggestions_wrapper"
            style={{
              top: cordinates.top,
              left: cordinates.left,
              maxWidth: cordinates.maxWidth,
            }}
          >
            {suggestions.map((city) => (
              <div key={city.name} className="suggestion_item" onClick={onSuggestionClickHandler}>
                {city.name}, {city.country}
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    updateCoordinates();
  }, [showSuggestions]);

  useEffect(() => {
    if (selectedCity !== "") {
      const filteredCities = cities.filter((city) =>
        city.name.toLowerCase().includes(selectedCity.toLowerCase()),
      );
      setSuggestions(filteredCities.slice(0, MAX_SUGGESTIONS));
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
      setSuggestions([]);
    }

    onChange && onChange(selectedCity);
    // eslint-disable-next-line
  }, [selectedCity]);

  useEffect(() => {
    window.addEventListener("resize", updateCoordinates);
    document.addEventListener("scroll", () => {
      setScrollTop(window.scrollY <= 20);
    });

    return () => {
      window.removeEventListener("resize", updateCoordinates);
      document.removeEventListener("scroll", () => {
        setScrollTop(window.scrollY <= 20);
      });
    };
  }, []);

  return (
    <div
      className={`main_search_box ${isNormalSize ? "normal_size" : ""} ${
        showIfError && isRequired ? "is_input_error" : ""
      } ${isRequired ? "is_input_error" : ""}`}
    >
      <div className="input_boxer" ref={inputBoxElement}>
        <input
          type="text"
          onChange={onTextEnterHandler}
          placeholder={placeholder}
          value={selectedCity}
        />
        <SearchIcon />
      </div>
      {selectedCity && showSelectedPeek && (
        <div className="flex_box justify_start addr_icon_wrap">
          <Location />
          <div className="selected_address">{selectedCity}</div>
        </div>
      )}
      {isScrolledToTop && (
        <Portal className={"search_suggestion_wrapper"}>
          <Suggestions
            cordinates={cordinates}
            showSuggestions={showSuggestions}
            suggestions={suggestions}
            onSuggestionClickHandler={onSuggestionClickHandler}
          />
        </Portal>
      )}
    </div>
  );
};

const TextInput = forwardRef(
  (
    {
      label,
      onChange,
      placeholder,
      icon,
      type,
      autoComplete,
      value,
      onKeyDown,
      onKeyUp,

      unitPosition,
      className,
      disabled,
      defaultValue,
      showIfError,
      isRequired,
      validationPattern,
      errorMessage,
      min,
      max,
    },
    ref,
  ) => {
    validationPattern =
      type === "email"
        ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        : validationPattern;
    const [internalValue, setInternalValue] = useState(defaultValue || "");

    useEffect(() => {
      setInternalValue(defaultValue || "");
    }, [defaultValue]);

    const placeholderText = placeholder ? placeholder : "Enter " + label + "...";

    const isTextArea = type === "textarea";
    let isInvalid = false;
    let showErrorStatus = false;
    const inputProperties = {
      className: "input_entry",
      placeholder: placeholderText,
      type: type ?? "text",
      required: isRequired,
      onChange,
      min,
      max,
    };

    if (value) inputProperties.value = value;
    if (autoComplete) inputProperties.autoComplete = autoComplete;
    if (disabled) inputProperties.disabled = disabled;
    if (validationPattern) isInvalid = !validationPattern.test(internalValue);

    if (isRequired && !internalValue) {
      showErrorStatus = true;
    }
    if (validationPattern && isInvalid && internalValue) {
      showErrorStatus = true;
    }
    const changeHandler = (e) => {
      setInternalValue(e.currentTarget.value);
      if (onChange) onChange(e);
    };

    return (
      <div
        className={`input_box ${className ? className : ""}  ${
          showIfError && showErrorStatus ? " is_input_error " : ""
        } ${showErrorStatus ? " to_be_filled_form_element" : ""}`}
      >
        {label && <div className="label">{label}</div>}
        <div className={`input_row ${unitPosition ? "unit_position_" + unitPosition : ""}`}>
          {isTextArea ? (
            <textarea
              value={internalValue}
              name={label}
              id={label}
              cols="30"
              rows="10"
              {...inputProperties}
              onChange={changeHandler}
              ref={ref}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
            ></textarea>
          ) : (
            <>
              <input
                defaultValue={defaultValue === 0 ? null : defaultValue}
                {...inputProperties}
                value={value}
                // value={internalValue}
                onChange={changeHandler}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                ref={ref}
              />
            </>
          )}
        </div>

        {showIfError && showErrorStatus && (
          <div className="error_message">{errorMessage || "Please enter valid information"}</div>
        )}
        {icon && <img className="icon" src={icon} alt="i" />}
      </div>
    );
  },
);

const Dropdown = ({
  className,
  isSmall,
  options,
  onChange,
  label,
  placeholder,
  isMulti,
  icon,
  isSearchable,
  defaultValue,
  value,
  menuPlacement = "bottom",
  onSearchKeyChange,
  showIfError,
  isRequired,
  errorMessage,
}) => {
  const placeholderText = placeholder ? placeholder : "Select " + (label ? label : "");

  const MultiValueLabel = (props) => {
    return <div>{`${placeholderText} (${props.selectProps.value.length})`}</div>;
  };

  const dynamicProperties = {};

  if (value) dynamicProperties.value = value;
  if (defaultValue) dynamicProperties.defaultValue = defaultValue;

  return (
    <div
      className={`input_box dropdown ${className ? className : ""} ${
        showIfError && isRequired && !value ? "is_input_error" : ""
      } ${isRequired && !value ? "to_be_filled_form_element" : ""} ${
        isSmall ? "smaller_version" : ""
      } b-0`}
    >
      {label && <div className="label">{label}</div>}
      {icon && <div className="icon">{icon}</div>}
      <Select
        onInputChange={onSearchKeyChange}
        menuPlacement={menuPlacement}
        components={{ MultiValueLabel }}
        isSearchable={isSearchable}
        isMulti={isMulti}
        onChange={onChange}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#F4EBFF",
            primary: "#6941c6",
          },
        })}
        placeholder={placeholderText}
        options={options}
        {...dynamicProperties}
      />
      {showIfError && isRequired && !value && (
        <div className="error_message">{errorMessage || "Please select a value"}</div>
      )}
    </div>
  );
};

const DateInput = ({
  label,
  onChange,
  placeholder,
  value,
  isIconOnly,
  showIfError,
  isAdult,
  onlyFuture,
  isRequired,
}) => {
  const [openCalendar, setCalendarVisibility] = useState(false);
  const [selectedDate, setSelectedDate] = useState(value || new Date());
  const [dateLabel, setDateLabel] = useState("--");
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const calendarInputElement = useRef(null);
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  const placeholderText = placeholder ? placeholder : "Enter " + label + "...";

  const changeHandler = (res) => {
    setCalendarVisibility(false);
    setSelectedDate(new Date(res));
    setDateLabel(moment(res).format("DD-MM-YYYY"));
    onChange && onChange(res);
  };

  const outSideClickHandler = (e) => {
    if (!openCalendar) return false;
    const parent = e.target.parentNode;
    if (
      !e.target.classList.value.includes("react-calendar_") &&
      !parent.classList.value.includes("react-calendar_")
    ) {
      setCalendarVisibility(false);
    }
  };

  const onScrollCanceller = () => {
    setCalendarVisibility(false);
  };

  useEffect(() => {
    if (calendarInputElement.current) {
      let right = "auto";
      let bottomInside = "auto";
      let { left, top, width, height } = calendarInputElement.current.getBoundingClientRect();

      if (left <= 15) left = "30px";
      else if (left + width >= window.innerWidth - 280) {
        //if right goes beyond the view
        left = "auto";
        right = "30px";
      } else left += "px";

      top += height + 10;
      if (top + height >= window.innerHeight - 260) {
        //if bottom goes beyond the view
        top = "auto";
        bottomInside = "5px"; //bottom + height + 10 + 'px';
      } else top += "px";
      setPosition({ left, top, right, bottom: bottomInside });
    }
  }, [openCalendar]);

  useEffect(() => {
    window.addEventListener("click", outSideClickHandler);
    window.addEventListener("scroll", onScrollCanceller);
    return () => {
      window.removeEventListener("click", outSideClickHandler);
      window.addEventListener("scroll", onScrollCanceller);
    };
  });

  useEffect(() => {
    if (onlyFuture) {
      setMinDate(moment().add(1, "d")._d);
    } else if (isAdult) {
      setMaxDate(moment().subtract(21, "y")._d);
    }
  }, [onlyFuture, isAdult]);

  const calculateDateValue = () => {
    if (
      moment(new Date(selectedDate)).format("MM/DD/YYYY") ===
      moment(new Date()).format("MM/DD/YYYY")
    ) {
      return null;
    }
    return moment(new Date(selectedDate)).format("MM/DD/YYYY");
  };

  return (
    <div
      className={`input_box ${isIconOnly ? "icon_only_calendar" : ""} ${
        showIfError && isRequired && !value ? "is_input_error" : ""
      } ${isRequired && !value ? "to_be_filled_form_element" : ""}
      `}
      ref={calendarInputElement}
    >
      {isIconOnly ? (
        <div className="icon_only_default_wrap" onClick={() => setCalendarVisibility(true)}>
          <div className="label">{dateLabel}</div>
          <img className="icon" src={CalendarIcon} alt="i" />
        </div>
      ) : (
        <>
          <div className="label">{label}</div>
          <input
            className="input_entry date_formatted"
            placeholder={placeholderText}
            type="text"
            readOnly={true}
            value={calculateDateValue()}
            onChange={() => {}}
            onClick={() => setCalendarVisibility(true)}
            style={{ paddingLeft: "40px" }}
          />
          <img className="icon" src={CalendarIcon} alt="i" />
        </>
      )}
      {portalize(
        openCalendar,
        <div
          className="calendar_wrapped"
          style={{
            right: position.right,
            left: position.left,
            bottom: position.bottom,
            top: position.top,
          }}
        >
          <Calendar
            onChange={changeHandler}
            value={selectedDate}
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>,
        "calendar_base",
      )}
      {showIfError && isRequired && !value && (
        <div className="error_message">{"Please enter valid information"}</div>
      )}
    </div>
  );
};

const RadioButton = ({
  options,
  id,
  onChange,
  selectedItem,
  name,
  isDisabled,
  isUnavailable,
  showIfError,
  isRequired,
  errorMessage,
}) => {
  const [thisSelectedItem, setThisSelectedItem] = useState("");

  useEffect(() => {
    if (isUnavailable) setThisSelectedItem(false);
    else setThisSelectedItem(selectedItem);
  }, [selectedItem, isUnavailable]);

  const onChangeHandler = (e) => {
    setThisSelectedItem(e.target.value);
    onChange && onChange(e);
  };

  return (
    <div
      className={`radio_box ${isDisabled ? "disabled" : ""} ${
        showIfError && isRequired && !selectedItem ? "is_input_error" : ""
      } ${isRequired && !selectedItem ? "to_be_filled_form_element" : ""} ${
        isUnavailable ? "unavailable" : ""
      }`}
    >
      <div className="radio_items_wrap">
        {options.map((item, index) => {
          const attributes = {};
          thisSelectedItem &&
            (attributes.checked =
              id + thisSelectedItem.split(" ").join("-") + index ===
              id + item.split(" ").join("-") + index);
          return (
            <div className="radio_item" key={index}>
              <input
                type="radio"
                name={name ? name : "check"}
                id={id + item.split(" ").join("-") + index}
                onChange={onChangeHandler}
                value={item}
                {...attributes}
              />
              <label htmlFor={id + item.split(" ").join("-") + index}>
                <span>{item}</span>
              </label>
            </div>
          );
        })}
      </div>
      {showIfError && isRequired && !selectedItem && (
        <div className="error_message">{errorMessage || "Required"}</div>
      )}
    </div>
  );
};

const CheckBox = ({
  label,
  onChange,
  isChecked,
  isDisabled,
  name,
  defaultChecked,
  neutralChecked,
  value,
  showIfError,
  isRequired,
}) => {
  const properties = {};
  isChecked && (properties.checked = isChecked);
  value && (properties.value = value);
  defaultChecked && (properties.defaultChecked = defaultChecked);

  return (
    <div
      className={`check_box  ${showIfError && isRequired && !value ? "is_input_error" : ""}  ${
        isRequired && !value ? "to_be_filled_form_element" : ""
      }`}
    >
      <input
        type="checkbox"
        name={name ? name : "check"}
        id={label + name}
        disabled={isDisabled}
        onChange={onChange}
        {...properties}
        indeterminate={neutralChecked}
      />
      <label htmlFor={label + name} className="checkbox_dupl">
        <Tick />
      </label>

      <label htmlFor={label + name} className="label_checkbox">
        {label}
      </label>
    </div>
  );
};

const Switch = ({ label, onChange, isChecked, isDisabled, name, showIfError, isRequired }) => {
  return (
    <div
      className={`switch_box  ${showIfError && isRequired ? "is_input_error" : ""}  ${
        isRequired ? "to_be_filled_form_element" : ""
      }`}
    >
      <label htmlFor={label + name}>{label}</label>
      <input
        type="checkbox"
        name={name ? name : "check"}
        id={label + name}
        disabled={isDisabled}
        onChange={onChange}
        checked={isChecked}
      />
      <label htmlFor={label + name} className="switch_show">
        <div className="circle"></div>
      </label>
    </div>
  );
};

const PhoneNumber = ({
  label,
  onChange,

  value,
  showIfError,
  isRequired,
  errorMessage,
}) => {
  const onChangeHandler = (val) => {
    onChange && onChange(val);
  };

  return (
    <div
      className={`input_box phone_no  ${
        showIfError && isRequired && !value ? "is_input_error" : ""
      } ${isRequired && !value ? "to_be_filled_form_element" : ""}`}
    >
      <div className="label">{label}</div>
      <Input
        country="US"
        placeholder="Only US phones"
        className="input_entry"
        withCountryCallingCode={true}
        value={value}
        onChange={onChangeHandler}
      />
      {showIfError && isRequired && !value && (
        <div className="error_message">{errorMessage || "Required"}</div>
      )}
    </div>
  );
};

const ItemSelectorOnly = ({
  text,
  options,
  onSelect,
  icon,
  isSquareButton,
  isComponentSelection,
  className,
  noShadow,
  showIfError,
  isRequired,
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div
      className={`item_selector ${noShadow ? "fully_transparent" : ""}  ${
        showIfError && isRequired ? "is_input_error" : ""
      } ${isRequired ? "to_be_filled_form_element" : ""}
      ${isComponentSelection ? "is_transparent" : ""} ${className ? className : ""}`}
    >
      <div
        className={`label ${isOpen ? "focused" : ""} ${isSquareButton ? "square" : ""}`}
        onClick={() => setOpen(!isOpen)}
      >
        {!isComponentSelection && (icon ? icon : <ArrowDown className="default_icon" />)}
        {text}
      </div>
      {isOpen && options && options.length > 0 && (
        <div className="values_wrap small_scroll">
          {options.map((item, index) => (
            <div
              className="value_item"
              key={index}
              onClick={() => {
                onSelect && onSelect(item);
                setOpen(false);
              }}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const MoneyFormattedInput = ({
  value,
  onChange,
  defaultValue,
  inputRef,
  className,
  disabled,
  showIfError,
  isRequired,
  placeholder,
  errorMessage,
}) => {
  const [innerValue, setInnerValue] = useState(defaultValue);

  useEffect(() => {
    if (!isNaN(defaultValue)) setInnerValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (!isNaN(value)) {
      setInnerValue(value);
    }
  }, [value]);

  const getClearedValue = (value) => {
    return typeof value === "number" ? value : value.replace(/[^\d.]/g, "");
  };

  const getFormated = (value) => {
    if (!value) return "";
    if (value === "") return "";
    value = getClearedValue(value);

    let floatValue = "";
    if (typeof value !== "number") {
      //clear more than one dot and use the first occurance
      const [first, ...rest] = value.split(".");
      value = rest.length > 0 ? first + "." + rest.join("") : first;

      if (isNaN(value)) return "";

      floatValue = value.indexOf(".") > -1 ? "." + value.split(".")[1] : "";
      value = parseFloat(value.split(".")[0]).toFixed(2);
    }

    return (
      Number(value).toLocaleString("en", {
        minimumFractionDigits: 0,
      }) + floatValue
    );
  };

  // const onChangeHandler = (e) => {
  //   setInnerValue(e.currentTarget.value);
  //   onChange &&
  //     onChange(
  //       // getFormated(e.target.value),
  //       e.currentTarget.value.replace(/[^0-9.]/g, "")
  //     );
  // };

  const onChangeHandler = (e) => {
    const { target } = e;
    let targetValue = target.value.replace("$", "");
    if (!isNaN(targetValue[0]) || targetValue === "") {
      const valueToBeAdded = targetValue.replace(/[^\d,]+/g, "");
      setInnerValue(valueToBeAdded);
      onChange &&
        onChange(
          // getFormated(e.target.value),
          e.currentTarget.value.replace(/[^0-9.]/g, "").replace("$", ""),
        );
    }
  };

  const hasValue = getFormated(innerValue)?.length > 0;

  return (
    <>
      <div className="input_boxer">
        {/*<PriceIcon className={`${hasValue ? 'visible' : 'unvisible'}`} />*/}
        <input
          className={
            "money_formatted_input_basic " +
            className +
            (showIfError && isRequired && !getFormated(innerValue) ? " is_input_error" : "") +
            (isRequired && !getFormated(innerValue) ? " to_be_filled_form_element" : "")
          }
          style={{ paddingLeft: "10px" }}
          value={hasValue ? "$" + getFormated(innerValue) : ""}
          onChange={onChangeHandler}
          placeholder={placeholder}
          disabled={disabled}
          ref={inputRef}
        />
      </div>
      {showIfError && isRequired && !getFormated(innerValue) && (
        <div className="error_message">{errorMessage || "Please enter valid information"}</div>
      )}
    </>
  );
};

const MoneyFormattedClosured = ({ value, onChange, defaultValue, showIfError, isRequired }) => {
  const [isEditOn, setEditOn] = useState(false);
  const [dealingValue, setDealingValue] = useState(parseInt(defaultValue) || 0);
  useEffect(() => {
    if (!isEditOn) onChange && onChange(parseInt((`${dealingValue}` || "").replace(/\D/g, "")));
    // eslint-disable-next-line
  }, [isEditOn]);
  return (
    <div
      className={`closure_input input_box  ${
        showIfError && isRequired && !value ? "is_input_error" : ""
      } ${isRequired && !value ? "to_be_filled_form_element" : ""}`}
    >
      <MoneyFormattedInput
        defaultValue={defaultValue}
        disabled={!isEditOn}
        onChange={(e) => setDealingValue(e.currentTarget.value)}
        value={value}
        className="input_entry"
      />
      <button onClick={() => setEditOn(!isEditOn)} className="small_text">
        {isEditOn ? (
          <>
            Save
            <Save />
          </>
        ) : (
          <>
            Edit <Edit />
          </>
        )}
      </button>
    </div>
  );
};

const ButtonGroupSelector = ({
  buttonsArray,
  onSelect,
  defaultSelected,
  colorSet,
  showIfError,
  isRequired,
  errorMessage,
}) => {
  const [selectedButton, setSelectedButton] = useState(defaultSelected);

  return (
    <div
      className={`input_box ${
        showIfError && isRequired && !selectedButton ? "is_input_error" : ""
      } ${isRequired && !selectedButton ? "to_be_filled_form_element" : ""}`}
    >
      <div className="button_group_selector">
        {buttonsArray?.map((item, index) => {
          return (
            <button
              className={`button ${selectedButton === item.label ? "selected" : ""}`}
              key={index}
              onClick={() => {
                setSelectedButton(item.label);
                onSelect && onSelect(item);
              }}
            >
              {colorSet && colorSet[index] && (
                <div className="color_dot" style={{ background: colorSet[index] }}></div>
              )}
              {item.label}
              {item.extraText && <span>{item.extraText}</span>}
            </button>
          );
        })}
      </div>
      {showIfError && isRequired && !selectedButton && (
        <div className="error_message">{errorMessage || "Please select a value"}</div>
      )}
    </div>
  );
};

export {
  MoneyFormattedInput,
  MoneyFormattedClosured,
  TextInput,
  Dropdown,
  DateInput,
  RadioButton,
  CheckBox,
  ButtonGroupSelector,
  PhoneNumber,
  ItemSelectorOnly,
  Switch,
  MainSearchBox,
};
