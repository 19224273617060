import React, { useState } from "react";
import { usePopper } from "react-popper";
import "./style.css";

const SearchDropDown = ({ searchFunc, index, onClick }) => {
  const [showPopper, setShowPopper] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchList, setSearchList] = useState([]);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const handleOnClick = (id, name) => {
    onClick(
      {
        id,
        name,
      },
      index
    );
    setShowPopper(false);
    setSearchText(name);
  };

  return (
    <>
      <input
        autoFocus
        autoComplete="off"
        className="categoryContainer-input"
        onChange={async (e) => {
          const val = e.target.value;
          setSearchText(val);
          await new Promise((res) => setTimeout(res, 100));
          const res = await searchFunc(val);
          setSearchList(res);
          val.length > 0 && setShowPopper(true);
        }}
        type="text"
        name="category"
        placeholder="Enter document name"
        value={searchText}
        maxLength={100}
        ref={setReferenceElement}
      />
      {showPopper && searchText.length > 0 && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="listwrapper"
        >
          <ul className="listcontainer">
            {searchList.map((option) => (
              <li
                key={option.id}
                className="listitem cursorPointer"
                onClick={() => {
                  handleOnClick(option.id, option.name);
                }}
              >
                {option.name}
              </li>
            ))}

            <li
              className="listitem cursorPointer"
              onClick={() => handleOnClick(null, searchText)}
            >
              {`Create New Document "${searchText}"`}
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default SearchDropDown;
