import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Skeleton from "@yisheng90/react-loading";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@mui/material/Box";
import { Collapse, IconButton } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  setSearchTextDocuments,
  nextPageCateDocuments,
  prevPageDocuments,
  setIsActiveDocuments,
  archiveDocumentsApi,
  unArchiveDocumentsApi,
  getDocumentsApi,
  getCategoryListApi,
  updateDocumentApi,
  craeteDocumentApi,
  setSortColumnDocuments,
  getDocumentSpecificListApi,
  setCreateDocumentError,
  setUpdateDocumentError,
  createSubDocumentApi,
  deleteSubDocumentApi,
} from "../../documentSlice";
import DeleteMessageModal from "pages/DocumentManagement/Tabs/Categories/DeleteMessageModal";
import Search from "../../../Borrower/Components/SearchBar/Search";
import Pagination from "../../../Borrower/Components/Pagination/Pagination";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { ReactComponent as Archieve } from "assets/svg/icons/archive.svg";
import { ReactComponent as EditIcon } from "assets/svg/icons/edit-001.svg";
import { ReactComponent as UnArchiveIcon } from "assets/svg/icons/archiveIcon.svg";
import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";

import IOSSwitch from "../../Components/Switch/Switch";
import ArchiveModal from "./ArchiveModal";
import { commonActions } from "../../../../wrappers/NewVersion/commonSlice";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as DeleteIcon } from "assets/svg/icons/trash-02.svg";
import DeleteModal from "../Categories/DeleteModal";

const DocumentsTab = () => {
  const dispatch = useDispatch();

  const newTableRef = useRef(null);

  const [categoryList, setCategoryList] = useState([]);
  const [documentSpecific, setDocumentSpecific] = useState([]);
  const [archive, setArchive] = useState(null);
  const [editId, setEditId] = useState(null);
  const [editText, setEditText] = useState("");
  const [editSwitch, setEditSwitch] = useState(false);
  const [editCategory, setEditCategory] = useState(null);
  const [editDocSpecific, setEditDocSpecific] = useState(2);
  const [newText, setNewText] = useState("");
  const [newSwitch, setNewSwitch] = useState(false);
  const [newCategory, setNewCategory] = useState(null);
  const [newDocSpecific, setNewDocSpecific] = useState(2);
  const [archiveModalIcon, setArchiveModalIcon] = useState(null);
  const [archiveModalHeader, setArchiveModalHeader] = useState(null);
  const [archiveModalMessage, setArchiveModalMessage] = useState(null);
  const [messageModalIcon, setMessageModalIcon] = useState(null);
  const [messageModalHeader, setMessageModalHeader] = useState(null);
  const [messageModalMessage, setMessageModalMessage] = useState(null);
  const [collapseDeleteId, setCollapseDeleteId] = useState(null);
  const [deleteModalHeader, setDeleteModalHeader] = useState(null);
  const [deleteModalMessage, setDeleteModalMessage] = useState(null);

  const { docsManagementDocuments } = useSelector((state) => state.common);

  const { isActive, documentsList, sortColumn, createDocument, updateDocument } = useSelector(
    ({ document }) => document.documents,
  );
  const { documents } = useSelector(({ document }) => document);

  const [createSubRow, setCreateSubRow] = useState(
    new Array(documentsList.data.data?.length).fill(false),
  );

  const [openCollapsable, setOpenCollapsable] = useState(
    new Array(documentsList.data.data?.length).fill(false),
  );

  const [subRowErrowMessage, setSubrowErrorMessages] = useState(
    new Array(documentsList.data.data?.length).fill(null),
  );

  const subRowNewReferences = new Array(documentsList.data.data?.length).fill(useRef(null));

  useEffect(() => {
    newTableRef.current?.focus();
  }, [docsManagementDocuments.isCreate]);

  useEffect(() => {
    const fetchCategoryList = async () => {
      const [res, res2] = await Promise.all([
        dispatch(getCategoryListApi()).unwrap(),
        dispatch(getDocumentSpecificListApi()).unwrap(),
      ]);

      setCategoryList(res);
      setDocumentSpecific(res2);
    };
    fetchCategoryList();

    return () => {
      dispatch(commonActions.toggleDocsManagementDocumentsFalse());
    };
  }, [dispatch]);

  const sortColName = useMemo(() => {
    return sortColumn.sortColumnName;
  }, [sortColumn.sortColumnName]);
  const sortColOrder = useMemo(() => {
    return sortColumn.sortColumnOrder;
  }, [sortColumn.sortColumnOrder]);

  const handleArrow = useCallback(
    (colName) => {
      return sortColName === colName && sortColOrder === "asc" ? (
        <ArrowUpwardIcon sx={{ fontSize: "16px", color: "#525252" }} />
      ) : sortColName === colName && sortColOrder === "desc" ? (
        <ArrowDownwardIcon sx={{ fontSize: "16px", color: "#525252" }} />
      ) : null;
    },
    [sortColName, sortColOrder],
  );

  const handleHeaderClick = (colName) => {
    if (colName === sortColName) {
      if (sortColOrder === "asc") {
        dispatch(
          setSortColumnDocuments({
            sortColumnName: colName,
            sortColumnOrder: "desc",
          }),
        );
      } else {
        dispatch(
          setSortColumnDocuments({
            sortColumnName: colName,
            sortColumnOrder: "asc",
          }),
        );
      }
    } else {
      dispatch(
        setSortColumnDocuments({
          sortColumnName: colName,
          sortColumnOrder: "asc",
        }),
      );
    }
  };

  const handleKeyDown = (event, type) => {
    if (event.key === "Enter") {
      if (type === "new") {
        newText !== "" && handleCreate();
      } else if (type === "edit") {
        editText !== "" && handleUpdate();
      }
    } else if (event.key === "Escape") {
      if (type === "new") {
        setNewText("");
        setNewSwitch(false);
        setNewDocSpecific(2);
        setNewCategory(null);
        dispatch(commonActions.toggleDocsManagementDocumentsFalse());
      } else if (type === "edit") {
        handleEditClose();
      }
    }
  };

  const handleShowEdit = (id, name, isLenderOnly, docSpecific, cat) => {
    dispatch(setUpdateDocumentError(null));

    setEditText(name);
    setEditSwitch(isLenderOnly);
    setEditDocSpecific(documentSpecific.find((item) => item.name === docSpecific)?.id);
    setEditCategory(categoryList.find((item) => item.name === cat)?.id);
    setEditId(id);
  };

  const handleShowEditSubDocument = (id, name, isLenderOnly, docSpecific, categoryId) => {
    setEditId(id);
    setEditText(name);
    setEditSwitch(isLenderOnly);
    setEditDocSpecific(docSpecific);
    setEditCategory(categoryId);
  };
  const handleEditClose = () => {
    setEditId(null);
  };
  const handleRadioButtonChange = (e) => {
    dispatch(setIsActiveDocuments(e.target.value));
  };
  const handleSearch = (val) => {
    dispatch(setSearchTextDocuments(val));
  };
  const handleNext = () => {
    dispatch(nextPageCateDocuments());
  };
  const handlePrev = () => {
    dispatch(prevPageDocuments());
  };
  const handleDocumentSpecificEdit = (val) => {
    setEditDocSpecific(val);
  };
  const handleCategoryEdit = (val) => {
    setEditCategory(val);
  };
  const handleDocumentSpecificNew = (val) => {
    setNewDocSpecific(val);
  };
  const handleCategoryNew = (val) => {
    setNewCategory(val);
  };
  const handleArchive = async () => {
    try {
      if (isActive === "true") {
        await dispatch(archiveDocumentsApi({ id: archive })).unwrap();
      } else {
        await dispatch(unArchiveDocumentsApi(archive)).unwrap();
      }
      dispatch(commonActions.toggleArchiveModalVisibility());
      dispatch(
        getDocumentsApi({
          isActive: documents.isActive,
          page: documents.currentPage,
          searchText: documents.searchText,
          sortColumnName: documents.sortColumn.sortColumnName,
          sortOrder: documents.sortColumn.sortColumnOrder,
        }),
      );
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">
            {isActive === "true" ? "Document Archived" : "Document Unarchived"}
          </span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleArchiveClick = (id) => {
    setArchive(id);
    if (isActive === "true") {
      setArchiveModalHeader("Archive Document");
      setArchiveModalMessage("Are you sure you want to archive document");
      setArchiveModalIcon("archive");
    } else {
      setArchiveModalHeader("Unarchive Document");
      setArchiveModalMessage("Are you sure you want to unarchive document");
      setArchiveModalIcon("unarchive");
    }
    dispatch(commonActions.toggleArchiveModalVisibility());
  };
  const handleUpdate = async () => {
    try {
      await dispatch(
        updateDocumentApi({
          id: editId,
          name: editText,
          isLenderOnly: editSwitch,
          documentSpecific: editDocSpecific,
          categoryId: editCategory,
        }),
      ).unwrap();
      await dispatch(
        getDocumentsApi({
          isActive: documents.isActive,
          page: documents.currentPage,
          searchText: documents.searchText,
          sortColumnName: documents.sortColumn.sortColumnName,
          sortOrder: documents.sortColumn.sortColumnOrder,
        }),
      ).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Document Updated"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
      setEditId(null);
      setEditText(null);
      setEditSwitch(false);
      setEditDocSpecific(2);
      setEditCategory(null);
    } catch (error) {
      setMessageModalIcon("edit");
      setMessageModalHeader("Can't Update Document");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
    }
  };
  const handleCreate = async () => {
    try {
      await dispatch(
        craeteDocumentApi({
          name: newText,
          isLenderOnly: newSwitch,
          documentSpecific: newDocSpecific,
          categoryId: newCategory,
        }),
      ).unwrap();
      await dispatch(
        getDocumentsApi({
          isActive: documents.isActive,
          page: documents.currentPage,
          searchText: documents.searchText,
          sortColumnName: documents.sortColumn.sortColumnName,
          sortOrder: documents.sortColumn.sortColumnOrder,
        }),
      ).unwrap();

      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Document Added"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
      dispatch(commonActions.toggleDocsManagementDocumentsFalse());
      setNewText("");
      setNewSwitch(false);
      setNewCategory(null);
      setNewDocSpecific(2);
    } catch (error) {
      setMessageModalIcon("new");
      setMessageModalHeader("Can't Add Document");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
      console.log(error);
    }
  };

  const handleOpenCollapse = (index) => {
    setOpenCollapsable((prev) => {
      prev[index] = !prev[index];
      return [...prev];
    });
  };

  const handleCollapseCreateClose = (index, l = 0) => {
    setCreateSubRow((prev) => {
      prev[index] = false;
      return [...prev];
    });

    if (l === 0) {
      setOpenCollapsable((prev) => {
        prev[index] = false;
        return [...prev];
      });
    }
  };

  const handlePlus = (index) => {
    setCreateSubRow((prev) => {
      prev[index] = true;
      return [...prev];
    });
    setOpenCollapsable((prev) => {
      prev[index] = true;
      return [...prev];
    });
  };

  const handleSubrowKeyDown = (event, index) => {
    if (event.key === "Enter") {
      handleSubrowCreate(index);
    } else if (event.key === "Escape") {
      handleCollapseCreateClose(index, documentsList.data.data[index].sudDocuments.length);
    }
  };

  const handleSubrowCreate = async (index) => {
    try {
      await dispatch(
        createSubDocumentApi({
          subDocumentName: newText,
          parentDocumentId: documentsList.data.data[index].id,
        }),
      ).unwrap();
      await dispatch(
        getDocumentsApi({
          isActive: documents.isActive,
          page: documents.currentPage,
          searchText: documents.searchText,
          sortColumnName: documents.sortColumn.sortColumnName,
          sortOrder: documents.sortColumn.sortColumnOrder,
        }),
      ).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Document added"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
      setCreateSubRow((prev) => {
        prev[index] = false;
        return [...prev];
      });
      setNewText("");
    } catch (error) {
      setMessageModalIcon("new");
      setMessageModalHeader("Can't Add Document");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
      setSubrowErrorMessages((prev) => {
        prev[index] = true;
        return [...prev];
      });
      console.log(error);
    }
  };

  const handleCollapseDelete = (id) => {
    setCollapseDeleteId(id);
    setDeleteModalHeader("Remove SubDocument");
    setDeleteModalMessage("Are you sure you want to remove this SubDocument?");
    dispatch(commonActions.toggleDeleteModalVisibility());
  };

  const handleDeleteYes = async () => {
    const id = collapseDeleteId;
    try {
      await dispatch(deleteSubDocumentApi(id)).unwrap();
      await dispatch(
        getDocumentsApi({
          isActive: documents.isActive,
          page: documents.currentPage,
          searchText: documents.searchText,
          sortColumnName: documents.sortColumn.sortColumnName,
          sortOrder: documents.sortColumn.sortColumnOrder,
        }),
      ).unwrap();
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"SubDocument Deleted"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="tabsContainer">
      <div className="tabsContainer-utilities">
        <div className="tabsContainer-utilities__left">
          <Search handleSearch={handleSearch} placeholder={"Search by document title"} />
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={isActive}
              onChange={handleRadioButtonChange}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value={true}
                control={
                  <Radio
                    size="small"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 16,
                      },
                      "&.Mui-checked": {
                        color: "#A459FF",
                      },
                    }}
                  />
                }
                label={<span className="radioLabel">{"Active Documents"}</span>}
              />
              <FormControlLabel
                value={false}
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 16,
                      },
                      "&.Mui-checked": {
                        color: "#A459FF",
                      },
                    }}
                  />
                }
                label={<span className="radioLabel">{"Archived Documents"}</span>}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <Pagination
          currentPage={documentsList.data?.pageIndex}
          totalPage={documentsList.data?.totalPages}
          handleNext={handleNext}
          handlePrev={handlePrev}
          isLoading={false}
        />
      </div>
      {documentsList.isLoading ? (
        <>
          <Skeleton height={44} />
          <Skeleton rows={5} height={60} />
        </>
      ) : (
        <div className="tabsContainer-table">
          <TableContainer component={Box}>
            <Table
              sx={{
                "&:last-child": {
                  border: "2px slid black",
                  borderRadius: "20px",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "56px" }} />
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => handleHeaderClick("name")}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        padding: " 13px 0px",
                        alignItems: "center",
                      }}
                    >
                      <p className="headerCell-text"> Document Title</p>
                      {handleArrow("name")}
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{ width: "140px", cursor: "pointer" }}
                    onClick={() => handleHeaderClick("isLenderOnly")}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        padding: " 13px 24px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p className="headerCell-text">For Lenders Only</p>
                      {handleArrow("isLenderOnly")}
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{ width: "190px", cursor: "pointer" }}
                    onClick={() => handleHeaderClick("documentSpecific")}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        padding: " 13px 24px",
                        alignItems: "center",
                      }}
                    >
                      <p className="headerCell-text">Borrower/Loan Specific</p>
                      {handleArrow("documentSpecific")}
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{ width: "190px", cursor: "pointer" }}
                    onClick={() => handleHeaderClick("category")}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        padding: " 13px 24px",
                        alignItems: "center",
                      }}
                    >
                      <p className="headerCell-text">Category</p>
                      {handleArrow("category")}
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: "116px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        padding: " 13px 24px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p className="headerCell-text">Actions</p>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {docsManagementDocuments.isCreate && (
                  <TableRow
                    style={{ backgroundColor: " #F5F5F5" }}
                    onKeyDown={(e) => handleKeyDown(e, "new")}
                  >
                    <TableCell />
                    <TableCell className={createDocument.error ? "redRowLeft" : ""}>
                      <Box sx={{ display: "flex", padding: "14px 0px" }}>
                        <input
                          className="categoryContainer-input"
                          onChange={(e) => {
                            if (createDocument.error) {
                              dispatch(setCreateDocumentError(null));
                            }
                            setNewText(e.target.value);
                          }}
                          type="text"
                          name="category"
                          placeholder="Enter category name"
                          value={newText}
                        />
                      </Box>
                    </TableCell>
                    <TableCell className={createDocument.error ? "redRowMiddle" : ""}>
                      <Box
                        sx={{
                          display: "flex",
                          padding: "14px 24px",
                          justifyContent: "center",
                        }}
                      >
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={newSwitch}
                          onChange={(e) => {
                            setNewSwitch(e.target.checked);
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell className={createDocument.error ? "redRowMiddle" : ""}>
                      <Box sx={{ display: "flex", padding: "14px 24px" }}>
                        <Dropdown
                          options={documentSpecific}
                          changeHandler={handleDocumentSpecificNew}
                          currentVal={newDocSpecific}
                          edit={true}
                          emptyState={false}
                        />
                      </Box>
                    </TableCell>
                    <TableCell className={createDocument.error ? "redRowMiddle" : ""}>
                      <Box sx={{ display: "flex", padding: "14px 24px" }}>
                        <Dropdown
                          options={categoryList}
                          changeHandler={handleCategoryNew}
                          edit={true}
                          currentVal={newCategory}
                        />
                      </Box>
                    </TableCell>
                    <TableCell className={createDocument.error ? "redRowRight" : ""}>
                      <Box
                        sx={{
                          boxSizing: "border-box",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          padding: "4px",
                          gap: "4px",
                          justifyContent: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            dispatch(commonActions.toggleDocsManagementDocumentsFalse());
                            setNewText("");
                            setNewSwitch(false);
                            setNewDocSpecific(2);
                            setNewCategory(null);
                            dispatch(setCreateDocumentError(null));
                          }}
                          ref={newTableRef}
                        >
                          <CloseIcon sx={{ fontSize: 20, color: "#525252" }} />
                        </IconButton>
                        <IconButton onClick={handleCreate} disabled={newText === ""}>
                          <CheckIcon sx={{ fontSize: 20, color: "#A459FF" }} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}

                {documentsList.data.data?.map((row, index) => {
                  const edit = row.id === editId;
                  const lastRow = index === documentsList.data.data.length - 1;
                  return (
                    <>
                      <TableRow
                        key={row.id}
                        style={edit ? { backgroundColor: " #F5F5F5" } : null}
                        onKeyDown={(e) => handleKeyDown(e, "edit")}
                      >
                        {row.subDocuments.length > 0 ? (
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleOpenCollapse(index)}
                                disabled={row.subDocuments.length === 0}
                              >
                                {openCollapsable[index] ? (
                                  <KeyboardArrowDownIcon />
                                ) : (
                                  <KeyboardArrowRightIcon />
                                )}
                              </IconButton>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell />
                        )}

                        <TableCell
                          className={`${
                            lastRow && edit && updateDocument.error ? "roundBorderLeft" : null
                          } ${edit && !lastRow && updateDocument.error ? "redRowLeft" : null} `}
                        >
                          <Box sx={{ display: "flex", padding: "14px 0px" }}>
                            {edit ? (
                              <input
                                className="categoryContainer-input"
                                onChange={(e) => {
                                  if (updateDocument.error) {
                                    dispatch(setUpdateDocumentError(null));
                                  }
                                  setEditText(e.target.value);
                                }}
                                type="text"
                                name="category"
                                placeholder="Enter category name"
                                value={editText}
                                autoFocus
                              />
                            ) : row.subDocuments.length ? (
                              <div>
                                <p className="documents--documentTitle">{row.name}</p>
                                <p className="subDocument-text">
                                  {row.subDocuments.length + " sub-documents"}
                                </p>
                              </div>
                            ) : (
                              <p className="documents--documentTitle">{row.name}</p>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell
                          className={`${
                            lastRow && edit && updateDocument.error ? "roundBorderMiddle" : null
                          } ${edit && !lastRow && updateDocument.error ? "redRowMiddle" : null} `}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              padding: "14px 24px",
                              justifyContent: "center",
                            }}
                          >
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={edit ? editSwitch : row.isLenderOnly}
                              disabled={!edit}
                              onChange={(e) => {
                                setEditSwitch(e.target.checked);
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          className={`${
                            lastRow && edit && updateDocument.error ? "roundBorderMiddle" : null
                          } ${edit && !lastRow && updateDocument.error ? "redRowMiddle" : null} `}
                        >
                          <Box sx={{ display: "flex", padding: "14px 24px" }}>
                            <Dropdown
                              options={documentSpecific}
                              changeHandler={handleDocumentSpecificEdit}
                              currentVal={
                                edit
                                  ? editDocSpecific
                                  : documentSpecific.find(
                                      (item) => item.name === row.documentSpecific,
                                    )?.id
                              }
                              edit={edit}
                              emptyState={false}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          className={`${
                            lastRow && edit && updateDocument.error ? "roundBorderMiddle" : null
                          } ${edit && !lastRow && updateDocument.error ? "redRowMiddle" : null} `}
                        >
                          <Box sx={{ display: "flex", padding: "14px 24px" }}>
                            <Dropdown
                              options={categoryList}
                              changeHandler={handleCategoryEdit}
                              edit={edit}
                              currentVal={
                                edit
                                  ? editCategory
                                  : categoryList.find((item) => item.name === row.category)?.id
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          className={`${
                            lastRow && edit && updateDocument.error ? "roundBorderRight" : null
                          } ${edit && !lastRow && updateDocument.error ? "redRowRight" : null} `}
                        >
                          <Box
                            sx={{
                              boxSizing: "border-box",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              padding: "4px",
                              gap: "4px",
                              justifyContent: "center",
                            }}
                          >
                            {isActive === "true" ? (
                              row.id === editId ? (
                                <>
                                  <IconButton onClick={handleEditClose}>
                                    <CloseIcon sx={{ fontSize: 20, color: "#525252" }} />
                                  </IconButton>
                                  <IconButton onClick={handleUpdate} disabled={editText === ""}>
                                    <CheckIcon sx={{ fontSize: 20, color: "#A459FF" }} />
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <IconButton onClick={() => handleArchiveClick(row.id)}>
                                    <Archieve />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      handleShowEdit(
                                        row.id,
                                        row.name,
                                        row.isLenderOnly,
                                        row.documentSpecific,
                                        row.category,
                                      )
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton onClick={() => handlePlus(index)}>
                                    <AddIcon sx={{ fontSize: 20, color: "#525252" }} />
                                  </IconButton>
                                </>
                              )
                            ) : (
                              <IconButton onClick={() => handleArchiveClick(row.id)}>
                                <UnArchiveIcon width={20} height={20} />
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            border: "0px solid red",
                          }}
                          colSpan={6}
                        >
                          <Collapse in={openCollapsable[index]} timeout={"auto"} unmountOnExit>
                            <Box>
                              <Table>
                                <TableBody>
                                  {createSubRow[index] && (
                                    <TableRow
                                      style={{ backgroundColor: "#FAFAFA" }}
                                      tabIndex={0}
                                      onKeyDown={(e) => handleSubrowKeyDown(e, index)}
                                    >
                                      <TableCell
                                        className={subRowErrowMessage[index] ? "redRowLeft" : ""}
                                      />

                                      <TableCell
                                        className={subRowErrowMessage[index] ? "redRowMiddle" : ""}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            paddingTop: "8px",
                                            paddingBottom: "8px",
                                            paddingLeft:
                                              createSubRow[index] &&
                                              row.subDocuments.length === 0 &&
                                              "52px",
                                          }}
                                        >
                                          <input
                                            className="categoryContainer-input"
                                            onChange={(e) => {
                                              if (createDocument.error) {
                                                dispatch(setCreateDocumentError(null));
                                              }
                                              setNewText(e.target.value);
                                            }}
                                            type="text"
                                            name="category"
                                            placeholder="Enter Subdocument name"
                                            value={newText}
                                          />

                                          {/* <div className="min-w-[481px]">
                                            <IOSSwitch
                                              sx={{ m: 1 }}
                                              checked={newSwitch}
                                              onChange={(e) => {
                                                setNewSwitch(e.target.checked);
                                              }}
                                            />
                                          </div> */}
                                        </Box>
                                      </TableCell>
                                      <TableCell
                                        className={subRowErrowMessage[index] ? "redRowMiddle" : ""}
                                      ></TableCell>
                                      <TableCell
                                        className={subRowErrowMessage[index] ? "redRowMiddle" : ""}
                                      />
                                      <TableCell
                                        style={{ width: "120px" }}
                                        className={subRowErrowMessage[index] ? "redRowRight" : ""}
                                      >
                                        <Box
                                          sx={{
                                            boxSizing: "border-box",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            paddingTop: "4px",
                                            paddingBottom: "4px",
                                            gap: "4px",
                                            paddingRight: "20px",
                                          }}
                                        >
                                          <>
                                            <IconButton
                                              onClick={() =>
                                                handleCollapseCreateClose(
                                                  index,
                                                  row.subDocuments.length,
                                                )
                                              }
                                            >
                                              <CloseIcon
                                                sx={{
                                                  fontSize: 20,
                                                  color: "#525252",
                                                }}
                                              />
                                            </IconButton>
                                            <IconButton
                                              onClick={() => handleSubrowCreate(index)}
                                              disabled={newText === ""}
                                            >
                                              <CheckIcon
                                                ref={subRowNewReferences[index]}
                                                sx={{
                                                  fontSize: 20,
                                                  color: "#A459FF",
                                                }}
                                              />
                                            </IconButton>
                                          </>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                  {row.subDocuments.map((subrow, indx) => {
                                    const edit = subrow.id === editId;
                                    return (
                                      <TableRow
                                        key={subrow.id}
                                        style={{ backgroundColor: "#FAFAFA" }}
                                      >
                                        <TableCell
                                          style={{
                                            width: "56px",
                                            height: "48px",
                                          }}
                                        />
                                        <TableCell>
                                          <div className="flex justify-between items-center">
                                            {edit ? (
                                              <input
                                                className="categoryContainer-input"
                                                onChange={(e) => {
                                                  if (updateDocument.error) {
                                                    dispatch(setUpdateDocumentError(null));
                                                  }
                                                  setEditText(e.target.value);
                                                }}
                                                type="text"
                                                name="category"
                                                placeholder="Enter category name"
                                                value={editText}
                                                autoFocus
                                              />
                                            ) : (
                                              <p className="subrow-documents">{subrow.name}</p>
                                            )}

                                            <div className="min-w-[481px]">
                                              <IOSSwitch
                                                sx={{ m: 1 }}
                                                checked={edit ? editSwitch : subrow.isLenderOnly}
                                                disabled={!edit}
                                                onChange={(e) => {
                                                  setEditSwitch(e.target.checked);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </TableCell>

                                        <TableCell
                                          className={createDocument.error ? "redRowMiddle" : ""}
                                        ></TableCell>

                                        <TableCell />
                                        <TableCell style={{ width: "40px" }}>
                                          <Box
                                            sx={{
                                              boxSizing: "border-box",
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              paddingRight: "8px",
                                            }}
                                          >
                                            {subrow.id === editId ? (
                                              <>
                                                <IconButton onClick={handleEditClose}>
                                                  <CloseIcon
                                                    sx={{ fontSize: 20, color: "#525252" }}
                                                  />
                                                </IconButton>
                                                <IconButton
                                                  onClick={handleUpdate}
                                                  disabled={editText === ""}
                                                >
                                                  <CheckIcon
                                                    sx={{ fontSize: 20, color: "#A459FF" }}
                                                  />
                                                </IconButton>
                                              </>
                                            ) : (
                                              <>
                                                <IconButton
                                                  onClick={() => handleCollapseDelete(subrow.id)}
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                                <IconButton
                                                  onClick={() =>
                                                    handleShowEditSubDocument(
                                                      subrow.id,
                                                      subrow.name,
                                                      subrow.isLenderOnly,
                                                      subrow.documentSpecificId,
                                                      subrow.categoryId,
                                                    )
                                                  }
                                                >
                                                  <EditIcon />
                                                </IconButton>
                                              </>
                                            )}
                                          </Box>
                                        </TableCell>
                                        {!createSubRow[index] && (
                                          <TableCell style={{ width: "40px" }} />
                                        )}
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      <ArchiveModal
        header={archiveModalHeader}
        message={archiveModalMessage}
        succesHandler={handleArchive}
        icon={archiveModalIcon}
      />

      <DeleteModal
        successHandler={handleDeleteYes}
        header={deleteModalHeader}
        message={deleteModalMessage}
      />
      <DeleteMessageModal
        message={messageModalMessage}
        icon={messageModalIcon}
        header={messageModalHeader}
      />
    </div>
  );
};

export default DocumentsTab;
