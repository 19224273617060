import { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from '../../components/Header';

import '../../index.scss';

export function OldVersion({ children }) {
    
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div id={'old-version'}>
            <Header />
            <main style={{ flex: 1 }}>
                {children}
            </main>
        </div>
    )
}