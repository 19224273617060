import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { ReactComponent as DownloadIcon } from "assets/svg/icons/download-20.svg";
import { ReactComponent as TrashIcon } from "assets/svg/icons/trash-02.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ChevronDownIcon } from "assets/svg/icons/chevron-down.svg";
import { ReactComponent as DocumentIcon } from "assets/svg/icons/file-05.svg";
import { ReactComponent as ListIcon } from "assets/svg/icons/list.svg";
import { ReactComponent as MailIcon } from "assets/svg/icons/mail-20.svg";
import DetailsButton from "../Button/DetailsButton";
import { ShareAccess } from "../ShareAccess";
import { TransactionInfo } from "../TransactionInfo";
import "./styles.scss";

import { RejectionReasonModal } from "pages/Borrower/Components/RejectionReasonModal";
import { useLoanContext } from "pages/Borrower/LoanDetail";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { commonActions } from "wrappers/NewVersion/commonSlice";
import DeleteMessageModal from "../../../DocumentManagement/Tabs/Categories/DeleteMessageModal";
import DeleteModal from "../../../DocumentManagement/Tabs/Categories/DeleteModal";
import ViewFileModal from "../../../DocumentManagement/Tabs/Documentss/ViewFileModal";
import { fileDownloader, fileRemover } from "../../actions";
import { usePopper } from "../../hooks/usePopper";
import {
  getLoanDocumentListApi,
  postTestLoanTemplateApi,
  setCurrentTab,
} from "../../loanDetailSlice";
import IconButton from "../Button/IconButton";
import DocumentList from "../DocumentList/DocumentList";
import Dropdown from "../Dropdown/Dropdown";
import CustomizedTooltip from "../Tooltip/Tooltip";

const MODAL_DELETE_FILE = "delete-file";
const MODAL_DELETE_FILES = "delete-files";
const MODAL_VIEW_FILES = "view-files";

const LoanInfo = () => {
  const dispatch = useDispatch();
  const { loanId } = useParams();
  const { canAddTemplate, canDocumentManager, canSendEmail, canBulkDelete, canBulkDownload } =
    useLoanContext();

  const [showPopper, handlePopper] = usePopper();
  const [messageModalHeader, setMessageModalHeader] = useState(null);
  const [messageModalMessage, setMessageModalMessage] = useState(null);
  const common = useSelector((state) => state.common);
  const handleChange = (e, newVal) => {
    dispatch(setCurrentTab(newVal));
  };
  const {
    currentTab,
    testLoanDocumentsData: {
      isLoading,
      data: { emptyState },
    },
  } = useSelector(({ loanDetail }) => loanDetail);

  const {
    testTemplatesData: { isLoading: isLoadingTemplates, data: templates },
  } = useSelector(({ template }) => template);

  const handleAddTemplate = (id) => {
    dispatch(postTestLoanTemplateApi({ templateId: id, loanId }));
    handlePopper();
  };

  const { loanDocumentSelectedFiles } = useSelector(({ loanDetail }) => loanDetail);

  const handleBulkDelete = () => {
    dispatch(commonActions.toggleDeleteFilesModalVisibility(MODAL_DELETE_FILES));
  };

  //Bulk
  const handleDeleteFilesModalYes = () => {
    const IS_BULK = true;

    try {
      dispatch(fileRemover(loanId, IS_BULK));
    } catch (error) {
      setMessageModalHeader("Can't delete Loan Document file");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
      console.log(error);
    }
  };

  //Single
  const handleDeleteFileModalYes = () => {
    try {
      dispatch(fileRemover(loanId));
    } catch (error) {
      setMessageModalHeader("Can't delete Loan Document file");
      setMessageModalMessage(error.message);
      dispatch(commonActions.toggleDeleteModalMessageVisibility());
      console.log(error);
    }
  };

  const handleDownloadAsZip = () => {
    const IS_BULK = true;

    loanDocumentSelectedFiles.length &&
      dispatch(
        fileDownloader(
          {
            fileName: "files",
          },
          IS_BULK,
        ),
      );
  };

  const tabPanelStyles = (tab) =>
    emptyState !== 0 && !isLoading
      ? {
          height: `${tab === currentTab ? "75%" : "auto"} !important`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }
      : { height: "auto !important" };

  return (
    <section className="flex-1 nv-propertiesSection w-100 h-100 pt-32 background-color-gray-50 pb-24">
      <div className="container d-flex h-100 gap-64 nv-borrower_bottom">
        <div className={"nv-propertiesSection__leftSide"}>
          <div className="w-100 h-100 font-indivisible border-radius-12 border-gray-200 background-color-white box-shadow-sm">
            <div className="py-24 d-flex align-items-center space-between w-100 px-40">
              <h3 className="font-indivisible documents-header">Documents</h3>
              <div className="d-flex align-items-center gap-12">
                {!!loanDocumentSelectedFiles.length ? (
                  <>
                    {canBulkDelete && (
                      <CustomizedTooltip title="Bulk Delete">
                        <div>
                          <IconButton onClick={handleBulkDelete} icon={<TrashIcon />} />
                        </div>
                      </CustomizedTooltip>
                    )}
                    {canBulkDownload && (
                      <DetailsButton
                        label="Download as ZIP"
                        endIcon={<DownloadIcon />}
                        onClick={handleDownloadAsZip}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {canSendEmail && (
                      <CustomizedTooltip title="Send email reminders to borrowers">
                        <div>
                          <IconButton icon={<MailIcon />} />
                        </div>
                      </CustomizedTooltip>
                    )}

                    {canDocumentManager && (
                      <DetailsButton
                        label={"Document Manager"}
                        startIcon={<DocumentIcon />}
                        onClick={async () => {
                          dispatch(commonActions.toggleDocumentDrawerVisibility());
                          await dispatch(getLoanDocumentListApi(loanId)).unwrap();
                        }}
                      />
                    )}

                    {canAddTemplate && (
                      <Dropdown
                        showPopper={showPopper}
                        handlePopper={handlePopper}
                        listContainer="templatelistcontainer"
                        maxVisibleItems={5}
                        listClassName="mt-6"
                        label={
                          <DetailsButton
                            label={"Add using Templates"}
                            startIcon={<ListIcon />}
                            endIcon={<ChevronDownIcon />}
                          />
                        }
                      >
                        {isLoadingTemplates ? (
                          <Skeleton
                            count={4}
                            className="listitem-template archiveColor cursorPointer"
                            height={30}
                            style={{ width: "180px", marginBottom: "6px" }}
                          />
                        ) : !!templates && !!templates.length ? (
                          templates?.map((template) => (
                            <li
                              onClick={() => handleAddTemplate(template.id)}
                              key={template.id}
                              className="listitem-template archiveColor cursorPointer"
                            >
                              {template.name}
                            </li>
                          ))
                        ) : null}
                      </Dropdown>
                    )}
                  </>
                )}
              </div>
            </div>
            <TabContext value={currentTab}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "#FFF",
                  paddingTop: "12px",
                  marginLeft: "40px",
                  marginRight: "40px",
                  marginBottom: "-1px",
                }}
              >
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Requires Attention" value="requiresAttention" />
                  {canDocumentManager && <Tab label="Show All Documents" value="all" />}
                </TabList>
              </Box>
              <TabPanel value="requiresAttention" sx={tabPanelStyles("requiresAttention")}>
                <DocumentList />
              </TabPanel>
              {canDocumentManager && (
                <TabPanel value="all" sx={tabPanelStyles("all")}>
                  <DocumentList />
                </TabPanel>
              )}
            </TabContext>
          </div>
        </div>
        <div className="nv-propertiesSection__rightSide pt-24">
          <TransactionInfo />
          <ShareAccess />
        </div>
      </div>
      <DeleteModal
        key={MODAL_DELETE_FILE}
        modalType={MODAL_DELETE_FILE}
        type="deleteFilesModal"
        action="toggleDeleteFilesModalVisibility"
        successHandler={handleDeleteFileModalYes}
        header="Delete File"
        message="Are you sure you want to delete this file?"
      />
      <DeleteModal
        key={MODAL_DELETE_FILES}
        modalType={MODAL_DELETE_FILES}
        type="deleteFilesModal"
        action="toggleDeleteFilesModalVisibility"
        successHandler={handleDeleteFilesModalYes}
        header="Delete Files"
        message="Are you sure you want to delete these files?"
      />

      {
        <ViewFileModal
          key={MODAL_VIEW_FILES}
          type="viewFilesModal"
          action="toggleViewFileModalVisibility"
        />
      }
      <DeleteMessageModal message={messageModalMessage} header={messageModalHeader} />
      <RejectionReasonModal />
    </section>
  );
};

export default LoanInfo;
