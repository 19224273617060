import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "../../commonSlice";
import { Drawer } from "../Drawer";
import { Avatar } from "@mui/material";
import { ImageUploadBox1 } from "../../../../pages/UserManagement/Components/ImageUploadBox1";
import {
  getCitizenshipStatuses,
  getEndUser,
  getEndUserProfile,
  getLenderUser,
  getLocationStatuses,
  getOwnershipStatuses,
  updateEndUserProfile,
  updateLenderUser,
} from "../../../../pages/UserManagement/userSlice";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { validationSchemaLenderUser } from "../../../../pages/UserManagement/constants";
import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import Input from "../../../../pages/UserManagement/Components/Input";
import { Button } from "../../../../pages/Borrower/Components/Button/Button";
import Skeleton from "react-loading-skeleton";
import DatePicker from "pages/UserManagement/Components/DatePicker";
import SearchInput from "pages/UserManagement/Components/SearchInput";
import ButtonGroup from "../ButtonGroup";
import { getAddress } from "pages/OnBoarding/onboardingSlice";
import dayjs from "dayjs";

export function ProfileSettingsDrawer({ user, setUser, accessType }) {
  const dispatch = useDispatch();
  const { profileSettings } = useSelector((state) => state.common);
  const [isLoading, setIsLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [citizenships, setCitizenships] = useState([]);
  const [locations, setLocations] = useState([]);
  const [ownerships, setOwnerships] = useState([]);
  const [file, setFile] = useState(new FormData());

  const getStatuses = async () => {
    const ownerships = await dispatch(getOwnershipStatuses()).unwrap();
    setOwnerships(ownerships);
    const locations = await dispatch(getLocationStatuses()).unwrap();
    setLocations(locations);
    const citizenships = await dispatch(getCitizenshipStatuses()).unwrap();
    setCitizenships(citizenships);
  };

  useEffect(() => {
    getStatuses();
  }, []);

  const { register, handleSubmit, formState, setValue, getValues, control, reset } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    criteriaMode: "all",
    shouldUnregister: false,
    resolver: yupResolver(validationSchemaLenderUser),
  });
  const { errors } = formState;

  const onSubmit = async (data) => {
    setIsLoading(true);
    const finalFormData = new FormData();
    finalFormData.append("id", user.id);
    finalFormData.append("firstName", data.firstName);
    finalFormData.append("lastName", data.lastName);
    finalFormData.append("phoneNumber", data.phone);
    finalFormData.append("email", data.email);
    finalFormData.append("avatar", file.get("avatar"));
    setPreviewImage(null);
    if (accessType !== "Admin" && accessType !== "Lender") {
      if (data.dateOfBirth) {
        const parsedDate = dayjs(data.dateOfBirth);
        finalFormData.append("dateOfBirth", parsedDate);
      }
      data.citizenship && finalFormData.append("citizenship", data.citizenship.id);
      data.currentAddress && finalFormData.append("currentAddress", data.currentAddress);
      data.locationStatus && finalFormData.append("locationStatus", data.locationStatus);
      data.previusAddress && finalFormData.append("previusAddress", data.previusAddress);
      data.ownOrRent && finalFormData.append("ownOrRent", data.ownOrRent);
      if (data.monthlyRent) {
        data.monthlyRent = data.monthlyRent.replace("$", "");
        data.monthlyRent = data.monthlyRent.replaceAll(",", "");
      }
      data.monthlyRent && finalFormData.append("monthlyRent", Number(data.monthlyRent));
      await dispatch(updateEndUserProfile(finalFormData)).unwrap();
      setUser(await dispatch(getEndUserProfile(user.id)).unwrap());
    } else {
      await dispatch(updateLenderUser(finalFormData)).unwrap();
      setUser(await dispatch(getLenderUser(user.id)).unwrap());
    }

    try {
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{"Profile Details Updated"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (user) {
      setValue("firstName", user?.firstName);
      setValue("lastName", user?.lastName);
      setValue("email", user?.email);
      setValue("avatar", user?.avatarUrl);
      user?.phoneNumber && setValue("phone", user?.phoneNumber, { shouldValidate: true });
      if (accessType !== "Admin" && accessType !== "Lender") {
        user?.dateOfBirth && setValue("dateOfBirth", dayjs(user?.dateOfBirth));
        user?.citizenship &&
          setValue("citizenship", {
            id:
              user?.citizenship === "USCitizen"
                ? 1
                : user?.citizenship === "PermanentResident"
                ? 2
                : 3,
            name:
              user?.citizenship === "USCitizen"
                ? "US citizen"
                : user?.citizenship === "PermanentResident"
                ? "Permanent resident"
                : "Non-permanent resident",
          });
        user?.currentAddress && setValue("currentAddress", user?.currentAddress);
        user?.locationStatus &&
          setValue("locationStatus", user?.locationStatus === "Plus2Years" ? 1 : 2);
        user?.previusAddress && setValue("previusAddress", user?.previusAddress);
        user?.ownOrRent &&
          setValue("ownOrRent", user?.ownOrRent === "Own" ? 1 : user?.ownOrRent === "Rent" ? 2 : 3);

        user?.monthlyRent && setValue("monthlyRent", `$${user?.monthlyRent?.toLocaleString()}`);
      }
      setIsLoading(false);
    }
  }, [user, dispatch, setValue]);

  const searchLocation = async (val) => {
    const res = await dispatch(getAddress(val)).unwrap();
    return res;
  };

  return (
    <Drawer
      size={"md"}
      isOpen={profileSettings.isVisible}
      header={<h4 className={"text-xl-bold"}>Edit Profile Details</h4>}
      handleClose={() => {
        dispatch(commonActions.toggleProfileSettingsVisibility()), setPreviewImage(null);
      }}
    >
      <div className={"d-flex flex-column h-100 nv-profileSettings"}>
        <div style={{ maxHeight: "92vh", overflowY: "auto" }} className={"p-24"}>
          {isLoading ? (
            <Grid container spacing={2} rowSpacing={2}>
              <Grid item xs={12}>
                <div className="flex flex-row gap-10">
                  <Skeleton circle width={64} height={64} />
                  <Skeleton height={90} width={"84%"} />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Skeleton className="width-100" height={60} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton className="width-100" height={60} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton className="width-100" height={60} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton className="width-100" height={60} />
              </Grid>

              {accessType !== "Admin" && accessType !== "Lender" && (
                <>
                  <Grid item xs={6}>
                    <Skeleton className="width-100" height={60} />
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton className="width-100" height={60} />
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton className="width-100" height={60} />
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton className="width-100" height={60} />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton className="width-100" height={60} />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton className="width-100" height={60} />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton className="width-100" height={60} />
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            <form className="overflow-y-visible" onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} rowSpacing={2}>
                <Grid item xs={12}>
                  <div className="d-flex flex-row gap-20">
                    <Avatar
                      src={previewImage !== null ? previewImage : user?.avatarUrl}
                      alt={user?.firstName}
                      className="big-avatar-text background-color-gray-100 cursor-pointer"
                      sx={{
                        width: 64,
                        height: 64,
                        marginRight: "-2px",
                      }}
                    >
                      {user?.firstName[0].toUpperCase()}
                      {user?.lastName[0].toUpperCase()}
                    </Avatar>
                    <ImageUploadBox1
                      className="flex-grow-1"
                      setPreviewUrl={setPreviewImage}
                      setFiles={(data) => setFile(data)}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Input
                    name="firstName"
                    type="text"
                    label="First Name"
                    register={register}
                    required={true}
                    errorMessage={errors?.firstName?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    name="lastName"
                    type="text"
                    label="Last Name"
                    register={register}
                    required={true}
                    errorMessage={errors?.lastName?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    name="email"
                    label="Email"
                    type={"email"}
                    register={register}
                    required={true}
                    disabled
                    errorMessage={errors?.email?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    name="phone"
                    label="Phone"
                    type={"phone"}
                    required={true}
                    register={register}
                    errorMessage={errors?.phone?.message}
                  />
                </Grid>
                {accessType !== "Admin" && accessType !== "Lender" && (
                  <>
                    <Grid item xs={6}>
                      <DatePicker
                        maxDate={dayjs().subtract(18, "year")}
                        name={"dateOfBirth"}
                        label={"Date of Birth"}
                        control={control}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <SearchInput
                        type={"select"}
                        label={"Citizenship"}
                        options={citizenships}
                        form={true}
                        control={control}
                        name="citizenship"
                        onClick={(val) => setValue("citizenship", val)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <SearchInput
                        type={"search"}
                        label={"Current Address"}
                        itsGoogle
                        control={control}
                        name="currentAddress"
                        searchFunc={searchLocation}
                        defaultValue={getValues().currentAddress ?? ""}
                        onClick={(val) => setValue("currentAddress", val)}
                        fullWidthList
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonGroup
                        options={locations}
                        name="locationStatus"
                        label="Time at Location"
                        control={control}
                        defaultValue={null}
                        setValue={setValue}
                      />
                    </Grid>
                    {getValues()?.locationStatus === 2 && (
                      <Grid item xs={12}>
                        <SearchInput
                          type={"search"}
                          label={"Previous Address"}
                          itsGoogle
                          control={control}
                          name="previusAddress"
                          searchFunc={searchLocation}
                          defaultValue={getValues().previusAddress ?? ""}
                          onClick={(val) => setValue("previusAddress", val)}
                          fullWidthList
                        />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <ButtonGroup
                        options={ownerships}
                        name="ownOrRent"
                        label="Own Or Rent"
                        control={control}
                        defaultValue={null}
                        setValue={setValue}
                      />
                    </Grid>

                    {getValues()?.ownOrRent === 2 && (
                      <Grid item xs={12}>
                        <Input
                          name="monthlyRent"
                          label="Montly Rent"
                          type={"currency"}
                          register={register}
                          required={false}
                          firstCurrencyValue={getValues()?.monthlyRent}
                          errorMessage={errors?.monthlyRent?.message}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </form>
          )}
        </div>
        <div
          className={
            "py-16 px-24 border-top-gray-200 d-flex gap-12 justify-content-end mt-auto nv-profileSettings__footer"
          }
        >
          <Button
            type="contained"
            backgroundColor="white"
            colorTextEnabled="#344054"
            borderColor="#d0d5dd"
            label={"Change Password"}
            disabled={!profileSettings.isVisible}
            onClick={() => dispatch(commonActions.toggleChangePasswordModalVisibility())}
          />
          <Button
            type="contained"
            label={"Save Changes"}
            disabled={!profileSettings.isVisible}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </Drawer>
  );
}
