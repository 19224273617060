import { useState, useEffect, useMemo, createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import { Header } from "wrappers/NewVersion/components/Header/index.jsx";

import { ReactComponent as ArrowLeft } from "assets/svg/icons/arrow-narrow-left.svg";
import "./styles.scss";
import {
  getTestLoanDetailApi,
  getTestLoanDocumentsApi,
  getTestLoanAccessControlApi,
  resetState,
  getAccessType,
} from "./loanDetailSlice";
import { getTestTemplatesApi } from "./templateSlice";

import { AppRoute } from "../../utils/constants/ConstantsUtil";
import { useParams } from "react-router-dom";
import LoanInfo from "./Components/LoanInfo/LoanInfo";
import DocumentDrawer from "./Components/DocumentDrawer/DocumentDrawer";
import { UserRoles } from "../../utils/constants/ConstantsUtil";

const LoanContext = createContext();

export const useLoanContext = () => {
  return useContext(LoanContext);
};

const LoanDetail = () => {
  const { borrowerId, loanId } = useParams();
  const dispatch = useDispatch();

  const [accessType, setAccesType] = useState(null);
  const {
    currentTab,
    testLoanDetailData: { data: loanDetail },
  } = useSelector(({ loanDetail }) => loanDetail);

  const handleNavigateBack = () => {
    dispatch(resetState());
  };
  const getAccess = async () => {
    const res = await dispatch(getAccessType(loanId)).unwrap();
    setAccesType(res?.name);
  };

  useEffect(() => {
    loanId && getAccess();
  }, [loanId]);

  useEffect(() => {
    const fetchData = async () => {
      Promise.all([
        dispatch(getTestLoanDetailApi(loanId)).unwrap(),
        dispatch(getTestLoanAccessControlApi(loanId)).unwrap(),
      ]);
    };
    const timeout = setTimeout(fetchData, 10);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, loanId]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getTestLoanDocumentsApi(loanId));
    };
    const timeout = setTimeout(fetchData, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, loanId, currentTab]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getTestTemplatesApi());
    };
    const timeout = setTimeout(fetchData, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, loanId, currentTab]);

  useEffect(() => {
    const parentElement = document.getElementById("div-routing");
    const hasBorrowerDetail = document.getElementById("loan-detail");

    if (parentElement && hasBorrowerDetail) {
      parentElement.classList.add("h-100");
    }
  }, []);

  const propertyAddressArray =
    typeof loanDetail?.propertyAddress === "string"
      ? loanDetail?.propertyAddress.split(",").map((item) => item.trim())
      : [];

  const canAddFile = useMemo(() => {
    const roles = Object.values(UserRoles);
    return roles.filter((item) => item !== UserRoles.VIEWER).includes(accessType);
  }, [accessType]);

  const canViewFile = useMemo(() => {
    const roles = Object.values(UserRoles);
    return roles.filter((item) => item !== UserRoles.VIEWER).includes(accessType);
  }, [accessType]);

  const canEditFile = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);

  const canAddTemplate = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);
  const canDocumentManager = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);
  const canSendEmail = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);

  const canMultiSelect = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);
  const canDelete = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);
  const canDownload = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);
  const canDownloadThreeDots = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);
  const canBulkDelete = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);
  const canBulkDownload = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);
  const canEditDetails = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);
  const canEditStatus = useMemo(() => {
    return [UserRoles.ADMIN, UserRoles.LENDER].includes(accessType);
  }, [accessType]);
  const canAddUserShareAccess = useMemo(() => {
    const roles = Object.values(UserRoles);
    return roles.filter((item) => item !== UserRoles.VIEWER).includes(accessType);
  }, [accessType]);
  const canRemoveUserShareAccess = useMemo(() => {
    const roles = Object.values(UserRoles);
    return roles.filter((item) => item !== UserRoles.VIEWER).includes(accessType);
  }, [accessType]);
  const canChangeRoleShareAccess = useMemo(() => {
    const roles = Object.values(UserRoles);
    return roles.filter((item) => item !== UserRoles.VIEWER).includes(accessType);
  }, [accessType]);

  return (
    <div id="loan-detail" className="h-100 d-flex flex-column loan-detail">
      <LoanContext.Provider
        value={{
          canAddFile,
          canViewFile,
          canEditFile,
          canAddTemplate,
          canDocumentManager,
          canSendEmail,
          canMultiSelect,
          canDelete,
          canDownload,
          canBulkDelete,
          canBulkDownload,
          canEditDetails,
          canEditStatus,
          canAddUserShareAccess,
          canRemoveUserShareAccess,
          canChangeRoleShareAccess,
          canDownloadThreeDots,
        }}
      >
        <div className="nv-borrower">
          <Header />
        </div>
        <section className="mainContainer pt-0 flex-1 gap-0">
          <div className="py-40 background-color-violet w-100 min-h-146 d-flex flex-column align-items-center justify-content-center">
            <div className="mainContainer__header_borrower_detail nv-borrower background-color-violet">
              <div className="mainContainer__header--top">
                <div className="h1__wrapper">
                  <Link to={AppRoute.BORROWER + `/${borrowerId}`} className="linkWithoutUnderline">
                    <ArrowLeft
                      fontSize={"20px"}
                      className="chevron__left text-white-important"
                      onClick={handleNavigateBack}
                    />
                  </Link>
                  <div className="text-white-important d-flex flex-column gap-4">
                    {loanDetail?.propertyAddress && (
                      <>
                        <span className="street">{propertyAddressArray[0]}</span>
                        <span className="state-and-pc">
                          {" "}
                          {propertyAddressArray[1]}, {propertyAddressArray[2]}{" "}
                          {propertyAddressArray[4]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LoanInfo />
        </section>
        <DocumentDrawer loanId={loanId} />
      </LoanContext.Provider>
    </div>
  );
};

export default LoanDetail;
