import axios from "axios";
import { DigitalLoanService } from "configs/axios";

const BASE_URL_IS = process.env.REACT_APP_BASE_URL_IS;
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const fetchIDPost = async (url, data) => {
  //to IDENTITY SERVER
  try {
    const response = await axios.post(BASE_URL_IS + url, data);
    // const response = await DigitalLoanService.post(BASE_URL_IS + url, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw error;
    }
  }
  // const response = await axios.post(BASE_URL_IS + url, data);
  // return response;
};
export const fetchPost = async (url, data, isFormData = false) => {
  try {
    const response = await axios.post(BASE_URL + url, data, {
      headers: {
        ...(isFormData && { "Content-Type": "multipart/form-data" }),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const fetchGet = async (url, config) => {
  try {
    const response = await axios.get(BASE_URL + url, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchPut = async (url, config) => {
  try {
    const response = await axios.put(BASE_URL + url, config);

    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const fetchDelete = async (url, config) => {
  try {
    const response = await axios.delete(BASE_URL + url, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const fetchPostTokenized = async (url, data, isMultipart = false) => {
  try {
    const headers = {};
    if (isMultipart) {
      const tokenType = localStorage.getItem("TokenType");
      const accessToken = localStorage.getItem("AccessToken");
      headers["Authorization"] = `${tokenType} ${accessToken}`;
      headers["Content-Type"] = "multipart/form-data";
    }

    const response = await DigitalLoanService.post(BASE_URL + url, data, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const fetchGetTokenized = async (url, config) => {
  try {
    const headers = {};
    const tokenType = localStorage.getItem("TokenType");
    const accessToken = localStorage.getItem("AccessToken");
    headers["Authorization"] = `${tokenType} ${accessToken}`;
    headers["Content-Type"] = "multipart/form-data";
    const response = await DigitalLoanService.get(BASE_URL + url, config, { headers });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const fetchPutTokenized = async (url, data, isMultipart = false) => {
  try {
    const headers = {};
    if (isMultipart) {
      const tokenType = localStorage.getItem("TokenType");
      const accessToken = localStorage.getItem("AccessToken");
      headers["Authorization"] = `${tokenType} ${accessToken}`;
      headers["Content-Type"] = "multipart/form-data";
    }
    const response = await DigitalLoanService.put(BASE_URL + url, data, {
      headers: headers,
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const fetchDeleteTokenized = async (url, config) => {
  try {
    const response = await DigitalLoanService.delete(BASE_URL + url, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      console.log(error);
      throw error;
    }
  }
};
