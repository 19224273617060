import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTestTemplates } from "api/template.api";

export const getTestTemplatesApi = createAsyncThunk(
  "templates/getTestTemplates",
  async (id) => {
    const res = await getTestTemplates(id);
    return res;
  }
);

const initialState = {
  testTemplatesData: {
    isLoading: true,
    error: null,
    data: {},
  },
};

const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTestTemplatesApi.pending, (state) => {
        state.testTemplatesData.isLoading = true;
        state.testTemplatesData.error = null;
      })
      .addCase(getTestTemplatesApi.fulfilled, (state, action) => {
        state.testTemplatesData.isLoading = false;
        state.testTemplatesData.data = action.payload;
      })
      .addCase(getTestTemplatesApi.rejected, (state, action) => {
        state.testTemplatesData.isLoading = false;
        state.testTemplatesData.error = action.error.message;
      });
  },
});
export default templateSlice.reducer;
