import React, { useState, useEffect, useRef } from "react";
import { usePopper } from "react-popper";
import Card from "./Card";
import "./style.css";

const BulkDropdown = ({ searchFunc, docList, onClick, onRemove }) => {
  const newTextInput = useRef(null);
  const [showPopper, setShowPopper] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  // const [docListSearchText, setDocListSearchText] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchList, setSearchList] = useState([]);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });
  // useEffect(() => {
  //   setDocListSearchText(docList?.map((doc) => doc.documentName + ","));
  // }, [docList]);

  const handleChange = async (e, index = null) => {
    const val = e.target.value;
    setSearchText(val);
    await new Promise((res) => setTimeout(res, 100));
    const res = await searchFunc(val);
    setSearchList(
      res.filter((item) => {
        return !docList.some((it) => {
          return it?.documentName === item?.name;
        });
      }),
    );
    val.length > 0 && setShowPopper(true);
  };

  const handleClick = (id, isNew, name, isLenderOnly, category) => {
    onClick({
      documentId: id,
      isNew: isNew,
      documentName: name,
      isLenderOnly,
      category,
    });
    setShowPopper(false);
    setSearchText("");
    newTextInput.current?.focus();
  };

  return (
    <div ref={setReferenceElement} className="w-100 h-100 d-flex" style={{ flexWrap: "wrap" }}>
      {docList?.map((doc, index) => (
        <Card
          text={doc.documentName}
          onClick={() => onRemove(index)}
          key={doc.documentName + index}
        />
      ))}
      <input
        style={{ width: "auto" }}
        ref={newTextInput}
        autoComplete="off"
        className="categoryContainer-input"
        onChange={async (e) => await handleChange(e)}
        type="text"
        name="category"
        placeholder={docList.length === 0 ? "Enter document name" : ""}
        value={searchText}
        maxLength={100}
      />
      {showPopper && searchText.length > 0 && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="listwrapper"
        >
          <ul className="listcontainer">
            {searchList.map((option) => (
              <li
                key={option.id}
                className="listitem cursorPointer"
                onClick={() =>
                  handleClick(option.id, false, option.name, option.isLenderOnly, option.category)
                }
              >
                {option.name}
              </li>
            ))}

            <li
              className="listitem cursorPointer"
              onClick={() => handleClick(null, true, searchText, false, null)}
            >
              {`Create New Document "${searchText}"`}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default BulkDropdown;
