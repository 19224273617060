import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@yisheng90/react-loading";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

import { validationSchemaProfileInfo } from "../constants";
import { ReactComponent as SuccessIcon } from "assets/svg/icons/check-verified-01.svg";
import { Button } from "pages/Borrower/Components/Button/Button";
import { ImageUploadBox1 } from "pages/UserManagement/Components/ImageUploadBox1";
import { Avatar } from "@mui/material";
import { Grid } from "@mui/material";
import Input from "./Input";
import {
  getLender,
  setCurrentUser,
  setCurrentUserId,
  getListOfLenders,
  createLender,
  updateLender,
  getLenderRoles,
} from "../userSlice";
import SearchInput from "./SearchInput";

const LenderProfileInfo = ({ id, previewImage, setPreviewImage }) => {
  const dispatch = useDispatch();
  const { lenders } = useSelector(({ user }) => {
    return user;
  });
  const isOpenDrawer = useSelector(({ common }) => common.lenderFormDrawer);
  const [lenderData, setLenderData] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(new FormData());

  const [roles, setRoles] = useState([]);

  const getRoles = async () => {
    const res = await dispatch(getLenderRoles()).unwrap();
    setRoles(res);
  };

  const { register, handleSubmit, formState, setValue, reset, control, getValues } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    criteriaMode: "all",
    resolver: yupResolver(validationSchemaProfileInfo),
  });
  const { isDirty, isValid, errors } = formState;
  const onSubmit = async (data) => {
    const finalFormData = new FormData();
    finalFormData.append("firstName", data.firstName);
    finalFormData.append("lastName", data.lastName);
    !id && finalFormData.append("password", data.password);
    !id && finalFormData.append("confirmPassword", data.confirmPassword);
    finalFormData.append("phoneNumber", data.phone);
    finalFormData.append("email", data.email);
    finalFormData.append("avatar", file.get("avatar"));
    finalFormData.append("role", data.role.id);
    setPreviewImage(null);
    setIsLoading(true);

    try {
      if (id) {
        finalFormData.append("id", id);
        await dispatch(updateLender(finalFormData)).unwrap();
        setIsUpdate(true);
      } else {
        const res = await dispatch(createLender(finalFormData)).unwrap();
        dispatch(setCurrentUser(res.id));
        dispatch(setCurrentUserId(res.userId));
      }
      toast(
        <div className="toastMessageContainer">
          <div className="succesIconBackground1">
            <SuccessIcon />
          </div>
          <span className="toastMessage-text">{id ? "Lender Updated" : "Lender Created"}</span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
      setIsLoading(false);

      await dispatch(
        getListOfLenders({
          isActive: lenders.isActive,
          searchText: lenders.searchText,
          page: lenders.currentPage,
          sortColumnName: lenders.sortColumn.sortColumnName,
          sortOrder: lenders.sortColumn.sortColumnOrder,
        }),
      ).unwrap();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getRoles();
  }, []);
  useEffect(() => {
    const fetchEndUser = async () => {
      if (isUpdate && isOpenDrawer && id) {
        setIsLoading(true);
        const res = await dispatch(getLender(id)).unwrap();
        setValue("firstName", res?.firstName);
        setValue("lastName", res?.lastName);
        setValue("email", res?.email);
        setValue("password", "12345");
        setValue("confirmPassword", "12345");
        res?.phoneNumber && setValue("phone", res?.phoneNumber);
        setValue("role", { id: res?.role, name: res?.roleDisplayName });
        setLenderData(res);
        setIsLoading(false);
        setIsUpdate(false);
      }
    };

    fetchEndUser();
  }, [isUpdate, id, dispatch, setValue, isOpenDrawer]);
  useEffect(() => {
    const fetchEndUser = async () => {
      if (id && isOpenDrawer) {
        setIsLoading(true);
        try {
          const res = await dispatch(getLender(id)).unwrap();
          setValue("firstName", res?.firstName);
          setValue("lastName", res?.lastName);
          setValue("email", res?.email);
          setValue("password", "123456");
          setValue("confirmPassword", "123456");
          res?.phoneNumber && setValue("phone", res?.phoneNumber);
          setValue("role", { id: res?.role, name: res?.roleDisplayName });

          setLenderData(res);
          setIsLoading(false);
        } catch (err) {
          console.log(err);
          setIsLoading(false);
        }
      }
    };

    fetchEndUser();
    return () => {
      reset({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
      });
    };
  }, [id, dispatch, setValue, reset, isOpenDrawer]);

  useEffect(() => {
    if (!isOpenDrawer.isVisible) {
      reset();
      setLenderData(null);
      setFile(new FormData());
    }
  }, [isOpenDrawer, reset, isLoading]);
  return (
    <div className="d-flex flex-column h-100 gap-16">
      <div style={{ maxHeight: "75vh", overflowY: "auto" }} className="p-24">
        {isLoading ? (
          <Grid container spacing={2} rowSpacing={2}>
            <Grid item xs={12}>
              <div className="d-flex flex-row  gap-20">
                <Skeleton circle width={64} />
                <Skeleton height={90} width={"84%"} />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="width-100" height={60} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton className="width-100" height={60} />
            </Grid>
          </Grid>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} rowSpacing={2}>
              <Grid item xs={12}>
                <div className="d-flex flex-row gap-20 ">
                  <Avatar
                    src={previewImage !== null ? previewImage : lenderData?.avatarUrl}
                    alt={lenderData?.firstName}
                    className="avatar-text background-color-gray-100 cursor-pointer fs-24"
                    sx={{
                      width: 64,
                      height: 64,
                      marginRight: "-2px",
                    }}
                  >
                    {lenderData?.firstName[0].toUpperCase()}
                    {lenderData?.lastName[0].toUpperCase()}
                  </Avatar>
                  <ImageUploadBox1
                    className="flex-grow-1"
                    setPreviewUrl={setPreviewImage}
                    setFiles={(data) => setFile(data)}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Input
                  name="firstName"
                  type="text"
                  label="First Name"
                  register={register}
                  required={true}
                  errorMessage={errors?.firstName?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name="lastName"
                  type="text"
                  label="Last Name"
                  register={register}
                  required={true}
                  errorMessage={errors?.lastName?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name="email"
                  label="Email"
                  type={"email"}
                  register={register}
                  required={true}
                  errorMessage={errors?.email?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name="phone"
                  label="Phone"
                  type={"phone"}
                  register={register}
                  errorMessage={errors?.phone?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name="password"
                  label="Password"
                  type={"password"}
                  register={register}
                  required={id ? false : true}
                  disabled={id ? true : false}
                  errorMessage={errors?.password?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name="confirmPassword"
                  label="Confirm Password"
                  type={"password"}
                  register={register}
                  required={id ? false : true}
                  disabled={id ? true : false}
                  errorMessage={errors?.confirmPassword?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <SearchInput
                  type={"select"}
                  options={roles}
                  form={true}
                  control={control}
                  name="role"
                  onClick={(val) => setValue("role", val)}
                  label={"Role"}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </div>
      <div
        className={
          "py-16 px-24 d-flex gap-12 justify-content-end mt-auto nv-profileSettings__footer"
        }
      >
        <Button
          type="contained"
          label={"Save"}
          onClick={handleSubmit(onSubmit)}
          disabled={id ? isLoading : !isDirty || !isValid || isLoading}
        />
      </div>
    </div>
  );
};

export default LenderProfileInfo;
