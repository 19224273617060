import React, { useMemo, memo } from "react";
import { IconButton } from "@mui/material";

import { ReactComponent as BackIcon } from "assets/svg/icons/skip-back.svg";
import { ReactComponent as ForwardIcon } from "assets/svg/icons/skip-forward.svg";

import "./styles.scss";

const Pagination = ({
  currentPage,
  totalPage,
  handleNext,
  handlePrev,
  isLoading,
  pageType = "Page",
}) => {
  const disabledLeft = useMemo(() => {
    return currentPage === 1 || isLoading;
  }, [currentPage, isLoading]);

  const disabledRight = useMemo(() => {
    return currentPage === totalPage || isLoading;
  }, [currentPage, totalPage, isLoading]);

  return (
    <div className="paginationContainer">
      <p className="paginationContainer-p">{`${pageType} ${currentPage || 0} of ${
        totalPage || 0
      }`}</p>
      <div className="paginationContainer-buttons">
        <IconButton
          onClick={() => {
            if (!disabledLeft) handlePrev();
          }}
          className={disabledLeft ? "disabled-arrow" : undefined}
          // disabled={disabledLeft}
        >
          <BackIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            if (!disabledRight) handleNext();
          }}
          className={disabledRight ? "disabled-arrow" : undefined}
          // disabled={disabledRight}
        >
          <ForwardIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default memo(Pagination);
