import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeaderQuestion,
  setShowNext,
  setOccupancyType,
  getOccupancyTypes,
  setSendData,
} from "../onboardingSlice";
import Button from "../Components/Button";
import { ReactComponent as BatteryFullIcon } from "assets/svg/icons/battery-full.svg";
import { ReactComponent as BatteryMidIcon } from "assets/svg/icons/battery-mid.svg";
import { ReactComponent as BatteryEmptyIcon } from "assets/svg/icons/battery-empty.svg";

const Step6 = () => {
  const dispatch = useDispatch();
  const { OccupancyType } = useSelector((state) => state.onboarding.body.form);
  const { postOnboarding, occupancyTypes } = useSelector((state) => state.onboarding.body);
  const question = "What will the property’s occupancy be at the time of closing?";
  useEffect(() => {
    dispatch(getOccupancyTypes());
  }, [dispatch]);
  useEffect(() => {
    dispatch(setHeaderQuestion(question));
  }, [question, dispatch]);

  const handleClick = (id) => {
    dispatch(setOccupancyType(id));
  };

  useEffect(() => {
    if (OccupancyType !== null) {
      dispatch(setShowNext(true));
      dispatch(
        setSendData({
          anonymusId: postOnboarding.data?.anonymusId,
          dataAsJson: JSON.stringify({ OccupancyType }),
        }),
      );
    }
    return () => {
      dispatch(setShowNext(false));
      dispatch(setSendData(null));
    };
  }, [OccupancyType, dispatch, postOnboarding]);
  return (
    <div className="buttonWrap">
      {occupancyTypes.data.map((type) => {
        return (
          <Button
            key={type?.id}
            buttonText={type.name}
            buttonIcon={
              type?.id === 1 ? (
                <BatteryFullIcon />
              ) : type?.id === 2 ? (
                <BatteryMidIcon />
              ) : type?.id === 3 ? (
                <BatteryEmptyIcon />
              ) : null
            }
            onClick={() => handleClick(type?.id)}
            active={OccupancyType === type?.id}
          />
        );
      })}
    </div>
  );
};

export default Step6;
