import axios from "axios";
import { removeAuthInfoFromStorage } from "wrappers/NewVersion/utils/helpers";

const IDENTITY_SERVER_URL = process.env.REACT_APP_BASE_URL_IS;
const DIGITAL_LOAN_SERVER_URL = process.env.REACT_APP_BASE_URL;

const IdentityService = axios.create({
  baseURL: `${IDENTITY_SERVER_URL}/User`,
  timeout: 10000,
});

const DigitalLoanService = axios.create({
  baseURL: `${DIGITAL_LOAN_SERVER_URL}/api`,
  timeout: 30 * 60 * 1000,
});

let isRefreshing = false;
let subscribers = [];

const refreshAccessToken = async () => {
  try {
    isRefreshing = true;
    const refreshToken = localStorage.getItem("RefreshToken");
    if (!refreshToken) {
      throw new Error("No refresh token available");
    }

    const refreshResponse = await IdentityService.post("/refresh-token", {
      clientId: "digitaloan-web",
      refreshToken: refreshToken,
    });

    localStorage.setItem("AccessToken", refreshResponse.data["AccessToken"]);
    localStorage.setItem("TokenType", refreshResponse.data["TokenType"]);
    localStorage.setItem("RefreshToken", refreshResponse.data["RefreshToken"]);
    onTokenRefreshed(refreshResponse.data["AccessToken"]);
  } catch (refreshError) {
    console.error("Token refresh failed:", refreshError);
    removeAuthInfoFromStorage();
  } finally {
    isRefreshing = false;
    subscribers = [];
  }
};

function onTokenRefreshed(accessToken) {
  subscribers.forEach((callback) => callback(accessToken));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

DigitalLoanService.interceptors.request.use(
  (config) => {
    const tokenType = localStorage.getItem("TokenType");
    const accessToken = localStorage.getItem("AccessToken");
    if (accessToken) {
      config.headers["Authorization"] = `${tokenType} ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

DigitalLoanService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    console.log(subscribers);

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (!isRefreshing) {
        return refreshAccessToken()
          .then((newAccessToken) => {
            originalRequest.headers["Authorization"] = `${localStorage.getItem(
              "TokenType",
            )} ${newAccessToken}`;
            return axios(originalRequest);
          })
          .catch((refreshError) => {
            return Promise.reject(refreshError);
          });
      }

      return new Promise((resolve) => {
        addSubscriber((accessToken) => {
          originalRequest.headers["Authorization"] = `${localStorage.getItem(
            "TokenType",
          )} ${accessToken}`;
          resolve(axios(originalRequest));
        });
      });
    }
    return Promise.reject(error);
  },
);

export { IdentityService, DigitalLoanService, refreshAccessToken };

// import axios from "axios";

// const IDENTITY_SERVER_URL = process.env.REACT_APP_BASE_URL_IS;
// const DIGITAL_LOAN_SERVER_URL = process.env.REACT_APP_BASE_URL;

// const defaultHeaders = {
//   Accept: "*/*",
//   "Access-Control-Allow-Origin": "*",
// };

// const IdentityService = axios.create({
//   baseURL: `${IDENTITY_SERVER_URL}/User`,
//   timeout: 3600,
// });

// const DigitalLoanService = axios.create({
//   baseURL: `${DIGITAL_LOAN_SERVER_URL}/api`,
//   timeout: 3600,
//   headers: defaultHeaders,
// });

// const refreshAccessToken = async () => {
//   try {
//     const refreshToken = localStorage.getItem("RefreshToken");
//     const accessToken = localStorage.getItem("AccessToken");
//     const headers = {
//       Authorization: `Bearer ${accessToken}`,
//     };

//     const refreshResponse = await IdentityService.post(
//       "/refresh-token",
//       {
//         clientId: "digitaloan-web",
//         refreshToken: refreshToken,
//       },
//       { headers: headers },
//     );

//     Object.keys(refreshResponse.data).forEach((key) => {
//       localStorage.setItem(key, refreshResponse.data[key]);
//     });
//   } catch (refreshError) {
//     console.error("Token refresh failed:", refreshError);
//     throw refreshError;
//   }
// };

// let isRefreshing = false;
// let refreshQueue = [];

// DigitalLoanService.interceptors.request.use(
//   async (config) => {
//     const tokenType = localStorage.getItem("TokenType");
//     const accessToken = localStorage.getItem("AccessToken");

//     if (config.headers["Content-Type"] === "multipart/form-data") {
//       config.headers["Authorization"] = `${tokenType} ${accessToken}`;
//     } else {
//       config.headers = {
//         ...config.headers,
//         ...defaultHeaders,
//         Authorization: `${tokenType} ${accessToken}`,
//         "Content-Type": "application/json",
//       };
//     }

//     return config;
//   },
//   (error) => Promise.reject(error),
// );

// DigitalLoanService.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;

//     if (error.response.status === 401 && !originalRequest._retry) {
//       if (isRefreshing) {
//         return new Promise((resolve, reject) => {
//           refreshQueue.push({ resolve, reject });
//         });
//       }

//       isRefreshing = true;
//       originalRequest._retry = true;

//       try {
//         await refreshAccessToken();
//         originalRequest.headers.Authorization = `${localStorage.getItem(
//           "TokenType",
//         )} ${localStorage.getItem("AccessToken")}`;
//         isRefreshing = false;
//         refreshQueue.forEach((cb) => cb.resolve(originalRequest));
//         refreshQueue = [];
//       } catch (refreshError) {
//         console.error("Token refresh failed:", refreshError);
//         return Promise.reject(refreshError);
//       }
//     }

//     return Promise.reject(error);
//   },
// );

// export { IdentityService, DigitalLoanService };
// export { refreshAccessToken };
