import React, { useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const Input = ({
  label,
  type,
  inputName,
  defaultSelectValue,
  options,
  register,
  required,
  disabled,
  errorMessage,
  onClick,
  placeholder = "",
  hasOptional = true,
}) => {
  const divRef = useRef();
  const [showPopper, setShowPopper] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchText, setSearchText] = useState(defaultSelectValue?.name);

  const handleOnClick = (id, name) => {
    onClick({
      id,
      name,
    });

    setShowPopper(false);
    setSearchText(name);
  };

  const handleFocus = () => {
    setIsFocused(true);
    divRef.current.focus();
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="inputContainer">
      <div className="inputContainer-labelRow">
        <label className={`inputLabel ${disabled ? "disabledInputFormLabel" : ""}`}>{label}</label>
        {!required && !disabled && hasOptional && (
          <span className="inputContainerOptional">optional</span>
        )}
      </div>

      <div
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={divRef}
        className={`input-wrapper justify-between ${isFocused ? "input-wrapper-focus" : ""}`}
      >
        <input
          type={type ? type : "number"}
          name={inputName}
          placeholder={placeholder}
          className={`outline-none ${disabled ? "disabledIputForm" : ""} ${
            errorMessage ? "inputErrorMode" : ""
          }`}
          {...(register && register(inputName))}
        />

        <input
          type="text"
          className="border-l pl-2 border-[#d6d6d6] max-w-[100px] cursor-pointer"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={() => setShowPopper(true)}
          value={searchText}
          readOnly={true}
        />

        {showPopper ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>

      <div className="flex -mt-1 w-full justify-end relative z-10">
        <div className="absolute">
          {showPopper && (
            <ClickAwayListener
              onClickAway={() => {
                setShowPopper(false);
              }}
            >
              <div className="listwrapper">
                <ul className={` ${"listcontainer searchDropdown-minWidth-InputDropdown"} `}>
                  <>
                    {options.map((option) => {
                      return (
                        <li
                          key={option.id}
                          className="listitem cursorPointer"
                          onClick={() => handleOnClick(option.id, option.name)}
                        >
                          {option.name}
                        </li>
                      );
                    })}
                  </>
                </ul>
              </div>
            </ClickAwayListener>
          )}
        </div>
      </div>

      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
    </div>
  );
};

export default Input;
