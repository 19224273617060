import React from "react";

import { ReactComponent as FileQuestionIcon } from "assets/svg/icons/file-question-02.svg";
import { ReactComponent as FileBlueIcon } from "assets/svg/icons/file-blue.svg";
import { ReactComponent as FileRedIcon } from "assets/svg/icons/file-red.svg";
import { ReactComponent as FileYellowIcon } from "assets/svg/icons/file-yellow.svg";
import { ReactComponent as FileGreenIcon } from "assets/svg/icons/file-green.svg";
import { ReactComponent as XCircleIcon } from "assets/svg/icons/x-circle-1.svg";
import { ReactComponent as ClockIcon } from "assets/svg/icons/clock.svg";
import { ReactComponent as MinusCircleIcon } from "assets/svg/icons/minus-circle.svg";
import { ReactComponent as CheckCircleIcon } from "assets/svg/icons/check-circle-1.svg";
import { ReactComponent as AlertCirleIcon } from "assets/svg/icons/alert-circle-red.svg";

import "./styles.scss";
import CustomizedTooltip from "pages/Borrower/Components/Tooltip/Tooltip";
import { useLoanContext } from "pages/Borrower/LoanDetail";

const DocumentOrFileBadge = ({
  isDocument = true,
  hasSubDocument = false,
  status,
  name,
  rejectionReason = false,
}) => {
  const { canEditFile } = useLoanContext();

  const background = {
    UploadedNeeded: "#F5F5F5",
    PendingReview: "#D1E9FF",
    Accepted: "#D0F0E5",
    Rejected: "#FEE4E2",
    InReview: "#FEF0C7",
  }[status];

  const icon = isDocument
    ? {
        UploadedNeeded: <FileQuestionIcon />,
        PendingReview: <ClockIcon />,
        Accepted: <CheckCircleIcon />,
        Rejected: <XCircleIcon />,
        InReview: <MinusCircleIcon />,
      }[status]
    : {
        PendingReview: <FileBlueIcon />,
        Accepted: <FileGreenIcon />,
        Rejected: <FileRedIcon />,
        InReview: <FileYellowIcon />,
      }[status];

  const statusForTooltip = {
    UploadedNeeded: "Upload Needed",
    PendingReview: "Pending Review",
    Accepted: "Accepted",
    Rejected: "Rejected",
    InReview: "In Review",
  }[status];

  return canEditFile && !hasSubDocument ? (
    <button
      type="button"
      className={`containerDocOrFileBadge ${
        canEditFile && !hasSubDocument && "containerDocOrFileBadge__border"
      }`}
    >
      <CustomizedTooltip title={statusForTooltip}>
        <div className="iconStatus" style={{ background: `${background}` }}>
          {icon}
        </div>
      </CustomizedTooltip>

      <div className={`${isDocument ? "documentTextBox" : "fileTextBox"}`}>{name}</div>
      {rejectionReason && (
        <div className="flex items-center">
          <div className="iconStatus">
            <AlertCirleIcon />
          </div>
          <CustomizedTooltip title={rejectionReason}>
            <span className="rejectionReasonText">{rejectionReason}</span>
          </CustomizedTooltip>
        </div>
      )}
    </button>
  ) : (
    <div
      className={`containerDocOrFileBadge ${
        canEditFile && !hasSubDocument && "containerDocOrFileBadge__border"
      }`}
    >
      <CustomizedTooltip title={statusForTooltip}>
        <div className="iconStatus" style={{ background: `${background}` }}>
          {icon}
        </div>
      </CustomizedTooltip>

      <div className={`${isDocument ? "documentTextBox" : "fileTextBox"}`}>{name}</div>
      {rejectionReason && (
        <div className="flex items-center">
          <div className="iconStatus">
            <AlertCirleIcon />
          </div>
          <CustomizedTooltip title={rejectionReason}>
            <span className="rejectionReasonText">{rejectionReason}</span>
          </CustomizedTooltip>
        </div>
      )}
    </div>
  );
};

export default DocumentOrFileBadge;
