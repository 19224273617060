import { useState, useRef, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { usePopper } from "react-popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Controller } from "react-hook-form";

import "./styles.scss";

const SearchInput = ({
  name,
  label,
  onClick,
  options,
  searchFunc,
  type,
  required = false,
  defaultValue = "",
  control,
  form,
  empty,
  itsGoogle = false,
  fullWidthList,
  errorMessage,
}) => {
  const [showPopper, setShowPopper] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchList, setSearchList] = useState([]);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  useEffect(() => {
    setSearchText(defaultValue);
  }, [defaultValue]);

  const handleOnClick = (id, name, email) => {
    onClick({
      id,
      name,
      email,
    });

    setShowPopper(false);
    setSearchText(email);
  };

  const handleOnClickGoogle = (option) => {
    onClick(option);

    setShowPopper(false);
    setSearchText(option);
  };

  const handleChange = async (e) => {
    const val = e.target.value;
    setSearchText(val);
    await new Promise((res) => setTimeout(res, 100));
    const res = await searchFunc(val);
    setSearchList(res);
    val.length > 0 && setShowPopper(true);
  };

  const handleFocus = () => {
    setIsFocused(true);
    inputRef.current.focus();
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className={`inputContainer`}>
      <div className="inputContainer-labelRow">
        <label htmlFor={name} className={"inputLabel"}>
          {label}
        </label>
        {!required && form && <span className="inputContainerOptional">optional</span>}
      </div>
      <div
        className={`input-wrapper ${isFocused ? "input-wrapper-focus" : ""}   ${
          errorMessage ? "inputErrorMode" : ""
        } `}
        tabIndex={0}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={setReferenceElement}
        onClick={() => {
          if (type === "select") {
            setShowPopper(true);
          }
        }}
      >
        {form ? (
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                onFocus={handleFocus}
                onBlur={handleBlur}
                ref={inputRef}
                onChange={(e) => handleChange(e)}
                readOnly={true}
                value={field.value ? field.value.name : ""}
              />
            )}
          />
        ) : (
          <input
            type="text"
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={inputRef}
            onChange={(e) => handleChange(e)}
            value={searchText}
            readOnly={type === "select" ? true : false}
          />
        )}
        {type === "select" && (showPopper ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </div>
      {errorMessage && <p className="errorMessage">{errorMessage}</p>}

      {showPopper && (empty || options?.length > 0 || searchList?.length > 0) && (
        <ClickAwayListener
          onClickAway={() => {
            type === "select" && setShowPopper(false);
          }}
        >
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="listwrapper"
          >
            <ul
              className={` ${
                fullWidthList
                  ? "listcontainer-full searchDropdown-minWidth-full"
                  : "listcontainer searchDropDown-minWidth"
              } `}
            >
              {type === "select" ? (
                <>
                  {options.map((option) => {
                    return (
                      <li
                        key={option.id}
                        className="listitem cursorPointer"
                        onClick={() => handleOnClick(option.id, option.name)}
                      >
                        {option.name}
                      </li>
                    );
                  })}
                  {empty}
                </>
              ) : itsGoogle ? (
                <>
                  {searchList?.map((option) => {
                    const splitAddress = option.split(", ");
                    return (
                      <li
                        key={option}
                        className="listitem-full cursorPointer"
                        onClick={() => handleOnClickGoogle(option)}
                      >
                        <div>
                          <p className="input-placement--main">{splitAddress[0]}</p>
                          <p className="input-placement--secondary">{`${splitAddress[1]}, ${
                            splitAddress[2] || ""
                          } ${splitAddress[4] || ""}`}</p>
                        </div>
                      </li>
                    );
                  })}
                </>
              ) : (
                <>
                  {searchList?.map((option) => {
                    return (
                      <li
                        key={option.userId}
                        className="listitem cursorPointer searchDropDown-listItem"
                        onClick={() => handleOnClick(option.userId, option.fullName, option.email)}
                      >
                        <div>
                          <p className="input-placement--main">{option.fullName}</p>
                          <p className="input-placement--secondary">{option.email}</p>
                        </div>
                      </li>
                    );
                  })}
                  {searchList?.length === 0 && (
                    <div
                      onClick={() => {
                        setShowPopper(false);
                      }}
                    >
                      {empty}
                    </div>
                  )}
                </>
              )}
            </ul>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};
export default SearchInput;
